import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router'

import paths from '../../../constants/paths'
import CompanyGroup from '../../../model/companyGroup'
import { CompanyReducer } from '../../../reducers/companies'
import { CompanyGroupReducer } from '../../../reducers/companyGroups'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { formatNumber } from '../../../utils/number-utils'
import { escapeRegExp } from '../../../utils/string-utils'
import { t } from '../../../utils/translation-utils'
import Table from '../../antd/table'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import ContextMenu from '../../elements/ContextMenu'
import Row from '../../elements/grid/row'
import Headline from '../../elements/Headline'
import Icon from '../../elements/Icon'
import Input from '../../elements/input'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import UserImage from '../../elements/UserImage'
import DumbLink from '../../widgets/DumbLink'
import jsBrowserHistory from '../../widgets/jsBrowserHistory'

type Props = {
  companies: CompanyReducer
  companyGroup?: CompanyGroup
  companyGroups: CompanyGroupReducer
  useCompanyGroup: boolean

  updateActiveCompany: (companyID: string) => void
  addCompanyToCompanyGroup: (groupID: string, companyID: string) => void
  removeCompanyFromCompanyGroup: (groupID: string, companyID: string) => void
}

export default function CompaniesOverview(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery))
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const [error, setError] = useState<Error | null>(null)
  const [addingGroupCompanies, setAddingGroupCompanies] = useState<string[]>([])
  const [removingGroupCompanies, setRemovingGroupCompanies] = useState<string[]>([])

  const { companyGroups } = props

  useEffect(() => {
    regularComponentDidUpdate(companyGroups.error, error, setError)
  }, [companyGroups, error])

  const handleCompanyClicked = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      props.updateActiveCompany(id)
      jsBrowserHistory.push('/')
    }
  }

  const handleAddToGroup = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (!props.companyGroup) {
        return
      }
      props.addCompanyToCompanyGroup(props.companyGroup.id, id)
      setAddingGroupCompanies((prev) => [...prev, id])
    }
  }
  const handleRemoveFromGroup = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (!props.companyGroup) {
        return
      }
      props.removeCompanyFromCompanyGroup(props.companyGroup.id, id)
      setRemovingGroupCompanies((prev) => [...prev, id])
    }
  }

  type CompanyRow = {
    key: string
    id: string
    name: string
    employeeCount: string
    userCount: string
    nationalID: string
    inGroup: boolean
  }

  const getColumns = () => {
    const columns: {
      title: string
      dataIndex: string
      key: string
      render?: (company: CompanyRow) => ReactNode
      className?: string
    }[] = [
      {
        title: t('companies.table.company'),
        dataIndex: '',
        key: 'xCompany',
        render: (company) => {
          return (
            <Headline>
              <UserImage company={company.name} size="small" />
              <DumbLink onClick={handleCompanyClicked(company.id)}>{company.name}</DumbLink>
            </Headline>
          )
        },
      },
      {
        title: t('companies.table.employees'),
        dataIndex: 'employeeCount',
        key: 'employeeCount',
      },
    ]
    columns.push({
      title: t('companies.table.users'),
      dataIndex: '',
      key: 'xUserCount',
      render: (company) => {
        return (
          <span>
            {company.userCount}
            <Link
              to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.USERS}
              className="table-hover table-hover-link"
            >
              {t('companies.table.see_user_list')}
            </Link>
          </span>
        )
      },
    })
    columns.push({ title: t('companies.table.national_id'), dataIndex: 'nationalID', key: 'nationalID' })
    if (props.useCompanyGroup) {
      columns.push({
        title: t('companies.table.in_group'),
        dataIndex: '',
        key: 'xInGroup',
        render: (company) => {
          if (company.inGroup) {
            return <span>{t('companies.table.in_group.true')}</span>
          }
          return (
            <div>
              {t('companies.table.in_group.false')}
              <br />
              <DumbLink onClick={handleAddToGroup(company.id)}>{t('companies.table.add_company_to_group')}</DumbLink>
            </div>
          )
        },
      })
    }
    columns.push({
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (company) => {
        return (
          <ContextMenu>
            <DumbLink onClick={handleCompanyClicked(company.id)}>
              <Icon type="company" color="grey" /> {t('companies.table.go_to_company')}
            </DumbLink>
            <Link to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.USERS}>
              <Icon type="team" color="grey" /> {t('companies.table.see_user_list')}
            </Link>
            {company.inGroup && (
              <DumbLink onClick={handleRemoveFromGroup(company.id)}>
                <Icon type="cross" color="grey" /> {t('companies.table.remove_company_from_group')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    })
    return columns
  }

  const getCompanies = (): CompanyRow[] => {
    return props.companies.companies
      .filter((company) => {
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          if (!pattern.test(company.name) && !pattern.test(company.nationalID)) {
            return false
          }
        }
        return true
      })
      .map((company) => {
        let inGroup = false
        if (props.companyGroup && props.companyGroup.companies.some((groupCompany) => groupCompany.id === company.id)) {
          inGroup = true
        }
        if (!inGroup && addingGroupCompanies.some((id) => id === company.id)) {
          inGroup = true
        }
        if (inGroup && removingGroupCompanies.some((id) => id === company.id)) {
          inGroup = false
        }
        return {
          key: company.id,
          id: company.id,
          name: company.name,
          employeeCount: formatNumber(company.employeeCount || 0, 0),
          userCount: formatNumber(company.userCount || 0, 0),
          nationalID: company.nationalID,
          inGroup,
        }
      })
      .toArray()
  }

  const companies = getCompanies()
  const count = companies.length

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TitleMenu>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <Link to={'/' + paths.COMPANIES + '/' + paths.ADD}>
            <Button type="primary">
              <Icon type="company-add" />
              {props.useCompanyGroup ? t('companies.add_company_outside_group') : t('companies.add_company')}
            </Button>
          </Link>
          {props.useCompanyGroup && (
            <Link
              to={
                '/' +
                paths.COMPANIES +
                '/' +
                paths.ADD +
                (props.useCompanyGroup ? '?companyGroupID=' + props.companyGroup?.id : '')
              }
              style={{ marginLeft: '20px' }}
            >
              <Button type="primary">
                <Icon type="company-add" />
                {t('companies.add_company_to_group')}
              </Button>
            </Link>
          )}
        </Row>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <Input.Search onChange={handleSearch} />
        </Row>
      </TitleMenu>
      <Title style={{ minHeight: '100px' }}>
        {t('companies.search.companies', { count })}
        {searchQuery ? <br /> : ''}
        {searchQuery ? t('companies.search.searching_for', { query: searchQuery }) : ''}
      </Title>

      <Table columns={getColumns()} dataSource={companies} pagination={false} />
    </div>
  )
}
