import RcCheckbox from 'rc-checkbox'
import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import classNames from '../../antd/_util/classNames'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  prefixCls?: string
  className?: string
  children?: ReactNode
  indeterminate?: boolean
  style?: CSSProperties
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
}

export default function Checkbox(props: Props): ReactElement | null {
  const {
    prefixCls = 'ant-checkbox',
    className,
    children,
    indeterminate = false,
    style,
    onMouseEnter,
    onMouseLeave,
    tabIndex,
    ...restProps
  } = props
  const checkboxProps = { ...restProps, tabIndex: tabIndex ? tabIndex.toString() : undefined }
  // if (checkboxGroup) {
  //   checkboxProps.onChange = () => checkboxGroup.toggleOption({ label: children, value: props.value })
  //   checkboxProps.checked = checkboxGroup.value.indexOf(props.value) !== -1
  //   checkboxProps.disabled = props.disabled || checkboxGroup.disabled
  // }
  const classString = classNames(className, {
    [`${prefixCls}-wrapper`]: true,
  })
  const checkboxClass = classNames({
    [`${prefixCls}-indeterminate`]: indeterminate,
  })
  return (
    <label className={classString} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <RcCheckbox {...checkboxProps} prefixCls={prefixCls} className={checkboxClass} />
      {children !== undefined ? <span>{children}</span> : null}
    </label>
  )
}
