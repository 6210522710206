import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { NewPayRoll } from '../../api/pay-rolls'
import paths from '../../constants/paths'
import Company from '../../model/company'
import Employee from '../../model/employee'
import OneTimePay, { OneTimePayCreationFields } from '../../model/oneTimePay'
import PayRoll from '../../model/payRoll'
import PaySlip from '../../model/paySlip'
import { formatError } from '../../utils/error-utils'
import { ConditionalSyncAction, executeConditionalSynchroniser } from '../../utils/sync-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import { Col, Row } from '../elements/grid'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import FixNationalIDForm, { FixNationalIDResult } from './FixNationalIDForm'
import { WarningEmployeeIDs } from './WarningsCard'

type Props = {
  warning?: WarningEmployeeIDs
  employeesMap: Record<string, Employee>
  paySlips: List<PaySlip>
  payRoll: PayRoll
  company: Company

  addPayRoll: (payRoll: NewPayRoll) => Promise<PayRoll | void>
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => Promise<OneTimePay | void>
}

type State = {
  negatePayRollID?: string
  extraPayRollID?: string
  finishedEmployeeIDs: string[]
}

export default function FixNationalID(props: Props): ReactElement | null {
  const [state, setState] = useState<State>({ finishedEmployeeIDs: [] })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Error[]>([])

  const setError = (error: string | Error) => {
    let e: Error
    if (typeof error === 'string') {
      e = new Error(error)
    } else {
      e = error
    }
    setErrors((state) => [...state, e])
  }

  const { warning } = props
  if (!warning) {
    return null
  }

  const employees = Object.values(props.employeesMap).filter(
    (employee) =>
      !state.finishedEmployeeIDs.some((id) => id === employee.id) &&
      !!employee.nationalID &&
      warning.employeeIDs?.some((id) => id === employee.id)
  )

  if (!employees) {
    return null
  }

  const handleSubmit = (values: FixNationalIDResult) => {
    if (values.employees.length === 0) {
      return // do nothing
    }
    const paySlips = values.employees.reduce((o: Record<string, PaySlip>, employee) => {
      const paySlip = props.paySlips.find((ps) => ps.employeeID === employee.id)
      if (!paySlip) {
        return o
      }
      o[employee.id] = paySlip
      return o
    }, {})

    setLoading(true)

    const calls: ConditionalSyncAction[] = []

    calls.push(() => {
      return props
        .addPayRoll({
          companyID: props.company.id,
          type: 'Negation',
          salaryPeriodID: props.payRoll.salaryPeriod.id,
          dispositionDate: props.payRoll.dispositionDate,
          paySlipIDs: Object.keys(paySlips).reduce((ids: string[], employeeID) => {
            const paySlip = paySlips[employeeID]
            if (!paySlip) {
              return ids
            }
            return [...ids, paySlip.id]
          }, []),
        })
        .then((payRoll) => {
          if (!payRoll) {
            setError(t('pay_roll.single.fix_national_id.errors.negate_payroll_failed'))
            return false
          }
          setState((state) => ({ ...state, negatePayRollID: payRoll.id, extraPayRollID: undefined }))
          return true
        })
        .catch((_e) => {
          setError(t('pay_roll.single.fix_national_id.errors.negate_payroll_failed'))
          return false
        })
    })
    const validEmployeeIDs: string[] = []
    values.employees.forEach((employee) => {
      const employeeUpdate = props.employeesMap[employee.id]
      if (!employeeUpdate) {
        setError(t('pay_roll.single.fix_national_id.errors.employee_update_failed'))
        return
      }
      // note: all these calls return true, because we want the whole show to continue
      calls.push(() => {
        return props
          .updateEmployee({ ...employeeUpdate, nationalID: employee.newNationalID })
          .then((savedEmployee): Promise<boolean> => {
            if (!savedEmployee) {
              setError(t('pay_roll.single.fix_national_id.errors.employee_update_failed'))
              return new Promise(() => true)
            }
            const paySlip = paySlips[savedEmployee.id]
            if (!paySlip) {
              setError(t('pay_roll.single.fix_national_id.errors.otp_creation_failed'))
              return new Promise(() => true)
            }
            return props
              .addOneTimePay(savedEmployee.id, {
                employeeID: savedEmployee.id,
                type: 'Pay Check Advanced',
                title: 'CPR-nummer-fejl-justering',
                amount: paySlip.payCheck,
                dispositionDate: props.payRoll.dispositionDate,
                approved: true,
              })
              .then((otp) => {
                if (!otp) {
                  return true
                }
                validEmployeeIDs.push(employee.id)
                return true
              })
              .catch((e) => {
                setError(e)
                return true
              })
          })
          .catch((e) => {
            setError(e)
            return true
          })
      })
    })
    calls.push(() => {
      if (validEmployeeIDs.length === 0) {
        return new Promise(() => [validEmployeeIDs, false])
      }
      return props
        .addPayRoll({
          companyID: props.company.id,
          type: 'Extra',
          salaryPeriodID: props.payRoll.salaryPeriod.id,
          dispositionDate: props.payRoll.dispositionDate,
          employeeIDs: validEmployeeIDs,
          paySlipIDs: [],
        })
        .then((payRoll) => {
          if (!payRoll) {
            setError(t('pay_roll.single.fix_national_id.errors.extra_payroll_failed'))
            return false
          }
          setState((state) => ({
            ...state,
            extraPayRollID: payRoll.id,
            finishedEmployeeIDs: [...state.finishedEmployeeIDs, ...validEmployeeIDs],
          }))
          return true
        })
        .catch((_e) => {
          setError(t('pay_roll.single.fix_national_id.errors.extra_payroll_failed'))
          return false
        })
    })
    executeConditionalSynchroniser(calls, () => {
      setLoading(false)
    })
  }

  return (
    <Card>
      {loading && <LoadingOverlay />}
      {errors.map((e, i) => (
        <Alert key={i} type={'error'} message={formatError(e)} showIcon />
      ))}
      <Title>{t('pay_roll.single.fix_national_id.title')}</Title>
      <p>{t('pay_roll.single.fix_national_id.intro.part_1', { count: employees.length })}</p>
      <p>{t('pay_roll.single.fix_national_id.intro.part_2', { count: employees.length })}</p>
      <p>{t('pay_roll.single.fix_national_id.intro.part_3', { count: employees.length })}</p>
      {(state.negatePayRollID || state.extraPayRollID) && (
        <Row style={{ marginBottom: '10px' }}>
          {state.negatePayRollID && (
            <Col span={12} style={{ padding: '0 10px' }}>
              <Link to={'/' + paths.PAY_ROLLS + '/' + state.negatePayRollID}>
                <Button block type="secondary">
                  {t('pay_roll.single.fix_national_id.negate_pay_roll_link')}
                </Button>
              </Link>
            </Col>
          )}
          {state.extraPayRollID && (
            <Col span={12} style={{ padding: '0 10px' }}>
              <Link to={'/' + paths.PAY_ROLLS + '/' + state.extraPayRollID}>
                <Button block type="secondary">
                  {t('pay_roll.single.fix_national_id.extra_pay_roll_link')}
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      )}
      <FixNationalIDForm key={state.finishedEmployeeIDs.length} employees={employees} onSubmit={handleSubmit} />
    </Card>
  )
}
