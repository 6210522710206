import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import AccountingDimension from '../../../model/accountingDimension'
import Company from '../../../model/company'
import CompanyUser from '../../../model/companyUser'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Department, { DepartmentCreationFields } from '../../../model/department'
import Employee from '../../../model/employee'
import EmployeeDimension, { EmployeeDimensionMutableFields } from '../../../model/employeeDimension'
import Employment from '../../../model/employment'
import EmploymentPosition from '../../../model/employmentPosition'
import SalaryCycle from '../../../model/salaryCycle'
import { ContractReducer } from '../../../reducers/contracts'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import FamilyLeaveFundValues from '../../../types/family-leave-fund'
import { formatDate, getDate, isTimeBefore } from '../../../utils/date-utils'
import { formatFamilyLeaveFund, formatIncomeType } from '../../../utils/format-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import EmploymentDetailsEdit from './EmploymentDetailsEdit'

type Props = {
  companyUser?: CompanyUser
  mutableContract: Contract
  employee: Employee
  activeEmployment: Employment
  company: Company
  companyUsers: List<CompanyUser>
  contracts: ContractReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  employeeContractDeltas: EmployeeContractDeltaReducer
  employments: EmploymentReducer
  employmentPositions: List<EmploymentPosition>
  employeeDimensions: List<EmployeeDimension>
  accountingDimensions: List<AccountingDimension>
  viewingContract: Contract
  salaryCycle: SalaryCycle
  hasFutureContractsFeature: boolean
  isMutableContract: boolean
  isFutureContract: boolean
  canEditObjects: boolean
  canHireFire: boolean
  hasDimensionValues: boolean

  addAlert: addAlertSignature
  updateEmployee: (employee: Employee) => void
  updateEmployment: (id: string, employment: Employment) => void
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  updateEmployeeDimensions: (employeeID: string, dimensions: EmployeeDimensionMutableFields[]) => void
  setRehireVisibility: (visible: boolean) => void
  setDeleteVisibility: (visible: boolean) => void
  setTerminateVisibility: (visible: boolean) => void
  setCreateFutureContractVisibility: (visible: boolean) => void
  setDeleteContractVisibility: (visible: boolean) => void
  markEmployeeReady: (employeeID: string) => Promise<Employee | void>
  unmarkEmployeeReady: (employeeID: string) => Promise<Employee | void>
}

export default function EmploymentDetailsCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const { isMutableContract, departments } = props

  const setEditVisibility = useCallback(
    (visible: boolean) => {
      if (!isMutableContract && departments.departments.size === 0) {
        return
      }
      setModalKey((prev) => prev + 1)
      setShowEdit(visible)
    },
    [isMutableContract, departments]
  )

  const { contracts } = props
  const previousContracts = usePrevious(contracts)
  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        setEditVisibility(false)
      }
    }
  }, [previousContracts, contracts, setEditVisibility])

  const { employees } = props
  const previousEmployees = usePrevious(employees)
  useEffect(() => {
    if (!isMutableContract && previousEmployees && previousEmployees.saving && !employees.saving) {
      if (!employees.error) {
        setEditVisibility(false)
      }
    }
  }, [isMutableContract, previousEmployees, employees, setEditVisibility])

  const getPosition = (): string => {
    const employmentPosition = props.employmentPositions.find(
      (position) => props.mutableContract.employmentPositionID === position.id
    )
    return employmentPosition ? employmentPosition.title : props.mutableContract.position
  }
  const getProductionUnit = (): string => {
    const productionUnit = props.company.productionUnits.find(
      (productionUnit) => props.mutableContract.productionUnitID === productionUnit.id
    )
    return productionUnit ? productionUnit.name.split(',')[0] : '-'
  }
  const getDepartment = (): string => {
    return (
      (props.employee.departmentID &&
        props.departments.departments.find((department) => department.id === props.employee.departmentID)?.name) ||
      t('employment_details.card.field.department.none')
    )
  }
  const getEmployment = () => {
    return formatDate(props.activeEmployment.startDate)
  }
  const getValidPeriod = () => {
    return t(
      ['employment_details', 'card', 'valid_period', props.mutableContract.validTo ? 'end_date' : 'no_end_date'],
      {
        from: formatDate(props.mutableContract.validFrom),
        to: props.mutableContract.validTo ? formatDate(props.mutableContract.validTo) : '',
      }
    )
  }

  const canBeRehired = props.employee.employmentStatus === 'Terminated' || props.activeEmployment.endDate
  const canBeTerminated = props.employee.affiliationType !== 'Freelancer' && !props.activeEmployment.endDate
  const canBeDeleted = !props.employee.immutableEndDate
  const canReadyEmployee = props.company.settingsEnabled.some((setting) => setting === 'EnableReadyEmployeeForPayroll')
  let dimension
  if (!props.hasDimensionValues) {
    const accountingDimension = props.accountingDimensions.find((dimension) =>
      props.employeeDimensions.some(
        (employeeDimension) =>
          employeeDimension.employeeID === props.employee.id && employeeDimension.dimensionID === dimension.id
      )
    )
    if (accountingDimension) {
      dimension = accountingDimension.dimensionCode
      if (accountingDimension.dimensionName) {
        dimension = `${accountingDimension.dimensionCode} (${accountingDimension.dimensionName})`
      }
    }
  }

  const canDelete =
    props.activeEmployment.id === props.viewingContract.employmentID &&
    props.employeeContractDeltas.contracts.count(
      (contract) => contract.contract.employmentID === props.activeEmployment.id
    ) > 1 &&
    (!props.employee.immutableEndDate ||
      isTimeBefore(getDate(props.employee.immutableEndDate), getDate(props.viewingContract.validFrom)))

  return (
    <Card>
      {props.canEditObjects && (
        <TitleMenu>
          {props.hasFutureContractsFeature && props.employee.earliestMutableContract && (
            <Button onClick={() => props.setCreateFutureContractVisibility(true)}>
              {t('employment_details.card.actions.create_future_contract')}
            </Button>
          )}
          {canDelete && (
            <Button onClick={() => props.setDeleteContractVisibility(true)} type="danger">
              {props.isFutureContract
                ? t('employment_details.card.actions.delete_contract.future')
                : t('employment_details.card.actions.delete_contract')}
            </Button>
          )}
          {(props.isMutableContract || props.departments.departments.size > 0) && (
            <Button className="ant-btn-edit" onClick={() => setEditVisibility(true)}>
              &nbsp;
            </Button>
          )}
        </TitleMenu>
      )}
      <Title>{t('employment_details.card.title')}</Title>
      <Row>
        <Col span={7}>
          {t('employment_details.card.field.employment_position')}:
          <br />
          <strong>{getPosition()}</strong>
        </Col>
        <Col span={7}>
          {t('employment_details.card.field.production_unit')}:
          <br />
          <strong>{getProductionUnit()}</strong>
        </Col>
        <Col span={7}>
          {t('employment_details.card.field.department')}:
          <br />
          <strong>{getDepartment()}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          {t('employment_details.card.field.position')}:
          <br />
          <strong>{props.mutableContract.position}</strong>
        </Col>
        <Col span={7}>
          {t('employment_details.card.field.employment_date')}:
          <br />
          <strong>{getEmployment()}</strong>
        </Col>
        <Col span={7}>
          {props.isFutureContract
            ? t('employment_details.card.field.period.future')
            : props.isMutableContract
            ? t('employment_details.card.field.period.valid')
            : t('employment_details.card.field.period.past')}
          :
          <br />
          <strong>{getValidPeriod()}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          {t('employment_details.card.field.income_type')}:
          <br />
          <strong>{formatIncomeType(props.activeEmployment.incomeType)}</strong>
        </Col>
        {(props.company.familyLeaveFund !== FamilyLeaveFundValues.BARSEL_DK ||
          props.company.familyLeaveFund !== props.mutableContract.familyLeaveFund) && (
          <Col span={7}>
            {t('employment_details.card.field.family_leave_fund')}:
            <br />
            <strong>{formatFamilyLeaveFund(props.mutableContract.familyLeaveFund)}</strong>
          </Col>
        )}
      </Row>
      {props.accountingDimensions.some((dimension) => dimension.usage === 'Employees') && (
        <Row>
          {!props.hasDimensionValues && (
            <Col span={7}>
              {t('employment_details.card.field.employee_dimension')}:
              <br />
              {dimension && <strong>{dimension}</strong>}
            </Col>
          )}
          {props.hasDimensionValues &&
            props.accountingDimensions
              .filter((dimension) => dimension.usage === 'Employees')
              .map((dimension) => {
                const value = props.employeeDimensions.find(
                  (employeeDimension) =>
                    employeeDimension.dimensionID === dimension.id && employeeDimension.employeeID === props.employee.id
                )
                return (
                  <Col span={7} key={dimension.dimensionCode}>
                    {dimension.dimensionCode}:<br />
                    {value && <strong>{value.dimensionValueCode}</strong>}
                  </Col>
                )
              })}
        </Row>
      )}

      {((props.canHireFire && (canBeRehired || canBeTerminated || canBeDeleted)) || canReadyEmployee) && (
        <Row>
          {canReadyEmployee && (
            <Col span={12} className="ready-buttons">
              {!props.employee.readiedByUserID && (
                <Button
                  type="secondary"
                  onClick={() => {
                    props.markEmployeeReady(props.employee.id).then((res) => {
                      if (res) {
                        props.addAlert('success', t('employment.alert.employee_ready', { name: props.employee.name }), {
                          timeout: 5,
                        })
                      }
                    })
                  }}
                >
                  {t('employment_details.card.actions.mark_ready')}
                </Button>
              )}
              {!!props.employee.readiedByUserID && (
                <div>
                  <Button
                    type="danger"
                    onClick={() => {
                      props.unmarkEmployeeReady(props.employee.id).then((res) => {
                        if (res) {
                          props.addAlert(
                            'success',
                            t('employment.alert.employee_unready', { name: props.employee.name }),
                            {
                              timeout: 5,
                            }
                          )
                        }
                      })
                    }}
                  >
                    {t('employment_details.card.actions.mark_unready')}
                  </Button>
                  <small>
                    {t('employment_details.card.actions.mark_unready.readied_by', {
                      name:
                        props.companyUsers.find((user) => user.userID === props.employee.readiedByUserID)?.name ??
                        t('common.unknown'),
                    })}
                  </small>
                </div>
              )}
            </Col>
          )}
          {props.canHireFire && (
            <Col span={canReadyEmployee ? 12 : 24} className="employment-buttons">
              {canBeRehired && (
                <Button
                  type="primary"
                  onClick={() => {
                    props.setRehireVisibility(true)
                  }}
                >
                  {t('employment_details.card.actions.rehire')}
                </Button>
              )}
              {canBeTerminated && (
                <Button
                  type="danger"
                  onClick={() => {
                    props.setTerminateVisibility(true)
                  }}
                >
                  {t('employment_details.card.actions.terminate')}
                </Button>
              )}
              {canBeDeleted && (
                <Button
                  type="danger"
                  onClick={() => {
                    props.setDeleteVisibility(true)
                  }}
                >
                  {t('employment_details.card.actions.delete')}
                </Button>
              )}
            </Col>
          )}
        </Row>
      )}

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <EmploymentDetailsEdit
          visible={showEdit}
          company={props.company}
          employee={props.employee}
          activeEmployment={props.activeEmployment}
          employeeDimensions={props.employeeDimensions}
          accountingDimensions={props.accountingDimensions}
          mutableContract={props.mutableContract}
          isFutureContract={props.isFutureContract}
          isMutableContract={props.isMutableContract}
          productionUnits={props.company.productionUnits}
          contracts={props.contracts}
          departments={props.departments}
          employees={props.employees}
          employments={props.employments}
          employmentPositions={props.employmentPositions}
          salaryCycle={props.salaryCycle}
          companyUser={props.companyUser}
          hasDimensionValues={props.hasDimensionValues}
          updateEmployee={props.updateEmployee}
          updateEmployment={props.updateEmployment}
          addContract={props.addContract}
          updateContract={props.updateContract}
          addDepartment={props.addDepartment}
          updateEmployeeDimensions={props.updateEmployeeDimensions}
        />
      </Modal>
    </Card>
  )
}
