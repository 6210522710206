import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import logo from '../../images/logo-white-165x49.png'
import { UserReducer } from '../../reducers/user'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Title from '../elements/Title'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ResetPasswordForm, { Fields } from './ResetPasswordForm'

import './ResetPassword.css'

type Props = {
  token: string
  user: UserReducer

  addAlert: addAlertSignature
  resetPassword: (token: string, password: string) => Promise<boolean | void>
}

export default function ResetPassword(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { user } = props

  useEffect(() => {
    const newError = user.error
    if (error !== newError) {
      setError(newError)
    }
  }, [user, error])

  const _handleSubmit = (values: Fields) => {
    props.resetPassword(props.token, values.password).then(() => {
      props.addAlert('success', t('password_reset.alert.success'), { timeout: 5 })
      jsBrowserHistory.push('/' + paths.LOGIN + document.location.search)
    })
  }

  return (
    <Card className="reset-password">
      <div className="reset-password-image">
        <img src={logo} alt="Salary" />
      </div>
      <div className="reset-password-form">
        <Title>{t('password_reset.title')}</Title>
        {error && <Alert message={formatError(error)} type="error" showIcon />}
        <ResetPasswordForm onSubmit={_handleSubmit} />
        {props.user.resettingPassword && <LoadingOverlay />}
      </div>
    </Card>
  )
}
