import React, { ReactElement } from 'react'

import Limits from '../../../constants/limits'
import { decorateFieldSignature, getFieldValueSignature } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Input from '../../elements/input'

type OneTimePayTitleFields = {
  title?: string
}

type Props<Fields extends OneTimePayTitleFields> = {
  editing: boolean
  maxLength?: number
  hardLengthLimit?: boolean

  getFieldValue: getFieldValueSignature<Fields>
  decorateField: decorateFieldSignature<Fields>
}

export default function OneTimePayTitle<Fields extends OneTimePayTitleFields>(
  props: Props<Fields>
): ReactElement | null {
  const charLength = (props.getFieldValue('title') ?? '').length
  const maxLength = props.maxLength ?? Limits.MAX_PAY_SLIP_TEXT_LENGTH

  return (
    <>
      {props.decorateField('title', {
        placeholder: t('otp_form.title'),
        validate: (val) => {
          if (!val) {
            return t('otp_form.title.required')
          }
          if (props.hardLengthLimit && val.length > maxLength) {
            return t('otp_form.title.too_long', { limit: maxLength })
          }
          return null
        },
      })(<Input disabled={!props.editing} />)}
      <span className="ant-form-char-limit">
        <span className="ant-form-char-limit-count">
          {t('otp_form.title.count', { length: charLength, max: maxLength })}
        </span>
        {!props.hardLengthLimit && charLength > maxLength && (
          <span className="ant-form-char-limit-warning">{t('otp_form.title.warning')}</span>
        )}
      </span>
    </>
  )
}
