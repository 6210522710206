import { addYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import AvailableAccountingIntegration from '../../model/availableAccountingIntegration'
import Dashboard from '../../model/dashboard'
import { AlertReducer } from '../../reducers/alerts'
import {
  formatAPIDate,
  formatDate,
  formatDateTime,
  getDate,
  isTimeBefore,
  trimCurrentYear,
} from '../../utils/date-utils'
import {
  formatAccountingIntegration,
  formatPaymentMethod,
  formatPayRollRunApprovalType,
  formatUserPayRollStatus,
} from '../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { convertDashboardPayRollStatus } from '../../utils/pay-roll-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { TableSorter } from '../../utils/table-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import Alerts from '../widgets/Alerts'

import './CompanyDashboards.css'

type Props = {
  alerts: AlertReducer
  availableAccountingIntegrations: List<AvailableAccountingIntegration>
  dashboards: List<Dashboard>

  removeAlert: removeAlertSignature
}

export default function CompanyDashboards(props: Props): ReactElement | null {
  const [inputSearch, setInputSearch] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  type Sort = {
    sortOn?: 'companyName' | 'unapprovedRegistrations' | 'nextNeedApproval'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearch), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearch])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(e.currentTarget.value.trim())
  }

  type CompanyRow = {
    key: string
    id: string
    name: string
    cvr: string
    employeeCount: number
    userCount: number
    warningCount: number
    rulePayRollRunApproval: string
    paymentIntegration: string
    accountingIntegration: string
    timeRegistrationsApproved: number
    timeRegistrationsPending: number
    reimbursementsApproved: number
    reimbursementsPending: number
    leaveRegistrationsApproved: number
    leaveRegistrationsPending: number
    carAllowancesApproved: number
    carAllowancesPending: number
    swipeEnabled: boolean
    swipeApproved: number
    swipePending: number
    totalPending: number
    nextPayRollNeedingApproval: string
    payRolls: {
      key: string
      id: string
      period: string
      statusText: React.ReactNode
      statusColor: string
      amount: string
      link?: string
    }[]
  }

  const columns = [
    {
      title: t('company_dashboards.table.header.company_info'),
      dataIndex: '',
      key: 'x1',
      className: 'company-dashboards-info',
      sorter: 'companyName',
      render: (company: CompanyRow) => {
        return (
          <div>
            {t('company_dashboards.table.company_info.name')}:{' '}
            <Link to={'/' + paths.COMPANIES + '/' + company.id + '?companyID=' + company.id}>
              <strong>{company.name}</strong>
            </Link>
            <br />
            {t('company_dashboards.table.company_info.national_id')}: {company.cvr}
            <br />
            <small>
              <Link to={'/' + paths.EMPLOYEES + '?companyID=' + company.id}>
                {t('company_dashboards.table.company_info.employees', { count: company.employeeCount })}
              </Link>
              <br />
              <Link to={'/' + paths.COMPANIES + '/' + company.id + '/users?companyID=' + company.id}>
                {t('company_dashboards.table.company_info.users', { count: company.userCount })}
              </Link>
            </small>
          </div>
        )
      },
    },
    {
      title: t('company_dashboards.table.header.pay_roll_settings'),
      dataIndex: '',
      key: 'x2',
      render: (company: CompanyRow) => {
        return (
          <div>
            {t('company_dashboards.table.pay_roll_settings.approval')}:{' '}
            <strong>{company.rulePayRollRunApproval}</strong>
            <br />
            {t('company_dashboards.table.pay_roll_settings.payment_integration')}:{' '}
            <strong>{company.paymentIntegration}</strong>
            <br />
            {t('company_dashboards.table.pay_roll_settings.accounting_integration')}:{' '}
            <strong>{company.accountingIntegration}</strong>
          </div>
        )
      },
    },
    {
      title: t('company_dashboards.table.header.registrations'),
      dataIndex: '',
      key: 'x3',
      sorter: 'unapprovedRegistrations',
      render: (company: CompanyRow) => {
        return (
          <div>
            {t('company_dashboards.table.registrations.hours')}:{' '}
            <strong>{formatNumber(company.timeRegistrationsApproved, 2)}</strong> (
            <Link
              to={'/' + paths.TIME_REGISTRATION + '?companyID=' + company.id}
              style={{ color: company.timeRegistrationsPending ? '#f00' : undefined }}
            >
              {t('company_dashboards.table.registrations.hours.format', {
                number: formatNumber(company.timeRegistrationsPending, 2),
              })}
            </Link>
            )
            <br />
            {t('company_dashboards.table.registrations.reimbursements')}:{' '}
            <strong>{formatCurrency(company.reimbursementsApproved)}</strong> (
            <Link
              to={'/' + paths.REIMBURSEMENT_VOUCHERS + '?companyID=' + company.id}
              style={{ color: company.reimbursementsPending ? '#f00' : undefined }}
            >
              {t('company_dashboards.table.registrations.reimbursements.format', {
                number: formatCurrency(company.reimbursementsPending),
              })}
            </Link>
            )
            <br />
            {t('company_dashboards.table.registrations.leave')}:{' '}
            <strong>{formatNumber(company.leaveRegistrationsApproved, 2)}</strong> (
            <Link
              to={'/' + paths.LEAVE_REGISTRATION + '?companyID=' + company.id}
              style={{ color: company.leaveRegistrationsPending ? '#f00' : undefined }}
            >
              {t('company_dashboards.table.registrations.leave.format', {
                number: formatNumber(company.leaveRegistrationsPending, 2),
              })}
            </Link>
            )
            <br />
            {t('company_dashboards.table.registrations.car_allowance')}:{' '}
            <strong>{formatNumber(company.carAllowancesApproved, 2)} km</strong> (
            <Link
              to={'/' + paths.CAR_ALLOWANCE + '?companyID=' + company.id}
              style={{ color: company.carAllowancesPending ? '#f00' : undefined }}
            >
              {t('company_dashboards.table.registrations.car_allowance.format', {
                number: formatNumber(company.carAllowancesPending, 2),
              })}
            </Link>
            )
            {(company.swipeApproved > 0 || company.swipePending > 0) && (
              <>
                <br />
                {t('company_dashboards.table.registrations.swipe')}:{' '}
                <strong>{formatCurrency(company.swipeApproved, 2)}</strong> (
                <Link
                  to={'/' + paths.SWIPE_OVERVIEW + '?companyID=' + company.id}
                  style={{ color: company.swipePending ? '#f00' : undefined }}
                >
                  {t('company_dashboards.table.registrations.swipe.format', {
                    number: formatCurrency(company.swipePending, 2),
                  })}
                </Link>
                )
              </>
            )}
            <br />
            {t('company_dashboards.table.registrations.notifications')}:{' '}
            <Link to={'/' + paths.NOTIFICATIONS + '?companyID=' + company.id}>
              <strong>{formatNumber(company.warningCount, 0)}</strong>
            </Link>
          </div>
        )
      },
    },
    {
      title: t('company_dashboards.table.header.next_pay_roll'),
      dataIndex: '',
      key: 'x4',
      sorter: 'nextNeedApproval',
      render: (company: CompanyRow) => {
        return (
          <div className="dashboard-calendar-inner">
            {company.payRolls.map((row) => {
              const cols = [
                <div key="col2" className="dashboard-calendar-col1">
                  {row.period}
                </div>,
                <div key="col3" className="dashboard-calendar-col2" style={{ color: row.statusColor }}>
                  {row.statusText}
                </div>,
                <div key="col4" className="dashboard-calendar-col3">
                  {row.amount}
                </div>,
              ]
              if (!row.link) {
                return (
                  <div key={row.key} className="dashboard-calendar-row">
                    {cols}
                  </div>
                )
              }
              return (
                <Link to={row.link} key={row.key} className="dashboard-calendar-row">
                  {cols}
                </Link>
              )
            })}
            {company.payRolls.length === 0 && <em>{t('company_dashboards.table.next_pay_roll.none')}</em>}
          </div>
        )
      },
    },
  ]

  const getCompanyDashboards = (): CompanyRow[] => {
    return props.dashboards
      .filter((company) => {
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          return !(!pattern.test(company.name) && !pattern.test(company.cvr))
        }
        return true
      })
      .map((company) => {
        const accountingIntegration =
          company.accountingIntegrationDisplayName ||
          props.availableAccountingIntegrations.find(
            (integration) => integration.type === company.accountingIntegration
          )?.displayName ||
          formatAccountingIntegration(company.accountingIntegration)
        const totalPending =
          (company.timeRegistrationsPending || 0) +
          (company.reimbursementsPending || 0) +
          (company.leaveRegistrationsPending || 0) +
          (company.carAllowancesPending || 0) +
          (company.swipePending || 0)
        const nextPayRollNeedingApproval = company.payRolls
          ? formatAPIDate(
              company.payRolls.reduce((date, payRoll) => {
                if (payRoll.approvalState !== 'Pending') {
                  return date
                }
                if (!date) {
                  return getDate(payRoll.salaryPeriod.end)
                }
                if (isTimeBefore(getDate(payRoll.salaryPeriod.end), date)) {
                  return getDate(payRoll.salaryPeriod.end)
                }
                return date
              }, addYears(getDate(), 1))
            )
          : formatAPIDate(addYears(getDate(), 1))
        return {
          key: company.id,
          id: company.id,
          name: company.name,
          cvr: company.cvr,
          employeeCount: company.employeeCount || 0,
          userCount: company.userCount || 0,
          warningCount: company.warningCount || 0,
          rulePayRollRunApproval: formatPayRollRunApprovalType(company.rulePayRollRunApproval),
          paymentIntegration: formatPaymentMethod(company.paymentIntegration),
          accountingIntegration,
          timeRegistrationsApproved: company.timeRegistrationsApproved || 0,
          timeRegistrationsPending: company.timeRegistrationsPending || 0,
          reimbursementsApproved: company.reimbursementsApproved || 0,
          reimbursementsPending: company.reimbursementsPending || 0,
          leaveRegistrationsApproved: company.leaveRegistrationsApproved || 0,
          leaveRegistrationsPending: company.leaveRegistrationsPending || 0,
          carAllowancesApproved: company.carAllowancesApproved || 0,
          carAllowancesPending: company.carAllowancesPending || 0,
          swipeEnabled: company.swipeEnabled || false,
          swipeApproved: company.swipeApproved || 0,
          swipePending: company.swipePending || 0,
          totalPending,
          nextPayRollNeedingApproval,
          payRolls: company.payRolls
            ? company.payRolls.map((payRoll) => {
                const state = convertDashboardPayRollStatus(payRoll)
                let status: string | React.ReactNode = formatUserPayRollStatus(state)
                let color = 'var(--sally-yellow)'
                let amount = formatCurrency(payRoll.totalTransfer, 0)
                let linkable = true
                switch (state) {
                  case 'Awaiting':
                  case 'Unknown':
                    amount = ''
                    color = 'var(--sally-grey)'
                    linkable = false
                    break
                  // case UserPayRollStatus.SUCCESS:
                  case 'Approved':
                  case 'Automatic Approval':
                  case 'No Approval':
                    color = 'var(--sally-green)'
                    break
                  // case UserPayRollStatus.CANCELLED:
                  // case UserPayRollStatus.FAILED:
                  case 'Rejected':
                    color = 'var(--sally-red)'
                    break
                  case 'Awaiting Approval': {
                    const deadline = payRoll.approvalDeadline
                    status = t('company_dashboards.table.status.awaiting_approval')
                    if (deadline) {
                      status = (
                        <Tooltip
                          title={
                            <>
                              {status}
                              <br />
                              {t('company_dashboards.table.status.deadline_format', {
                                time: trimCurrentYear(formatDateTime(deadline)),
                              })}
                            </>
                          }
                        >
                          {status}
                          <br />
                          {trimCurrentYear(formatDate(deadline))}
                        </Tooltip>
                      )
                    }
                    break
                  }
                  default:
                    break
                }
                const endYear = getDate(payRoll.salaryPeriod.end).getFullYear().toString()
                return {
                  id: payRoll.id,
                  key: payRoll.id,
                  period:
                    trimCurrentYear(formatDate(payRoll.salaryPeriod.start), endYear) +
                    ' - ' +
                    formatDate(payRoll.salaryPeriod.end),
                  statusText: status,
                  statusColor: color,
                  amount,
                  link: linkable ? '/' + paths.PAY_ROLLS + '/' + payRoll.id + '?companyID=' + company.id : undefined,
                }
              })
            : [],
        }
      })
      .toArray()
      .sort((a, b) => {
        let i = a
        let j = b
        if (sort.sortOrder === 'descend') {
          j = a
          i = b
        }
        switch (sort.sortOn) {
          case 'unapprovedRegistrations':
            return j.totalPending - i.totalPending
          case 'nextNeedApproval':
            return i.nextPayRollNeedingApproval.localeCompare(j.nextPayRollNeedingApproval)
          default:
            return i.name.localeCompare(j.name)
        }
      })
  }

  const tableChange = (_1: unknown, _2: unknown, sorter: TableSorter) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'companyName':
      case 'unapprovedRegistrations':
      case 'nextNeedApproval':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  const dashboards = getCompanyDashboards()
  const activeCount = dashboards.length

  return (
    <div className="company-dashboards">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <Input.Search placeholder={t('company_dashboards.header.search')} onChange={handleSearch} />
        <Link to={'/' + paths.COMPANIES + '/' + paths.ADD}>
          <Button type="primary" className="gtm-add-company">
            <Icon type="company-add" />
            {t('company_dashboards.header.add_company')}
          </Button>
        </Link>
      </TitleMenu>
      <Title>
        {t('company_dashboards.title.companies', { count: activeCount })}
        {searchQuery ? t('company_dashboards.title.search_format', { query: searchQuery }) : ''}
      </Title>
      <Table
        columns={columns}
        dataSource={dashboards}
        onChange={tableChange}
        pagination={{
          defaultCurrent: getPage(),
          defaultPageSize: 50,
          onChange: setPage,
        }}
        className="company-dashboards-table company-dashboards-table-visible"
      />
    </div>
  )
}
