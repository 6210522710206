import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import LeaveBalance from '../../../model/leaveBalance'
import LeaveType, { LeaveTypeName } from '../../../model/leaveType'
import { OneTimePayCategory, OneTimePayType } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementBalance from '../../../model/supplementBalance'
import SupplementType from '../../../model/supplementType'
import { DateFormat } from '../../../model/types'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { UserReducer } from '../../../reducers/user'
import RemunerationType from '../../../types/remuneration-type'
import { isFreeChoiceSupplement, isSHSupplement } from '../../../utils/employee-contract-utils'
import { additionalBonusTypes, bonusTypes, oneTimePayCategoriesByType } from '../../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatOneTimePayCategory, formatOneTimePayType } from '../../../utils/format-utils'
import {
  forceParseInputNumber,
  formatCurrency,
  formatInputNumber,
  formatNumber,
  parseInputNumber,
} from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import { isUserSupport } from '../../../utils/user-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Switch from '../../elements/switch'
import OneTimePayAccountingText from '../../form-elements/one-time-pay/OneTimePayAccountingText'
import OneTimePayAmount from '../../form-elements/one-time-pay/OneTimePayAmount'
import OneTimePayCostCenter from '../../form-elements/one-time-pay/OneTimePayCostCenter'
import OneTimePayDispositionDate from '../../form-elements/one-time-pay/OneTimePayDispositionDate'
import OneTimePayTitle from '../../form-elements/one-time-pay/OneTimePayTitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import SupportUserLock from '../../widgets/SupportUserLock'

type Props = {
  editing: boolean
  canApproveObjects: boolean
  employee: Employee
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
  supplementBalances: List<SupplementBalance>
  supplementTypes: List<SupplementType>
  leaveBalances: List<LeaveBalance>
  leaveTypes: List<LeaveType>
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  user: UserReducer
}

type Fields = {
  dispositionDate?: DateFormat
  type: OneTimePayType
  category: OneTimePayCategory
  amount?: string
  title?: string
  rate?: string
  units?: string
  costCenterID?: string
  accountingText?: string
  useRate: boolean
  approved: boolean
  withPension: boolean
  withVacation: boolean
  asInKind: boolean
}

export type BonusResult = {
  dispositionDate: DateFormat
  type: OneTimePayType
  category: OneTimePayCategory
  amount: number
  title: string
  rate?: number
  units?: number
  costCenterID?: string
  accountingText?: string
  approved: boolean
}

function BonusEditForm(props: Props & FormComponentProps<Fields, BonusResult>): ReactElement | null {
  const { decorateField, getFieldValue, setFieldValue } = props

  let types: OneTimePayType[] = [...bonusTypes]
  // remove the other bonus ones, since we use a switch for those
  types = types.filter(
    (type) => type !== 'Bonus No Pension' && type !== 'Bonus No Vacation' && type !== 'Bonus No Vacation and Pension'
  )
  // add some additional types, if we've got that setting
  if (props.company.settingsEnabled.some((setting) => setting === 'MoreBonusOptions')) {
    additionalBonusTypes.forEach((type) => types.push(type))
  }
  const contract = props.employee.activeContract
  let fritvalgBalance: number | undefined = undefined
  if (
    (contract &&
      contract.remuneration &&
      contract.remuneration.supplements.some((row) => !!row.type && isFreeChoiceSupplement(row.type.name))) ||
    props.supplementBalances.some(
      (balance) => balance.left !== 0.0 && isFreeChoiceSupplement(balance.supplementTypeName)
    )
  ) {
    types.push('Fritvalg')
    fritvalgBalance = props.supplementBalances.find((balance) =>
      isFreeChoiceSupplement(balance.supplementTypeName)
    )?.left
  }
  let shBalance: number | undefined
  if (
    (contract &&
      contract.remuneration &&
      contract.remuneration.supplements.some((row) => !!row.type && isSHSupplement(row.type.name))) ||
    props.supplementBalances.some((balance) => balance.left !== 0.0 && isSHSupplement(balance.supplementTypeName))
  ) {
    types.push('SH-Dage')
    shBalance = props.supplementBalances.find((balance) => isSHSupplement(balance.supplementTypeName))?.left
  }
  if (props.company.settingsEnabled.some((setting) => setting === 'SHDageFritvalgIncreaseTypes')) {
    types.push('Fritvalg Increase')
    types.push('SH-Dage Increase')
  }
  const getLeaveTypeID = (name: LeaveTypeName) => {
    return props.leaveTypes.find((leaveType) => leaveType.name === name)?.id || ''
  }
  let vacationAccrualBalance: LeaveBalance | undefined = undefined
  if (
    props.company.settingsEnabled.some((setting) => setting === 'AllowNonSalariedPaidVacation') &&
    contract &&
    (contract.remunerationType !== RemunerationType.SALARIED ||
      (contract.remuneration &&
        !contract.remuneration.leave.some((leave) => leave.typeID === getLeaveTypeID('DenmarkVacationAccrual'))))
  ) {
    vacationAccrualBalance = props.leaveBalances.find(
      (balance) => balance.leaveTypeID === getLeaveTypeID('DenmarkVacationAccrual')
    )
    if (vacationAccrualBalance && vacationAccrualBalance.value > 0.0) {
      types.push('Paid Vacation')
    }
  }
  if (contract && contract.vacationFundCVR === props.company.nationalID) {
    types.push('Gross Vacation Money')
  }
  let overtimeMinutesLeft = 0
  let overtimeRate = 0
  if (
    props.company.settingsEnabled.some((setting) => setting === 'EnableFlexAndOvertime') &&
    contract &&
    contract.remuneration &&
    contract.remuneration.leave.some((leave) => leave.typeID === getLeaveTypeID('DenmarkOvertime'))
  ) {
    const leaveBalance = props.leaveBalances.find(
      (balance) => balance.leaveTypeID === getLeaveTypeID('DenmarkOvertime')
    )
    if (leaveBalance && leaveBalance.left > 0) {
      overtimeMinutesLeft = leaveBalance.left
      const leave = contract.remuneration.leave.find((leave) => leave.typeID === getLeaveTypeID('DenmarkOvertime'))
      if (leave) {
        overtimeRate = leave.rate || 0
      }
    }
  }
  if (overtimeMinutesLeft > 0 && overtimeRate > 0) {
    types.push('Overtime Payout')
  }
  const hasPension =
    contract && contract.remuneration && contract.remuneration.pension.some((pension) => pension.scheme !== 'ATP')
  const hasVacation = props.employee.affiliationType !== 'Director'
  const categories: OneTimePayCategory[] = ['DKArt-0000']
  const extraCategories = oneTimePayCategoriesByType(props.getFieldValue('type'))
  const hasCategories = extraCategories.length > 0
  categories.push(...extraCategories)
  const askForDraft =
    props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects
  const willNotAppearSeparately = getFieldValue('type') === 'Free Phone' || getFieldValue('type') === 'ATP'
  const noTitleInput =
    getFieldValue('type') === 'Tax Deduction Modification Increase' ||
    getFieldValue('type') === 'Tax Deduction Modification Decrease' ||
    willNotAppearSeparately
  const noAmount =
    getFieldValue('type') === 'Benefit In Kind' ||
    getFieldValue('type') === 'Overtime Payout' ||
    getFieldValue('type') === 'Work Hours' ||
    willNotAppearSeparately
  const hasBalance =
    (getFieldValue('type') === 'SH-Dage' && shBalance !== undefined) ||
    (getFieldValue('type') === 'Fritvalg' && fritvalgBalance !== undefined)
  const restrictedTo58Characters =
    getFieldValue('type') === 'Honorary Gift Benefit In Kind' || getFieldValue('type') === 'Benefit In Kind'

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          <OneTimePayDispositionDate
            {...props}
            oneTimePayID={props.oneTimePayID}
            oneTimePays={props.oneTimePays.oneTimePays.toArray()}
            title={t('bonus.edit.form.disposition_date')}
          />
        </Col>
        {!noAmount && (
          <>
            <Col span={hasBalance ? 6 : 12}>
              <OneTimePayAmount
                {...props}
                description={t('bonus.edit.form.amount')}
                disableRate={false}
                disabled={false}
              />
              {getFieldValue('type') === 'Paid Vacation' && vacationAccrualBalance && (
                <p style={{ margin: '0' }}>
                  {t('bonus.edit.form.amount.paid_vacation', {
                    total: formatCurrency(vacationAccrualBalance.value),
                    part: formatCurrency(vacationAccrualBalance.value / vacationAccrualBalance.left),
                  })}
                </p>
              )}
              {hasBalance && (
                <p style={{ margin: '0' }}>
                  {t('bonus.edit.form.amount.sh_fritvalg', {
                    amount: formatCurrency(getFieldValue('type') === 'SH-Dage' ? shBalance : fritvalgBalance, 2),
                  })}
                </p>
              )}
            </Col>
            {hasBalance && (
              <Col span={6} style={{ paddingTop: '24px' }}>
                <Button
                  style={{ float: 'right' }}
                  onClick={() =>
                    setFieldValue(
                      'amount',
                      formatInputNumber(getFieldValue('type') === 'SH-Dage' ? shBalance : fritvalgBalance)
                    )
                  }
                >
                  {t('bonus.edit.form.amount.empty_balance')}
                </Button>
              </Col>
            )}
          </>
        )}
        {getFieldValue('type') === 'Overtime Payout' && (
          <>
            <Col span={6}>
              {decorateField('units', {
                title: t('bonus.edit.form.overtime_payout_units'),
                suffix: t('bonus.edit.form.overtime_payout_units.suffix'),
                validate: (val) => {
                  if (!props.editing) {
                    return null
                  }
                  if (!val) {
                    return t('bonus.edit.form.overtime_payout_units.required')
                  }
                  if (!val.match(/^([0-9,.]+)/)) {
                    return t('bonus.edit.form.overtime_payout_units.invalid')
                  }
                  const num = forceParseInputNumber(val)
                  if (num < 0) {
                    return t('bonus.edit.form.overtime_payout_units.not_negative')
                  }
                  if (num > overtimeMinutesLeft / 60) {
                    return t('bonus.edit.form.overtime_payout_units.less_than_available')
                  }
                  return null
                },
              })(<Input disabled={!props.editing} />)}
              <p>
                {t('bonus.edit.form.overtime_payout_units.note.line_1', {
                  rate: formatCurrency(overtimeRate),
                  hours_left: formatNumber(overtimeMinutesLeft / 60, 2),
                })}
                <br />
                {t('bonus.edit.form.overtime_payout_units.note.line_2', {
                  value: getFieldValue('units')
                    ? formatCurrency(forceParseInputNumber(getFieldValue('units')) * overtimeRate)
                    : '...',
                })}
              </p>
            </Col>
            <Col span={6} style={{ paddingTop: '24px' }}>
              <Button
                style={{ float: 'right' }}
                onClick={() => setFieldValue('units', formatInputNumber(overtimeMinutesLeft / 60))}
              >
                {t('bonus.edit.form.amount.empty_balance')}
              </Button>
            </Col>
          </>
        )}
        {getFieldValue('type') === 'Work Hours' && (
          <Col span={12}>
            {decorateField('amount', {
              title: t('bonus.edit.form.work_hours_units'),
              suffix: t('bonus.edit.form.work_hours_units.suffix'),
              validate: (val) => {
                if (!props.editing) {
                  return null
                }
                if (!val) {
                  return t('bonus.edit.form.work_hours_units.required')
                }
                if (!val.match(/^(-?[0-9,.]+)/)) {
                  return t('bonus.edit.form.work_hours_units.invalid')
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
        )}
        {getFieldValue('type') === 'Free Phone' && (
          <Col span={12} style={isUserSupport(props.user) ? {} : { paddingTop: '18px' }}>
            <SupportUserLock>
              <OneTimePayAmount
                {...props}
                description={t('bonus.edit.form.amount.free_phone')}
                required={false}
                disableRate={true}
                disabled={!props.editing}
              />
            </SupportUserLock>
            {!isUserSupport(props.user) && <p>{t('bonus.edit.form.no_amount.free_phone')}</p>}
          </Col>
        )}
        {getFieldValue('type') === 'ATP' && (
          <Col span={12}>
            {decorateField('amount', {
              title: t('bonus.edit.form.atp_hours'),
              helpText: (
                <>
                  <p>{t('bonus.edit.form.atp_hours.help.line_1')}</p>
                  <p>{t('bonus.edit.form.atp_hours.help.line_2')}</p>
                  <p>{t('bonus.edit.form.atp_hours.help.line_3')}</p>
                </>
              ),
              suffix: t('bonus.edit.form.atp_hours.suffix'),
              validate: (val) => {
                if (!props.editing) {
                  return null
                }
                if (val && !val.match(/^(-?[0-9,.]+)/)) {
                  return t('bonus.edit.form.atp_hours.invalid')
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
        )}
        {getFieldValue('type') === 'Anniversary Bonus' && (
          <Col span={24}>
            <p>{t('bonus.edit.form.amount.anniversary_bonus')}</p>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('type', {
            placeholder: t('bonus.edit.form.type'),
            validate: (val) => (!val ? t('bonus.edit.form.type.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} disabled={!props.editing}>
              {types.map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {formatOneTimePayType(type)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      {(getFieldValue('type') === 'Bonus' ||
        getFieldValue('type') === 'Fritvalg Increase' ||
        getFieldValue('type') === 'SH-Dage Increase') && (
        <Row>
          {hasPension && (
            <Col span={12}>
              {decorateField('withPension', {
                title: t('bonus.edit.form.with_pension'),
                noBlur: true,
                valueOnChecked: true,
              })(<Switch disabled={!props.editing} />)}
            </Col>
          )}
          {hasVacation && (
            <Col span={12}>
              {decorateField('withVacation', {
                title: t('bonus.edit.form.with_vacation'),
                noBlur: true,
                valueOnChecked: true,
              })(<Switch disabled={!props.editing} />)}
            </Col>
          )}
        </Row>
      )}
      {(askForDraft || getFieldValue('type') === 'Honorary Gift') && (
        <Row>
          {askForDraft && (
            <Col span={12}>
              {decorateField('approved', {
                placeholder: t('bonus.edit.form.approved'),
                valueOnChecked: true,
              })(
                <Switch
                  disabled={!props.editing}
                  checkedChildren={t('bonus.edit.form.approved.checked')}
                  unCheckedChildren={t('bonus.edit.form.approved.unchecked')}
                />
              )}
            </Col>
          )}
          {getFieldValue('type') === 'Honorary Gift' && (
            <Col span={12}>
              {decorateField('asInKind', {
                placeholder: t('bonus.edit.form.as_in_kind'),
                noBlur: true,
                valueOnChecked: true,
              })(<Switch disabled={!props.editing} />)}
            </Col>
          )}
        </Row>
      )}
      {hasCategories && (
        <Row>
          <Col span={24}>
            {decorateField('category', {
              placeholder: t('bonus.edit.form.category'),
            })(
              <Select dropdownMatchSelectWidth={false} disabled={!props.editing}>
                {categories.map((category) => {
                  return (
                    <Select.Option key={category} value={category}>
                      {formatOneTimePayCategory(category)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <OneTimePayCostCenter {...props} />
      {!noTitleInput && (
        <Row>
          <Col span={24}>
            <OneTimePayTitle
              {...props}
              maxLength={restrictedTo58Characters ? 58 : undefined}
              hardLengthLimit={restrictedTo58Characters}
            />
            {getFieldValue('asInKind') && (
              <p style={{ fontWeight: 'bold' }}>{t('bonus.edit.form.as_in_kind.no_title')}</p>
            )}
          </Col>
        </Row>
      )}
      <OneTimePayAccountingText {...props} />
      {props.editing && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
      {props.oneTimePays.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, BonusResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      type: 'Bonus',
      category: 'DKArt-0000',
      useRate: false,
      approved:
        !props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects,
      withPension: true,
      withVacation: true,
      asInKind: false,
    }
    if (props.oneTimePayID) {
      const oneTimePay = props.oneTimePays.oneTimePays.find((oneTimePay) => oneTimePay.id === props.oneTimePayID)
      if (oneTimePay) {
        fields.dispositionDate = oneTimePay.dispositionDate
        fields.approved = oneTimePay.approved
        switch (oneTimePay.type) {
          case 'Bonus':
            fields.type = 'Bonus'
            break
          case 'Bonus No Pension':
            fields.type = 'Bonus'
            fields.withPension = false
            fields.withVacation = true
            break
          case 'Bonus No Vacation':
            fields.type = 'Bonus'
            fields.withPension = true
            fields.withVacation = false
            break
          case 'Bonus No Vacation and Pension':
            fields.type = 'Bonus'
            fields.withPension = false
            fields.withVacation = false
            break
          case 'Fritvalg Increase':
            fields.type = 'Fritvalg Increase'
            fields.withPension = true
            fields.withVacation = true
            break
          case 'Fritvalg Increase No Pension':
            fields.type = 'Fritvalg Increase'
            fields.withPension = false
            fields.withVacation = true
            break
          case 'Fritvalg Increase No Vacation':
            fields.type = 'Fritvalg Increase'
            fields.withPension = true
            fields.withVacation = false
            break
          case 'Fritvalg Increase No Vacation and Pension':
            fields.type = 'Fritvalg Increase'
            fields.withPension = false
            fields.withVacation = false
            break
          case 'SH-Dage Increase':
            fields.type = 'SH-Dage Increase'
            fields.withPension = true
            fields.withVacation = true
            break
          case 'SH-Dage Increase No Pension':
            fields.type = 'SH-Dage Increase'
            fields.withPension = false
            fields.withVacation = true
            break
          case 'SH-Dage Increase No Vacation':
            fields.type = 'SH-Dage Increase'
            fields.withPension = true
            fields.withVacation = false
            break
          case 'SH-Dage Increase No Vacation and Pension':
            fields.type = 'SH-Dage Increase'
            fields.withPension = false
            fields.withVacation = false
            break
          default:
            fields.type = oneTimePay.type
            break
        }
        fields.category = oneTimePay.category || 'DKArt-0000'
        fields.amount = formatInputNumber(oneTimePay.amount)
        fields.rate = oneTimePay.rate ? formatInputNumber(oneTimePay.rate) : undefined
        fields.units = oneTimePay.units ? formatInputNumber(oneTimePay.units) : undefined
        if (!!fields.rate && !!fields.units) {
          fields.useRate = true
        }
        fields.title = oneTimePay.title
        fields.costCenterID = oneTimePay.costCenterID
        fields.accountingText = oneTimePay.accountingText
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'amount':
      case 'rate':
      case 'units':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      // @ts-expect-error Permit fallthrough
      case 'type': {
        const type = val as OneTimePayType
        if (type === 'G-Dage') {
          values.category = 'DKArt-0103'
        } else {
          values.category = 'DKArt-0000'
        }
        if (allValues.type === 'Honorary Gift' && type !== 'Honorary Gift') {
          values.asInKind = false
        }
        if (type === 'Overtime Payout' && !allValues.units) {
          values.units = '0'
        }
        if (type === 'Free Phone') {
          values.amount = '0'
        }
      }
      // fallthrough
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values: Fields): BonusResult => {
    const result: BonusResult = {
      dispositionDate: values.dispositionDate!,
      type: values.type,
      category: values.category,
      amount: forceParseInputNumber(values.amount),
      title: values.title!,
      approved: values.approved,
      costCenterID: values.costCenterID,
      accountingText: values.accountingText,
    }
    if (values.useRate) {
      result.rate = forceParseInputNumber(values.rate)
      result.units = forceParseInputNumber(values.units)
      result.amount = 0
    } else {
      result.rate = undefined
      result.units = undefined
    }
    if (values.type === 'Overtime Payout') {
      result.units = forceParseInputNumber(values.units)
      result.rate = undefined
      result.amount = 0
    }
    if (result.accountingText === '') {
      result.accountingText = undefined
    }
    if (result.type === 'Bonus') {
      if (!values.withPension && !values.withVacation) {
        result.type = 'Bonus No Vacation and Pension'
      } else if (!values.withVacation) {
        result.type = 'Bonus No Vacation'
      } else if (!values.withPension) {
        result.type = 'Bonus No Pension'
      }
    }
    if (result.type === 'Fritvalg Increase') {
      if (!values.withPension && !values.withVacation) {
        result.type = 'Fritvalg Increase No Vacation and Pension'
      } else if (!values.withVacation) {
        result.type = 'Fritvalg Increase No Vacation'
      } else if (!values.withPension) {
        result.type = 'Fritvalg Increase No Pension'
      }
    }
    if (result.type === 'SH-Dage Increase') {
      if (!values.withPension && !values.withVacation) {
        result.type = 'SH-Dage Increase No Vacation and Pension'
      } else if (!values.withVacation) {
        result.type = 'SH-Dage Increase No Vacation'
      } else if (!values.withPension) {
        result.type = 'SH-Dage Increase No Pension'
      }
    }
    if (result.type === 'Free Phone') {
      result.title = 'Fri telefon'
    }
    if (result.type === 'ATP') {
      result.title = 'ATP'
    }
    if (
      result.type === 'Tax Deduction Modification Increase' ||
      result.type === 'Tax Deduction Modification Decrease'
    ) {
      result.title = 'Skattedage'
    }
    if (result.type === 'Honorary Gift' && values.asInKind) {
      result.type = 'Honorary Gift Benefit In Kind'
    }
    return result
  },
})(BonusEditForm)
