import { addMonths, differenceInYears, subMonths, subWeeks } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import Dashboard from '../../model/dashboard'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import Swipe from '../../model/swipe'
import Transfer from '../../model/transfer'
import { DateFormat } from '../../model/types'
import { WarningType } from '../../model/warning'
import { AlertReducer } from '../../reducers/alerts'
import { WarningReducer } from '../../reducers/warnings'
import {
  buildDate,
  formatAPIDate,
  formatDateTime,
  formatShortDate,
  getDate,
  isSameOrAfter,
  isSameOrBefore,
  isTimeAfter,
  trimCurrentYear,
} from '../../utils/date-utils'
import { formatPaymentMethod, formatPayRollStatus, formatTransferType } from '../../utils/format-utils'
import { parseNationalID } from '../../utils/national-id-utils'
import { formatCurrency, formatDisplayNumber, formatNumber, formatOrdinalSuffix } from '../../utils/number-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { t, tx } from '../../utils/translation-utils'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Tooltip from '../elements/tooltip'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import Onboarding from './Onboarding'

import './Dashboard.css'

type Props = {
  alerts: AlertReducer
  company: Company
  companyFeatures: List<CompanyFeature>
  dashboards: List<Dashboard>
  employees: List<Employee>
  payRolls: List<PayRoll>
  swipes: List<Swipe>
  transfers: List<Transfer>
  warnings: WarningReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteWarning: (id: string) => Promise<boolean | void>
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
}

export default function DashboardComponent(props: Props): ReactElement | null {
  const [anniversaryLimit, setAnniversaryLimit] = useState(10)

  const hasPhoneSupportFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Phone Support')
  }

  const hasReimbursementVouchersFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Reimbursement Vouchers')
  }
  const hasSwipeEnabled = (): boolean => {
    return props.company?.enableSwipe || props.swipes.size > 0
  }

  const getDashboard = () => {
    return props.dashboards.find((company) => company.id === props.company.id)
  }

  const getTotalTransfersThisYear = (): string => {
    const dashboard = getDashboard()
    if (!dashboard) {
      return '-'
    }
    return formatCurrency(dashboard.totalTransfersThisYear, 0)
  }
  const getAverageTransfersLastYear = (): string => {
    const dashboard = getDashboard()
    if (!dashboard) {
      return '-'
    }
    return formatCurrency(dashboard.averageTransfersLastYear, 0)
  }

  type CalendarEntry = {
    id: string
    key: string
    period: string
    statusText: ReactElement | string
    statusColor: string
    amount: string
    link?: string
  }
  const getCalendarEntries = (): CalendarEntry[] => {
    return props.payRolls.reduce((entries: CalendarEntry[], payRoll) => {
      if (entries.length >= 5) {
        return entries
      }
      let status: ReactElement | string = formatPayRollStatus(payRoll)
      let color = 'var(--sally-yellow)'
      let amount = formatCurrency(payRoll.totalTransfer, 0)
      let linkable = true
      switch (convertPayRollStatus(payRoll)) {
        case 'Scheduled':
        case 'Awaiting':
        case 'Unknown':
          amount = ''
          color = 'var(--sally-grey)'
          linkable = false
          break
        case 'Approved':
        case 'Automatic Approval':
        case 'Success':
        case 'No Approval':
          color = 'var(--sally-green)'
          break
        case 'Cancelled':
        case 'Rejected':
        case 'Failed':
          color = 'var(--sally-red)'
          break
        case 'Awaiting Approval': {
          const deadline = payRoll.tasks[0].approvalDeadline
          status = (
            <>
              {deadline
                ? t('dashboard.pay_rolls.awaiting_approval_deadline', {
                    date: trimCurrentYear(formatShortDate(deadline)),
                  })
                : t('dashboard.pay_rolls.awaiting_approval')}
            </>
          )
          if (deadline) {
            status = (
              <Tooltip
                title={
                  <>
                    {t('dashboard.pay_rolls.awaiting_approval')}
                    <br />
                    {t('dashboard.pay_rolls.awaiting_approval_deadline_tooltip', {
                      date: trimCurrentYear(formatDateTime(deadline)),
                    })}
                  </>
                }
              >
                {status}
              </Tooltip>
            )
          }
          break
        }
        case 'Tentative':
        case 'Reviewed':
        default:
          break
      }
      entries.push({
        id: payRoll.id,
        key: payRoll.id,
        period: formatShortDate(payRoll.salaryPeriod.start) + ' - ' + formatShortDate(payRoll.salaryPeriod.end),
        statusText: status,
        statusColor: color,
        amount,
        link: linkable ? '/' + paths.PAY_ROLLS + '/' + payRoll.id : undefined,
      })
      return entries
    }, [])
  }

  type AnniversaryRow = {
    key: string
    id: string
    employee: string
    type: 'Birthday' | 'Anniversary'
    date: string
    sortDate: DateFormat
    age: number
  }
  const getAnniversaryEntries = (): AnniversaryRow[] => {
    const from = subWeeks(getDate(), 1)
    const to = addMonths(getDate(), 1)
    const currentYear = getDate().getFullYear()
    return props.employees
      .filter((employee) => !!employee.activeEmployment && employee.employmentStatus !== 'Terminated')
      .reduce((anniversaries: AnniversaryRow[], employee) => {
        let birthDay = parseNationalID(employee.nationalIDType, employee.nationalID)
        if (!birthDay && employee.birthDate) {
          birthDay = getDate(employee.birthDate)
        }
        if (birthDay) {
          const thisYear = buildDate(currentYear, birthDay.getMonth(), birthDay.getDate())
          if (isSameOrAfter(thisYear, from) && isSameOrBefore(thisYear, to)) {
            anniversaries.push({
              key: `birthday-${employee.id}`,
              id: employee.id,
              employee: employee.name || employee.email || '-',
              type: 'Birthday',
              date: trimCurrentYear(formatShortDate(thisYear)),
              sortDate: formatAPIDate(thisYear),
              age: differenceInYears(thisYear, birthDay),
            })
          }
        }
        if (employee.activeEmployment) {
          const startDate = getDate(employee.activeEmployment.startDate)
          const thisYear = buildDate(currentYear, startDate.getMonth(), startDate.getDate())
          if (isSameOrAfter(thisYear, from) && isSameOrBefore(thisYear, to)) {
            const diffAge = differenceInYears(thisYear, startDate)
            if (diffAge > 0 && diffAge % 5 === 0) {
              // only for anniversaries that are divisible by 5
              anniversaries.push({
                key: `anniversary-${employee.id}`,
                id: employee.id,
                employee: employee.name || employee.email || '-',
                type: 'Anniversary',
                date: trimCurrentYear(formatShortDate(thisYear)),
                sortDate: formatAPIDate(thisYear),
                age: diffAge,
              })
            }
          }
        }
        return anniversaries
      }, [])
      .sort((a, b) => a.sortDate.localeCompare(b.sortDate))
  }

  type NotificationRow = {
    key: string
    warningType: WarningType
    level: 'error' | 'warning'
    title: string
    date: string
    link?: string
  }

  const getNotifications = (): NotificationRow[] => {
    const compareTime = subMonths(getDate(), 6)
    return props.warnings.warnings
      .filter(
        (warning) =>
          isTimeAfter(getDate(warning.createdAt), compareTime) &&
          (warning.warningType === 'EmployeeInvalidNationalID' ||
            warning.warningType === 'VoucherBookingFailed' ||
            warning.warningType === 'NETSOnboardingFailed' ||
            warning.warningType === 'NotRegisteredAsEmployer' ||
            warning.warningType === 'EmployeeMissingContract')
      )
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      .reduce((list: NotificationRow[], warning) => {
        let title = ''
        let employee
        let link
        switch (warning.warningType) {
          case 'EmployeeInvalidNationalID':
          case 'EmployeeMissingContract':
            employee = props.employees.find((employee) => employee.id === warning.subjectID)
            break
        }
        switch (warning.warningType) {
          case 'EmployeeInvalidNationalID':
            if (!employee) {
              return list
            }
            title = t('dashboard.notifications.invalid_national_id', { name: employee.name })
            link = '/' + paths.EMPLOYEES + '/' + employee.id + '/profile'
            break
          case 'EmployeeMissingContract':
            if (!employee) {
              return list
            }
            title = t('dashboard.notifications.missing_contract', { name: employee.name })
            link = '/' + paths.EMPLOYEES + '/' + employee.id + '/' + paths.CONTRACTS + '/' + paths.ADD
            break
          case 'VoucherBookingFailed':
            // don't repeat this
            if (list.some((row) => row.warningType === warning.warningType)) {
              return list
            }
            title = t('dashboard.notifications.voucher_booking_failed')
            link = '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING
            break
          case 'NETSOnboardingFailed':
            title = t('dashboard.notifications.nats_onboarding_failed')
            link = '/' + paths.COMPANIES + '/' + warning.companyID + '/payments'
            break
          case 'NotRegisteredAsEmployer':
            title = t('dashboard.notifications.not_registered_as_employer')
            break
          case 'FixCreditCardIntegration':
            title = t('dashboard.notifications.fix_credit_card_integration')
            link = '/' + paths.COMPANIES + '/' + warning.companyID + '/invoices'
            break
        }
        list.push({
          key: warning.id,
          warningType: warning.warningType,
          level: 'error',
          title,
          date: formatShortDate(warning.createdAt),
          link,
        })
        return list
      }, [])
      .slice(0, 5)
  }

  const getNextTransfers = () => {
    return props.transfers
      .filter((transfer) => isSameOrBefore(getDate(), getDate(transfer.paymentDate)))
      .map((transfer) => ({
        key: transfer.id,
        description: formatTransferType(transfer.type, transfer.transferDestinationType),
        recipient: transfer.recipient,
        amount: formatCurrency(transfer.amount, 2),
        paymentDate: formatShortDate(transfer.paymentDate),
        method: formatPaymentMethod(transfer.paymentMethod),
      }))
      .toArray()
      .slice(0, 5)
  }

  const company = props.company
  const employees = props.employees.filter((employee) => {
    return employee.affiliationType !== 'Freelancer' && employee.employmentStatus !== 'Terminated'
  })
  const waitingEmployees = employees.filter((employee) => {
    return employee.onboardingState === 'Draft' || !employee.earliestMutableContract
  })

  const calendarEntries = getCalendarEntries()
  const anniversaryEntries = getAnniversaryEntries()
  const unshownAnniversaries = anniversaryEntries.length - anniversaryLimit
  const nextTransfers = getNextTransfers()
  const registrations = getDashboard()
  const notifications = getNotifications()
  return (
    <div className="dashboard">
      {!!company && (
        <Onboarding
          company={company}
          employees={props.employees}
          warnings={props.warnings}
          deleteWarning={props.deleteWarning}
          enableCompanySettings={props.enableCompanySettings}
          disableCompanySettings={props.disableCompanySettings}
        />
      )}

      <div className="dashboard-main">
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
        <Row>
          <Col span={waitingEmployees.size > 0 ? 6 : 8}>
            <Card
              className="dashboard-stats-card icon-stats-blue"
              onClick={() => jsBrowserHistory.push('/' + paths.PAY_ROLLS)}
            >
              <div className="stat-large">{getTotalTransfersThisYear()}</div>
              <div className="stat-small">{t('dashboard.main_boxes.total_transfers_this_year')}</div>
            </Card>
          </Col>
          <Col span={waitingEmployees.size > 0 ? 6 : 8}>
            <Card
              className="dashboard-stats-card icon-graph-green"
              onClick={() => jsBrowserHistory.push('/' + paths.PAY_ROLLS)}
            >
              <div className="stat-large">{getAverageTransfersLastYear()}</div>
              <div className="stat-small">{t('dashboard.main_boxes.average_transfers_last_year')}</div>
            </Card>
          </Col>
          <Col span={waitingEmployees.size > 0 ? 6 : 8}>
            <Card
              className="dashboard-stats-card icon-user-orange"
              onClick={() => jsBrowserHistory.push('/' + paths.EMPLOYEES)}
            >
              <div className="stat-large">{formatNumber(employees.size, 0)}</div>
              <div className="stat-small">{t('dashboard.main_boxes.employee', { count: employees.size })}</div>
            </Card>
          </Col>
          {waitingEmployees.size > 0 && (
            <Col span={6}>
              <Card
                className="dashboard-stats-card icon-user-orange"
                onClick={() => jsBrowserHistory.push(`/${paths.EMPLOYEES}/${paths.AWAITING_CONTRACT}`)}
              >
                <div className="stat-large">{formatNumber(waitingEmployees.size, 0)}</div>
                <div className="stat-small">
                  {t('dashboard.main_boxes.employee_awaiting', { count: waitingEmployees.size })}
                </div>
              </Card>
            </Col>
          )}
        </Row>

        <Row>
          <Col span={10}>
            {notifications.length > 0 && (
              <Card className="dashboard-notifications-card">
                <Headline>
                  <Link to={'/' + paths.NOTIFICATIONS}>{t('dashboard.notifications.title')}</Link>
                </Headline>
                <div className="dashboard-notifications-inner">
                  {notifications.map((row) => {
                    const className =
                      'dashboard-notifications-row ' +
                      (row.level === 'error'
                        ? 'dashboard-notifications-row-error'
                        : 'dashboard-notifications-row-warning')
                    if (!row.link) {
                      return (
                        <div key={row.key} className={className}>
                          <span className="title">{row.title}</span>
                          <small>{row.date}</small>
                        </div>
                      )
                    }
                    return (
                      <Link to={row.link} key={row.key} className={className}>
                        <span className="title">{row.title}</span>
                        <small>{row.date}</small>
                      </Link>
                    )
                  })}
                </div>
              </Card>
            )}
            <Card className="dashboard-registrations-card">
              <Headline>{t('dashboard.registrations.title')}</Headline>
              <div className="dashboard-registrations-header">
                <div className="dashboard-registrations-header1">&nbsp;</div>
                <div className="dashboard-registrations-header2">{t('dashboard.registrations.header.sum')}</div>
                <div className="dashboard-registrations-header3">
                  {t('dashboard.registrations.header.need_approval')}
                </div>
              </div>
              <div className="dashboard-registrations-inner">
                <div className="dashboard-registrations-row">
                  <div className="dashboard-registrations-col1">{t('dashboard.registrations.table.hours')}</div>
                  <div className="dashboard-registrations-col2">
                    {formatNumber(registrations?.timeRegistrationsApproved, 2)}
                  </div>
                  <div className="dashboard-registrations-col3">
                    <Link
                      to={'/' + paths.TIME_REGISTRATION}
                      style={{ color: registrations?.timeRegistrationsPending ? '#f00' : undefined }}
                    >
                      {formatNumber(registrations?.timeRegistrationsPending, 2)}
                    </Link>
                  </div>
                </div>
                <div className="dashboard-registrations-row">
                  <div className="dashboard-registrations-col1">{t('dashboard.registrations.table.leave')}</div>
                  <div className="dashboard-registrations-col2">
                    {formatNumber(registrations?.leaveRegistrationsApproved, 2)}
                  </div>
                  <div className="dashboard-registrations-col3">
                    <Link
                      to={'/' + paths.LEAVE_REGISTRATION}
                      style={{ color: registrations?.leaveRegistrationsPending ? '#f00' : undefined }}
                    >
                      {formatNumber(registrations?.leaveRegistrationsPending, 2)}
                    </Link>
                  </div>
                </div>
                <div className="dashboard-registrations-row">
                  <div className="dashboard-registrations-col1">{t('dashboard.registrations.table.car_allowance')}</div>
                  <div className="dashboard-registrations-col2">
                    {formatNumber(registrations?.carAllowancesApproved, 2)}
                  </div>
                  <div className="dashboard-registrations-col3">
                    <Link
                      to={'/' + paths.CAR_ALLOWANCE}
                      style={{ color: registrations?.carAllowancesPending ? '#f00' : undefined }}
                    >
                      {formatNumber(registrations?.carAllowancesPending, 2)}
                    </Link>
                  </div>
                </div>
                {hasReimbursementVouchersFeature() && (
                  <div className="dashboard-registrations-row">
                    <div className="dashboard-registrations-col1">
                      {t('dashboard.registrations.table.reimbursement')}
                    </div>
                    <div className="dashboard-registrations-col2">
                      {formatCurrency(registrations?.reimbursementsApproved)}
                    </div>
                    <div className="dashboard-registrations-col3">
                      <Link
                        to={'/' + paths.REIMBURSEMENT_VOUCHERS}
                        style={{ color: registrations?.reimbursementsPending ? '#f00' : undefined }}
                      >
                        {formatCurrency(registrations?.reimbursementsPending)}
                      </Link>
                    </div>
                  </div>
                )}
                {hasSwipeEnabled() && (
                  <div className="dashboard-registrations-row">
                    <div className="dashboard-registrations-col1">{t('dashboard.registrations.table.swipe')}</div>
                    <div className="dashboard-registrations-col2">{formatCurrency(registrations?.swipeApproved)}</div>
                    <div className="dashboard-registrations-col3">
                      <Link
                        to={'/' + paths.SWIPE_OVERVIEW}
                        style={{ color: registrations?.swipePending ? '#f00' : undefined }}
                      >
                        {formatCurrency(registrations?.swipePending)}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </Card>
            <Card className="dashboard-graph-card">
              <div className="dashboard-graph-inner">
                <Headline>{t('dashboard.reports.title')}</Headline>
                <p>
                  {tx('dashboard.reports.description', {
                    link: (
                      <Link to={'/' + paths.COMPANIES + '/' + company.id + '/reports'}>
                        {t('dashboard.reports.link')}
                      </Link>
                    ),
                  })}
                </p>
              </div>
            </Card>
            <Card className="dashboard-anniversaries-card">
              <Headline>{t('dashboard.anniversaries.title')}</Headline>
              <div className="dashboard-anniversaries-inner">
                {anniversaryEntries.slice(0, anniversaryLimit).map((anniversary) => {
                  let text = null
                  switch (anniversary.type) {
                    case 'Birthday':
                      text = (
                        <>
                          {tx('dashboard.anniversaries.birthday_wrapper', {
                            year: formatDisplayNumber(anniversary.age),
                            ordinal_suffix: formatOrdinalSuffix(anniversary.age),
                            word: <strong>{t('dashboard.anniversaries.birthday')}</strong>,
                          })}
                        </>
                      )
                      break
                    case 'Anniversary':
                      text = (
                        <>
                          {tx('dashboard.anniversaries.anniversary_wrapper', {
                            year: formatDisplayNumber(anniversary.age),
                            ordinal_suffix: formatOrdinalSuffix(anniversary.age),
                            word: <strong>{t('dashboard.anniversaries.anniversary')}</strong>,
                          })}
                        </>
                      )
                      break
                  }
                  return (
                    <div key={anniversary.key} className="dashboard-anniversaries-row">
                      <div key="col1" className="dashboard-calendar-col1">
                        <Link to={'/' + paths.EMPLOYEES + '/' + anniversary.id}>{anniversary.employee}</Link>
                      </div>
                      <div key="col2" className="dashboard-calendar-col2">
                        {text}
                      </div>
                      <div key="col3" className="dashboard-calendar-col3">
                        {anniversary.date}
                      </div>
                    </div>
                  )
                })}
                {unshownAnniversaries > 0 && (
                  <div key={`load-more`} className="dashboard-anniversaries-row">
                    <div className="dashboard-calendar-fullcol">
                      <DumbLink onClick={() => setAnniversaryLimit((prev) => prev + 5)}>
                        {t('dashboard.anniversaries.load_more', {
                          number: unshownAnniversaries >= 5 ? 'fem' : formatDisplayNumber(unshownAnniversaries),
                        })}
                        {unshownAnniversaries > 5 && (
                          <>
                            ({' '}
                            {t('dashboard.anniversaries.load_more_out_of', {
                              number: formatDisplayNumber(unshownAnniversaries),
                            })}
                          </>
                        )}
                      </DumbLink>
                    </div>
                  </div>
                )}
                {anniversaryEntries.length === 0 && <em>{t('dashboard.anniversaries.empty')}</em>}
              </div>
            </Card>
            {hasPhoneSupportFeature() && (
              <Card className="dashboard-graph-card">
                <div className="dashboard-graph-inner">
                  <Headline>{t('dashboard.support.title')}</Headline>
                  <div className="stat-small" style={{ paddingBottom: 10 }}>
                    {t('dashboard.support.phone_introduction')}
                  </div>
                  <div className="stat-large">+45 31 16 75 72</div>
                  <div className="stat-small" style={{ paddingTop: 10 }}>
                    {t('dashboard.support.chat_alternative')}
                  </div>
                </div>
              </Card>
            )}
          </Col>
          <Col span={14}>
            <Card className="dashboard-calendar-card">
              <Headline>{t('dashboard.pay_rolls.title')}</Headline>
              <div className="dashboard-calendar-inner">
                {calendarEntries.map((row) => {
                  const cols = [
                    <div key="col2" className="dashboard-calendar-col1">
                      {row.period}
                    </div>,
                    <div key="col3" className="dashboard-calendar-col2" style={{ color: row.statusColor }}>
                      {row.statusText}
                    </div>,
                    <div key="col4" className="dashboard-calendar-col3">
                      {row.amount}
                    </div>,
                  ]
                  if (!row.link) {
                    return (
                      <div key={row.key} className="dashboard-calendar-row">
                        {cols}
                      </div>
                    )
                  }
                  return (
                    <Link to={row.link} key={row.key} className="dashboard-calendar-row">
                      {cols}
                    </Link>
                  )
                })}
                {calendarEntries.length === 0 && <em>{t('dashboard.pay_rolls.empty')}</em>}
              </div>
            </Card>
            <Card className="dashboard-transfers-card">
              <Headline>{t('dashboard.transfers.title')}</Headline>
              {nextTransfers.length > 0 && (
                <table className="dashboard-transfers dashboard-table">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>{t('dashboard.transfers.header.method')}</th>
                      <th>{t('dashboard.transfers.header.type')}</th>
                      <th>{t('dashboard.transfers.header.amount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nextTransfers.map((row) => {
                      return (
                        <tr key={row.key}>
                          <td>{row.paymentDate}</td>
                          <td>{row.method}</td>
                          <td>{row.description}</td>
                          <td>{row.amount}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {nextTransfers.length === 0 && <em className="empty-table-note">{t('dashboard.transfers.empty')}</em>}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
