import { List } from 'immutable'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Company, { CompanyMutableFields } from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import CompanyUser from '../../../model/companyUser'
import { CompanyReducer } from '../../../reducers/companies'
import { CompanyPaymentIntegrationReducer } from '../../../reducers/companyPaymentIntegrations'
import { UserReducer } from '../../../reducers/user'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { getMainMenuCompanyMenuPay, MenuContext } from '../../../utils/menu-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import PayrollApprovalCodeModal, { ApprovalCodeResult } from './PayrollApprovalCodeModal'
import PayrollTabForm, { ResultFields } from './PayrollTabForm'

type Props = {
  company: Company
  companies: CompanyReducer
  companyFeatures: List<CompanyFeature>
  companyUser?: CompanyUser
  companyUsers: List<CompanyUser>
  user: UserReducer
  userCompanies: List<CompanyUser>
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer

  addAlert: addAlertSignature
  updateCompany: (company: CompanyMutableFields) => Promise<Company | void>
  updateUserCompany: (userCompany: CompanyUser) => void
  getCompanyPaymentIntegrations: () => void
}

export default function PayrollTab(props: Props): ReactElement | null {
  const [showPayrollApprovalCodeModal, setShowPayrollApprovalCodeModal] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { companyPaymentIntegrations, getCompanyPaymentIntegrations } = props

  useEffect(() => {
    if (!companyPaymentIntegrations.loaded && !companyPaymentIntegrations.loading) {
      getCompanyPaymentIntegrations()
    }
  }, [companyPaymentIntegrations, getCompanyPaymentIntegrations])

  const { companies, userCompanies, addAlert, company } = props
  const previousCompanies = usePrevious(companies)
  const previousUserCompanies = usePrevious(userCompanies)

  const { menu } = useContext(MenuContext)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        addAlert('success', t('company_pay_roll.alert.success', { name: company.name }), { timeout: 5 })
      }
    }

    regularComponentDidUpdate(companies.error, error, setError)
  }, [previousCompanies, previousUserCompanies, companies, company, userCompanies, addAlert, error])

  const hasPaymentIntegration = (): boolean => {
    return companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => (v.paymentIntegrationType === 'Stripe' || v.paymentIntegrationType === 'NETS') && v.status !== 'Expired'
    )
  }

  const isAdmin = (): boolean => {
    if (props.user.userType === 'Admin' || props.user.userType === 'Support') {
      return true
    }
    if (!props.companyUser) {
      return false
    }
    return props.companyUser.permissions.some((permission) => permission.permission === 'Admin')
  }

  const handleSubmit = (values: ResultFields) => {
    const company: CompanyMutableFields = {
      ...props.company,
      rulePayRollRunApproval: values.rulePayRollRunApproval,
      numberOfPayRollApprovers: values.numberOfPayRollApprovers,
      numberOfPayRollReviewers: values.numberOfPayRollReviewers,
      allowEmptyTaxCardMail: values.allowEmptyTaxCardMail,
      departmentApproverNotificationDaysBefore1: values.departmentApproverNotificationDaysBefore1,
      departmentApproverNotificationDaysBefore2: values.departmentApproverNotificationDaysBefore2,
    }
    if (!isAdmin()) {
      company.numberOfPayRollApprovers = undefined // can't be set when not admin
      company.numberOfPayRollReviewers = undefined
    }
    if (!company.dkSpecific) {
      return // something is wrong
    }
    company.dkSpecific.allowAutomaticZeroTaxReport = values.allowAutomaticZeroTaxReport
    props.updateCompany(company)
  }

  const handleApprovalCodeSubmit = (values: ApprovalCodeResult) => {
    setShowPayrollApprovalCodeModal(false)
    if (!values.enable) {
      return
    }
    const company: CompanyMutableFields = {
      ...props.company,
      payrollApprovalCode: values.approvalCode,
    }
    if (!isAdmin()) {
      return
    }
    props.updateCompany(company)
  }

  if (!companyPaymentIntegrations.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <TitleMenu>
        {company.askForPayrollApprovalCode ? (
          <span style={{ marginRight: '15px' }}>{t('company_pay_roll.header.approval_code.enabled')}</span>
        ) : (
          isAdmin() && (
            <Button onClick={() => setShowPayrollApprovalCodeModal(true)}>
              {t('company_pay_roll.header.approval_code.button')}
            </Button>
          )
        )}
        {!props.user.user?.uiSettings.sideMenu &&
          getMainMenuCompanyMenuPay(menu)
            .reverse()
            .map((item) => {
              if (item.type !== 'item-label') {
                return null
              }
              return (
                <Link to={item.link}>
                  <Button>{t(item.labelID)}</Button>
                </Link>
              )
            })}
      </TitleMenu>
      <Title>{t('company_pay_roll.title')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <PayrollTabForm
        company={props.company}
        companies={props.companies}
        companyFeatures={props.companyFeatures}
        companyUsers={props.companyUsers}
        isAdmin={isAdmin()}
        hasPaymentIntegration={hasPaymentIntegration}
        onSubmit={handleSubmit}
      />

      <Modal
        key={`approval-code`}
        visible={showPayrollApprovalCodeModal}
        onOk={() => setShowPayrollApprovalCodeModal(false)}
        onCancel={() => setShowPayrollApprovalCodeModal(false)}
        width={582}
        footer={null}
      >
        <PayrollApprovalCodeModal
          company={props.company}
          onSubmit={handleApprovalCodeSubmit}
          onBack={() => setShowPayrollApprovalCodeModal(false)}
        />
      </Modal>
    </Card>
  )
}
