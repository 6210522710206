const uuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

export function replaceUUIDInURL(path: string, newUUID: string): string {
  return path.replace(uuid, newUUID)
}

export function removeUUIDFromURL(path: string): string {
  // first we remove the UUID, then we remove any double // (or more), then remove any trailing /
  return path.replace(uuid, '').replace(/\/\/+/, '/').replace(/\/$/, '')
}
