import { addDays, addMonths, getISOWeek, subDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import Company from '../../../model/company'
import TimeRegistration from '../../../model/timeRegistration'
import { VacationCalendarReducer } from '../../../reducers/vacationCalendars'
import { buildDate, formatDate, isTimeBefore, isTimeSame } from '../../../utils/date-utils'
import { capitalise } from '../../../utils/string-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../button'
import Card from '../card'
import Col from '../grid/col'
import Row from '../grid/row'
import Subtitle from '../Subtitle'
import { CurrentMonth, getToday, HolidayStorage, initialiseHolidayStorage, isDayHoliday, moveMonth } from './shared'

type Props = {
  company: Company
  timeRegistrations: List<TimeRegistration>
  vacationCalendars: VacationCalendarReducer

  timeRegistrationFilter: (reg: TimeRegistration) => boolean
  formatTimeRegistration: (reg: TimeRegistration) => ReactNode
  onEditRegistration: (timeRegistrationID: string) => void
  onNewRegistration: (date: Date) => void

  getVacationCalendarYear: (companyID: string, year: number) => void
}

export default function SingleCalendar(props: Props): ReactElement | null {
  const [current, setCurrent] = useState<CurrentMonth>(getToday())
  const [holidays, setHolidays] = useState<HolidayStorage>(initialiseHolidayStorage())

  const { vacationCalendars, company, getVacationCalendarYear } = props
  useEffect(() => {
    if (
      (!vacationCalendars.years[current.year] && !vacationCalendars.loading) ||
      (!vacationCalendars.loading && !vacationCalendars.loaded)
    ) {
      getVacationCalendarYear(company.id, current.year)
    }
  }, [vacationCalendars, company, current, getVacationCalendarYear])

  const setMonth = (dir: number) => setCurrent(moveMonth(current, dir))

  const today = () => setCurrent(getToday())

  const weeks = (): Date[][] => {
    const result: Date[][] = []
    let tmpDate = buildDate(current.year, current.month, 1)
    while (tmpDate.getDay() !== 1) {
      // Monday is 1
      tmpDate = subDays(tmpDate, 1)
    }
    const nextMonth = addMonths(buildDate(current.year, current.month, 1), 1)
    while (isTimeBefore(tmpDate, nextMonth)) {
      const currentWeek: Date[] = []
      do {
        // this method will ensure we get full weeks, even if the month is cutting
        // weeks in half at either end
        currentWeek.push(tmpDate)
        tmpDate = addDays(tmpDate, 1)
      } while (tmpDate.getDay() !== 1) // Monday is 1
      result.push(currentWeek)
    }
    return result
  }

  const currentVacationCalendar = vacationCalendars.years[current.year]

  return (
    <Card className="sally-calendar sally-single-calendar">
      <Row>
        <Col span={24} className="sally-calendar-nav">
          <Button className="btn-previous" onClick={() => setMonth(-1)}>
            {t('calendar.navigation.previous_month')}
          </Button>
          <Button className="btn-today" onClick={() => today()}>
            {t('calendar.navigation.today')}
          </Button>
          <Button className="btn-next" onClick={() => setMonth(1)}>
            {t('calendar.navigation.next_month')}
          </Button>
        </Col>
      </Row>
      <Subtitle>{capitalise(formatDate(buildDate(current.year, current.month), t('date.month_of_year')))}</Subtitle>
      <table className="table-sally-calendar">
        <tbody>
          {weeks().map((week, j) => {
            return (
              <tr key={j}>
                {week.map((date, i) => {
                  const outside = date.getMonth() !== current.month
                  const isHoliday = isDayHoliday(holidays, setHolidays, currentVacationCalendar, date)
                  const regs = props.timeRegistrations.filter(
                    (reg) => props.timeRegistrationFilter(reg) && isTimeSame(reg.date, date)
                  )
                  return (
                    <React.Fragment key={i}>
                      {date.getDay() === 1 && <td className="week-number">#{getISOWeek(date)}</td>}
                      <td
                        className={
                          'date-cell' + (outside ? ' outside-month' : '') + (isHoliday ? ' employee-holiday' : '')
                        }
                      >
                        <div className="date-cell-grid">
                          <span className="date-number">
                            {date.getDate()}{' '}
                            {!outside && !isHoliday && (
                              <span
                                className="new-reg"
                                onClick={() => props.onNewRegistration(date)}
                                title={t('calendar.single.new_registration')}
                              >
                                +
                              </span>
                            )}
                          </span>
                          {regs.size > 0 && (
                            <ul>
                              {regs.map((reg) => {
                                return (
                                  <li
                                    key={reg.id}
                                    onClick={() => props.onEditRegistration(reg.id)}
                                    title={'calendar.single.edit_registration'}
                                  >
                                    {props.formatTimeRegistration(reg)}
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </div>
                      </td>
                    </React.Fragment>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Card>
  )
}
