import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import Company from '../../model/company'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import Subtitle from '../elements/Subtitle'
import TitleMenu from '../elements/TitleMenu'

type Props = {
  fullHeight?: boolean
  company?: Company

  setVisibility?: (visible: boolean) => void
}

export default function UserTerms(props: Props): ReactElement | null {
  const [scrollAtBottom, setScrollAtBottom] = useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (!ref.current) {
      return
    }
    const scrollContainer = ref.current as HTMLDivElement
    setScrollAtBottom(scrollContainer.scrollTop === scrollContainer.scrollHeight - scrollContainer.clientHeight)
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const scrollContainer = ref.current as HTMLDivElement
    scrollContainer.addEventListener('scroll', handleScroll)
    return () => scrollContainer.removeEventListener('scroll', handleScroll)
  }, [ref])

  return (
    <div className={'terms' + (props.fullHeight ? ' full-height' : '') + (scrollAtBottom ? ' terms-scrolled' : '')}>
      <div className="terms-text" ref={ref}>
        {props.company && props.fullHeight && (
          <TitleMenu>
            <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/support'}>
              <Button>Tilbage</Button>
            </Link>
          </TitleMenu>
        )}
        <Subtitle>Forretningsbetingelser for serviceydelser udbudt af Salary.dk ApS</Subtitle>
        <p>
          Disse forretningsbetingelser ("Forretningsbetingelserne") gælder som aftaleforhold mellem kunden ("Kunden") og
          Ageras Salary.dk ApS, cvr. nummer: 37628409, Fiolstræde 17, 1171 København K ("Salary").
        </p>

        <Headline>1. Serviceydelsen</Headline>
        <p>
          1.1. Salary stiller en software til rådighed via sin hjemmeside, mobile platforme eller lignende
          ("Softwaren"), som muliggør, at Kunden via selvbetjening kan foretage administration, udbetaling af løn til
          Kundens medarbejdere m.v. (i et hele benævnt "Serviceydelsen").
        </p>
        <p>
          1.2. Omfanget af Serviceydelsen defineres på baggrund af Kundens valg af servicepakke, som kan indeholde flere
          eller færre funktioner ("Servicepakken"). Valget foretages på Salary's hjemmeside og vil fremgå af Kundens
          profil. Udover Serviceydelsen kan Salary tilbyde tillægsydelser, som inkluderes i Servicepakkerne. Disse
          tillægsydelser indgår i definitionen af Serviceydelsen. Levering under de enkelte Servicepakker kan være
          betinget af en betaling.
        </p>
        <p>
          1.3. Igennem forretningsforholdet mellem Kunden og Salary opnår Kunden en adgang til at anvende Softwaren, som
          ikke er eksklusiv. Dette gælder også ved Kundens valg af tillægsydelser. Kunden kommer således ikke til at eje
          Softwaren eller Serviceydelserne eller en kopi eller del heraf. Ved Kundens valg af tillægsydelser kan der
          være selvstændige betingelser, som skal accepteres i sammenhæng med disse Forretningsbetingelser.
        </p>
        <p>
          1.4. Adgangen til at anvende Serviceydelsen, herunder Softwaren, kan alene ske til brug for Kundens formål og
          gælder udelukkende for Kunden og dennes Medarbejdere. Ved tilmelding til Serviceydelsen skal Kunden og dennes
          Medarbejdere have deres eget unikke brugernavn og kodeord til brug af Serviceydelsen. Det er Kundens pligt at
          sikre, at Kunden og Medarbejderne opretholder den fornødne sikkerhed for at beskytte brugernavn og kodeord for
          at forhindre misbrug m.v. af Serviceydelsen.
        </p>
        <p>
          1.5. I Serviceydelsen indgår hjælp til den generelle brug af Serviceydelsen. En sådan hjælp kan ydes af Salary
          eller en af Salary godkendt Tredjepart, som Kunden indgår aftale med via Serviceydelsen. Hjælp til for
          eksempel indtastning og registrering af informationer, korrektion af forkerte lønudbetalinger eller fejlagtige
          indberetninger indgår ikke i Serviceydelsen og kan ydes af Salary eller godkendte Tredjeparter mod betaling af
          den mellem parterne aftalte pris.
        </p>

        <Headline>2. Accept og Tilladelse</Headline>
        <p>
          2.1. Ved Kundens registrering på Salary's hjemmeside accepterer Kunden Forretningsbetingelserne for
          Serviceydelsen, og som det gældende aftale forhold mellem Kunden og Salary. Forretningsbetingelserne gælder
          også for tredjemand, som på vegne af Kunden måtte udføre selvbetjeningen.
        </p>
        <p>
          2.2. Serviceydelsen herunder tillægsydelser ydes til Kunden i egenskab af forretningsdrivende, herunder også
          selvom at Kundens medarbejdere opnår adgang til hele eller dele af Softwaren og indeholdte informationer i
          egenskab af medarbejdere.
        </p>
        <p>
          2.3. Kunden samtykker til, at Salary og dets Tredjeparter kan foretage markedsføring overfor Kunden og
          medarbejdere ("Medarbejderne"). Kunden kan skriftlig tilbagekalde sådant samtykke.
        </p>
        <p>
          2.4. Kunden og dennes Medarbejdere giver tilladelse til, at Salary og dets Tredjeparter markedsfører sine
          produkter og serviceydelser overfor Kunden og dennes Medarbejdere. Markedsføring kan ske på diverse medier og
          kommunikationsformer, herunder f.eks. e-mails. Markedsføringen kan gøre brug af uploadede af informationer om
          Kunden, herunder dennes Medarbejdere. Kunden accepterer, at Salary kan bruge Kundens navn som og logo til
          markedsføring. Kunden kan skriftligt tilbagekalde sådant samtykke.
        </p>
        <p>
          2.5. Kunden accepterer Salary's øvrige politikker, herunder politik for persondata og Cookies, som er
          tilgængelige i den til enhver tid gældende udgave på Salary's hjemmeside. De øvrige politikker skal ses som
          værende en integreret del af disse Forretningsbetingelser.
        </p>

        <Headline>3. Tredjeparter</Headline>
        <p>
          3.1. For at kunne levere Serviceydelsen og eventuelle tillægsydelser kan Salary gøre brug af leverandører og
          lignende forretningspartnere ("Tredjeparter"). Kunden accepterer og tillader, at informationer der uploades i
          forbindelse med Serviceydelsen kan blive behandlet af og udvekslet med Tredjeparter, som arbejder med Salary.
        </p>
        <p>
          3.2. Såfremt Tredjeparter tilbyder tillægsydelser via Salary's Serviceydelse (åben API (application
          programming interface)) er brugen af tillægsydelserne underlagt den pågældende Tredjeparts vilkår. Kunden
          fritager Salary for ethvert ansvar i forbindelse med brug af sådanne Tredjeparters tillægsydelser.
        </p>
        <p>
          3.3. Såfremt Kunden eller Medarbejdere benytter tillægsydelser fra Tredjeparter i forbindelse med brug af
          Serviceydelsen, kan der ske udveksling af informationer mellem Serviceydelsen og eventuelle tillægsydelser.
          Kunden accepter, at Salary er uden ansvar for sådan en udveksling, behandling og opbevaring af informationer
          af Tredjeparten, idet der opstår et selvstændigt retsforhold mellem Kunden og/eller Medarbejderne og
          Tredjeparten ved brug af tillægsydelse.
        </p>

        <Headline>4. Kundens pligter og ansvar</Headline>
        <p>
          4.1. Det er Kunden, herunder dennes Medarbejdere, som er ansvarlige for rigtigheden og kvaliteten af de
          informationer som registreres og benyttes i Softwaren. Det er således Kunden, som indtaster de nødvendige
          informationer, der skal anvendes i forbindelse med brugen af Serviceydelsen. Kunden skal derfor sikre sig, at
          informationerne er registrere korrekt i Softwaren. Det er Kundens ansvar at sikre at informationerne, der
          bruges i Serviceydelsen, til enhver tid er ajourført. Kunden har således pligt til også at registrere, når en
          medarbejder ikke længere er ansat hos Kunden.
        </p>
        <p>
          4.2. Som en del af Serviceydelsen vil Salary så vidt det er muligt indhente informationer fra relevante
          myndigheder og registre for at sikre, at Kundens indtastede informationer er opdaterede. Indhentningen af
          informationer kan ske løbende og automatisk. Det skal bemærkes, at såfremt informationer skal indhentes fra
          myndigheder eller registre fra andre lande end Danmark, kan det betyde, at det ikke kan indhentes automatisk.
          I sådant et tilfælde vil Kunden, herunder dennes Medarbejdere, skulle registrere informationerne selv. Det vil
          fremgå af Salary's hjemmeside, hvilke landes myndigheder og registre, som der kan indhentes informationer fra.
        </p>
        <p>
          4.3. Adgang til personlige informationer ved for eksempel at indtaste cpr. nummer kan være strafbart og
          erstatningspådragende. Det er Kundens ansvar at sikre, at Kunden og dennes Medarbejdere ikke skaffer sig
          uautoriseret adgang til personlige informationer. Overtrædelse heraf kan medføre øjeblikkelig ophævelse af
          aftaleforholdet ved mellem Kunden og Salary.
        </p>
        <p>
          4.4. For at sikre at Salary kan opfylde sine forpligtelser i forbindelse med Serviceydelsen, giver Kunden
          Salary ret til at bruge de indtastede informationer. Det er Kundens pligt til at sikre sig, at Salary har de
          fornødne tilladelser, fuldmagter, aftaler og legitimationsinformationer, der skal til for at Salary kan levere
          Serviceydelsen. Det er således også Kundens ansvar at sikre sig, at Medarbejderne har givet samtykke til den
          databehandling, som er en del af Serviceydelsen.
        </p>
        <p>
          4.5. Afhængig af hvilken Servicepakke, som Kunden har valgt, giver Serviceydelsen Medarbejderne adgang til
          visse informationer, herunder Medarbejderens egne lønsedler m.v., og Softwaren sender informationer, herunder
          lønsedler, til Medarbejdernes e-mail eller digitale postkasse. Det er Kundens ansvar, at sikre at
          Medarbejderne er vidende om adgangen til Serviceydelsen. Såfremt Medarbejderne ikke ønsker at modtage
          informationer, herunder lønsedler, igennem de af Salary udbudte muligheder i forbindelse med Serviceydelsen,
          er det Kundens eget ansvar at sikre leveringen af informationer, herunder lønsedler, sker til disse
          medarbejdere på anden vis.
        </p>
        <p>
          4.6. Det er Kundens ansvar, at lønnen er betalt og rapporteret på lovlig og korrekt vis. Det er endvidere
          Kundens ansvar de fornødne disponible midler til dækning af lønudbetalingerne er til rådighed på Kundens
          konti. Herudover er Kunden ansvarlig for, at administrationen af lønnen overholder lovgivningens krav,
          herunder til bogføring og opbevaring af originalbilag, behandling af personoplysninger, m.v.
        </p>
        <p>
          4.7. Kunden må ikke give andre adgang til Serviceydelsen, herunder Softwaren, uden at vedkommende har en
          direkte aftale med Salary. Kunden og vedkommende vil herefter begge være ansvarlige for handlinger, der
          foretages på vegne af Kunden. Såfremt vedkommende er godkendt af Salary, er Kunden berettiget til at give
          vedkommende adgang til brug for nærmere bestemte opgaver. Udføres administrationen af andre end Kunden, er
          Kunden berettiget til at give sådanne tredjeparter adgang til Serviceydelsen. Godkendte parter kan enten
          findes på Salary's hjemmeside eller ved henvendelse til Salary.
        </p>
        <p>
          4.8. Det er Kunden ansvar, at Serviceydelsen ikke bliver anvendt på en måde, som er i strid med relevant
          lovgivning eller anden regulering, eller at Salary's navn, omdømme eller goodwill lider skade. Overtrædelse
          heraf kan medføre øjeblikkelig ophævelse af aftaleforholdet ved mellem Kunden og Salary.
        </p>
        <p>
          4.9. Ved Kundens accept af Forretningsbetingelserne bekræftes det samtidig, at Kunden ikke er i konkurrence
          med Salary eller arbejder for virksomheder, der er i konkurrence med Salary. I konkurrence med defineres ved,
          at Kunden arbejder i eller er ejer af en virksomhed, som sælger samme produkt som Serviceydelsen. Alt
          software, design, arkitektur, tekst og lignende er beskyttet af ophavsretten samtidig med, at navne og
          egenudviklede termer kan være anvendte varemærker. Enhver overtrædelse heraf kan blive retsforfulgt, og krav
          om betaling vil finde sted.
        </p>

        <Headline>5. Pris og betalingsvilkår</Headline>
        <p>
          5.1. Afhængig af Kundens valg af Servicepakke vil prisen være afstemt derefter. Endvidere kan prisen stige ved
          valg af tillægsydelser, som ikke er en del af en Servicepakke. Prisen for Servicepakkerne vil oplyst i danske
          kroner (DKK) og eksklusiv moms og forefindes på Salary's hjemmeside.
        </p>
        <p>
          5.2. Salary kan ændre priserne med en måneds varsel. Ændringer i valutakurser, afgifter, forsikring,
          nettoprisindekset og omkostninger til Tredjeparter medfører, at Salary kan justere priserne, således at Salary
          stilles uændret. Ved en sådant ændring kan Kunden blive adviseret herom via Salary's Hjemmeside eller e-mail.
          Såfremt Kunden ikke er enig i en ændring af prisen, kan Kunden opsige aftaleforholdet mellem Kunden og Salary
          med øjeblikkelig virkning, dog således, at Kunden fortsat skal betale for faktisk foretaget brug af
          Serviceydelsen indtil ophørstidspunktet. Kunden kan ikke i den forbindelse rejse erstatnings- eller andre
          kompensationskrav mod Salary.
        </p>
        <p>
          5.3. Efter hver lønafregning vil Serviceydelsen automatisk generere en faktura til Kunden. Det fakturerede
          beløb for Serviceydelsen vil blive betalt af Kunden til Salary igennem en tilmeldte betalingsløsning anerkendt
          af Salary.
        </p>
        <p>
          5.4. Såfremt Kunden ikke betaler det fakturerede beløb rettidigt, kan Salary iværksætte en rykkerprocedure der
          indeholder frister og gebyrer ved brug af Kundens opgivne e-mail. Kunden accepterer, at rykkere afsendt af
          Salary til Kunden skal anses for leverede ved brug af Kundens e-mail. Såfremt den manglende betaling for
          Serviceydelsen fortsætter, kan adgangen til Serviceydelsen og medfølgende tillægsydelser spærres. Spærringen
          ophæves ved modtagelse af Kundens betaling, medmindre Salary har opsagt Forretningsbetingelserne med Kunden.
        </p>
        <p>
          5.5. Ved for sen betaling af det til Kunden fakturerede tillægges en rente af det forfaldne beløb med 3% pr.
          måned fra forfaldstid og til betaling sker.
        </p>

        <Headline>6. Informationer og sikkerhed</Headline>
        <p>
          6.1. Informationer, herunder informationer om Medarbejderne, som er registreret i Serviceydelsen, er Kundens
          ejendom. Kunden kan kræve at få de registrerede informationer udleveret i et elektronisk format valgt af
          Salary. Endvidere har Kunden mulighed for at hente elektroniske kopier og/eller foretage udskrifter af de
          lønsedler, som er genereret igennem Serviceydelsen. Ved Forretningsbetingelsernes ophør eller en Medarbejders
          fratrædelse, jf. nedenfor, vil Kunden have adgang til informationerne i seks måneder, hvorefter Salary uden
          yderligere ansvar kan slette informationerne permanent.
        </p>
        <p>
          6.2. Kundens Medarbejdere har adgang til at hente elektroniske kopier og/eller foretage udskrifter af deres
          lønsedler, som er genereret igennem Serviceydelsen. Adgangen hertil ophører seks måneder efter
          Forretningsbetingelsernes ophør mellem Kunden og Salary, eller såfremt Medarbejdere ikke længere er ansat hos
          Kunden, hvorefter Salary uden yderligere ansvar kan slette informationerne permanent.
        </p>
        <p>
          6.3. Kunden og Medarbejderne har efter de seks måneders adgang ikke længere ret til at kræve udleveret
          informationer registreret eller brugt i Serviceydelsen. Herudover er Salary uanset de i øvrige bestemmelser i
          dette afsnit berettiget til fortsat at opbevare informationerne så længe og i det omfang Salary finder det
          nødvendigt for at kunne opfylde en juridisk forpligtelse. Salary er ligeledes berettiget til at opbevare
          Kundens og Medarbejdernes informationer efter aftalens ophør med henblik på eventuel opfølgning på
          aftaleforholdet mellem Kunden og Salary og til markedsføringsformål, samt for at anvende informationerne i
          anonymiseret form til statistik og analyse.
        </p>
        <p>
          6.4. De informationer, som Kunden eller Medarbejderne har registreret og benytter i Serviceydelsen, har Salary
          som udgangspunkt ikke adgang til. Undtagelsen hertil er Salary's særligt udpegede medarbejdere, som i visse
          tilfælde har adgang til Kundens og Medarbejdernes informationer. Disse udpegede medarbejdere vil være
          underlagt tavshedspligt.
        </p>
        <p>
          6.5. Salary bruger Tredjeparter til at levere services og hardware såsom servere og databaser til at
          understøtte Serviceydelsen til Kunden. Ved brug af Serviceydelsen vil der ske udveksling af informationer om
          Kunden og Medarbejderne mellem Serviceydelsen, Kunden og de relevante myndigheder og registre, som er
          nødvendige for, at Serviceydelsen kan leveres. Udvekslingen af informationerne vil være tilsvarende den, hvis
          Kunden selv varetog Serviceydelsen.
        </p>
        <p>
          6.6. Udveksling af eller adgang til informationer, f.eks. persondata, via Serviceydelsen mellem Kunden og
          tredjemænd, som hjælper Kunden, er Kundens ansvar.
        </p>
        <p>
          6.7. Salary kan i særlige tilfælde overdrage statistik informationer udledt via informationerne i
          Serviceydelsen til tredjemand. Sådanne informationer vil være anonymiseret således, at der ikke kan ske
          overdragelse af persondata, som kan identificere Kunden eller dennes Medarbejdere.
        </p>
        <p>
          6.8. Hvor det skønnes rimeligt for eksempel ud fra en værditabsbetragtning kan Salary give tredjemand og
          myndigheder adgang til Kundens og Medarbejdernes informationer, f.eks. i forbindelse med dom, myndighedskrav
          Kundens konkurs og Medarbejderes dødsfald.
        </p>
        <p>
          6.9. Salary har ikke ansvar for opbevaring af informationer efter Forretningsbetingelserne ophør, med mindre
          er anført i disse Forretningsbetingelser.
        </p>
        <p>
          6.10. Salary har truffet de fornødne sikkerhedsforanstaltninger mod, at informationerne brugt i Serviceydelsen
          ulovligt eller hændeligt forringes, fortabes, eller tilintetgøres, samt mod, at informationerne misbruges,
          kommer til uvedkommendes kendskab eller behandles i strid med lov om behandling af personoplysninger.
        </p>
        <p>
          6.11. Ved brug af Serviceydelsen, hvor Kunden anvender informationer, brugernavne eller kodeord, som stammer
          fra tredjemand, indestår Kunden for, at videregivelse af sådanne informationer og Salary's databehandling af
          sådanne informationer ikke krænker rettigheder eller aftale med tredjemand. Kunden skadesløsholder Salary for
          ethvert tab, som Salary måtte lide som følge af denne bestemmelse.
        </p>

        <Headline>7. Ændringer og overdragelse af Serviceydelsen eller vilkår</Headline>
        <p>
          7.1. Ændringer af Serviceydelsen og tillægsydelser kan foretages af Salary. Endvidere er Salary berettiget til
          at ændre formen og strukturen af Serviceydelsen og eventuelle tillægsydelser. Enhver ændring kan ske med eller
          uden varsel og i henhold til disse Forretningsbetingelser. En ændring kan påvirke dele af Serviceydelsen,
          herunder informationer, som er registreret og gemt i Softwaren.
        </p>
        <p>
          7.2. Forretningsbetingelserne kan til enhver tid ændres af Salary uden varsel til Kunden. Dog vil Salary
          stræbe efter at give et varsel via Salary's hjemmeside. Forretningsbetingelserne er tilgængelige på Salary's
          hjemmeside. Ved Kundens fortsatte brug af Serviceydelsen efter ændringer i Forretningsbetingelserne er
          foretaget, betragtes ændringerne som accepteret. Kunden har en forpligtelse til at holde sig løbende
          orienteret om ændringer i Forretningsbetingelser. Hvis Kunden ikke kan acceptere ændringerne, kan Kunden
          betragte sig som opsagt under forudsætning at meddelelse gives til Salary. Erstatnings- eller andre
          kompensationskrav mod Salary vil i den forbindelse ikke kunne rejses af Kunden.
        </p>
        <p>
          7.3. Rettigheder og pligter, som er givet til Salary ved disse Forretningsbetingelser, kan Salary overdrage
          helt eller delvist til koncernforbundne virksomheder eller tredjemand.
        </p>
        <p>
          7.4. Kunden har ikke ret til hverken helt eller delvist at overdrage rettigheder og pligter samt adgang til
          Serviceydelsen til tredjemand. Kunden kan dog give adgang til rådgivere eller tredjemand som nærmere beskrevet
          i disse Forretningsbetingelser.
        </p>

        <Headline>8. Immaterielle rettigheder</Headline>
        <p>
          8.1. Med undtagelse af Kundens informationer, som Kunden har registreret i systemet, er Softwaren og de
          informationer, som Softwaren genererer, beskyttet af ophavsret og andre immaterielle rettigheder og tilhører
          eller er licenseret til Salary. Ved Serviceydelsen modtager Kunden alene en brugsret, og Salary's og/eller
          tredjeparters immaterielle rettigheder overdrages ikke til Kunden.
        </p>
        <p>
          8.2. Ved enhver mistanke om en krænkelse eller reel krænkelse af Salary's immaterielle rettigheder eller
          uautoriseret brug af Serviceydelsen, som kunden får kendskab til, skal Kunden give meddelelse til Salary.
        </p>
        <p>
          8.3. De informationer, som Kunden og dennes Medarbejdere registrerer i forbindelse med brugen af
          Serviceydelsen, tilhører Kunden og dennes Medarbejdere. Salary har ved Kundens tiltrædelse af disse
          Forretningsbetingelser fået tilladelse til at bruge førnævnte informationer, herunder f.eks. Kundens og
          Medarbejderes billeder, logo, kontrakter, overenskomster, således at Salary kan levere Serviceydelsen samt
          foretage statistik i anonymiseret form.
        </p>

        <Headline>9. Salary's ansvar og tavshedspligt</Headline>
        <p>
          9.1. Det er Kundens ansvar at sikre, at de informationer, som bliver registreret i forbindelse med brugen af
          Serviceydelsen, ikke krænker tredjemands rettigheder eller kan virke stødende eller er i strid med relevant
          lovgivning eller anden regulering.
        </p>
        <p>
          9.2. Serviceydelsen stilles til rådighed af Salary i den til enhver tid værende stand, som den måtte være på
          tidspunktet for Kundens brug heraf. Salary fraskriver sig enhver garanti, tilsikring, indeståelse, anprisning
          eller andre vilkår, udover hvad er fremgår af disse Forretningsbetingelser.
        </p>
        <p>
          9.3. Salary indestår ikke for lønudbetalinger, skattebetalinger, betaling af pensionsbidrag eller andre af
          Kundens betalinger overfor Kundens Medarbejdere eller tredjeparter.
        </p>
        <p>
          9.4. I forbindelse med Kundens brug af Serviceydelsen fraskriver Salary sig ethvert ansvar for tab, som Kunden
          måtte pådrage sig, uanet om tabet opstår i eller udenfor kontrakt, herunder for driftstab, følgeskader eller
          andre indirekte tab, tab af informationer, tab begrundet i produktansvar eller tab der er opstået som følge af
          simpel uagtsomhed fra Salary's side.
        </p>
        <p>
          9.5. Kunden har en pligt til at meddele Salary om fejl ved Serviceydelsen, som Kunden bliver opmærksom på.
          Salary er alene pligtig at rette fejlen, som Kunden eller Salary er blevet opmærksom på.
        </p>
        <p>
          9.6. Tillægsydelser og anden service udbudt af Tredjeparter, som er tilgængelige i forbindelse med
          Serviceydelsen, og som Kunden aktivt selv har valgt at benytte, er Salary ikke ansvarlig for. Salary kan
          derfor ikke holdes ansvarlig for rigtigheden, kvaliteten, fuldstændigheden og pålideligheden m.v. af
          informationerne og ej heller de resultater, som genereres igennem tillægsydelserne, eller for Tredjeparters
          funktionalitet, tilgængelig eller sikkerhed. Det påhviler Kunden at føre bevis for, at et af Kunden lidt tab
          skyldes ansvarspådragende fejl fra Salary's side.
        </p>
        <p>
          9.7. Kunden accepterer, at uanset hvilket tab eller ansvarsgrundlag som Kunden har lidt, kan Salary's samlede
          ansvar maksimalt andrage en størrelse svarende til de foregående 12 måneders fakturerede beløb før det
          ansvarspådragende forholds indtræden, dog højst 50.000 DKK.
        </p>
        <p>
          9.8. Salary holdes skadesløs af Kunden mod ethvert krav eller tab, der skyldes produktansvar, tab eller ansvar
          hos tredjemand, herunder Tredjeparter, i det omfang det hidrører fra Kundens brug af Serviceydelsen.
        </p>
        <p>
          9.9. Salary og dennes medarbejdere har tavshedspligt om alle informationer, som kan henføres direkte til
          Kunden, og som Salary måtte få kendskab om Kunden eller dennes Medarbejdere, og har ikke en ret til at
          videregive sådanne informationer til tredjemand, medmindre sådanne informationer er offentligt tilgængelige,
          eller hvor Salary har fået informationerne fra en tredjemand udenfor fortrolighed, eller hvor Salary er
          forpligtet til at videregive informationerne ifølge lovgivning eller efter fra en myndighed eller domstol,
          eller hvor videregivelse er berettiget ifølge indeværende Forretningsbetingelser.
        </p>

        <Headline>10. Drift og force majeure</Headline>
        <p>
          10.1. Salary tilsigter en høj driftsstabilitet og vil i tilfælde af nedbrud eller driftsforstyrrelser genskabe
          normal drift så hurtigt som muligt. Vedligeholdelse og opgradering af Serviceydelsen og tillægsydelser vil så
          vidt det er muligt blive placeret i tidsrummet mellem kl. 20.00-07.00 CET, og vil blive varslet forinden.
        </p>
        <p>
          10.2. Salary er uden ansvar for tab opstået som følge af nedbrud eller driftsforstyrrelser. Salary er ikke
          forpligtet til at erstatte sådanne tab. Selv på de områder, hvor der gælder et strengere ansvar, er Salary
          ikke ansvarlige for tab, som skyldes:
          <br />- Beskadigelser af data eller manglende adgang til eller nedbrud i IT-systemer, der kan henføres til
          nedenstående begivenheder, uanset om det er Salary eller en Tredjepart, der står for driften.
          <br />- Svigt i strømforsyning eller telekommunikation til Salary, naturkatastrofer, krig, oprør, borgerlige
          uroligheder, lovindgreb eller forvaltningsakter, sabotage, terror eller hærværk (herunder computervirus og
          –hacking).
          <br />- Konflikter såsom strejke, lockout, boykot eller blokade, uanset om den er rettet mod eller værksat af
          Salary selv eller dennes organisation, og uanset årsagen hertil. Det gælder også, når konflikten kun rammer
          dele af Salary's virksomhed.
          <br />- Andre omstændigheder, som er uden for Salary's kontrol.
        </p>

        <Headline>11. Opsigelse af serviceydelser</Headline>
        <p>
          11.1. Ved tilmelding til Serviceydelserne accepterer Kunden Forretningsbetingelserne. Forretningsbetingelserne
          løber indtil opsigelse i overensstemmelse med Forretningsbetingelserne. Kunden kan med en måneds varsel opsige
          Forretningsbetingelserne. Kunden kan dog altid skifte Servicepakke og tillægsydelser.
        </p>
        <p>
          11.2. Salary kan opsige Forretningsbetingelserne med 3 måneders varsel, eller uden varsel ved Kundens
          væsentlige misligholdelse af disse Forretningsbetingelserne eller ved Kundens konkurs eller insolvens.
        </p>

        <Headline>12. Tvister</Headline>
        <p>
          12.1. Nærværende Forretningsbetingelser i enhver henseende læses, fortolkes og udfyldes i overensstemmelse med
          dansk ret. Enhver tvist, der måtte opstå som følge af disse Forretningsbetingelser og de heraf følgende
          retsforhold mellem Kunden og Salary, deres fortolkning eller udfyldning skal anlægges ved Byret i København.
        </p>
      </div>

      {props.setVisibility && (
        <div className="terms-footer">
          <Button type="secondary" onClick={() => props.setVisibility && props.setVisibility(false)}>
            Jeg har læst betingelserne
          </Button>
        </div>
      )}
    </div>
  )
}
