import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import { PayRollStatus, PayRollTaskType, PayRollType } from '../../model/payRoll'
import { SalaryFrequency } from '../../model/salaryCycle'
import Warning from '../../model/warning'
import { AlertReducer } from '../../reducers/alerts'
import { PayRollReducer } from '../../reducers/payRolls'
import { formatDateTime, formatShortDate, trimCurrentYear } from '../../utils/date-utils'
import { formatPayRollStatus, formatSalaryCycleDescription } from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/Icon'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'

import './PayRolls.css'

type Props = {
  alerts: AlertReducer
  company: Company
  payRolls: PayRollReducer
  warnings: List<Warning>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteWarning: (id: string) => void
}

export default function PayRolls(props: Props): ReactElement | null {
  type PayRollRow = {
    id: string
    key: string
    periodStart: string
    periodEnd: string
    cycleFrequency: SalaryFrequency
    cyclePrepaid: boolean
    payRollType: PayRollType
    payRollTaskType?: PayRollTaskType
    active: boolean
    isManual: boolean
    status: PayRollStatus
    statusText: string
    runAt?: string
    hasDeadline: boolean
    statusClass: string
    amount: string
    link?: string
  }

  const columns = [
    {
      title: t('pay_rolls.header.state'),
      dataIndex: '',
      key: 'xStatus',
      width: '26%',
      render: (payRoll: PayRollRow) => {
        if (payRoll.runAt) {
          return (
            <>
              {payRoll.statusText}
              <br />({payRoll.hasDeadline ? t('pay_rolls.table.deadline') : t('pay_rolls.table.run_at')} {payRoll.runAt}
              )
            </>
          )
        }
        return payRoll.statusText
      },
    },
    {
      title: t('pay_rolls.header.period'),
      dataIndex: '',
      key: 'xPeriod',
      width: '28%',
      render: (payRoll: PayRollRow) => {
        const start = payRoll.periodStart.split(' 20')
        const end = payRoll.periodEnd.split(' 20')
        return (
          <span className="period-view">
            <strong>
              {start[0]}{' '}
              <em>
                20
                {start[1]}
              </em>
            </strong>
            <Icon type="arrow-long-right" color="black" />
            <strong>
              {end[0]}{' '}
              <em>
                20
                {end[1]}
              </em>
            </strong>
          </span>
        )
      },
    },
    {
      title: t('pay_rolls.header.type'),
      dataIndex: '',
      key: 'xType',
      width: '27%',
      render: (payRoll: PayRollRow) => {
        if (payRoll.payRollTaskType === 'ZeroTaxReport') {
          return <>{t('pay_rolls.type.zero_tax_report')}</>
        }
        switch (payRoll.payRollType) {
          case 'Negation':
            return (
              <>
                {formatSalaryCycleDescription(payRoll.cycleFrequency, payRoll.cyclePrepaid)}
                <br />
                {t('pay_rolls.type.negation')}
              </>
            )
          case 'TerminatedEmployments':
            return (
              <>
                {formatSalaryCycleDescription(payRoll.cycleFrequency, payRoll.cyclePrepaid)}
                <br />
                {t('pay_rolls.type.terminated_employments')}
              </>
            )
          default:
            if (payRoll.isManual) {
              return (
                <>
                  {formatSalaryCycleDescription(payRoll.cycleFrequency, payRoll.cyclePrepaid)}
                  <br />
                  {t('pay_rolls.type.manual')}
                </>
              )
            }
            return <>{formatSalaryCycleDescription(payRoll.cycleFrequency, payRoll.cyclePrepaid)}</>
        }
      },
    },
    { title: t('pay_rolls.header.amount'), dataIndex: 'amount', key: 'amount', width: '20%' },
  ]

  const getPayRolls = (): PayRollRow[] => {
    return props.payRolls.payRolls
      .map((payRoll) => {
        let active = false
        let className = 'pay-rolls-table-row-waiting'
        let amount = formatCurrency(payRoll.totalTransfer, 0)
        let linkable = true
        let runAt = undefined
        let hasDeadline = false
        switch (convertPayRollStatus(payRoll)) {
          case 'Unknown':
          //@ts-expect-error fallthrough
          case 'Scheduled':
            linkable = false
          //fallthrough
          case 'Awaiting':
            active = true
            amount = ''
            break
          case 'Approved':
          //@ts-expect-error fallthrough
          case 'Automatic Approval': {
            const tmp = payRoll.overdueTime || (payRoll.tasks.length > 0 && payRoll.tasks[0].approvalDeadline)
            if (tmp) {
              runAt = trimCurrentYear(formatDateTime(tmp))
            }
          }
          //@ts-expect-error fallthrough
          case 'No Approval':
            active = true
          //fallthrough
          case 'Success':
            className = 'pay-rolls-table-row-success'
            break
          //@ts-expect-error fallthrough
          case 'Rejected':
            active = true
          //fallthrough
          case 'Cancelled':
          case 'Failed':
            className = 'pay-rolls-table-row-error'
            break
          case 'Awaiting Approval':
          case 'Tentative':
          //@ts-expect-error fallthrough
          case 'Reviewed': {
            const tmp = payRoll.overdueTime || (payRoll.tasks.length > 0 && payRoll.tasks[0].approvalDeadline)
            if (tmp) {
              runAt = trimCurrentYear(formatDateTime(tmp))
              hasDeadline = true
            }
            active = true
          }
          //fallthrough
          default:
            className = 'pay-rolls-table-row-warning'
            break
        }
        return {
          id: payRoll.id,
          key: payRoll.id,
          periodStart: formatShortDate(payRoll.salaryPeriod.start),
          periodEnd: formatShortDate(payRoll.salaryPeriod.end),
          cycleFrequency: payRoll.salaryCycle.frequency,
          cyclePrepaid: payRoll.salaryCycle.prepaid,
          payRollType: payRoll.payRollType,
          payRollTaskType: payRoll.tasks && payRoll.tasks.length > 0 ? payRoll.tasks[0].type : undefined,
          active,
          isManual: payRoll.isManual,
          status: payRoll.status,
          statusText: formatPayRollStatus(payRoll),
          runAt,
          hasDeadline,
          statusClass: className,
          amount,
          link: linkable ? '/' + paths.PAY_ROLLS + '/' + payRoll.id : undefined,
        }
      })
      .toArray()
  }
  const getActivePayRolls = () => {
    return getPayRolls().filter((payRoll) => payRoll.active)
  }
  const getPreviousPayRolls = () => {
    return getPayRolls().filter((payRoll) => !payRoll.active)
  }

  const getRowClassName = (payRoll: PayRollRow) => {
    const classNames = []
    if (payRoll.link) {
      classNames.push('pay-rolls-table-row-clickable')
    }
    classNames.push(payRoll.statusClass)
    return classNames.join(' ')
  }

  const activePayRolls = getActivePayRolls()
  const activeCount = activePayRolls.length
  const count = getPreviousPayRolls().length

  if (!props.company.verified) {
    return (
      <Card className="no-pay-roll-card">
        <Subtitle>{t('pay_rolls.not_approved.title')}</Subtitle>
        <p>{t('pay_rolls.not_approved.description')}</p>

        <a href="mailto:support@salary.dk">{t('pay_rolls.not_approved.support_link')}</a>
      </Card>
    )
  }

  const dormantWarningID = props.warnings.find((warning) => warning.warningType === 'CompanyDormant')?.id

  return (
    <div className="pay-rolls">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {dormantWarningID && (
        <Alert
          type={'error'}
          message={
            <>
              {t('pay_rolls.dormant.description')}
              <Button
                size="large"
                type="secondary"
                regular
                style={{ float: 'right' }}
                onClick={() => props.deleteWarning(dormantWarningID)}
              >
                {t('pay_rolls.dormant.button_text')}
              </Button>
            </>
          }
          showIcon
        />
      )}

      <TitleMenu>
        <Link to={'/' + paths.PAY_ROLLS + '/' + paths.ADD}>
          <Button type="primary">{t('pay_rolls.create_manual_pay_roll')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('pay_rolls.active.title')}</Title>

      {activeCount > 0 && (
        <Table
          columns={columns}
          dataSource={activePayRolls}
          showHeader={false}
          pagination={false}
          className="pay-rolls-table"
          onRowClick={(payRoll: PayRollRow) => {
            if (payRoll.link) {
              jsBrowserHistory.push(payRoll.link)
            }
          }}
          rowClassName={getRowClassName}
        />
      )}

      {(count > 0 || activeCount === 0) && (
        <div>
          <Subtitle>{t('pay_rolls.inactive.title')}</Subtitle>
          <Table
            columns={columns}
            dataSource={getPreviousPayRolls()}
            showHeader={false}
            pagination={count > 10 ? { defaultCurrent: getPage(), onChange: setPage } : false}
            className="pay-rolls-table"
            onRowClick={(payRoll: PayRollRow) => {
              if (payRoll.link) {
                jsBrowserHistory.push(payRoll.link)
              }
            }}
            rowClassName={getRowClassName}
          />
        </div>
      )}
    </div>
  )
}
