import Company from '../model/company'
import CompanyGroup, { CompanyGroupUser } from '../model/companyGroup'
import CompanyUser from '../model/companyUser'
import User, { AccessToken } from '../model/user'
import { request, RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchUser(): Promise<RequestResponse<User>> {
  return secureRequest('GET', url('/v2/users'), { ignore401: true })
}

export function postUser(
  email: string,
  password: string,
  name: string,
  phoneNumber: string,
  phoneNumberCountryCode: string
): Promise<RequestResponse<AccessToken>> {
  return request('POST', url('/v2/users'), {
    body: {
      email,
      password,
      name,
      phoneNumber,
      phoneNumberCountryCode,
      language: 'da',
    },
  })
}

export function postLogin(
  email: string,
  password: string,
  deviceToken?: string,
  mfaChallengeID?: string,
  mfaResponse?: string,
  recoveryCode?: string,
  trustDevice = false
): Promise<RequestResponse<AccessToken>> {
  return request('POST', url('/v2/login'), {
    body: { email, password, deviceToken, mfaChallengeID, mfaResponse, recoveryCode, trustDevice },
  })
}

export function deleteLogin(): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/login'))
}

export function postLoginUserID(
  userID: string,
  mfaChallengeID?: string,
  mfaResponse?: string
): Promise<RequestResponse<AccessToken>> {
  return request('POST', url('/v2/login/' + userID), {
    body: { mfaChallengeID, mfaResponse },
  })
}

export function postAuthToken(
  webToken: string,
  mfaChallengeID?: string,
  mfaResponse?: string,
  recoveryCode?: string,
  trustDevice = false
): Promise<RequestResponse<AccessToken>> {
  return request('POST', url('/v2/authToken'), {
    body: { token: webToken, mfaChallengeID, mfaResponse, recoveryCode, trustDevice },
  })
}

export function postPasswordRequest(email: string): Promise<RequestResponse> {
  return request('POST', url('/v2/passwordReset/'), {
    body: { email },
  })
}

export function postPasswordReset(token: string, password: string): Promise<RequestResponse> {
  return request('POST', url('/v2/passwordReset/' + token), {
    body: { password },
  })
}

export function putUser(user: User): Promise<RequestResponse<User>> {
  return secureRequest('PUT', url('/v2/users/' + user.id), {
    body: {
      language: user.language,
      name: user.name,
      phoneNumber: user.phoneNumber,
      phoneNumberCountryCode: user.phoneNumberCountryCode,
      uiSettings: user.uiSettings,
    },
  })
}

export function postEmailChangeRequest(email: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/emailChange'), {
    body: { email },
  })
}

export function postEmailChangeConfirm(token: string): Promise<RequestResponse<User>> {
  return request('POST', url('/v2/emailChange/' + token))
}

export function postEmailVerify(token: string): Promise<RequestResponse> {
  return request('POST', url('/v2/emailVerification/' + token))
}

export function postPasswordChange(oldPassword: string, newPassword: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/passwordChange'), {
    body: { oldPassword, newPassword },
  })
}

export function postAcceptUserInvite(token: string): Promise<RequestResponse<CompanyUser & CompanyGroupUser>> {
  return secureRequest('POST', url('/v2/userInvites/' + token))
}

type AcceptUserInviteBody = {
  company?: Company
  companyGroup?: CompanyGroup
}

export function getAcceptUserInvite(token: string): Promise<RequestResponse<AcceptUserInviteBody>> {
  return secureRequest('GET', url('/v2/userInvites/' + token))
}

export function postUserSurveys(score: number, comment: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/userSurveys'), {
    body: {
      score,
      comment,
    },
  })
}
