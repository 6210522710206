import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import paths from '../../../constants/paths'
import PDFPreviewTypes from '../../../constants/pdf-preview-types'
import Employee from '../../../model/employee'
import PaySlip from '../../../model/paySlip'
import { EmployeeReducer } from '../../../reducers/employees'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { secureUrl } from '../../../utils/request-utils'
import { t } from '../../../utils/translation-utils'
import Table from '../../antd/table'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import PayInvoicesForm, { PaySlipFields } from './PayInvoicesForm'

interface Props {
  employee: Employee
  employees: EmployeeReducer
  paySlips: List<PaySlip>
  hasPremiumPackage: boolean

  updateEmployee: (employee: Employee) => void
}

export default function PayInvoicesTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { employees } = props

  useEffect(() => {
    regularComponentDidUpdate(employees.error, error, setError)
  }, [employees, error])

  const handleSubmit = (values: PaySlipFields) => {
    const employee = { ...props.employee, ...values }
    props.updateEmployee(employee)
  }

  type PaySlipRow = {
    key: string
    id: string
    dispositionDate: string
    salaryPeriod: string
    netPay: string
    settled: boolean
  }

  const columns = [
    { title: t('freelancer.pay_invoice.table.header.period'), dataIndex: 'salaryPeriod', key: 'salaryPeriod' },
    { title: t('freelancer.pay_invoice.table.header.net_pay'), dataIndex: 'netPay', key: 'netPay' },
    {
      title: t('freelancer.pay_invoice.table.header.disposition_date'),
      dataIndex: 'dispositionDate',
      key: 'dispositionDate',
    },
    {
      title: t('freelancer.pay_invoice.table.header.completed'),
      dataIndex: '',
      key: 'x4',
      render: (paySlip: PaySlipRow) => (
        <span>{paySlip.settled ? t('freelancer.pay_invoice.table.completed') : ''}</span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x5',
      width: 1,
      render: (paySlip: PaySlipRow) => (
        <Link
          to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.PAY_SLIP + '/' + paySlip.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="download" color="grey" />
        </Link>
      ),
    },
  ]

  const getPaySlips = (): PaySlipRow[] => {
    return props.paySlips
      .map((paySlip) => {
        const combinedPayInvoice = {
          key: paySlip.id,
          id: paySlip.id,
          dispositionDate: formatDate(paySlip.dispositionDate),
          salaryPeriod: formatDate(paySlip.salaryPeriod.start) + ' - ' + formatDate(paySlip.salaryPeriod.end),
          netPay: '-',
          settled: paySlip.settled,
        }
        if (paySlip.calculations) {
          paySlip.calculations.forEach((calculation) => {
            if (calculation.type === 'PayCheck') {
              combinedPayInvoice.netPay = formatCurrency(calculation.result, 0)
            }
          })
        }
        return combinedPayInvoice
      })
      .toArray()
  }

  const paySlips = getPaySlips()
  const hasPaySlips = paySlips.some((paySlip) => paySlip.settled)
  return (
    <Card>
      {hasPaySlips && (
        <TitleMenu>
          <Link
            to={secureUrl('/v2/paySlipsPDF?employeeID=' + props.employee.id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              <Icon type="download" color="grey" />
              {t('freelancer.pay_invoice.header.download_all')}
            </Button>
          </Link>
        </TitleMenu>
      )}
      <Title>{t('freelancer.pay_invoice.header.title')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <PayInvoicesForm
        employee={props.employee}
        employees={props.employees}
        hasPremiumPackage={props.hasPremiumPackage}
        onSubmit={handleSubmit}
      />

      <Table columns={columns} dataSource={paySlips} size="small" pagination={false} />
    </Card>
  )
}
