import React, { ReactElement, useEffect } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { confirmEmailChange } from '../actions/user'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  confirmEmailChange: (token: string) => void
}

function ConfirmEmailChange(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { confirmEmailChange, params } = props
  useEffectOnce(() => {
    confirmEmailChange(splatString(params.splat))
  })

  const { user, addAlert } = props
  const previousUser = usePrevious(user)

  useEffect(() => {
    // Check for save callback
    if (previousUser && previousUser.confirmingEmailChange && !user.confirmingEmailChange) {
      // Check for no error occurred
      if (!user.error) {
        addAlert('success', 'Din mail er nu opdateret.', {
          timeout: 5,
        })
        setTimeout(() => {
          // Redirect to account page
          jsBrowserHistory.push('/' + paths.ACCOUNT + '/email')
        }, 500)
      } else {
        addAlert('warning', 'Linket til ændring af mail er ikke gyldigt, prøv igen.')
        // Redirect to account page
        jsBrowserHistory.push('/' + paths.ACCOUNT + '/email')
      }
    }
  })

  return (
    <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
      <LoadingOverlay />
    </div>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    addAlert: addAlert,
    confirmEmailChange: confirmEmailChange,
  }
)(ConfirmEmailChange)
