import React, { ReactElement, useEffect } from 'react'

import { getCompanyAccountingIntegration } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAsynchronousTasks } from '../actions/asynchronous-tasks'
import { getCompanyPaymentIntegrations } from '../actions/company-payment-integrations'
import { getCompanyUsers } from '../actions/company-users'
import { updateEmployee } from '../actions/employees'
import { getIncomeTaxReports } from '../actions/income-tax-reports'
import { getInvoices } from '../actions/invoices'
import { addOneTimePay } from '../actions/one-time-pays'
import { getPayRollDeviations } from '../actions/pay-roll-deviations'
import {
  addPayRoll,
  approvePayRoll,
  deletePayRoll,
  getPayRolls,
  payRollSetDispositionDate,
  payRollSetPaymentMethod,
  rejectPayRoll,
  reopenPayRoll,
  resendSKAT,
  restartPayRoll,
  reviewPayRoll,
} from '../actions/pay-rolls'
import { getPaySlips } from '../actions/pay-slips'
import { addStripeConfiguration, updateStripeConfiguration } from '../actions/payment-configurations'
import { getShipments } from '../actions/shipments'
import { getTimeRegistrations } from '../actions/time-registrations'
import { getTransfers } from '../actions/transfers'
import { getVouchers } from '../actions/vouchers'
import { deleteWarning } from '../actions/warnings'
import { NewPayRoll, NotificationChannel } from '../api/pay-rolls'
import PayRollsSingleComponent from '../components/pay-rolls-single/PayRollsSingle'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import Employee from '../model/employee'
import OneTimePay, { OneTimePayCreationFields } from '../model/oneTimePay'
import PayRoll from '../model/payRoll'
import StripeConfiguration, { StripeConfigurationSetup } from '../model/stripeConfiguration'
import { PaymentMethod } from '../model/transfer'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../reducers/companyAccountingIntegration'
import { CompanyPaymentIntegrationReducer } from '../reducers/companyPaymentIntegrations'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { IncomeTaxReportReducer } from '../reducers/incomeTaxReports'
import { InvoiceReducer } from '../reducers/invoices'
import { PayRollDeviationReducer } from '../reducers/payRollDeviations'
import { PayRollReducer } from '../reducers/payRolls'
import { PaySlipReducer } from '../reducers/paySlips'
import { ShipmentReducer } from '../reducers/shipments'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { TransferReducer } from '../reducers/transfers'
import { UserReducer } from '../reducers/user'
import { VoucherReducer } from '../reducers/vouchers'
import { WarningReducer } from '../reducers/warnings'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  asynchronousTasks: AsynchronousTaskReducer
  user: UserReducer
  companies: CompanyReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  companyUsers: CompanyUserReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  payRolls: PayRollReducer
  payRollDeviations: PayRollDeviationReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  timeRegistrations: TimeRegistrationReducer
  paySlips: PaySlipReducer
  vouchers: VoucherReducer
  transfers: TransferReducer
  invoices: InvoiceReducer
  incomeTaxReports: IncomeTaxReportReducer
  shipments: ShipmentReducer
  warnings: WarningReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addPayRoll: (payRoll: NewPayRoll) => Promise<PayRoll | void>
  getPayRolls: () => void
  approvePayRoll: (
    payRollID: string,
    version: string,
    approvalCode: string | undefined,
    userIDs?: string[],
    notificationChannel?: NotificationChannel
  ) => void
  reviewPayRoll: (
    payRollID: string,
    version: string,
    userIDs: string[],
    notificationChannel: NotificationChannel
  ) => void
  reopenPayRoll: (payRollID: string, version: string, note?: string, notify?: boolean) => void
  rejectPayRoll: (payRollID: string, version: string) => void
  restartPayRoll: (payRollID: string, version: string) => void
  deletePayRoll: (payRollID: string) => void
  payRollSetDispositionDate: (payRollID: string, dispositionDate: DateFormat) => void
  payRollSetPaymentMethod: (payRollID: string, version: string, paymentMethod: PaymentMethod) => void
  resendSKAT: (payRollID: string, version: string) => void
  getTimeRegistrations: (
    companyID?: string,
    employeeID?: string,
    payRollID?: string,
    fromDate?: DateFormat,
    toDate?: DateFormat
  ) => void
  getCompanyUsers: (companyID?: string) => void
  getPaySlips: (payRollID: string) => void
  getVouchers: (payRollID: string) => void
  getTransfers: (payRollID: string | undefined, companyID: string | undefined, fromDate?: DateFormat) => void
  getInvoices: (payRollID: string) => void
  getIncomeTaxReports: (payRollID: string) => void
  getCompanyAccountingIntegration: () => void
  getCompanyPaymentIntegrations: () => void
  getPayRollDeviations: (payRollID: string) => void
  getAsynchronousTasks: (companyID: string) => void
  deleteWarning: (warningID: string) => void
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
  getShipments: (payRollID: string) => void
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => Promise<OneTimePay | void>
}

function PayRollsSingle(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { payRolls, getPayRolls } = props
  useEffect(() => {
    if (!payRolls.loading && !payRolls.loaded) {
      getPayRolls()
    }
  }, [payRolls, getPayRolls])

  const payRollID = splatString(props.params.splat)

  const {
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
    companyPaymentIntegrations,
    getCompanyPaymentIntegrations,
    companyUsers,
    getCompanyUsers,
  } = props

  useEffect(() => {
    if (!companyAccountingIntegration.loading && !companyAccountingIntegration.loaded) {
      getCompanyAccountingIntegration()
    }
    if (!companyPaymentIntegrations.loading && !companyPaymentIntegrations.loaded) {
      getCompanyPaymentIntegrations()
    }
    if (!companyUsers.loading && !companyUsers.loaded) {
      getCompanyUsers()
    }
  }, [
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
    companyPaymentIntegrations,
    getCompanyPaymentIntegrations,
    companyUsers,
    getCompanyUsers,
  ])

  const { companies, asynchronousTasks, getAsynchronousTasks } = props

  useEffect(() => {
    if (companies.company) {
      if (!asynchronousTasks.loading && !asynchronousTasks.loaded) {
        getAsynchronousTasks(companies.company.id)
      }
    }
  }, [companies, asynchronousTasks, getAsynchronousTasks])

  const {
    timeRegistrations,
    getTimeRegistrations,
    paySlips,
    getPaySlips,
    vouchers,
    getVouchers,
    transfers,
    getTransfers,
    invoices,
    getInvoices,
    incomeTaxReports,
    getIncomeTaxReports,
    payRollDeviations,
    getPayRollDeviations,
    shipments,
    getShipments,
  } = props

  useEffect(() => {
    if (payRollID) {
      if (
        (!timeRegistrations.companyID && timeRegistrations.payRollID !== payRollID) ||
        (!timeRegistrations.loading && !timeRegistrations.loaded)
      ) {
        getTimeRegistrations(undefined, undefined, payRollID, undefined, undefined)
      }
      if (paySlips.payRollID !== payRollID || (!paySlips.loading && !paySlips.loaded)) {
        getPaySlips(payRollID)
      }
      if (vouchers.payRollID !== payRollID || (!vouchers.loading && !vouchers.loaded)) {
        getVouchers(payRollID)
      }
      if (transfers.payRollID !== payRollID || (!transfers.loading && !transfers.loaded)) {
        getTransfers(payRollID, undefined)
      }
      if (invoices.payRollID !== payRollID || (!invoices.loading && !invoices.loaded)) {
        getInvoices(payRollID)
      }
      if (incomeTaxReports.payRollID !== payRollID || (!incomeTaxReports.loading && !incomeTaxReports.loaded)) {
        getIncomeTaxReports(payRollID)
      }
      if (payRollDeviations.payRollID !== payRollID || (!payRollDeviations.loading && !payRollDeviations.loaded)) {
        getPayRollDeviations(payRollID)
      }
      if (shipments.payRollID !== payRollID || (!shipments.loading && !shipments.loaded)) {
        getShipments(payRollID)
      }
    }
  }, [
    payRollID,
    timeRegistrations,
    getTimeRegistrations,
    paySlips,
    getPaySlips,
    vouchers,
    getVouchers,
    transfers,
    getTransfers,
    invoices,
    getInvoices,
    incomeTaxReports,
    getIncomeTaxReports,
    payRollDeviations,
    getPayRollDeviations,
    shipments,
    getShipments,
  ])

  const loading =
    !props.payRolls.loaded ||
    !props.companyAccountingIntegration.loaded ||
    !props.payRollDeviations.loaded ||
    props.timeRegistrations.loading ||
    props.employees.loading ||
    !props.paySlips.loaded ||
    !props.vouchers.loaded ||
    props.transfers.loading ||
    !props.invoices.loaded ||
    !props.incomeTaxReports.loaded ||
    !props.shipments.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.payRolls.loaded, text: 'lønkørsler' },
            { loading: !props.companyAccountingIntegration.loaded, text: 'bogføringsintegration' },
            { loading: !props.payRollDeviations.loaded, text: 'afvigelser' },
            { loading: props.timeRegistrations.loading, text: 'tidsregistreringer' },
            { loading: props.employees.loading, text: 'medarbejdere' },
            { loading: !props.paySlips.loaded, text: 'lønsedler' },
            { loading: !props.vouchers.loaded, text: 'bogføringer' },
            { loading: props.transfers.loading, text: 'overførsler' },
            { loading: !props.invoices.loaded, text: 'fakturaer' },
            { loading: !props.incomeTaxReports.loaded, text: 'skatterapporter' },
            { loading: !props.shipments.loaded, text: 'skattesvar' },
          ])}
        />
      </div>
    )
  }

  const payRoll = props.payRolls.payRolls.find((payRoll) => payRoll.id === payRollID)

  if (!props.companies.company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!payRoll) {
    jsBrowserHistory.push('/' + paths.PAY_ROLLS)
    return null
  }

  const employeesMap: Record<string, Employee> = {}
  const employeesNumberMap: Record<string, Employee> = {}
  props.employees.employees.forEach((employee) => {
    employeesMap[employee.id] = employee
    if (employee.activeEmployment) {
      employeesNumberMap[employee.activeEmployment.employeeNumber] = employee
    }
  })

  return (
    <PayRollsSingleComponent
      alerts={props.alerts}
      user={props.user}
      asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
      company={props.companies.company}
      companyUsers={props.companyUsers}
      payRoll={payRoll}
      companyAccountingIntegration={props.companyAccountingIntegration}
      employeesMap={employeesMap}
      employeesNumberMap={employeesNumberMap}
      departments={props.departments.departments}
      payRolls={props.payRolls}
      payRollDeviations={props.payRollDeviations.payRollDeviations}
      timeRegistrations={props.timeRegistrations.timeRegistrations}
      paySlips={props.paySlips.paySlips}
      vouchers={props.vouchers.vouchers}
      transfers={props.transfers.transfers}
      invoices={props.invoices.invoices}
      incomeTaxReports={props.incomeTaxReports.incomeTaxReports}
      shipments={props.shipments.shipments}
      warnings={props.warnings.warnings}
      companyPaymentIntegrations={props.companyPaymentIntegrations}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      addPayRoll={props.addPayRoll}
      approvePayRoll={props.approvePayRoll}
      reviewPayRoll={props.reviewPayRoll}
      reopenPayRoll={props.reopenPayRoll}
      rejectPayRoll={props.rejectPayRoll}
      restartPayRoll={props.restartPayRoll}
      deletePayRoll={props.deletePayRoll}
      payRollSetDispositionDate={props.payRollSetDispositionDate}
      payRollSetPaymentMethod={props.payRollSetPaymentMethod}
      resendSKAT={props.resendSKAT}
      deleteWarning={props.deleteWarning}
      addStripeConfiguration={props.addStripeConfiguration}
      updateStripeConfiguration={props.updateStripeConfiguration}
      updateEmployee={props.updateEmployee}
      addOneTimePay={props.addOneTimePay}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    asynchronousTasks: state.asynchronousTasks,
    companies: state.companies,
    companyAccountingIntegration: state.companyAccountingIntegration,
    companyUsers: state.companyUsers,
    payRolls: state.payRolls,
    payRollDeviations: state.payRollDeviations,
    employees: state.employees,
    departments: state.departments,
    timeRegistrations: state.timeRegistrations,
    paySlips: state.paySlips,
    vouchers: state.vouchers,
    transfers: state.transfers,
    invoices: state.invoices,
    incomeTaxReports: state.incomeTaxReports,
    shipments: state.shipments,
    warnings: state.warnings,
    companyPaymentIntegrations: state.companyPaymentIntegrations,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getCompanyAccountingIntegration: getCompanyAccountingIntegration,
    getPayRolls: getPayRolls,
    getPayRollDeviations: getPayRollDeviations,
    addPayRoll: addPayRoll,
    approvePayRoll: approvePayRoll,
    reviewPayRoll: reviewPayRoll,
    reopenPayRoll: reopenPayRoll,
    rejectPayRoll: rejectPayRoll,
    restartPayRoll: restartPayRoll,
    deletePayRoll: deletePayRoll,
    payRollSetDispositionDate: payRollSetDispositionDate,
    payRollSetPaymentMethod: payRollSetPaymentMethod,
    resendSKAT: resendSKAT,
    getTimeRegistrations: getTimeRegistrations,
    getCompanyUsers: getCompanyUsers,
    getPaySlips: getPaySlips,
    getVouchers: getVouchers,
    getTransfers: getTransfers,
    getInvoices: getInvoices,
    getIncomeTaxReports: getIncomeTaxReports,
    deleteWarning: deleteWarning,
    getCompanyPaymentIntegrations: getCompanyPaymentIntegrations,
    getAsynchronousTasks: getAsynchronousTasks,
    addStripeConfiguration: addStripeConfiguration,
    updateStripeConfiguration: updateStripeConfiguration,
    getShipments: getShipments,
    updateEmployee: updateEmployee,
    addOneTimePay: addOneTimePay,
  }
)(PayRollsSingle)
