import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { acceptUserInvite, logout } from '../actions/user'
import { getAcceptUserInvite } from '../api/user'
import Alert from '../components/elements/alert'
import Button from '../components/elements/button'
import Card from '../components/elements/card'
import Title from '../components/elements/Title'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import { CompanyGroupUser } from '../model/companyGroup'
import CompanyUser from '../model/companyUser'
import { UserReducer } from '../reducers/user'
import { formatError, isRequestError } from '../utils/error-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RequestError } from '../utils/request-utils'
import { RouteProps, splatString } from '../utils/route-utils'
import { t, tx } from '../utils/translation-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  acceptUserInvite: (token: string) => Promise<(CompanyUser & CompanyGroupUser) | RequestError | void>
  logout: () => Promise<boolean | void>
}

type State = {
  email: string
  company?: {
    name: string
    nationalID: string
  }
  companyGroup?: {
    name: string
  }
}

function AcceptUserInvite(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { user, addAlert } = props
  const previousUser = usePrevious(user)
  const tokenID = splatString(props.params.splat)
  const [loadingInfo, setLoadingInfo] = useState(false)
  const [accepting, setAccepting] = useState(false)
  const [info, setInfo] = useState<State>()
  const [error, setError] = useState<Error>()

  useEffect(() => {
    if (previousUser && previousUser.acceptingUserInvite && !user.acceptingUserInvite) {
      if (user.error) {
        addAlert('warning', t('accept_user_invite.warning.broken_token'))
      }
    }
  })

  useEffect(() => {
    if (!info && !loadingInfo && !error) {
      setLoadingInfo(true)
      getAcceptUserInvite(tokenID)
        .then((res) => {
          if (!res) {
            return
          }
          if (res.data.company) {
            setInfo({
              email: user.email ?? user.name ?? t('common.unknown'),
              company: {
                name: res.data.company.name,
                nationalID: res.data.company.nationalID,
              },
            })
          }
          if (res.data.companyGroup) {
            setInfo({
              email: user.email ?? user.name ?? t('common.unknown'),
              companyGroup: {
                name: res.data.companyGroup.name,
              },
            })
          }
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
        })
        .finally(() => {
          setLoadingInfo(false)
        })
    }
  }, [info, loadingInfo, tokenID, user, error])

  if (accepting || !info) {
    if (error) {
      return (
        <Card style={{ maxWidth: '500px' }}>
          <Alert type={'error'} message={formatError(error)} showIcon />
        </Card>
      )
    }
    return (
      <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card style={{ maxWidth: '500px' }}>
      {error && <Alert type={'error'} message={formatError(error)} showIcon />}
      {info.company && (
        <>
          <Title>{t('accept_user_invite.company.title')}</Title>
          <p style={{ fontSize: '14pt', lineHeight: '16pt' }}>
            {tx('accept_user_invite.company.intro.line_1', {
              email: <strong>{info.email}</strong>,
              companyName: <strong>{info.company.name}</strong>,
              companyCVR: info.company.name,
            })}
          </p>
          <p>{t('accept_user_invite.company.intro.line_2')}</p>
        </>
      )}
      {info.companyGroup && (
        <>
          <Title>{t('accept_user_invite.company_group.title')}</Title>
          <p style={{ fontSize: '14pt', lineHeight: '16pt' }}>
            {tx('accept_user_invite.company_group.intro.line_1', {
              email: <strong>{info.email}</strong>,
              name: <strong>{info.companyGroup.name}</strong>,
            })}
          </p>
          <p>{t('accept_user_invite.company_group.intro.line_2')}</p>
        </>
      )}
      <div className="button-row">
        <Button
          onClick={(e) => {
            e.stopPropagation()
            setAccepting(true)
            props
              .acceptUserInvite(tokenID)
              .then((res) => {
                if (!res || !(res instanceof RequestError)) {
                  // Redirect to dashboard, but ensure a hard refresh
                  document.location = '/'
                }
              })
              .catch((e) => {
                if (isRequestError(e)) {
                  setError(e)
                }
              })
          }}
          type="primary"
        >
          {t('accept_user_invite.button.yes')}
        </Button>
        <Button
          onClick={() => {
            document.location = '/'
          }}
          type="danger"
        >
          {t('accept_user_invite.button.no')}
        </Button>
        <Button
          onClick={() => {
            props
              .logout()
              .then((res) => {
                if (res) {
                  document.location = '/' + paths.ACCEPT_USER_INVITE + '/' + tokenID
                }
              })
              .catch((e) => {
                setError(e)
              })
          }}
          type="secondary"
        >
          {t('accept_user_invite.button.switch_user')}
        </Button>
      </div>
    </Card>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    addAlert: addAlert,
    acceptUserInvite: acceptUserInvite,
    logout: logout,
  }
)(AcceptUserInvite)
