import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import { CompanyGroupMutableFields, CompanyGroupUser } from '../../model/companyGroup'
import CompanySetting from '../../model/companySetting'
import { UserPermission } from '../../model/companyUser'
import UserInvite from '../../model/userInvite'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyGroupReducer } from '../../reducers/companyGroups'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { UserReducer } from '../../reducers/user'
import { UserInviteCompanyGroupReducer } from '../../reducers/userInviteCompanyGroups'
import CompanyGroupUserType from '../../types/company-group-user-type'
import { t } from '../../utils/translation-utils'
import Alerts from '../widgets/Alerts'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AccountingIntegrationTab from './company-group/AccountingIntegrationTab'
import CompaniesOverview from './company-group/CompaniesOverview'
import ReportsTab from './company-group/ReportsTab'
import SettingsTab from './company-group/SettingsTab'
import UsersOverview from './company-group/UsersOverview'

import './Companies.css'

type Props = {
  section?: string
  alerts: AlertReducer
  companies: CompanyReducer
  companyGroups: CompanyGroupReducer
  companyGroupUser?: CompanyGroupUser
  pricingPackages: PricingPackageReducer
  userInviteCompanyGroups: UserInviteCompanyGroupReducer
  user: UserReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateActiveCompany: (id: string) => void
  addCompanyToCompanyGroup: (companyGroupID: string, companyID: string) => void
  removeCompanyFromCompanyGroup: (companyGroupID: string, companyID: string) => void
  removeUserFromCompanyGroup: (companyGroupID: string, userID: string) => void
  updateCompanyGroupUser: (
    companyGroupID: string,
    userID: string,
    companyGroupUser: CompanyGroupUser
  ) => Promise<CompanyGroupUser | void>
  updateCompanyGroup: (companyGroupID: string, companyGroup: CompanyGroupMutableFields) => void
  setCompanyGroupCompanySettings: (
    companyGroupID: string,
    settingsEnable: CompanySetting[],
    settingDisable: CompanySetting[],
    retroactive: boolean
  ) => void
  getCompanyGroupUserInvites: (companyGroupID: string) => void
  addCompanyGroupUserInvite: (
    companyGroupID: string,
    email: string,
    groupUserType: CompanyGroupUserType,
    permissions: UserPermission[]
  ) => Promise<UserInvite | void>
  deleteCompanyGroupUserInvite: (companyGroupID: string, email: string) => void
  getPricingPackages: (includePricingPackageID: string[]) => void
}

export default function Companies(props: Props): ReactElement | null {
  const showingCompanyGroup = () => {
    return !!props.companyGroupUser
  }

  const isGroupAdmin = () => {
    return !!props.companyGroupUser && props.companyGroupUser.groupUserType === CompanyGroupUserType.ADMIN
  }

  const getMenuItems = () => {
    const menuItems: { key: string; title: string; link?: string }[] = []
    if (!showingCompanyGroup()) {
      return menuItems
    }
    menuItems.push({ key: 'companies', title: t('companies.menu.companies'), link: paths.COMPANIES })
    if (isGroupAdmin()) {
      menuItems.push({ key: 'settings', title: t('companies.menu.settings') })
    }
    menuItems.push({ key: 'users', title: t('companies.menu.users') })
    if (isGroupAdmin()) {
      menuItems.push({ key: 'reports', title: t('companies.menu.reports') })
      menuItems.push({ key: 'accounting', title: t('companies.menu.accounting') })
    }
    return menuItems
  }

  let section = props.section
  const menuItems = getMenuItems()

  if (section !== 'companies' && !props.companyGroups.companyGroup) {
    section = 'companies'
  }
  if (!showingCompanyGroup()) {
    section = 'companies'
  }

  if (props.companyGroups.saving) {
    return <LoadingOverlay />
  }

  const companyGroup = props.companyGroups.companyGroup || undefined
  if (!companyGroup) {
    section = 'companies'
  }

  return (
    <div className="companies">
      {showingCompanyGroup() && companyGroup && (
        <div className="companies-menu">
          <div className="companies-menu-group-name">
            {t('companies.header.group')}
            <br />
            <strong>{companyGroup.name}</strong>
          </div>
          {menuItems.map((item) => {
            return (
              <Link
                key={item.key}
                to={'/' + (item.link ? item.link : paths.COMPANY_GROUPS + '/' + companyGroup.id + '/' + item.key)}
                className={section === item.key ? 'active' : ''}
              >
                {item.title}
              </Link>
            )
          })}
        </div>
      )}
      <div className="companies-main">
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
        {section === 'companies' && (
          <CompaniesOverview
            companies={props.companies}
            companyGroup={companyGroup}
            companyGroups={props.companyGroups}
            useCompanyGroup={showingCompanyGroup()}
            updateActiveCompany={props.updateActiveCompany}
            addCompanyToCompanyGroup={props.addCompanyToCompanyGroup}
            removeCompanyFromCompanyGroup={props.removeCompanyFromCompanyGroup}
          />
        )}
        {section === 'settings' && companyGroup && (
          <SettingsTab
            companyGroup={companyGroup}
            companyGroups={props.companyGroups}
            pricingPackages={props.pricingPackages}
            addAlert={props.addAlert}
            getPricingPackages={props.getPricingPackages}
            updateCompanyGroup={props.updateCompanyGroup}
            setCompanyGroupCompanySettings={props.setCompanyGroupCompanySettings}
          />
        )}
        {section === 'users' && companyGroup && (
          <UsersOverview
            companyGroup={companyGroup}
            companyGroupUser={props.companyGroupUser}
            companyGroups={props.companyGroups}
            userInviteCompanyGroups={props.userInviteCompanyGroups}
            user={props.user}
            addAlert={props.addAlert}
            removeUserFromCompanyGroup={props.removeUserFromCompanyGroup}
            getCompanyGroupUserInvites={props.getCompanyGroupUserInvites}
            addCompanyGroupUserInvite={props.addCompanyGroupUserInvite}
            deleteCompanyGroupUserInvite={props.deleteCompanyGroupUserInvite}
            updateCompanyGroupUser={props.updateCompanyGroupUser}
          />
        )}
        {section === 'reports' && companyGroup && <ReportsTab companyGroup={companyGroup} addAlert={props.addAlert} />}
        {section === 'accounting' && companyGroup && <AccountingIntegrationTab companyGroup={companyGroup} />}
      </div>
    </div>
  )
}
