import { addDays, compareAsc, getISOWeek, startOfWeek, subDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import Contract from '../../../model/contract'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import Project from '../../../model/project'
import TimeRegistration, { TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import TimeRegistrationTemplate, {
  TimeRegistrationTemplateCreationFields,
  TimeRegistrationTemplateLine,
} from '../../../model/timeRegistrationTemplate'
import { Day } from '../../../model/types'
import { AlertReducer } from '../../../reducers/alerts'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../../../reducers/timeRegistrationTemplates'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import {
  formatAPIDate,
  formatDate,
  getDate,
  isSameOrBefore,
  isTimeAfter,
  isTimeBefore,
  isTimeBetween,
} from '../../../utils/date-utils'
import { getCurrentWorkWeek, numberToDay, week } from '../../../utils/day-utils'
import { formatError } from '../../../utils/error-utils'
import { formatDisplayNumber, formatNumber } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import { validateWorkHours } from '../../../utils/work-hours-util'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import { Col, Row } from '../../elements/grid'
import Icon from '../../elements/Icon'
import Subtitle from '../../elements/Subtitle'
import Switch from '../../elements/switch'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import DetailedTimeRegistrationEdit from '../time-registration/DetailedTimeRegistrationEdit'
import WorkHoursWeekForm, { WorkHoursResult } from './WorkHoursWeekForm'

type BaseProps = {
  alerts: AlertReducer
  company: Company
  timeRegistrationTemplates: TimeRegistrationTemplateReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getTimeRegistrationTemplates: (companyID: string) => void
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (
    companyID: string | undefined,
    employeeID: string | undefined
  ) => Promise<boolean | void>
}

type RegistrationProps = BaseProps & {
  templateMode: false
  employee: Employee
  employeeContractDeltas: EmployeeContractDeltaReducer
  timeRegistrations: TimeRegistrationReducer
  projects: List<Project>
  costCenters: List<CostCenter>
  departments: List<Department>
  companyFeatures: List<CompanyFeature>
  leaveTypes: List<LeaveType>
  canEditObjects: boolean

  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
}

type TemplateProps = BaseProps & {
  templateMode: true
  employee?: Employee
}

export default function WorkHoursForm(props: RegistrationProps | TemplateProps): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [editing, setEditing] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [detailedWeeks, setDetailedWeeks] = useState<Date[]>([])
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(getDate(), { weekStartsOn: 1 }))
  const [error, setError] = useState<Error | null>(null)

  const templateMode = props.templateMode

  const { company, getTimeRegistrationTemplates, timeRegistrationTemplates } = props

  useEffect(() => {
    if (
      (!timeRegistrationTemplates.loaded && !timeRegistrationTemplates.loading) ||
      timeRegistrationTemplates.companyID !== company.id
    ) {
      getTimeRegistrationTemplates(company.id)
    }
  }, [company, timeRegistrationTemplates, getTimeRegistrationTemplates])

  const setEditVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const setTemplateModal = (t: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowTemplateModal(t)
  }

  const timeRegistrations = props.templateMode ? null : props.timeRegistrations
  const { addAlert } = props

  useEffect(() => {
    if (!templateMode) {
      if (regularComponentDidUpdate(timeRegistrations!.error, error, setError)) {
        return
      }
    }
    if (regularComponentDidUpdate(timeRegistrationTemplates.error, error, setError)) {
      return
    }
  }, [templateMode, timeRegistrations, timeRegistrationTemplates, error])

  const previousTimeRegistrations = usePrevious(templateMode ? null : timeRegistrations)

  useEffect(() => {
    if (templateMode) {
      return
    }
    if (previousTimeRegistrations?.saving && !timeRegistrations!.saving) {
      if (!timeRegistrations!.error) {
        addAlert('success', t('work_hours_registration_tab.alert.success'), { timeout: 5 })
        setEditVisibility(false)
      }
    }
  }, [templateMode, timeRegistrations, previousTimeRegistrations, addAlert])

  const previousTimeRegistrationTemplates = usePrevious(timeRegistrationTemplates)

  useEffect(() => {
    if (templateMode) {
      return
    }
    if (previousTimeRegistrationTemplates?.saving && !timeRegistrationTemplates.saving) {
      if (!timeRegistrationTemplates.error) {
        setTemplateModal(false)
      }
    }
  }, [templateMode, previousTimeRegistrationTemplates, timeRegistrationTemplates, setError])

  const changeDetailed = (weekStart: Date, detailed: boolean) => {
    if (!detailed) {
      setDetailedWeeks((prev) => prev.filter((d) => compareAsc(d, weekStart) !== 0))
    } else {
      setDetailedWeeks((prev) => [...prev, weekStart])
    }
  }

  const remoteLeaveTypeID = props.templateMode
    ? undefined
    : props.leaveTypes.find((type) => type.name === 'DenmarkRemoteWorkDay')?.id
  const leaveRegistrations = props.templateMode
    ? []
    : props.timeRegistrations.timeRegistrations
        .filter(
          (reg) =>
            reg.employeeID === props.employee.id && reg.class === 'Leave' && reg.leaveTypeID !== remoteLeaveTypeID
        )
        .toArray()
  const registrations = props.templateMode
    ? []
    : props.timeRegistrations.timeRegistrations
        .filter((reg) => reg.employeeID === props.employee.id && reg.class === 'Work Hours')
        .toArray()
  type registrationWeek = {
    weekStart: Date
    workWeek: Day[]
    registrations: Record<Day, TimeRegistration[]>
    leaveRegistrations: Record<Day, TimeRegistration[]>
  }
  const getWorkWeek = (weekStart: Date) => {
    if (props.templateMode) {
      return []
    }
    const weekEnd = addDays(weekStart, 6)
    const contractA = props.employeeContractDeltas.contracts.find((c) =>
      isTimeBetween(weekStart, c.validFrom, c.validTo ?? weekStart)
    )
    const contracts: Contract[] = []
    if (contractA) {
      contracts.push(contractA.contract)
      if (contractA.validTo && isTimeBefore(contractA.validTo, weekEnd)) {
        const contractB = props.employeeContractDeltas.contracts.find((c) =>
          isTimeBetween(weekEnd, c.validFrom, c.validTo ?? weekEnd)
        )
        if (contractB) {
          contracts.push(contractB.contract)
        }
      }
    }
    let test = weekStart
    const workWeek: Day[] = []
    contracts.forEach((contract) => {
      // use the earliest of validTo or weekEnd
      let against = contract.validTo ?? weekEnd
      if (isTimeAfter(against, weekEnd)) {
        against = weekEnd
      }
      while (isSameOrBefore(test, against)) {
        const day = numberToDay(test.getDay())
        if (getCurrentWorkWeek(contract.workCycle, contract.workCycleAnchorDate, weekStart).some((d) => d === day)) {
          workWeek.push(day)
        }
        test = addDays(test, 1)
      }
    })
    return workWeek
  }

  const weeks = registrations
    .reduce((weeks: registrationWeek[], reg) => {
      const weekStart = startOfWeek(getDate(reg.date), { weekStartsOn: 1 })
      let weekIndex = weeks.findIndex((week) => compareAsc(week.weekStart, weekStart) === 0)
      if (weekIndex < 0) {
        weeks.push({
          weekStart: weekStart,
          workWeek: getWorkWeek(weekStart),
          registrations: {
            ['Monday']: [],
            ['Tuesday']: [],
            ['Wednesday']: [],
            ['Thursday']: [],
            ['Friday']: [],
            ['Saturday']: [],
            ['Sunday']: [],
          },
          leaveRegistrations: {
            ['Monday']: [],
            ['Tuesday']: [],
            ['Wednesday']: [],
            ['Thursday']: [],
            ['Friday']: [],
            ['Saturday']: [],
            ['Sunday']: [],
          },
        })
        weekIndex = weeks.length - 1
      }
      const week = weeks[weekIndex]
      week.registrations[numberToDay(getDate(reg.date).getDay())].push(reg)
      weeks[weekIndex] = week
      return weeks
    }, [])
    .sort((a, b) => {
      return formatAPIDate(b.weekStart).localeCompare(formatAPIDate(a.weekStart))
    })

  const remove = (id: string) => {
    if (props.templateMode) {
      return
    }
    if (window.confirm(t('common.are_you_sure'))) {
      setDeleting([...deleting, id])
      props.deleteTimeRegistration(id)
    }
  }

  type TimeRegistrationRow = {
    key: string
    id: string
    date: string
    hours: string
    note: string
  }

  const detailedColumns = [
    { title: t('work_hours_registration_tab.detailed.table.date'), dataIndex: 'date', key: 'date' },
    { title: t('work_hours_registration_tab.detailed.table.hours'), dataIndex: 'hours', key: 'hours' },
    { title: t('work_hours_registration_tab.detailed.table.note'), dataIndex: 'note', key: 'note' },
    {
      title: '',
      key: 'x3',
      className: 'employee-table-actions',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (deleting.indexOf(timeRegistration.id) !== -1) {
          return null
        }
        if (!props.templateMode && !props.canEditObjects) {
          return null
        }
        return (
          <div>
            {!props.templateMode && (
              <Tooltip title={t('work_hours_registration_tab.detailed.table.actions.edit')}>
                <span onClick={() => setEditVisibility(timeRegistration.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="edit" color="grey" />
                </span>
              </Tooltip>
            )}
            <Tooltip title={t('work_hours_registration_tab.detailed.table.actions.delete')}>
              <span
                onClick={(e) => {
                  e.preventDefault()
                  remove(timeRegistration.id)
                }}
                style={{ cursor: 'pointer' }}
              >
                <Icon type="cross" color="grey" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const noneTimeRegistrationTemplate = () => {
    if (!props.templateMode || !props.employee) {
      return
    }
    props
      .saveTimeRegistrationTemplates({
        companyID: props.company.id,
        employeeID: props.employee.id,
        class: 'Work Hours',
        lines: [],
      })
      .then((res) => {
        if (res) {
          addAlert('success', t('work_hours_registration_tab.alert.template_none_saved'), { timeout: 5 })
        }
      })
  }

  const handleSimpleSubmit = (values: WorkHoursResult) => {
    if (props.templateMode) {
      const lines = values.days.reduce((lines: TimeRegistrationTemplateLine[], day) => {
        if (day.hours === 0) {
          // ignore days without any hours
          return lines
        }
        return [
          ...lines,
          {
            weekday: numberToDay(day.date.getDay()),
            hours: day.hours,
            start: day.start,
            end: day.end,
            note: '',
          },
        ]
      }, [])
      props
        .saveTimeRegistrationTemplates({
          companyID: props.company.id,
          employeeID: props.employee?.id ?? undefined,
          class: 'Work Hours',
          lines,
        })
        .then((res) => {
          if (res) {
            addAlert('success', t('work_hours_registration_tab.alert.template_saved'), { timeout: 5 })
          }
        })
    } else {
      values.days.forEach((day) => {
        const date = formatAPIDate(day.date)
        const existing = registrations.find((reg) => reg.date === date)
        if (existing) {
          if (day.hours === 0 && !day.start) {
            // if 0 and start unset, we consider it a deletion
            props.deleteTimeRegistration(existing.id)
          } else if (existing.hours !== day.hours || existing.start !== day.start) {
            // only update if an actual difference
            props.updateTimeRegistration({ ...existing, hours: day.hours, start: day.start, end: day.end })
          }
        } else if (day.hours > 0 || !!day.start) {
          // only create ones with hours or a start set
          props.createTimeRegistration({
            employeeID: props.employee.id,
            class: 'Work Hours',
            date,
            hours: day.hours,
            start: day.start,
            end: day.end,
            approved: true,
            note: '',
          })
        }
      })
    }
  }

  const deleteTimeRegistrationTemplate = () => {
    if (props.employee) {
      props.deleteTimeRegistrationTemplate(undefined, props.employee.id).then(() => {
        addAlert('success', t('work_hours_registration_tab.alert.template_deleted'), { timeout: 5 })
      })
    } else {
      props.deleteTimeRegistrationTemplate(props.company.id, undefined).then(() => {
        addAlert('success', t('work_hours_registration_tab.alert.template_deleted'), { timeout: 5 })
      })
    }
  }

  if (props.templateMode) {
    if (!timeRegistrationTemplates.loaded) {
      return <LoadingOverlay />
    }
  } else {
    if (!props.employeeContractDeltas.loaded || !timeRegistrationTemplates.loaded) {
      return <LoadingOverlay />
    }
    if (!props.employee.activeEmployment) {
      return <NoEmploymentCard />
    }
    if (
      !props.employee.activeContract ||
      props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
    ) {
      return <NoContractCard employee={props.employee} />
    }
  }

  const templateEnabled = props.templateMode || props.company.settingsEnabled.includes('EnableWorkHoursAutomatic')

  const templateButton = () => {
    if (props.templateMode) {
      return null
    }
    if (!templateEnabled) {
      return null
    }

    return (
      <div className="work-week-template">
        <Button type="secondary" onClick={() => setTemplateModal(true)}>
          {t('work_hours_registration_tab.template')}
        </Button>
      </div>
    )
  }

  const showWeek = (w: registrationWeek) => {
    if (props.templateMode) {
      const findEmployee = (t: TimeRegistrationTemplate): boolean => t.employeeID === props.employee?.id
      const findCompany = (t: TimeRegistrationTemplate): boolean => t.companyID === props.company.id && !t.employeeID
      const employeeTemplate = props.timeRegistrationTemplates.timeRegistrationTemplates.find((t) => findEmployee(t))
      const companyTemplate = props.timeRegistrationTemplates.timeRegistrationTemplates.find((t) => findCompany(t))
      return (
        <Card key={formatAPIDate(w.weekStart)} className="work-week-simple">
          <div className="work-week-header">
            <Subtitle>{t('work_hours_registration_tab.week.title.template')}</Subtitle>
          </div>
          <WorkHoursWeekForm
            company={props.company}
            weekStart={w.weekStart}
            registrations={
              (employeeTemplate ?? companyTemplate)?.lines.reduce(
                (o: Partial<Record<Day, TimeRegistrationTemplateLine[]>>, line) => {
                  const list = o[line.weekday]
                  if (!list) {
                    o[line.weekday] = [line]
                  } else {
                    o[line.weekday] = [...list, line]
                  }
                  return o
                },
                {}
              ) ?? {}
            }
            workWeek={week}
            templateMode={true}
            companyTemplate={!props.employee}
            hasEmployeeTemplate={!!employeeTemplate}
            hasCompanyTemplate={!!companyTemplate}
            alerts={props.alerts}
            removeAlert={props.removeAlert}
            onDelete={() => deleteTimeRegistrationTemplate()}
            onNone={() => noneTimeRegistrationTemplate()}
            onSubmit={handleSimpleSubmit}
          />
        </Card>
      )
    }
    // we decide to force the display detailed,
    const forceDetailed =
      w.workWeek.length === 0 || // if no work week
      week.some(
        // if any day has more than one registration OR we are outside the work week,
        (day) =>
          w.registrations[day].length > 1 || (!w.workWeek.some((d) => d == day) && w.registrations[day].length > 0)
      ) ||
      week.some(
        // or if there are both registrations with start/end and without
        (day) =>
          w.registrations[day].some((r) => !!r.start || !!r.end) &&
          week.some((day) => w.registrations[day].some((r) => !r.start && !r.end))
      )
    const isDetailed = detailedWeeks.some((d) => compareAsc(d, w.weekStart) === 0) || forceDetailed
    const detailedSwitch = (
      <div className="ant-switch-wrapper" style={{ display: 'inline-block', marginLeft: '20px' }}>
        <Switch
          checked={detailedWeeks.some((d) => compareAsc(d, w.weekStart) === 0)}
          onChange={(checked: boolean) => {
            changeDetailed(w.weekStart, checked)
          }}
        />
        <span className="ant-switch-text">{t('work_hours_registration_tab.week.detailed_toggle')}</span>
      </div>
    )
    const summaryHours = week.reduce((hours, day) => {
      return (
        hours +
        w.registrations[day].reduce((hours, reg) => {
          return hours + (reg.hours ?? 0)
        }, 0)
      )
    }, 0)
    const summary = (
      <div className="work-week-summary">
        {tx('work_hours_registration_tab.week.summary', {
          summary: (
            <span>
              {t('work_hours_registration_tab.week.summary.count', {
                count: summaryHours,
                number: formatNumber(summaryHours, 2),
              })}
            </span>
          ),
        })}
      </div>
    )
    if (!isDetailed) {
      return (
        <Card key={formatAPIDate(w.weekStart)} className="work-week-simple">
          <div className="work-week-header">
            <Subtitle>
              {t('work_hours_registration_tab.week.title', {
                week_number: formatNumber(getISOWeek(w.weekStart)),
                week_from: formatDate(w.weekStart),
                week_to: formatDate(addDays(w.weekStart, 6)),
              })}
            </Subtitle>
            <TitleMenu>
              {templateButton()}
              <div className="work-week-detailed-button">{detailedSwitch}</div>
            </TitleMenu>
          </div>
          {summary}
          <WorkHoursWeekForm
            company={props.company}
            weekStart={w.weekStart}
            registrations={w.registrations}
            leaveRegistrations={leaveRegistrations}
            leaveTypes={props.leaveTypes}
            workWeek={w.workWeek}
            templateMode={false}
            templateEnabled={templateEnabled}
            onSubmit={handleSimpleSubmit}
          />
        </Card>
      )
    }
    const regs = week
      .reduce((regs: TimeRegistration[], day) => {
        return [...regs, ...w.registrations[day]]
      }, [])
      .map(
        (reg): TimeRegistrationRow => ({
          key: reg.id,
          id: reg.id,
          date: formatDate(reg.date),
          hours: formatDisplayNumber(reg.hours),
          note: reg.note,
        })
      )
    return (
      <Card key={formatAPIDate(w.weekStart)} className="work-week-detailed">
        <div className="work-week-header">
          <Subtitle>
            {t('work_hours_registration_tab.week.title', {
              week_number: formatNumber(getISOWeek(w.weekStart)),
              week_from: formatDate(w.weekStart),
              week_to: formatDate(addDays(w.weekStart, 6)),
            })}
          </Subtitle>
          <TitleMenu>
            {templateButton()}
            <div className="work-week-detailed-button">
              <Button onClick={() => setEditVisibility(true)} className="register-time-registration">
                <Icon type="stopwatch" color="grey" />
                {t('work_hours_registration_tab.week.new_registration')}
              </Button>
              {!forceDetailed && detailedSwitch}
            </div>
          </TitleMenu>
        </div>
        {summary}
        <Table columns={detailedColumns} dataSource={regs} size="small" pagination={false} />
      </Card>
    )
  }

  const currentWeek: registrationWeek = weeks.find((w) => compareAsc(w.weekStart, currentWeekStart) === 0) ?? {
    weekStart: currentWeekStart,
    workWeek: getWorkWeek(currentWeekStart),
    registrations: {
      ['Monday']: [],
      ['Tuesday']: [],
      ['Wednesday']: [],
      ['Thursday']: [],
      ['Friday']: [],
      ['Saturday']: [],
      ['Sunday']: [],
    },
    leaveRegistrations: {
      ['Monday']: [],
      ['Tuesday']: [],
      ['Wednesday']: [],
      ['Thursday']: [],
      ['Friday']: [],
      ['Saturday']: [],
      ['Sunday']: [],
    },
  }

  const violationErrors =
    !props.templateMode &&
    props.companyFeatures.some((feature) => feature.featureType === 'Work Hours Warnings') &&
    props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursWarnings')
      ? validateWorkHours(props.timeRegistrations.timeRegistrations.toArray(), props.employee.id)
      : []

  return (
    <Card className="employees-work-hours-tab">
      {error && <Alert type={'error'} message={formatError(error)} showIcon />}
      <Title>{t('work_hours_registration_tab.title')}</Title>
      {!templateMode && (
        <>
          <p>{t('work_hours_registration_tab.header.help.work_hours.line_1')}</p>
          <p>{t('work_hours_registration_tab.header.help.work_hours.line_2')}</p>
          {violationErrors.map((v, i) => {
            const msg = (msgID: string, date: Date) => {
              return (
                <div className="employees-work-hours-tab-violation-message">
                  <p>{t(msgID)}</p>
                  <div>
                    <Button onClick={() => setCurrentWeekStart(date)}>
                      {t('work_hours_registration_tab.violation.go_to_week')}
                    </Button>
                  </div>
                </div>
              )
            }
            return (
              <React.Fragment key={i}>
                {v.averageRuleViolations.map((d, j) => {
                  return (
                    <Alert
                      key={`a-${j}`}
                      type={'warning'}
                      message={msg('work_hours_registration_tab.violation.average_rule_violation', d)}
                      showIcon
                    />
                  )
                })}
                {v.restHoursViolations.map((d, j) => {
                  return (
                    <Alert
                      key={`r-${j}`}
                      type={'warning'}
                      message={msg('work_hours_registration_tab.violation.rest_hours_violation', d)}
                      showIcon
                    />
                  )
                })}
                {v.dayOffViolations.map((d, j) => {
                  return (
                    <Alert
                      key={`d-${j}`}
                      type={'warning'}
                      message={msg('work_hours_registration_tab.violation.day_off_violation', d)}
                      showIcon
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
          <Row style={{ marginBottom: '15px' }}>
            <Col span={8}>
              <Button onClick={() => setCurrentWeekStart((prev) => subDays(prev, 7))}>
                {t('work_hours_registration_tab.week.previous')}
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <Button onClick={() => setCurrentWeekStart(startOfWeek(getDate(), { weekStartsOn: 1 }))}>
                {t('work_hours_registration_tab.week.this_week')}
              </Button>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => setCurrentWeekStart((prev) => addDays(prev, 7))}>
                {t('work_hours_registration_tab.week.next')}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {showWeek(currentWeek)}

      {!props.templateMode && (
        <Modal
          key={`edit-${modalKey}`}
          visible={editing}
          onOk={() => setEditVisibility(false)}
          onCancel={() => setEditVisibility(false)}
          width={376}
          footer={null}
        >
          <DetailedTimeRegistrationEdit
            visible={editing !== false}
            company={props.company}
            employee={props.employee}
            mode={'Work Hours'}
            timeRegistrationID={typeof editing === 'string' ? editing : undefined}
            timeRegistrations={props.timeRegistrations}
            projects={props.projects}
            costCenters={props.costCenters}
            departments={props.departments}
            createTimeRegistration={props.createTimeRegistration}
            updateTimeRegistration={props.updateTimeRegistration}
            includeDateRange={[currentWeek.weekStart, addDays(currentWeek.weekStart, 6)]}
            suggestedDate={currentWeek.weekStart}
          />
        </Modal>
      )}
      {!props.templateMode && (
        <Modal
          key={`template-${modalKey}`}
          visible={showTemplateModal}
          onOk={() => setTemplateModal(false)}
          onCancel={() => setTemplateModal(false)}
          width={750}
          footer={null}
        >
          <WorkHoursForm
            templateMode={true}
            alerts={props.alerts}
            company={props.company}
            employee={props.employee}
            timeRegistrationTemplates={props.timeRegistrationTemplates}
            addAlert={props.addAlert}
            removeAlert={props.removeAlert}
            getTimeRegistrationTemplates={props.getTimeRegistrationTemplates}
            saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
            deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
          />
        </Modal>
      )}
    </Card>
  )
}
