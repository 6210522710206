import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import OneTimePayApproved from '../../form-elements/one-time-pay/OneTimePayApproved'
import OneTimePayDispositionDate from '../../form-elements/one-time-pay/OneTimePayDispositionDate'
import OneTimePayTitle from '../../form-elements/one-time-pay/OneTimePayTitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  editing: boolean
  forEIncome: boolean
  canApproveObjects: boolean
  company: Company
  employee: Employee
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
}

type Fields = {
  dispositionDate?: DateFormat
  title?: string
  approved: boolean
}

export type FreeTextResult = {
  dispositionDate: DateFormat
  title: string
  approved: boolean
}

function FreeTextEditForm(props: Props & FormComponentProps<Fields, FreeTextResult>): ReactElement | null {
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          <OneTimePayDispositionDate
            {...props}
            oneTimePays={props.oneTimePays.oneTimePays.toArray()}
            oneTimePayID={props.oneTimePayID}
            title={t('free_text.form.disposition_date')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <OneTimePayTitle
            {...props}
            maxLength={props.forEIncome ? 58 : undefined}
            hardLengthLimit={props.forEIncome}
          />
        </Col>
      </Row>
      <OneTimePayApproved {...props} />
      {props.editing && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
      {props.oneTimePays.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, FreeTextResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      approved:
        !props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects,
    }
    if (props.oneTimePayID) {
      const oneTimePay = props.oneTimePays.oneTimePays.find((oneTimePay) => oneTimePay.id === props.oneTimePayID)
      if (oneTimePay) {
        fields.dispositionDate = oneTimePay.dispositionDate
        fields.title = oneTimePay.title
        fields.approved = oneTimePay.approved
      }
    }
    return fields
  },
  onSubmit: (values) => ({ ...values, dispositionDate: values.dispositionDate!, title: values.title! }),
})(FreeTextEditForm)
