import { isSameDay, subMonths } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { disableCompanySettings, enableCompanySettings } from '../actions/companies'
import { getDashboards } from '../actions/dashboards'
import { getPayRolls } from '../actions/pay-rolls'
import { getSwipes } from '../actions/swipes'
import { getTransfers } from '../actions/transfers'
import { deleteWarning } from '../actions/warnings'
import DashboardComponent from '../components/dashboard/DashboardComponent'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import Company from '../model/company'
import CompanySetting from '../model/companySetting'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DashboardReducer } from '../reducers/dashboards'
import { EmployeeReducer } from '../reducers/employees'
import { PayRollReducer } from '../reducers/payRolls'
import { SwipeReducer } from '../reducers/swipes'
import { TransferReducer } from '../reducers/transfers'
import { UserReducer } from '../reducers/user'
import { WarningReducer } from '../reducers/warnings'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { isDepartmentRestricted } from '../utils/permissions-utils'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  user: UserReducer
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  companyUsers: CompanyUserReducer
  dashboards: DashboardReducer
  employees: EmployeeReducer
  payRolls: PayRollReducer
  transfers: TransferReducer
  swipes: SwipeReducer
  warnings: WarningReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getPayRolls: () => void
  getSwipes: () => void
  getTransfers: (payRollID: string | undefined, companyID: string | undefined, fromDate: DateFormat) => void
  getDashboards: (companyID: string) => void
  deleteWarning: (id: string) => Promise<boolean | void>
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
}

function Dashboard(props: Reducers & Actions): ReactElement | null {
  const {
    companyUsers,
    payRolls,
    getPayRolls,
    companies,
    transfers,
    getTransfers,
    swipes,
    getSwipes,
    dashboards,
    getDashboards,
  } = props
  useEffect(() => {
    if (isDepartmentRestricted(companyUsers.companyUser)) {
      return
    }
    if (!payRolls.loading && !payRolls.loaded) {
      getPayRolls()
    }
    if (companies.company) {
      const companyID = companies.company.id
      const fromDate = subMonths(getDate(), 6)
      if (
        transfers.companyID !== companyID ||
        (!!transfers.fromDate && !isSameDay(fromDate, getDate(transfers.fromDate))) ||
        (transfers.payRollID && !transfers.loading && transfers.loaded) ||
        (!transfers.loading && !transfers.loaded)
      ) {
        getTransfers(undefined, companyID, formatAPIDate(fromDate))
      }
      if (!swipes.loading && !swipes.loaded) {
        getSwipes()
      }
      if (dashboards.companyID !== companyID || (!dashboards.loading && !dashboards.loaded)) {
        getDashboards(companyID)
      }
    }
  }, [
    companyUsers,
    payRolls,
    getPayRolls,
    companies,
    transfers,
    getTransfers,
    swipes,
    getSwipes,
    dashboards,
    getDashboards,
  ])

  if (isDepartmentRestricted(companyUsers.companyUser)) {
    jsBrowserHistory.push('/' + paths.APPROVE_TAB)
    return null
  }

  const company = props.companies.company
  if (!company) {
    return null
  }

  const loading = !props.payRolls.loaded || !props.dashboards.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <DashboardComponent
      alerts={props.alerts}
      company={company}
      companyFeatures={props.companyFeatures.companyFeatures}
      dashboards={props.dashboards.dashboards}
      employees={props.employees.employees}
      payRolls={props.payRolls.payRolls}
      swipes={props.swipes.swipes}
      transfers={props.transfers.transfers}
      warnings={props.warnings}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      deleteWarning={props.deleteWarning}
      enableCompanySettings={props.enableCompanySettings}
      disableCompanySettings={props.disableCompanySettings}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    companies: state.companies,
    companyUsers: state.companyUsers,
    companyFeatures: state.companyFeatures,
    dashboards: state.dashboards,
    employees: state.employees,
    payRolls: state.payRolls,
    swipes: state.swipes,
    transfers: state.transfers,
    warnings: state.warnings,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getDashboards: getDashboards,
    getPayRolls: getPayRolls,
    getSwipes: getSwipes,
    getTransfers: getTransfers,
    deleteWarning: deleteWarning,
    enableCompanySettings: enableCompanySettings,
    disableCompanySettings: disableCompanySettings,
  }
)(Dashboard)
