import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import CompanyUser from '../../../model/companyUser'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Department, { DepartmentCreationFields } from '../../../model/department'
import Employee from '../../../model/employee'
import EmployeeDimension, { EmployeeDimensionMutableFields } from '../../../model/employeeDimension'
import Employment from '../../../model/employment'
import EmploymentPosition from '../../../model/employmentPosition'
import { LeaveAdjustmentCreationFields } from '../../../model/leaveAdjustment'
import LeaveType from '../../../model/leaveType'
import PensionCompany from '../../../model/pensionCompany'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import SupplementType from '../../../model/supplementType'
import { AccountingDimensionReducer } from '../../../reducers/accountingDimensions'
import { CompanyAccountingIntegrationReducer } from '../../../reducers/companyAccountingIntegration'
import { ContractReducer } from '../../../reducers/contracts'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import PensionScheme from '../../../types/pension-scheme'
import { formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import { formatLoadingText } from '../../../utils/loading-utils'
import { t, tx } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import EmploymentBenefitsCard from './EmploymentBenefitsCard'
import EmploymentChoiceCard from './EmploymentChoiceCard'
import EmploymentDetailsCard from './EmploymentDetailsCard'
import EmploymentPayCard from './EmploymentPayCard'
import EmploymentPensionCard from './EmploymentPensionCard'
import EmploymentVacationCard from './EmploymentVacationCard'

type Props = {
  companyUser?: CompanyUser
  employee: Employee
  company: Company
  companyUsers: List<CompanyUser>
  contracts: ContractReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  employments: EmploymentReducer
  employmentPositions: List<EmploymentPosition>
  employeeContractDeltas: EmployeeContractDeltaReducer
  employeeDimensions: List<EmployeeDimension>
  viewingContract?: Contract
  salaryCycles: List<SalaryCycle>
  salaryCycle?: SalaryCycle
  pensionCompanies: List<PensionCompany>
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  salaryTypes: List<SalaryType>
  leaveBalances: LeaveBalanceReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  accountingDimensions: AccountingDimensionReducer
  hasFutureContractsFeature: boolean
  canEditObjects: boolean
  canHireFire: boolean

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  updateEmployment: (id: string, employment: Employment) => void
  addContract: (contract: ContractCreationFields) => Promise<Contract | void>
  updateContract: (contract: ContractMutableFields) => Promise<Contract | void>
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  getLeaveBalances: () => void
  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
  setEmployeeContract: (employeeID: string, contractID: string) => void
  updateEmployeeDimensions: (employeeID: string, employeeDimensions: EmployeeDimensionMutableFields[]) => void
  setRehireVisibility: (visible: boolean) => void
  setDeleteVisibility: (visible: boolean) => void
  setTerminateVisibility: (visible: boolean) => void
  setCreateFutureContractVisibility: (visible: boolean) => void
  setDeleteContractVisibility: (visible: boolean) => void
  getCompanyAccountingIntegration: () => void
  getAccountingDimensions: () => void
  markEmployeeReady: (employeeID: string) => Promise<Employee | void>
  unmarkEmployeeReady: (employeeID: string) => Promise<Employee | void>
}

export default function EmploymentTab(props: Props): ReactElement | null {
  const {
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
    accountingDimensions,
    getAccountingDimensions,
    company,
  } = props
  const hasEmployeeDimensionsEnabled = company.settingsEnabled.some((setting) => setting === 'EnableEmployeeDimensions')
  useEffect(() => {
    if (hasEmployeeDimensionsEnabled) {
      if (!companyAccountingIntegration.loaded && !companyAccountingIntegration.loading) {
        getCompanyAccountingIntegration()
      }
      if (!accountingDimensions.loaded && !accountingDimensions.loading && !accountingDimensions.error) {
        getAccountingDimensions()
      }
    }
  }, [
    hasEmployeeDimensionsEnabled,
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
    accountingDimensions,
    getAccountingDimensions,
  ])

  const { contracts, addAlert, employee } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        addAlert('success', t('employment.alert.success', { name: employee.name }), { timeout: 5 })
      }
    }
  }, [previousContracts, contracts, addAlert, employee])

  const viewingContract = props.viewingContract || props.employee.activeContract

  const isMutableContract = (): boolean => {
    return props.canEditObjects && !!viewingContract
  }

  const isFutureContract = (): boolean => {
    if (
      !viewingContract ||
      !props.employee.earliestMutableContract ||
      viewingContract.id === props.employee.earliestMutableContract.id ||
      !props.employee.earliestMutableContract.validTo
    ) {
      return false
    }
    return isTimeAfter(getDate(viewingContract.validFrom), getDate(props.employee.earliestMutableContract.validTo))
  }

  const hasFutureContracts = (): boolean => {
    if (!props.employeeContractDeltas.loaded || !viewingContract) {
      return false
    }
    return props.employeeContractDeltas.contracts.some((delta) =>
      isTimeAfter(getDate(delta.validFrom), getDate(viewingContract.validFrom))
    )
  }

  const showContractStateAlert = () => {
    if (!viewingContract || !props.canEditObjects || props.employeeContractDeltas.employeeID !== props.employee.id) {
      return null
    }
    let newContractWarning = null
    if (!isMutableContract()) {
      newContractWarning = 'immutable'
    } else if (props.hasFutureContractsFeature && isFutureContract()) {
      newContractWarning = 'future'
    } else if (props.hasFutureContractsFeature && hasFutureContracts()) {
      newContractWarning = 'hasFuture'
    }

    switch (newContractWarning) {
      case 'immutable':
        return (
          <Alert
            message={
              <>
                {props.employee.earliestMutableContract
                  ? tx('employment.contract_warning.immutable.has_mutable', {
                      link: (
                        <DumbLink
                          onClick={() => {
                            if (props.employee.earliestMutableContract) {
                              props.setEmployeeContract(props.employee.id, props.employee.earliestMutableContract.id)
                            }
                          }}
                          style={{ color: '#fff', textDecoration: 'underline' }}
                        >
                          {t('employment.contract_warning.immutable.has_mutable.link')}
                        </DumbLink>
                      ),
                    })
                  : t('employment.contract_warning.immutable.no_mutable')}
              </>
            }
            type="warning"
            showIcon
          />
        )
      case 'future':
        return (
          <Alert
            message={t('employment.contract_warning.future', { date: formatDate(viewingContract.validFrom) })}
            type="warning"
            showIcon
          />
        )
      case 'hasFuture':
        return <Alert message={t('employment.contract_warning.has_future')} type="warning" showIcon />
      default:
        break
    }
  }

  const addATP = () => {
    if (!isMutableContract() || !props.viewingContract?.remuneration) {
      return
    }
    props.updateContract({
      ...props.viewingContract,
      remuneration: {
        ...props.viewingContract.remuneration,
        pension: [...props.viewingContract.remuneration.pension, { scheme: PensionScheme.ATP, title: 'ATP' }],
      },
    })
  }

  if (!props.departments.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([{ loading: !props.departments.loaded, text: t('loading.reducer.departments') }])}
        />
      </div>
    )
  }
  if (
    hasEmployeeDimensionsEnabled &&
    (!props.companyAccountingIntegration.loaded || !props.accountingDimensions.loaded)
  ) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            {
              loading: !props.companyAccountingIntegration.loaded,
              text: t('loading.reducer.company_accounting_integration'),
            },
            { loading: !props.accountingDimensions.loaded, text: t('loading.reducer.accounting_dimensions') },
          ])}
        />
      </div>
    )
  }

  const activeEmployment = props.employee.activeEmployment

  if (!activeEmployment) {
    return <NoEmploymentCard />
  }

  if (!viewingContract) {
    return <NoContractCard employee={props.employee} />
  }

  if (!props.salaryCycle) {
    return null
  }

  // if the integration is not set, we behave as if they were dimension values
  const usesDimensionValues = props.companyAccountingIntegration.accountingIntegration?.hasDimensionValues ?? true
  // mutableContract down the line refers to the viewingContract if isMutableContract is true
  return (
    <div className="employees-single-employment-tab">
      {showContractStateAlert()}
      {props.employee.affiliationType === 'Standard' &&
        !viewingContract.remuneration?.pension.some((pension) => pension.scheme === 'ATP') && (
          <Alert
            type={'error'}
            message={
              <>
                {tx('employment.no_atp_warning', { em: <em>{t('employment.no_atp_warning.em')}</em> })}
                <Button size="large" regular style={{ float: 'right' }} onClick={() => addATP()}>
                  {t('employment.no_atp_warning.button')}
                </Button>
              </>
            }
            showIcon
          />
        )}
      <EmploymentDetailsCard
        mutableContract={viewingContract}
        company={props.company}
        companyUsers={props.companyUsers}
        employee={props.employee}
        activeEmployment={activeEmployment}
        contracts={props.contracts}
        departments={props.departments}
        employees={props.employees}
        employeeContractDeltas={props.employeeContractDeltas}
        employments={props.employments}
        employmentPositions={props.employmentPositions}
        employeeDimensions={props.employeeDimensions}
        accountingDimensions={props.accountingDimensions.accountingDimensions}
        viewingContract={viewingContract}
        salaryCycle={props.salaryCycle}
        hasFutureContractsFeature={props.hasFutureContractsFeature}
        isMutableContract={isMutableContract()}
        isFutureContract={isFutureContract()}
        companyUser={props.companyUser}
        canEditObjects={props.canEditObjects}
        canHireFire={props.canHireFire}
        hasDimensionValues={usesDimensionValues}
        addAlert={props.addAlert}
        updateEmployee={props.updateEmployee}
        updateEmployment={props.updateEmployment}
        addContract={props.addContract}
        updateContract={props.updateContract}
        addDepartment={props.addDepartment}
        setRehireVisibility={props.setRehireVisibility}
        setDeleteVisibility={props.setDeleteVisibility}
        setTerminateVisibility={props.setTerminateVisibility}
        updateEmployeeDimensions={props.updateEmployeeDimensions}
        setCreateFutureContractVisibility={props.setCreateFutureContractVisibility}
        setDeleteContractVisibility={props.setDeleteContractVisibility}
        markEmployeeReady={props.markEmployeeReady}
        unmarkEmployeeReady={props.unmarkEmployeeReady}
      />
      <EmploymentPayCard
        mutableContract={viewingContract}
        employee={props.employee}
        contracts={props.contracts}
        company={props.company}
        salaryCycles={props.salaryCycles}
        leaveTypes={props.leaveTypes}
        supplementTypes={props.supplementTypes}
        salaryTypes={props.salaryTypes}
        isMutableContract={isMutableContract()}
        addContract={props.addContract}
        updateContract={props.updateContract}
      />
      <EmploymentBenefitsCard
        mutableContract={viewingContract}
        employee={props.employee}
        contracts={props.contracts}
        isMutableContract={isMutableContract()}
        addContract={props.addContract}
        updateContract={props.updateContract}
      />
      <EmploymentVacationCard
        company={props.company}
        mutableContract={viewingContract}
        employee={props.employee}
        contracts={props.contracts}
        employees={props.employees}
        salaryCycle={props.salaryCycle}
        leaveTypes={props.leaveTypes}
        supplementTypes={props.supplementTypes}
        leaveBalances={props.leaveBalances}
        isMutableContract={isMutableContract()}
        updateEmployee={props.updateEmployee}
        addContract={props.addContract}
        updateContract={props.updateContract}
        getLeaveBalances={props.getLeaveBalances}
        addLeaveAdjustment={props.addLeaveAdjustment}
      />
      <EmploymentPensionCard
        mutableContract={viewingContract}
        settingsEnabled={props.company.settingsEnabled}
        employee={props.employee}
        contracts={props.contracts}
        pensionCompanies={props.pensionCompanies}
        leaveTypes={props.leaveTypes}
        isMutableContract={isMutableContract()}
        addContract={props.addContract}
        updateContract={props.updateContract}
      />
      <EmploymentChoiceCard
        mutableContract={viewingContract}
        settingsEnabled={props.company.settingsEnabled}
        employee={props.employee}
        contracts={props.contracts}
        supplementTypes={props.supplementTypes}
        isMutableContract={isMutableContract()}
        addContract={props.addContract}
        updateContract={props.updateContract}
      />
    </div>
  )
}
