import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../../../api/reimbursement-vouchers'
import paths from '../../../constants/paths'
import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CompanyUser from '../../../model/companyUser'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import ExpenseCategory from '../../../model/expenseCategory'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import ReimbursementVoucher from '../../../model/reimbursementVoucher'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { ReimbursementVoucherReducer } from '../../../reducers/reimbursementVouchers'
import { FileChangeEvent } from '../../../utils/antd-utils'
import { getAccessToken } from '../../../utils/cookie-utils'
import { formatDate } from '../../../utils/date-utils'
import { formatCurrency, formatNumber } from '../../../utils/number-utils'
import { url } from '../../../utils/request-utils'
import { t, tx } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import UploadDragger from '../../antd/upload/Dragger'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import ReimbursementVoucherEdit from '../../reimbursement-vouchers/ReimbursementVoucherEdit'
import DumbLink from '../../widgets/DumbLink'
import jsBrowserHistory from '../../widgets/jsBrowserHistory'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import ReimbursementEdit from './ReimbursementEdit'

type Props = {
  subsection?: string
  employee: Employee
  employees: List<Employee>
  isFreelancer: boolean
  company: Company
  companyUser?: CompanyUser
  companyUsers: List<CompanyUser>
  canEditObjects: boolean
  canApproveObjects: boolean
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenters: List<CostCenter>
  costCenterAccounting: CostCenterAccounting

  addReimbursementVoucher: (o: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  addOneTimePay: (id: string, o: OneTimePayCreationFields) => void
  updateOneTimePay: (id: string, o: OneTimePayMutableFields) => void
  deleteOneTimePay: (id: string) => void
  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
}

export default function ReimbursementTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [voucherEditing, setVoucherEditing] = useState<string | boolean>(false)
  const [mutable, setMutable] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [voucherID, setVoucherID] = useState<string | undefined>(undefined)

  const oneTimePaySaving = props.oneTimePays.saving
  const wasOneTimePaySaving = usePrevious(oneTimePaySaving)
  const oneTimePayError = props.oneTimePays.error

  const setEditVisibility = useCallback(
    (visible: string | boolean, mutable = true) => {
      setModalKey(modalKey + 1)
      setEditing(visible)
      setMutable(mutable)
    },
    [setEditing, modalKey, setModalKey, setMutable]
  )

  const setVoucherVisibility = useCallback(
    (id: string | boolean) => {
      if (id === false && typeof voucherEditing === 'string') {
        // when closing, send them to the reimbursement voucher overview
        jsBrowserHistory.push('/' + paths.REIMBURSEMENT_VOUCHERS)
      } else {
        setModalKey(modalKey + 1)
        setVoucherEditing(id)
      }
    },
    [setModalKey, modalKey, voucherEditing, setVoucherEditing]
  )

  const reimbursementVoucherList = props.reimbursementVouchers.reimbursementVouchers

  useEffect(() => {
    if (!voucherID || voucherEditing !== false) {
      return
    }
    const voucher = reimbursementVoucherList.find((voucher) => voucher.id === voucherID)
    if (!voucher) {
      return
    }
    // make sure we only send them further once the voucher is actually ready
    if (voucher.approvalState === 'Ready') {
      setUploading(false)
      setVoucherVisibility(voucherID)
    }
  }, [voucherID, voucherEditing, reimbursementVoucherList, setUploading, setVoucherVisibility])

  useEffect(() => {
    if (wasOneTimePaySaving && !oneTimePaySaving) {
      if (!oneTimePayError) {
        setEditVisibility(false)
      }
    }
  }, [wasOneTimePaySaving, oneTimePaySaving, oneTimePayError, setEditVisibility])

  const showHistory = props.subsection === 'history'

  type OneTimePayRow = {
    key: string
    id: string
    date: string
    amount: string
    rate?: string
    units?: string
    title: string
    immutable: boolean
    approved: boolean
    original: OneTimePay
  }

  const getReimbursements = (): OneTimePayRow[] => {
    return props.oneTimePays.oneTimePays
      .filter((oneTimePay) => oneTimePay.type === 'Reimbursement')
      .filter((oneTimePay) => (showHistory ? oneTimePay.settled : !oneTimePay.settled))
      .toArray()
      .map((oneTimePay: OneTimePay): OneTimePayRow => {
        let title = oneTimePay.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }
        return {
          key: oneTimePay.id,
          id: oneTimePay.id,
          date: formatDate(oneTimePay.dispositionDate),
          amount: formatCurrency(oneTimePay.amount, 2),
          rate: oneTimePay.rate ? formatCurrency(oneTimePay.rate, 2) : undefined,
          units: oneTimePay.units ? formatNumber(oneTimePay.units) : undefined,
          title,
          immutable: oneTimePay.immutable,
          approved: oneTimePay.approved,
          original: oneTimePay,
        }
      })
  }

  const approve = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, oneTimePay.id])
      props.approveOneTimePays([oneTimePay.id])
    }
  }
  const unapprove = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== oneTimePay.id))
      props.unapproveOneTimePays([oneTimePay.id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const oneTimePays = getReimbursements()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving(oneTimePays.map((otp) => otp.id))
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  const remove = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, oneTimePay.id])
        props.deleteOneTimePay(oneTimePay.id)
      }
    }
  }

  const columns = [
    {
      title: t('reimbursements.table.header.approved'),
      key: 'x1',
      className: 'one-time-pay-table-approved',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.approved) {
          return (
            <span>
              {t('reimbursements.table.approved.true')}
              {!oneTimePay.immutable && props.canApproveObjects && (
                <Tooltip title={t('reimbursements.table.approved.remove')}>
                  <DumbLink onClick={unapprove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="cross" color="grey" />
                  </DumbLink>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(oneTimePay.id) !== -1) {
          return t('reimbursements.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('reimbursements.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(oneTimePay.original)} type="standard">
            {t('reimbursements.table.approved.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('reimbursements.table.header.date'), dataIndex: 'date', key: 'date' },
    { title: t('reimbursements.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: t('reimbursements.table.header.amount'),
      key: 'xAmount',
      render: (oneTimePay: OneTimePayRow) => {
        if (!oneTimePay.rate) {
          return oneTimePay.amount
        }
        return (
          <>
            {oneTimePay.amount}
            <br />
            <small>
              ({t('reimbursements.table.amount_format', { units: oneTimePay.units, rate: oneTimePay.rate })})
            </small>
          </>
        )
      },
    },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (oneTimePay: OneTimePayRow) => {
        if (deleting.indexOf(oneTimePay.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip
              title={
                oneTimePay.immutable ? t('reimbursements.table.actions.view') : t('reimbursements.table.actions.edit')
              }
            >
              <span
                onClick={() => setEditVisibility(oneTimePay.id, !oneTimePay.immutable)}
                style={{ cursor: 'pointer' }}
              >
                <Icon type={oneTimePay.immutable ? 'search' : 'edit'} color="grey" />
              </span>
            </Tooltip>
            {!oneTimePay.immutable && (
              <Tooltip title={t('reimbursements.table.actions.delete')}>
                <DumbLink onClick={remove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </DumbLink>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done':
        if (e.file.response.data) {
          props
            .addReimbursementVoucher({
              companyID: props.company.id,
              employeeID: props.employee.id,
              fileID: e.file.response.data.id,
            })
            .then((res) => {
              if (!res) {
                return
              }
              setVoucherID(res.id)
            })
        }
        break
      default:
        break
    }
  }

  const hasUnapprovedReimbursements = () => getReimbursements().some((otp) => !otp.approved)

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.salaryCycle) {
    return null
  }

  return (
    <Card className="employees-single-form">
      {uploading && <LoadingOverlay />}
      <TitleMenu>
        {!showHistory && props.canEditObjects && props.company.enableReimbursementVouchers && !!props.companyUser && (
          <div style={{ float: 'left', marginRight: '20px' }}>
            <UploadDragger
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ Authorization: getAccessToken() }}
              accept={'*'}
              showUploadList={false}
              onChange={handleFileUpload}
            >
              <Button type="secondary" className="gtm-reimbursement-upload">
                <Icon type="upload" />
                {t('reimbursements.header.new_reimbursement_voucher')}
              </Button>
            </UploadDragger>
          </div>
        )}
        {!showHistory && props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20 }}>
            <Icon type="user" color="grey" />
            {t('reimbursements.header.new_reimbursement')}
          </Button>
        )}
        {showHistory ? (
          <Link
            to={
              '/' +
              (props.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) +
              '/' +
              props.employee.id +
              '/reimbursement'
            }
          >
            <Button className="gtm-hide-reimbursement-history" style={{ paddingRight: 14 }}>
              {t('reimbursements.header.hide_history')}
            </Button>
          </Link>
        ) : (
          <Link
            to={
              '/' +
              (props.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) +
              '/' +
              props.employee.id +
              '/reimbursement/history'
            }
          >
            <Button className="gtm-show-reimbursement-history" style={{ paddingRight: 14 }}>
              {t('reimbursements.header.show_history')}
            </Button>
          </Link>
        )}

        {hasUnapprovedReimbursements() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('reimbursements.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>{showHistory ? t('reimbursements.title.history') : t('reimbursements.title.standard')}</Title>
      {props.company.enableReimbursementVouchers && (
        <p>
          {tx('reimbursements.header.reimbursement_vouchers_note', {
            link: (
              <Link to={'/' + paths.REIMBURSEMENT_VOUCHERS}>
                {t('reimbursements.header.reimbursement_vouchers_note.link')}
              </Link>
            ),
          })}
        </p>
      )}
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getReimbursements()} size="small" pagination={false} />
      {props.oneTimePays.saving && <LoadingOverlay />}

      <Modal
        key={`otp-${modalKey}`}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <ReimbursementEdit
          visible={editing !== false}
          editing={mutable}
          canApproveObjects={props.canApproveObjects}
          employee={props.employee}
          company={props.company}
          oneTimePayID={typeof editing === 'boolean' ? undefined : editing}
          oneTimePays={props.oneTimePays}
          salaryCycle={props.salaryCycle}
          costCenters={props.costCenters}
          costCenterAccounting={props.costCenterAccounting}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>

      <Modal
        key={`voucher-${modalKey}`}
        visible={voucherEditing !== false}
        onOk={() => setVoucherVisibility(false)}
        onCancel={() => setVoucherVisibility(false)}
        width={980}
        footer={null}
      >
        <ReimbursementVoucherEdit
          visible={voucherEditing !== false}
          reimbursementVoucherID={typeof voucherEditing === 'boolean' ? undefined : voucherEditing}
          company={props.company}
          companyUsers={props.companyUsers}
          employees={props.employees}
          expenseCategories={props.expenseCategories}
          reimbursementVouchers={props.reimbursementVouchers}
          costCenterAccounting={props.costCenterAccounting || 'Off'}
          costCenters={props.costCenters}
          updateReimbursementVoucher={props.updateReimbursementVoucher}
          updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
          approveReimbursementVouchers={props.approveReimbursementVouchers}
          unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
          draftReimbursementVouchers={props.draftReimbursementVouchers}
          closeModal={() => setVoucherVisibility(false)}
        />
      </Modal>
    </Card>
  )
}
