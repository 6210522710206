import { List } from 'immutable'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import { PaymentMethod, TransferState, TransferType } from '../../model/transfer'
import { DateFormat } from '../../model/types'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { TransferReducer } from '../../reducers/transfers'
import { UserReducer } from '../../reducers/user'
import { formatDate, getDate, isTimeAfter } from '../../utils/date-utils'
import { formatPaymentMethod, formatTransferType } from '../../utils/format-utils'
import { formatLoadingText } from '../../utils/loading-utils'
import { getMainMenuCompanyMenuTransfer, MenuContext } from '../../utils/menu-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import EarlyPaymentsModal from './EarlyPaymentsModal'
import TransferDateModal from './TransferDateModal'
import TransferReportModal from './TransferReportModal'

type Props = {
  user: UserReducer
  asynchronousTasks: AsynchronousTaskReducer
  companies: CompanyReducer
  company: Company
  employees: List<Employee>
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  transfers: TransferReducer

  addAlert: addAlertSignature
  getCompanyPaymentIntegrations: () => void
  getTransfers: (payRollID?: string, companyID?: string) => void
  automateTransfer: (transferID: string, method: PaymentMethod) => void
  tryTransferAgain: (transferID: string) => void
  updateTransferDate: (transferID: string, date: DateFormat) => void
  updateCompany: (company: Company) => Promise<Company | void>
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => void
  getAsynchronousTasks: (companyID: string) => void
  approveTransfer: (transferID: string) => void
  declineTransfer: (transferID: string) => void
}

export default function TransfersTab(props: Props): ReactElement | null {
  const [showDate, setShowDate] = useState<string | boolean>(false)
  const [showEarlyPayments, setShowEarlyPayments] = useState(false)
  const [showTransferReport, setShowTransferReport] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const { menu } = useContext(MenuContext)

  const {
    companyPaymentIntegrations,
    getCompanyPaymentIntegrations,
    company,
    transfers,
    getTransfers,
    asynchronousTasks,
    getAsynchronousTasks,
  } = props

  useEffect(() => {
    if (!companyPaymentIntegrations.loading && !companyPaymentIntegrations.loaded) {
      getCompanyPaymentIntegrations()
    }
    const companyID = company.id
    if (
      transfers.companyID !== companyID ||
      transfers.fromDate ||
      transfers.payRollID ||
      (!transfers.loading && !transfers.loaded)
    ) {
      getTransfers(undefined, companyID)
    }

    if (!asynchronousTasks.loading && !asynchronousTasks.loaded) {
      getAsynchronousTasks(companyID)
    }
  })

  const setDateVisibility = (transferID: string | boolean) => {
    setShowDate(transferID)
    setModalKey((prev) => prev + 1)
  }
  const setEarlyPaymentsVisibility = (visible: boolean) => {
    setShowEarlyPayments(visible)
    setModalKey((prev) => prev + 1)
  }
  const setTransferReportVisibility = (visible: boolean) => {
    setShowTransferReport(visible)
    setModalKey((prev) => prev + 1)
  }

  const previousTransfers = usePrevious(transfers)

  useEffect(() => {
    // Check for save callback
    if (previousTransfers && previousTransfers.saving && !transfers.saving) {
      // Check for no error occurred
      if (!transfers.error) {
        // Check for open date modal
        if (showDate) {
          setDateVisibility(false)
        }
      }
    }
  })

  const hasIntegration = () => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => v.paymentIntegrationType !== 'None' && v.status !== 'Expired'
    )
  }
  const hasNETSIntegration = () => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired'
    )
  }

  const completeTransfer = (transferIDs: string[]) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      transferIDs.forEach((transferID: string) => {
        props.approveTransfer(transferID)
      })
    }
  }

  const markTransferPending = (transferIDs: string[]) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      transferIDs.forEach((transferID: string) => {
        props.declineTransfer(transferID)
      })
    }
  }

  type TransferRow = {
    key: string
    id: string
    type: string
    payTransfer: boolean
    paymentDate: string
    paymentMethod: PaymentMethod
    amount: number
    approved: boolean
    completed: boolean
    state: TransferState
    overdue: boolean
    canUseNETS: boolean
    canUseStripe: boolean
    canUseManual: boolean
    canChangeDate: boolean
    isInvoice: boolean
    ids: string[]
  }

  const columns = [
    { title: t('transfers_tab.table.header.type'), dataIndex: 'type', key: 'type' },
    {
      title: t('transfers_tab.table.header.payment_date'),
      dataIndex: '',
      key: 'xPaymentDate',
      render: (transfer: TransferRow) => {
        return (
          <span>
            {transfer.paymentDate}
            {transfer.canChangeDate && (
              <span>
                {' '}
                (
                <DumbLink
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    setDateVisibility(transfer.id)
                  }}
                  type="standard"
                >
                  {t('transfers_tab.table.action.edit_date')}
                </DumbLink>
                )
              </span>
            )}
          </span>
        )
      },
    },
    {
      title: t('transfers_tab.table.header.amount'),
      dataIndex: '',
      key: 'xAmount',
      render: (transfer: TransferRow) => formatCurrency(transfer.amount, 2),
    },
    {
      title: t('transfers_tab.table.header.status'),
      dataIndex: '',
      key: 'xStatus',
      render: (transfer: TransferRow): ReactElement | string | null => {
        let status = transfer.approved
          ? transfer.completed
            ? t('transfers_tab.table.status.completed')
            : t('transfers_tab.table.status.approved')
          : t('transfers_tab.table.status.pending')
        if (transfer.state === 'Failed') {
          status = t('transfers_tab.table.status.failed')
        }
        const method = formatPaymentMethod(transfer.paymentMethod)
        status += ' (' + method[0].toLowerCase() + method.substr(1) + ')'
        return status
      },
    },
    {
      title: t('transfers_tab.table.header.completed'),
      dataIndex: '',
      key: 'xState',
      render: (transfer: TransferRow) => {
        if (transfer.paymentMethod !== 'Manual' || !transfer.approved) {
          if (transfer.state === 'Failed') {
            return t('transfers_tab.table.completed.failed')
          } else if (transfer.state === 'Pending') {
            return t('transfers_tab.table.completed.false')
          } else {
            return t('transfers_tab.table.completed.true')
          }
        }
        if (transfer.state === 'Pending') {
          return (
            <DumbLink onClick={completeTransfer(transfer.ids)} type="standard">
              {t('transfers_tab.table.action.complete')}
            </DumbLink>
          )
        }
        if (transfer.state === 'Completed') {
          return (
            <DumbLink onClick={markTransferPending(transfer.ids)} type="standard">
              {t('transfers_tab.table.action.remove')}
            </DumbLink>
          )
        }
        return null
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      render: (transfer: TransferRow) => {
        if (!hasIntegration()) {
          return null
        }
        const canUseNETS = transfer.canUseNETS
        const canUseStripe = transfer.canUseStripe
        const canUseManual = transfer.canUseManual
        const canTryAgain = transfer.paymentMethod === 'Stripe' && transfer.state === 'Failed'
        return (
          <span>
            {(canUseNETS || canUseStripe) &&
              tx(
                [
                  'transfers_tab',
                  'table',
                  'action',
                  'switch_wrapper',
                  canUseNETS ? (canUseStripe ? 'both' : 'nets') : 'stripe',
                ],
                {
                  nets_link: (
                    <DumbLink
                      onClick={(e) => {
                        e.preventDefault()
                        props.automateTransfer(transfer.id, 'NETS')
                      }}
                      type="standard"
                    >
                      {t('transfers_tab.table.action.switch_to_nets')}
                    </DumbLink>
                  ),
                  stripe_link: (
                    <DumbLink
                      onClick={(e) => {
                        e.preventDefault()
                        props.automateTransfer(transfer.id, 'Stripe')
                      }}
                      type="standard"
                    >
                      {!canUseNETS
                        ? t('transfers_tab.table.action.switch_to_stripe.full')
                        : t('transfers_tab.table.action.switch_to_stripe.simple')}
                    </DumbLink>
                  ),
                }
              )}
            {canUseManual && (
              <DumbLink
                onClick={(e) => {
                  e.preventDefault()
                  props.automateTransfer(transfer.id, 'Manual')
                }}
                type="standard"
              >
                {t('transfers_tab.table.action.switch_to_manual')}
              </DumbLink>
            )}
            {(canUseNETS || canUseStripe || canUseManual) && canTryAgain && <br />}
            {canTryAgain && (
              <DumbLink
                onClick={(e) => {
                  e.preventDefault()
                  props.tryTransferAgain(transfer.id)
                }}
                type="standard"
              >
                {t('transfers_tab.table.action.try_again')}
              </DumbLink>
            )}
          </span>
        )
      },
    },
  ]

  const getTransferRows = (): TransferRow[] => {
    const hasNETS = props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired'
    )
    const hasStripe = props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => v.paymentIntegrationType === 'Stripe' && v.status !== 'Expired'
    )
    const combinedTransfers = props.transfers.transfers
      .filter((transfer) => transfer.type !== 'DK FLF Company')
      .sort((a, b) => {
        const sort1 = b.paymentDate.localeCompare(a.paymentDate, 'da-dk')
        if (sort1 !== 0) {
          return sort1
        }
        return a.type.localeCompare(b.type, 'da-dk')
      })
      .reduce((combinedTransfers: Record<string, TransferRow>, transfer) => {
        let canChangeDate = false
        if (transfer.paymentMethod !== 'Manual' && transfer.approved && !transfer.completed) {
          if ((['DK ASkat', 'DK AM-bidrag', 'DK Feriepenge'] as TransferType[]).indexOf(transfer.type) !== -1) {
            canChangeDate = true
          }
        }
        const combinedTransfer: TransferRow = {
          key: transfer.id,
          id: transfer.id,
          type: formatTransferType(transfer.type, transfer.transferDestinationType),
          payTransfer: transfer.type === 'Salary' || transfer.type === 'FreelanceFee',
          paymentDate: formatDate(transfer.paymentDate),
          paymentMethod: transfer.paymentMethod,
          amount: 0,
          ids: [],
          approved: transfer.approved,
          completed: transfer.completed,
          state: transfer.state,
          overdue: transfer.deadlineChangeAutomation
            ? isTimeAfter(getDate(transfer.deadlineChangeAutomation), getDate())
            : true,
          canUseNETS: false,
          canUseStripe: false,
          canUseManual: false,
          canChangeDate,
          isInvoice: transfer.type === 'Invoice',
        }

        combinedTransfer.canUseNETS =
          transfer.type !== 'DK Feriepenge Custom' &&
          !combinedTransfer.payTransfer &&
          !combinedTransfer.completed &&
          combinedTransfer.approved &&
          combinedTransfer.paymentMethod !== 'NETS' &&
          hasNETS
        combinedTransfer.canUseStripe =
          combinedTransfer.approved &&
          combinedTransfer.paymentMethod === 'Manual' &&
          combinedTransfer.isInvoice &&
          hasStripe
        combinedTransfer.canUseManual =
          !combinedTransfer.completed &&
          combinedTransfer.approved &&
          combinedTransfer.paymentMethod !== 'Manual' &&
          !combinedTransfer.isInvoice

        let type: string = transfer.type
        if (type === 'Pension') {
          type = `${type}-${transfer.transferDestinationType}`
        }
        let key = transfer.id
        if (combinedTransfer.overdue) {
          key = `${type}-${transfer.paymentDate}-${transfer.paymentMethod}-${transfer.completed}`
        }
        if (!combinedTransfers[key]) {
          combinedTransfers[key] = combinedTransfer
        }
        combinedTransfers[key].amount += transfer.amount
        combinedTransfers[key].ids.push(transfer.id)

        return combinedTransfers
      }, {})
    const transfers: TransferRow[] = []
    for (const key in combinedTransfers) {
      transfers.push(combinedTransfers[key])
    }
    return transfers
  }

  if (!props.companyPaymentIntegrations.loaded || !props.transfers.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            {
              loading: !props.companyPaymentIntegrations.loaded,
              text: t('loading.reducer.company_payment_integrations'),
            },
            { loading: !props.transfers.loaded, text: t('loading.reducer.transfers') },
          ])}
        />
      </div>
    )
  }

  return (
    <div>
      <Card>
        <TitleMenu>
          {hasNETSIntegration() && (
            <Button className="gtm-change-set-early-payments" onClick={() => setEarlyPaymentsVisibility(true)}>
              {t('transfers_tab.header.early_payments')}
            </Button>
          )}
          {!props.user.user?.uiSettings?.sideMenu &&
            getMainMenuCompanyMenuTransfer(menu)
              .reverse()
              .map((item) => {
                if (item.type !== 'item-label') {
                  return null
                }
                return (
                  <Link to={item.link}>
                    <Button>{t(item.labelID)}</Button>
                  </Link>
                )
              })}
          <Button onClick={() => setTransferReportVisibility(true)}>
            <Icon type="download" color="grey" />
            {t('transfers_tab.header.transfer_report')}
          </Button>
        </TitleMenu>
        <Title>{t('transfers_tab.title')}</Title>

        <Table columns={columns} dataSource={getTransferRows()} size="small" pagination={false} />

        {props.transfers.saving && <LoadingOverlay text={t('transfers_tab.saving')} />}
      </Card>

      <Modal
        key={`date-${modalKey}`}
        visible={!!showDate}
        onOk={() => setDateVisibility(false)}
        onCancel={() => setDateVisibility(false)}
        width={376}
        footer={null}
      >
        {typeof showDate === 'string' && showDate && (
          <TransferDateModal
            visible={!!showDate}
            transferID={showDate}
            transfers={props.transfers}
            updateTransferDate={props.updateTransferDate}
          />
        )}
      </Modal>

      <Modal
        key={`early-${modalKey}`}
        visible={showEarlyPayments}
        onOk={() => setEarlyPaymentsVisibility(false)}
        onCancel={() => setEarlyPaymentsVisibility(false)}
        width={376}
        footer={null}
      >
        <EarlyPaymentsModal
          visible={showEarlyPayments}
          companies={props.companies}
          company={props.company}
          updateCompany={props.updateCompany}
          enableCompanySettings={props.enableCompanySettings}
          disableCompanySettings={props.disableCompanySettings}
        />
      </Modal>

      <Modal
        key={`report-${modalKey}`}
        visible={showTransferReport}
        onOk={() => setTransferReportVisibility(false)}
        onCancel={() => setTransferReportVisibility(false)}
        width={582}
        footer={null}
      >
        <TransferReportModal
          visible={showTransferReport}
          asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
          company={props.company}
          addAlert={props.addAlert}
        />
      </Modal>
    </div>
  )
}
