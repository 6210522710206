import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import TimeRegistration, { TimeRegistrationClass } from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { isSameOrAfter } from '../utils/date-utils'
import { ReducerAction } from '../utils/reducer-utils'

interface HashMap {
  [key: string]: boolean
}

export interface TimeRegistrationReducer {
  companyID: string | undefined
  employeeID: string | undefined
  payRollID: string | undefined
  fromDate: DateFormat | undefined
  toDate: DateFormat | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  timeRegistrations: List<TimeRegistration>
  hashMap: HashMap
  error: Error | null
}

const Params = Record<TimeRegistrationReducer>({
  companyID: undefined,
  employeeID: undefined,
  payRollID: undefined,
  fromDate: undefined,
  toDate: undefined,
  loading: false,
  loaded: false,
  saving: false,
  timeRegistrations: List<TimeRegistration>(),
  hashMap: {},
  error: null,
})

export interface TimeRegistrationAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  payRollID?: string
  fromDate?: DateFormat
  toDate?: DateFormat
  registrations?: TimeRegistration[]
  registration?: TimeRegistration
  registrationIDs?: string[]
  registrationID?: string
  approved?: boolean
  registrationType?: TimeRegistrationClass
  deleteFromDate?: Date
}

const comparator = (a: TimeRegistration, b: TimeRegistration) => {
  return b.date.localeCompare(a.date, 'da-dk')
}

export default (
  state: Record<TimeRegistrationReducer> = Params(),
  action: TimeRegistrationAction = { type: '' }
): Record<TimeRegistrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  if (action.type !== ActionTypes.TIME_REGISTRATION_LOADING) {
    if (action.payRollID && state.get('payRollID')) {
      // only process actions for the active payroll
      if (action.payRollID !== state.get('payRollID')) {
        return state
      }
    } else if (action.employeeID && state.get('employeeID')) {
      // only process actions for the active employee
      if (action.employeeID !== state.get('employeeID')) {
        return state
      }
    }
  }

  let idx = -1
  const registrations = state.get('timeRegistrations').toArray()
  const hashMap = state.get('hashMap')
  switch (action.type) {
    case ActionTypes.TIME_REGISTRATION_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID,
        employeeID: action.employeeID,
        payRollID: action.payRollID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        hashMap: {},
      })
    case ActionTypes.TIME_REGISTRATION_LOADED:
    case ActionTypes.TIME_REGISTRATION_LOADED_PARTIAL:
      if (!action.registrations) {
        return state
      }
      action.registrations.forEach((registration) => {
        if (hashMap[registration.id]) {
          return
        }
        registrations.push(registration)
        hashMap[registration.id] = true
      })
      return Params({
        loaded: true,
        loading: action.type === ActionTypes.TIME_REGISTRATION_LOADED_PARTIAL,
        companyID: action.companyID,
        employeeID: action.employeeID,
        payRollID: action.payRollID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        timeRegistrations: List(registrations).sort(comparator),
        hashMap,
      })
    case ActionTypes.TIME_REGISTRATION_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('employeeID', action.employeeID || state.get('employeeID'))
        .set('payRollID', action.payRollID || state.get('payRollID'))
        .set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_CREATED:
      if (!action.registration) {
        return state
      }
      idx = state
        .get('timeRegistrations')
        .findIndex((item) => !!action.registration && item.id === action.registration.id)
      hashMap[action.registration.id] = true
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('timeRegistrations', state.get('timeRegistrations').set(idx, action.registration).sort(comparator))
          .set('hashMap', hashMap)
      }
      return state
        .set('saving', false)
        .set('timeRegistrations', state.get('timeRegistrations').push(action.registration).sort(comparator))
        .set('hashMap', hashMap)
    case ActionTypes.TIME_REGISTRATION_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_APPROVING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_APPROVED:
      if (typeof action.approved !== 'boolean') {
        return state
      }
      return state.set('saving', false).set(
        'timeRegistrations',
        state
          .get('timeRegistrations')
          .map((registration) => {
            if (!action.registrationIDs) {
              return registration
            }
            if (action.registrationIDs.indexOf(registration.id) !== -1) {
              registration.approved = !!action.approved
            }
            return registration
          })
          .sort(comparator)
      )
    case ActionTypes.TIME_REGISTRATION_APPROVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_UPDATED:
      if (!action.registration) {
        return state
      }
      idx = state.get('timeRegistrations').findIndex((item) => item.id === action.registrationID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('timeRegistrations', state.get('timeRegistrations').set(idx, action.registration).sort(comparator))
    case ActionTypes.TIME_REGISTRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_DELETED:
      if (!action.registrationID) {
        return state
      }
      idx = state.get('timeRegistrations').findIndex((item) => item.id === action.registrationID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      hashMap[action.registrationID] = false
      return state
        .set('saving', false)
        .set('timeRegistrations', state.get('timeRegistrations').delete(idx))
        .set('hashMap', hashMap)
    case ActionTypes.TIME_REGISTRATION_BULK_DELETED:
      if (!action.employeeID || action.employeeID !== state.get('employeeID')) {
        return state
      }
      if (action.companyID) {
        if (action.companyID !== state.get('companyID')) {
          return state
        }
        return state
          .set('saving', false)
          .set(
            'timeRegistrations',
            state.get('timeRegistrations').filter((timeReg) => {
              if (
                action.registrationType === 'Work Hours' &&
                action.deleteFromDate &&
                !isSameOrAfter(timeReg.date, action.deleteFromDate)
              ) {
                return true
              } else if (timeReg.state !== 'Pending' || timeReg.class !== action.registrationType) {
                return true
              }
              hashMap[timeReg.id] = false
              return false
            })
          )
          .set('hashMap', hashMap)
      }
      return state
        .set('saving', false)
        .set(
          'timeRegistrations',
          state.get('timeRegistrations').filter((timeReg) => {
            if (
              timeReg.state !== 'Pending' ||
              timeReg.employeeID !== action.employeeID ||
              timeReg.class !== action.registrationType
            ) {
              return true
            }
            hashMap[timeReg.id] = false
            return false
          })
        )
        .set('hashMap', hashMap)
    case ActionTypes.TIME_REGISTRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
