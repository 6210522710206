import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import Employee from '../../model/employee'
import SalaryType from '../../model/salaryType'
import { AgreementReducer } from '../../reducers/agreements'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { SupportAccessReducer } from '../../reducers/supportAccess'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatDateTime, getDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import FeatureLock from '../widgets/FeatureLock'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ImportExcelEmployeesModal from './ImportExcelEmployeesModal'
import RequestDeletionModal from './RequestDeletionModal'
import RequestImportModal from './RequestImportModal'
import TermsModal from './TermsModal'

type Props = {
  agreements: AgreementReducer
  company: Company
  asynchronousTasks: AsynchronousTaskReducer
  salaryTypes: List<SalaryType>
  employees: List<Employee>
  supportAccess: SupportAccessReducer

  addAlert: addAlertSignature
  grantSupportAccess: () => void
  revokeSupportAccess: () => void
  loadSupportAccess: () => void
  startExcelEmployeesData: (id: string) => Promise<AsynchronousTask | void>
  storeStagedImportData: (id: string) => Promise<AsynchronousTask | void>
  getAsynchronousTasks: (id: string) => void
}

export default function SupportTab(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState<number>(1)
  const [showImport, setShowImport] = useState<boolean>(false)
  const [showRequestDeletion, setShowRequestDeletion] = useState<boolean>(false)
  const [showRequestImport, setShowRequestImport] = useState<boolean>(false)
  const [showTerms, setShowTerms] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { supportAccess, loadSupportAccess } = props

  useEffect(() => {
    if (!supportAccess.loading && !supportAccess.loaded) {
      loadSupportAccess()
    }
  }, [supportAccess, loadSupportAccess])

  const asynchronousTaskLoading = props.asynchronousTasks.loading
  const asynchronousTaskLoaded = props.asynchronousTasks.loaded
  const getAsynchronousTasks = props.getAsynchronousTasks
  const company = props.company

  useEffect(() => {
    if (!asynchronousTaskLoading && !asynchronousTaskLoaded) {
      getAsynchronousTasks(company.id)
    }
  }, [asynchronousTaskLoaded, asynchronousTaskLoading, getAsynchronousTasks, company])

  const supportAccessSaving = props.supportAccess.saving
  const supportAccessError = props.supportAccess.error
  const wasSupportAccessSaving = usePrevious(supportAccessSaving)

  const addAlert = props.addAlert

  useEffect(() => {
    if (wasSupportAccessSaving && !supportAccessSaving) {
      if (!supportAccessError) {
        addAlert('success', t('support_tab.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  }, [wasSupportAccessSaving, supportAccessSaving, supportAccessError, addAlert, company])

  useEffect(() => {
    regularComponentDidUpdate(supportAccessError, error, setError)
  }, [supportAccessError, error])

  const setImportVisibility = (showImport: boolean): void => {
    // Increment modalKey to create a new component
    setModalKey(modalKey + 1)
    setShowImport(showImport)
  }

  const setRequestDeletionVisibility = (show: boolean): void => {
    // Increment modalKey to create a new component
    setModalKey(modalKey + 1)
    setShowRequestDeletion(show)
  }

  const setRequestImportVisibility = (show: boolean): void => {
    // Increment modalKey to create a new component
    setModalKey(modalKey + 1)
    setShowRequestImport(show)
  }

  const setTermsVisibility = (show: boolean) => {
    setShowTerms(show)
  }

  if (!props.supportAccess.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const hasSupportAccess = props.supportAccess.expiresAt
  return (
    <div>
      <Card>
        <TitleMenu>
          <Link to={'/' + paths.TERMS}>
            <Button>{t('support_tab.header.terms')}</Button>
          </Link>
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setTermsVisibility(true)
            }}
          >
            <Button>{t('support_tab.header.data_provider_terms')}</Button>
          </DumbLink>
        </TitleMenu>
        <Title>{t('support_tab.title')}</Title>

        <Row>
          <Col span={24}>
            {t('support_tab.access.title')}:
            <br />
            <strong>
              {hasSupportAccess
                ? t('support_tab.access.until', { time: formatDateTime(props.supportAccess.expiresAt || getDate()) })
                : t('support_tab.access.false')}
            </strong>
          </Col>
        </Row>
        <Row>&nbsp;</Row>

        <div className="companies-single-form">
          {error && <Alert message={formatError(error)} type="error" showIcon />}
          <Row>
            <Col span={24}>
              <Button
                onClick={hasSupportAccess ? props.revokeSupportAccess : props.grantSupportAccess}
                size="large"
                type="secondary"
              >
                {hasSupportAccess ? t('support_tab.access.remove') : t('support_tab.access.provide')}
              </Button>
            </Col>
          </Row>
          {props.supportAccess.saving && <LoadingOverlay />}
          <Row>
            <Col span={24}>
              <FeatureLock featureType={'Import Export'} description={t('support_tab.request_import.button.lock')}>
                <Button
                  size="large"
                  type="secondary"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    setRequestImportVisibility(true)
                  }}
                >
                  {t('support_tab.request_import.button')}
                </Button>
              </FeatureLock>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FeatureLock featureType={'Import Export'} description={t('support_tab.employee_import.button.lock')}>
                <Button
                  type="secondary"
                  className="gtm-import-employees-excel"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    setImportVisibility(true)
                  }}
                >
                  {t('support_tab.employee_import.button')}
                </Button>
              </FeatureLock>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button
                type="danger"
                className="gtm-request-deletion"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setRequestDeletionVisibility(true)
                }}
              >
                {t('support_tab.request_deletion.button')}
              </Button>
            </Col>
          </Row>
        </div>
      </Card>

      <Modal
        key={`import-${modalKey}`}
        visible={showImport}
        onOk={() => setImportVisibility(false)}
        onCancel={() => setImportVisibility(false)}
        width={980}
        footer={null}
      >
        <ImportExcelEmployeesModal
          visible={showImport}
          asynchronousTasks={props.asynchronousTasks}
          salaryTypes={props.salaryTypes}
          employees={props.employees}
          startExcelEmployeesData={props.startExcelEmployeesData}
          storeStagedImportData={props.storeStagedImportData}
          closeModal={() => setImportVisibility(false)}
        />
      </Modal>

      <Modal
        key={`request-deletion-${modalKey}`}
        visible={showRequestDeletion}
        onOk={() => setRequestDeletionVisibility(false)}
        onCancel={() => setRequestDeletionVisibility(false)}
        width={600}
        footer={null}
      >
        <RequestDeletionModal
          company={props.company}
          addAlert={props.addAlert}
          closeModal={() => setRequestDeletionVisibility(false)}
        />
      </Modal>

      <Modal
        key={`request-import-${modalKey}`}
        visible={showRequestImport}
        onOk={() => setRequestImportVisibility(false)}
        onCancel={() => setRequestImportVisibility(false)}
        width={600}
        footer={null}
      >
        <RequestImportModal
          company={props.company}
          addAlert={props.addAlert}
          closeModal={() => setRequestImportVisibility(false)}
        />
      </Modal>

      <Modal
        visible={showTerms}
        onOk={() => setTermsVisibility(false)}
        onCancel={() => setTermsVisibility(false)}
        width={582}
        footer={null}
      >
        <TermsModal visible={showTerms} agreements={props.agreements} />
      </Modal>
    </div>
  )
}
