import { CardCvcElement, CardExpiryElement, CardNumberElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import Company from '../../../model/company'
import StripeConfiguration from '../../../model/stripeConfiguration'
import { formatCurrency } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  clientSecret: string
  company: Company
  closeModal: () => void
  addAlert: addAlertSignature
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
}

type StripeProps = Props & {
  stripe: Stripe | null
  elements: StripeElements | null
}

function StripeSetupForm(props: StripeProps): ReactElement | null {
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { stripe, elements } = props
    if (!stripe || !elements) {
      return
    }

    if (!props.clientSecret) {
      return
    }

    setSaving(true)

    const element = elements.getElement(CardNumberElement)
    if (!element) {
      return
    }

    stripe
      .confirmCardSetup(props.clientSecret, {
        payment_method: {
          card: element,
          billing_details: {
            name: 'Jenny Rosen',
          },
        },
      })
      .then((result) => {
        if (result.error) {
          setSaving(false)
          setError(result.error.message)
        } else if (result.setupIntent) {
          props.updateStripeConfiguration(result.setupIntent.id).then(() => {
            props.addAlert('success', t('stripe.alert.success'), { timeout: 5 })
            props.closeModal()
          })
        }
      })
  }

  const INPUT_STYLE = {
    style: {
      base: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        fontWeight: '500',
        color: 'var(--sally-black)',
      },
      invalid: {
        color: 'var(--sally-red)',
        iconColor: 'var(--sally-red)',
      },
    },
  }
  return (
    <div>
      {error && <Alert message={error} type="error" showIcon />}
      <form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <label htmlFor="cardNumber">{t('stripe.edit.form.card_number')}</label>
            <div className="ant-input" style={{ paddingTop: 10 }}>
              <CardNumberElement id="cardNumber" options={INPUT_STYLE} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ paddingRight: 5 }}>
            <label htmlFor="expiry">{t('stripe.edit.form.expiry')}</label>
            <div className="ant-input" style={{ paddingTop: 10 }}>
              <CardExpiryElement
                id="expiry"
                options={{ ...INPUT_STYLE, placeholder: t('stripe.form.expiry.placeholder') }}
              />
            </div>
          </Col>
          <Col span={12} style={{ paddingLeft: 5 }}>
            <label htmlFor="cvc">{t('stripe.edit.form.cvc')}</label>
            <div className="ant-input" style={{ paddingTop: 10 }}>
              <CardCvcElement id="cvc" options={INPUT_STYLE} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: '10px' }}>
            <p>
              {tx('stripe.edit.fee_explanation.line_1', { fee: <strong>{formatCurrency(2.48, 2)}</strong> })}
              <br />
              {tx('stripe.edit.fee_explanation.line_2', {
                link: (
                  <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfer-bank-account'}>
                    {t('stripe.edit.fee_explanation.line_2_link')}
                  </Link>
                ),
              })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary" block disabled={!props.stripe}>
              {t('stripe.edit.form.submit')}
            </Button>
          </Col>
        </Row>
      </form>
      {saving && <LoadingOverlay />}
    </div>
  )
}

export default function InjectedCardSetupForm(props: Props): ReactElement | null {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => <StripeSetupForm stripe={stripe} elements={elements} {...props} />}
    </ElementsConsumer>
  )
}
