import Employee, { EmployeeCreation } from '../model/employee'
import EmployeeBatchResult, { BatchAction, BatchFields, BatchOperation } from '../model/employeeBatchResult'
import ProfileImage from '../model/profileImage'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchEmployees(companyID: string, limit: number, offset: number): Promise<RequestResponse<Employee[]>> {
  return secureRequest('GET', url('/v2/employees', { companyID, includeActiveContract: true, limit, offset }))
}

export function postEmployee(companyID: string, employee: EmployeeCreation): Promise<RequestResponse<Employee>> {
  return secureRequest('POST', url('/v2/employees'), {
    body: {
      companyID: companyID,
      nationalIDType: employee.nationalIDType,
      nationalID: employee.nationalID,
      name: employee.name,
      address: employee.address,
      postalCode: employee.postalCode,
      city: employee.city,
      country: employee.country,
      gender: employee.gender,
      birthDate: employee.birthDate,
      tin: employee.tin,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      phoneNumberCountryCode: employee.phoneNumberCountryCode,
      transferDestinationType: employee.transferDestinationType,
      bankRegistrationNumber: employee.bankRegistrationNumber,
      bankAccountNumber: employee.bankAccountNumber,
      paySlipTransportEMail: employee.paySlipTransportEMail,
      paySlipTransportMitDK: employee.paySlipTransportMitDK,
      paySlipTransportEBoks: employee.paySlipTransportEBoks,
      paySlipTransportSMS: employee.paySlipTransportSMS,
      language: employee.language,
      affiliationType: employee.affiliationType,
      departmentID: employee.departmentID,
      employment: employee.employeeNumber
        ? {
            employeeNumber: employee.employeeNumber,
            startDate: employee.employmentStartDate,
            endDate: employee.employmentEndDate == null ? undefined : employee.employmentEndDate,
            preferredTaxCardType: employee.preferredTaxCardType,
            contractBookContractID: employee.contractBookContractID,
            incomeType: employee.incomeType,
          }
        : undefined,
      onboardingState: employee.onboardingState,
      sendLogin: employee.sendLogin,
    },
  })
}

export function putEmployee(employee: Employee): Promise<RequestResponse<Employee>> {
  return secureRequest('PUT', url('/v2/employees/' + employee.id), {
    body: {
      nationalIDType: employee.nationalIDType,
      nationalID: employee.nationalID,
      name: employee.name,
      address: employee.address,
      postalCode: employee.postalCode,
      city: employee.city,
      country: employee.country,
      gender: employee.gender,
      birthDate: employee.birthDate,
      tin: employee.tin,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      phoneNumberCountryCode: employee.phoneNumberCountryCode,
      transferDestinationType: employee.transferDestinationType,
      bankRegistrationNumber: employee.bankRegistrationNumber,
      bankAccountNumber: employee.bankAccountNumber,
      paySlipTransportEMail: employee.paySlipTransportEMail,
      paySlipTransportMitDK: employee.paySlipTransportMitDK,
      paySlipTransportEBoks: employee.paySlipTransportEBoks,
      paySlipTransportSMS: employee.paySlipTransportSMS,
      language: employee.language,
      affiliationType: employee.affiliationType,
      departmentID: employee.departmentID,
      onboardingState: employee.onboardingState,
      hasSwipe: employee.hasSwipe,
      nondisburseableAccountingAccountID: employee.nondisburseableAccountingAccountID,
      reimbursementVoucherAutoApprovalLimit: employee.reimbursementVoucherAutoApprovalLimit,
      vacationExcessLimit: employee.vacationExcessLimit,
      disableReimbursementVouchers: employee.disableReimbursementVouchers,
      disableWorkHours: employee.disableWorkHours,
      automaticVacationTransferLimit: employee.automaticVacationTransferLimit,
      automaticOptionalVacationTransferLimit: employee.automaticOptionalVacationTransferLimit,
      registrationsNeverApprovedFromEmployeeApp: employee.registrationsNeverApprovedFromEmployeeApp,
    },
  })
}

export function delEmployee(employeeID: string, removeOrphans: boolean): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/employees/' + employeeID + '?removeOrphans=' + removeOrphans))
}

export function postEmployeePDFPassword(
  employeeID: string,
  password: string | null
): Promise<RequestResponse<Employee>> {
  return secureRequest('POST', url('/v2/employees/' + employeeID + '/pdfPassword'), {
    body: {
      password: password,
    },
  })
}

export function postInvite(employeeID: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/employeeInvite'), {
    body: { employeeID },
  })
}
export function delEmployeeUser(employeeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/employeeUsers/' + employeeID))
}

export function putProfileImage(employeeID: string, image: ProfileImage): Promise<RequestResponse> {
  return secureRequest('PUT', url('/v2/employeeProfileImages/' + employeeID), {
    body: {
      type: image.type,
      fileID: image.fileID,
      standardImage: image.standardImage,
    },
  })
}

export function delProfileImage(employeeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/employeeProfileImages/' + employeeID))
}

interface DefaultWorkSchedules {
  days: number
}

export function fetchDefaultWorkSchedules(
  employeeID: string,
  salaryPeriodID: string
): Promise<RequestResponse<DefaultWorkSchedules>> {
  return secureRequest('GET', url('/v2/defaultWorkSchedules', { employeeID, salaryPeriodID }))
}

type EmployeeBatchResponse = {
  done: boolean
  taskID?: string
  employees: EmployeeBatchResult[]
}

export function postEmployeesBatch(
  companyID: string,
  employeeIDs: string[],
  action: BatchAction,
  operation?: BatchOperation,
  fields?: BatchFields
): Promise<RequestResponse<EmployeeBatchResponse>> {
  type Body = BatchFields & {
    employeeIDs: string[]
    action: BatchAction
    operation?: BatchOperation
  }
  const body: Body = {
    employeeIDs,
    action,
  }
  if (operation) {
    body.operation = operation
  }
  if (fields && fields.amount) {
    body.amount = fields.amount
  }
  if (fields && fields.paySlipTransportSetting) {
    body.paySlipTransportSetting = fields.paySlipTransportSetting
  }
  if (fields && fields.departmentID) {
    body.departmentID = fields.departmentID
  }
  if (fields && fields.language) {
    body.language = fields.language
  }
  if (fields && fields.preferredTaxCard) {
    body.preferredTaxCard = fields.preferredTaxCard
  }
  if (fields && fields.supplementTypeID) {
    body.supplementTypeID = fields.supplementTypeID
  }
  if (fields && fields.supplementCompensationRate) {
    body.supplementCompensationRate = fields.supplementCompensationRate
  }
  if (fields && fields.pensionEmployeePercentage !== undefined) {
    body.pensionEmployeePercentage = fields.pensionEmployeePercentage
  }
  if (fields && fields.pensionEmployeeFixedAmount !== undefined) {
    body.pensionEmployeeFixedAmount = fields.pensionEmployeeFixedAmount
  }
  if (fields && fields.pensionEmployerPercentage !== undefined) {
    body.pensionEmployerPercentage = fields.pensionEmployerPercentage
  }
  if (fields && fields.pensionEmployerFixedAmount !== undefined) {
    body.pensionEmployerFixedAmount = fields.pensionEmployerFixedAmount
  }
  if (fields && fields.pensionCompanyID) {
    body.pensionCompanyID = fields.pensionCompanyID
  }
  if (fields && fields.pensionCustomerID) {
    body.pensionCustomerID = fields.pensionCustomerID
  }
  if (fields && fields.pensionUnionAgreementNumber) {
    body.pensionUnionAgreementNumber = fields.pensionUnionAgreementNumber
  }
  if (fields && fields.employmentPositionID) {
    body.employmentPositionID = fields.employmentPositionID
  }
  if (fields && fields.position) {
    body.position = fields.position
  }
  if (fields && fields.title) {
    body.title = fields.title
  }
  if (fields && fields.description) {
    body.description = fields.description
  }
  if (fields && fields.assetCategoryID) {
    body.assetCategoryID = fields.assetCategoryID
  }
  if (fields && fields.assetsToReturn) {
    body.assetsToReturn = fields.assetsToReturn
  }
  if (fields && fields.validFrom) {
    body.validFrom = fields.validFrom
  }
  if (fields && fields.productionUnitID) {
    body.productionUnitID = fields.productionUnitID
  }
  if (fields && fields.accrual) {
    body.accrual = fields.accrual
  }
  if (fields && fields.salaryCycleID) {
    body.salaryCycleID = fields.salaryCycleID
  }
  if (fields && fields.oneTimePayType) {
    body.oneTimePayType = fields.oneTimePayType
  }
  if (fields && fields.dispositionDate) {
    body.dispositionDate = fields.dispositionDate
  }
  if (fields && fields.quantity) {
    body.quantity = fields.quantity
  }
  if (fields && fields.salaryTypeID) {
    body.salaryTypeID = fields.salaryTypeID
  }
  if (fields && fields.affiliationType) {
    body.affiliationType = fields.affiliationType
  }
  return secureRequest('POST', url('/v2/employees/batch', { companyID }), {
    body,
  })
}

export type EmployeeConversionType = 'Freelancer' | 'Standard'

export function postEmployeeConvert(
  employeeID: string,
  type: EmployeeConversionType
): Promise<RequestResponse<Employee>> {
  return secureRequest('POST', url('/v2/employees/' + employeeID + '/convert'), {
    body: {
      type,
    },
  })
}

export function postEmployeeReady(employeeID: string): Promise<RequestResponse<Employee>> {
  return secureRequest('POST', url('/v2/employees/' + employeeID + '/ready'))
}

export function deleteEmployeeReady(employeeID: string): Promise<RequestResponse<Employee>> {
  return secureRequest('DELETE', url('/v2/employees/' + employeeID + '/ready'))
}

type cprResponse = {
  birthDate?: DateFormat
  address: string
  postalCode: string
  city?: string
  name: string
  hiddenAddress: boolean
}

export function postLookupNationalID(nationalID: string): Promise<RequestResponse<cprResponse>> {
  return secureRequest('POST', url('/v2/cprLookup'), {
    body: {
      cpr: nationalID,
    },
  })
}
