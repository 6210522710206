import { addDays, isSameDay, subDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, ReactNode } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import CompanyUser from '../../../model/companyUser'
import ContractDelta from '../../../model/contractDelta'
import Employee from '../../../model/employee'
import PensionCompany from '../../../model/pensionCompany'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { displayContractDelta } from '../../../utils/contract-delta-utils'
import { formatShortDate, getDate } from '../../../utils/date-utils'
import {
  isContractDeltaActive,
  isContractDeltaExpired,
  isContractDeltaFuture,
} from '../../../utils/employee-contract-utils'
import { formatSourceType } from '../../../utils/format-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import jsBrowserHistory from '../../widgets/jsBrowserHistory'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  employeeContractDeltas: EmployeeContractDeltaReducer
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  companyUsers: List<CompanyUser>
  pensionCompanies: List<PensionCompany>
  hasFutureContractsFeature: boolean
  canEditObjects: boolean

  addAlert: addAlertSignature
  setCreateFutureContractVisibility: (visibility: boolean) => void
  setEmployeeContract: (employeeID: string, id: string) => void
  setLeaveVisibility: (visibility: boolean) => void
}

export default function ContractHistoryTab(props: Props): ReactElement | null {
  type EmptyDeltaRow = {
    empty: true
    contractID: string
    leaveFrom: Date
    leaveTo: Date
  }
  type ContractDeltaRow = ContractDelta & {
    empty: false
  }
  const getContractDeltas = () => {
    const deltas = props.employeeContractDeltas.contracts.reverse()
    let previousStartDate: Date | undefined = undefined
    let previousContractID: string | undefined = undefined
    return deltas.reduce((deltasWithHoles: (EmptyDeltaRow | ContractDeltaRow)[], delta) => {
      if (
        previousStartDate &&
        previousContractID &&
        !isSameDay(addDays(getDate(delta.contract.validTo), 1), getDate(previousStartDate))
      ) {
        deltasWithHoles.push({
          empty: true,
          contractID: previousContractID,
          leaveFrom: addDays(getDate(delta.contract.validTo), 1),
          leaveTo: subDays(previousStartDate, 1),
        })
      }
      previousStartDate = getDate(delta.contract.validFrom)
      previousContractID = delta.contractID
      deltasWithHoles.push({ ...delta, empty: false })
      return deltasWithHoles
    }, [])
  }

  if (!props.employeeContractDeltas.loaded) {
    return <LoadingOverlay />
  }
  const contractDeltas = getContractDeltas()

  return (
    <Card className="contract-history">
      {props.hasFutureContractsFeature && props.canEditObjects && (
        <TitleMenu>
          <Button
            onClick={() => {
              props.setEmployeeContract(props.employee.id, contractDeltas[0].contractID)
              props.setCreateFutureContractVisibility(true)
            }}
          >
            {t('contract_history.actions.new_future_contract')}
          </Button>
        </TitleMenu>
      )}
      <Title>{t('contract_history.title')}</Title>
      <p>&nbsp;</p>
      {contractDeltas.map((delta) => {
        if (delta.empty) {
          return (
            <Row key={delta.contractID} className={'contract-history-item leave'}>
              <Col span={7}>
                {t('contract_history.history.range_format', {
                  from: formatShortDate(delta.leaveFrom),
                  to: formatShortDate(delta.leaveTo),
                })}
              </Col>
              <Col span={12}>
                <span className={'contract-leave'}>{t('contract_history.history.leave')}</span>
              </Col>
              {props.canEditObjects && (
                <Col span={5}>
                  <Button
                    type={'danger'}
                    className={'ant-btn-delete'}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault()
                      props.setEmployeeContract(props.employee.id, delta.contractID)
                      props.setLeaveVisibility(true)
                    }}
                  >
                    {t('contract_history.actions.remove_leave')}
                  </Button>
                </Col>
              )}
            </Row>
          )
        }
        const changes = displayContractDelta(
          delta,
          props.salaryCycles.toArray(),
          props.salaryTypes.toArray(),
          props.pensionCompanies.toArray()
        )
        const expired = isContractDeltaExpired(delta)
        const active = isContractDeltaActive(delta)
        const future = isContractDeltaFuture(delta)
        const changeLine: ReactNode[] = []
        const firstLine = active
          ? t('contract_history.history.state.active')
          : future
          ? t('contract_history.history.state.future')
          : expired
          ? t('contract_history.history.state.expired')
          : undefined
        if (firstLine) {
          changeLine.push(firstLine)
        }
        if (delta.initial) {
          changeLine.push(t('contract_history.history.state.initial'))
        }
        if (delta.contract.lastEditSource) {
          changeLine.push(
            <>
              <br />
              {t('contract_history.history.state.last_edit', {
                name:
                  props.companyUsers.find((user) => user.userID === delta.contract.lastEditUserID)?.name ??
                  formatSourceType(delta.contract.lastEditSource),
              })}
            </>
          )
        }
        return (
          <Row
            key={delta.contractID}
            className={
              'contract-history-item ' +
              (expired ? 'expired' : '') +
              (active ? 'active' : '') +
              (future ? 'future' : '')
            }
          >
            <Col span={8}>
              {t('contract_history.history.range_format', {
                from: formatShortDate(delta.validFrom),
                to: delta.validTo ? formatShortDate(delta.validTo) : '',
              })}
              <span className={'contract-change-line'}>
                {changeLine.map((e, i) => (i === 0 ? e : <React.Fragment key={`line-${i}`}>, {e}</React.Fragment>))}.
              </span>
            </Col>
            <Col span={11}>
              {changes.map((change, i) => {
                switch (change.type) {
                  case 'change':
                    return (
                      <span
                        key={delta.contractID + '-' + i}
                        className={
                          'contract-change' +
                          (change.increase ? ' increase' : '') +
                          (change.decrease ? ' decrease' : '')
                        }
                      >
                        {!change.value ? (change.increase ? '+' : change.decrease ? '-' : '') : ''}
                        {change.message}
                        {change.value ? ': ' + (change.increase ? '+' : change.decrease ? '-' : '') + change.value : ''}
                      </span>
                    )
                  case 'remove':
                    return (
                      <span key={delta.contractID + '-' + i} className={'contract-change remove'}>
                        -{change.message}
                      </span>
                    )
                  case 'initial':
                    return (
                      <span key={delta.contractID + '-' + i} className={'contract-change initial'}>
                        {change.message}
                        {change.value ? ': ' + change.value : ''}
                      </span>
                    )
                  default:
                    break
                }
                return ''
              })}
              {!delta.initial && changes.length === 0 && (
                <span className={'contract-no-change'}>{t('contract_history.history.change.no_change')}</span>
              )}
            </Col>
            <Col span={5}>
              {props.hasFutureContractsFeature && (
                <Button
                  className={!props.canEditObjects ? 'ant-btn-view' : 'ant-btn-edit'}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    props.setEmployeeContract(props.employee.id, delta.contractID)
                    jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + props.employee.id + '/employment')
                  }}
                />
              )}
            </Col>
          </Row>
        )
      })}
    </Card>
  )
}
