import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { setByPath } from '../../utils/object-utils'
import { t, tx } from '../../utils/translation-utils'
import { trimSpaces } from '../../utils/validation-utils'
import Form from '../antd/form'
import Modal from '../antd/modal'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import Input from '../elements/input'
import PhoneNumberForm from '../form-elements/PhoneNumberForm'
import DumbLink from '../widgets/DumbLink'
import UserTerms from '../widgets/UserTerms'

type Props = {
  name?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  email?: string
  password?: string
  terms: boolean
}

type Fields = {
  name?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
  email?: string
  password?: string
  terms: boolean
}

export type RegisterResult = {
  name: string
  phoneNumber: string
  phoneNumberCountryCode: string
  email: string
  password: string
  terms: boolean
}

function RegisterForm(props: Props & FormComponentProps<Fields, RegisterResult>): ReactElement | null {
  const [showTerms, setShowTerms] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { decorateField, getFieldValue, getFieldError } = props

  return (
    <div>
      {decorateField('name', {
        placeholder: t('register.form.name'),
        validate: (val) => (!val ? t('register.form.name.required') : null),
      })(<Input tabIndex={1} />)}
      <PhoneNumberForm
        decorateField={decorateField}
        getFieldValue={getFieldValue}
        getFieldError={getFieldError}
        tabIndex={2}
        requirePhoneNumber
      />
      {decorateField('email', {
        placeholder: t('register.form.email'),
        validate: (val) => {
          if (!val) {
            return t('register.form.email.required')
          }
          if (!validateEmail(val)) {
            return t('register.form.email.invalid')
          }
          return null
        },
      })(<Input tabIndex={3} />)}
      {decorateField('password', {
        placeholder: t('register.form.password'),
        validate: (val) => {
          if (!val) {
            return t('register.form.password.required')
          }
          if (val.length < 8) {
            return t('register.form.password.at_least_8_characters')
          }
          return null
        },
      })(<Input type="password" tabIndex={4} />)}
      <Form.Item validateStatus={props.getFieldError('terms') ? 'error' : 'success'}>
        {decorateField('terms', {
          validate: (val) => {
            if (!val) {
              return t('register.form.terms.required')
            }
            return null
          },
          skipWrapper: true,
          skipLabel: true,
        })(
          <Checkbox tabIndex={5} id="checkbox-accept-terms">
            {tx('register.form.terms', {
              terms: (
                <DumbLink
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    setShowTerms(true)
                  }}
                >
                  {t('register.form.terms.terms')}
                </DumbLink>
              ),
            })}
          </Checkbox>
        )}
        <Modal
          visible={showTerms}
          onOk={() => setShowTerms(false)}
          onCancel={() => setShowTerms(false)}
          width={579}
          footer={null}
        >
          <UserTerms setVisibility={setShowTerms} />
        </Modal>

        <Button
          htmlType="submit"
          type="secondary"
          noArrow
          id="btn-register-user"
          tabIndex={6}
          onClick={() => {
            setTimeout(() => {
              // set it immediately
              setSubmitting(true)
              setTimeout(() => {
                // if the form has a validation error, release it after 0.5 second
                if (props.hasError()) {
                  setSubmitting(false)
                }
              }, 500)
            }, 10)
            return true
          }}
          disabled={submitting}
        >
          {t('register.form.submit')}
        </Button>
        <p style={{ marginTop: '15px', marginBottom: '0' }}>
          {tx('register.form.already_user', {
            link: <Link to={'/' + paths.LOGIN + document.location.search}>{t('register.form.already_user.link')}</Link>,
          })}
        </p>
      </Form.Item>
    </div>
  )
}

export default withValidations<Props, Fields, RegisterResult>({
  mapPropsToFields: (props) => ({
    name: props.name,
    phoneNumber: props.phoneNumber,
    phoneNumberCountryCode: props.phoneNumberCountryCode,
    email: props.email,
    password: props.password,
    terms: props.terms,
  }),
  onChange: (key, val) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'phoneNumber':
        setByPath(values, key, trimSpaces(val as string))
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    name: values.name!,
    phoneNumber: values.phoneNumber!,
    phoneNumberCountryCode: values.phoneNumberCountryCode!,
    email: values.email!,
    password: values.password!,
  }),
})(RegisterForm)
