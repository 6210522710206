import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import LeaveType, { LeaveSubTypeName } from '../../../model/leaveType'
import TimeRegistration from '../../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import LeaveDurations from '../../../types/leave-duration'
import { formatDate } from '../../../utils/date-utils'
import { formatLeaveDuration, formatLeaveSubType, formatLeaveTypeName } from '../../../utils/format-utils'
import { t } from '../../../utils/translation-utils'
import Table from '../../antd/table'
import Icon from '../../elements/Icon'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  timeRegistrations: TimeRegistrationReducer
  leaveTypes: List<LeaveType>
  canEditObjects: boolean
  canApproveObjects: boolean

  approving: string[]
  unapprove: (id: string) => void
  approve: (id: string) => void
  remove: (id: string) => void
  deleting: string[]
  timeRegistrationFilter: (reg: TimeRegistration) => boolean
  setEditVisibility: (id: string) => void
}

export default function LeaveTabTable(props: Props): ReactElement | null {
  const getLeaveTypeById = (id: string) => {
    return props.leaveTypes.find((type) => type.id === id)
  }

  type TimeRegistrationRow = {
    key: string
    id: string
    approved: boolean
    date: string
    description: string
    immutable: boolean
    deletable: boolean
    original: TimeRegistration
    category?: LeaveSubTypeName
  }

  const getTimeRegistrations = (): TimeRegistrationRow[] => {
    return props.timeRegistrations.timeRegistrations
      .filter((timeRegistration) => props.timeRegistrationFilter(timeRegistration))
      .map((timeRegistration) => {
        const leaveType = getLeaveTypeById(timeRegistration.leaveTypeID!)!
        let description = formatLeaveTypeName(
          leaveType.name,
          false,
          props.employee.activeContract && props.employee.activeContract.remunerationType
        )
        if (timeRegistration.days !== 1) {
          let extra: string | undefined
          switch (timeRegistration.days) {
            case 1:
              extra = formatLeaveDuration(LeaveDurations.FULL_DAY).toLowerCase()
              break
            case 0.75:
              extra = formatLeaveDuration(LeaveDurations.THREE_QUARTERS_DAY).toLowerCase()
              break
            case 0.5:
              extra = formatLeaveDuration(LeaveDurations.HALF_DAY).toLowerCase()
              break
            case 0.25:
              extra = formatLeaveDuration(LeaveDurations.QUARTER_DAY).toLowerCase()
              break
            default:
              extra = t('unit.day_count', { count: timeRegistration.days ?? 0 })
              break
          }
          if (extra) {
            description = t('leave.tab.table.duration.format', { description, extra })
          }
        }
        return {
          key: timeRegistration.id,
          id: timeRegistration.id,
          approved: timeRegistration.approved,
          date: formatDate(timeRegistration.date),
          description,
          immutable: timeRegistration.immutable,
          deletable: leaveType.settledDeletable || !timeRegistration.immutable,
          original: timeRegistration,
          category: leaveType.leaveSubTypes.find((subType) => subType.id === timeRegistration.leaveSubTypeID)?.name,
        }
      })
      .toArray()
      .reduce((list: TimeRegistrationRow[], timeReg) => {
        if (!timeReg) {
          return list
        }
        list.push(timeReg)
        return list
      }, [])
  }

  const columns = [
    {
      title: t('leave.tab.table.header.approved'),
      key: 'x1',
      className: 'time-registration-table-approved',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (timeRegistration.approved) {
          return (
            <span>
              {t('leave.tab.table.approved.true')}
              {props.canApproveObjects && !timeRegistration.immutable && (
                <Tooltip title={t('leave.tab.table.approved.remove')}>
                  <span
                    onClick={(e) => {
                      e.preventDefault()
                      props.unapprove(timeRegistration.id)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Icon type="cross" color="grey" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (props.approving.indexOf(timeRegistration.id) !== -1) {
          return t('leave.tab.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('leave.tab.table.approved.false')
        }
        return (
          <DumbLink
            onClick={(e) => {
              e.preventDefault()
              props.approve(timeRegistration.id)
            }}
            type="standard"
          >
            {t('leave.tab.table.approved.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('leave.tab.table.header.date'), dataIndex: 'date', key: 'date' },
    {
      title: t('leave.tab.table.header.description'),
      render: (timeRegistration: TimeRegistrationRow) => {
        if (timeRegistration.category) {
          return (
            <span>
              {timeRegistration.description}
              <br />
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                {formatLeaveSubType(timeRegistration.category)}
              </span>
            </span>
          )
        }
        return timeRegistration.description
      },
    },
    {
      title: '',
      key: 'x3',
      className: 'employee-table-actions',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (props.deleting.indexOf(timeRegistration.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            {!timeRegistration.immutable && (
              <Tooltip title={t('leave.tab.table.actions.edit')}>
                <span onClick={() => props.setEditVisibility(timeRegistration.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="edit" color="grey" />
                </span>
              </Tooltip>
            )}
            {timeRegistration.deletable && (
              <Tooltip title={t('leave.tab.table.actions.delete')}>
                <span
                  onClick={(e) => {
                    e.preventDefault()
                    props.remove(timeRegistration.id)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon type="cross" color="grey" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const timeRegistrationRows = getTimeRegistrations()
  return (
    <>
      <Table columns={columns} dataSource={timeRegistrationRows} size="small" pagination={false} />
      {props.timeRegistrations.saving && <LoadingOverlay />}
    </>
  )
}
