import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import {
  createContractBookIntegration,
  deleteContractBookIntegration,
  getContractBookContracts,
  getContractBookIntegrations,
  setContractBookContractActive,
  updateContractBookIntegration,
} from '../actions/contract-book'
import ContractBookComponent from '../components/contract-book/ContractBook'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import ContractBookIntegration, {
  ContractBookIntegrationCreationFields,
  ContractBookIntegrationMutableFields,
} from '../model/contractBookIntegration'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { ContractBookContractReducer } from '../reducers/contractBookContracts'
import { ContractBookIntegrationReducer } from '../reducers/contractBookIntegrations'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  contractBookIntegrations: ContractBookIntegrationReducer
  contractBookContracts: ContractBookContractReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getContractBookIntegrations: () => void
  updateContractBookIntegration: (
    contractBookID: string,
    integration: ContractBookIntegrationMutableFields
  ) => Promise<ContractBookIntegration | void>
  createContractBookIntegration: (
    integration: ContractBookIntegrationCreationFields
  ) => Promise<ContractBookIntegration | void>
  deleteContractBookIntegration: (contractBookID: string) => Promise<boolean | void>
  getContractBookContracts: () => void
  setContractBookContractActive: (contractID: string, active: boolean) => void
}

function ContractBook(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <IntegrationsLayout location={props.location}>
      <ContractBookComponent
        alerts={props.alerts}
        company={company}
        contractBookIntegrations={props.contractBookIntegrations}
        contractBookContracts={props.contractBookContracts}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        getContractBookIntegrations={props.getContractBookIntegrations}
        updateContractBookIntegration={props.updateContractBookIntegration}
        createContractBookIntegration={props.createContractBookIntegration}
        deleteContractBookIntegration={props.deleteContractBookIntegration}
        getContractBookContracts={props.getContractBookContracts}
        setContractBookContractActive={props.setContractBookContractActive}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyUsers: state.companyUsers,
    user: state.user,
    contractBookIntegrations: state.contractBookIntegrations,
    contractBookContracts: state.contractBookContracts,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getContractBookIntegrations: getContractBookIntegrations,
    updateContractBookIntegration: updateContractBookIntegration,
    createContractBookIntegration: createContractBookIntegration,
    deleteContractBookIntegration: deleteContractBookIntegration,
    getContractBookContracts: getContractBookContracts,
    setContractBookContractActive: setContractBookContractActive,
  }
)(ContractBook)
