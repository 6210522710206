import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMonth,
  isSameSecond,
  isSameYear,
  isValid,
  parse,
  startOfDay,
  startOfHour,
  startOfWeek,
} from 'date-fns'
import { da, enGB } from 'date-fns/locale'

import { DateFormat, DateTimeFormat, Day } from '../model/types'
import { getClock } from './cookie-utils'
import { dayToNumber } from './day-utils'
import { getCurrentLocale } from './language-utils'
import { addLeadingZeros } from './number-utils'
import { t } from './translation-utils'

export function utcToCopenhagenTime(date: DateFormat | DateTimeFormat | Date): Date {
  // NOTE: This function can be a little slow, so avoid calling it too much.
  // trick to change the time zone from UTC to Europe/Copenhagen
  // if a Z is missing from the end of the datestamp, we add it to ensure it is interpreted as UTC
  // we then use toLocaleString to convert it to en-US, which new Date() can read
  // because toLocaleString takes timeZone as an option
  // taken from here: https://stackoverflow.com/questions/10087819/convert-date-to-another-timezone-in-javascript
  return new Date(
    (date instanceof Date ? date : new Date(date + (date[date.length - 1] !== 'Z' ? 'Z' : ''))).toLocaleString(
      'en-US',
      { timeZone: 'Europe/Copenhagen' }
    )
  )
}

function utc(date: DateFormat | DateTimeFormat | Date, isDate = false): Date {
  if (date instanceof Date) {
    return utcToCopenhagenTime(date)
  }
  // if it is a date, then just interpret it as is, we don't care about time zones
  if (isDate) {
    return parse(date, 'yyyy-MM-dd', new Date())
  }
  return utcToCopenhagenTime(date)
}

/**
 * A cache of strings to Date.  This is only for situations when getDate() is called with a string parameter, i.e.
 * a date/date-time format.  As these strings will _always_ yield the same Date in the same session, this should
 * reduce parsing times for certain situations where a lot of Date comparisons are needed.
 */
const dateCache = new Map<string, Date>()

type timeLocale = 'da-DK' | 'en-GB'
let currentLocale: timeLocale = getCurrentLocale()
function handleCurrentLocale(): Locale {
  const locale = getCurrentLocale()
  if (locale !== currentLocale) {
    currentLocale = locale
  }
  let fnsLocale = da
  switch (locale) {
    case 'da-DK':
      fnsLocale = da
      break
    case 'en-GB':
      fnsLocale = enGB
      break
  }
  return fnsLocale
}

let clockCache: DateTimeFormat
let clockUTCCache: Date

/**
 * Our main entry into building a new date, either from an existing source or generate the current time.
 *
 * Since our internal function for converting a date-time format to the correct time zone is a little slow,
 * try avoiding loops where `getDate()` without parameters gets called too much.  Instead, save it in a variable before
 * the loop.
 *
 * @param date
 * @param forceTime Ensures that the date is interpreted as a time, useful when you wish to calculate time from a midnight
 */
export function getDate(date?: DateFormat | DateTimeFormat | Date, forceTime = false): Date {
  if (!date) {
    if (clockUTCCache) {
      return clockUTCCache
    }
    if (clockCache) {
      clockUTCCache = utc(clockCache)
      return clockUTCCache
    }
    const clock = getClock()
    if (clock) {
      clockCache = clock
      clockUTCCache = utc(clockCache)
      return clockUTCCache
    }
    return utc(new Date().toISOString())
  }
  if (date instanceof Date) {
    return date
  }
  if (forceTime && date.indexOf('T') === -1) {
    // adding the midnight to the end, forces its interpretation as a time
    // during DST, 2024-05-13 becomes 2024-05-13 02:00, for instance
    date = date + 'T00:00:00.000Z'
    // we do it before the cache check, since the cache would return a wrong result
  }
  if (dateCache.has(date)) {
    return dateCache.get(date)! // since we just checked with "has", we know it's set
  }
  const v = utc(date, date.indexOf('T') === -1)
  dateCache.set(date, v) // yes, even if it is invalid, we save it, because the same string won't become more valid later
  return v
}

/**
 * Avoid calling new Date() directly, always prefer buildDate, since it will take care of timezones
 *
 * @param year
 * @param month Month according to JavaScript standard, i.e. 0-indexed, so January is 0 and December is 11.
 * @param day Defaults to 1, i.e. beginning of month
 */
export function buildDate(year: number, month: number, day = 1): Date {
  return getDate(`${year}-${addLeadingZeros(month + 1)}-${addLeadingZeros(day)}`)
}

/**
 * Uses date-fns' isValid function to detect whether it is valid.
 * Note: Using new Date with an invalid date (e.g. new Date(2022, 1, 31) - 31 February 2022) will not be detected,
 * as new Date will simply "correct" it (in the example to 3 March 2022).
 * @param d
 */
export function isValidDate(d: any): boolean {
  return d instanceof Date && isValid(d) && !isNaN(d.getTime())
}

/**
 * Formats Date or Date/DateTimeFormat into a human-readable date.
 * Per default it formats it like '1. januar 1900'
 * @param date Date or DateFormat or DateTimeFormat
 * @param dateFormat Format according to date-fns' parser.
 * @param locale A date-fns locale, will use cookie to determine which (if not provided).
 */
export function formatDate(
  date: Date | DateFormat | DateTimeFormat,
  dateFormat = t('date.date_format'),
  locale?: Locale
): string {
  if (!locale) {
    locale = handleCurrentLocale()
  }
  if (date instanceof Date) {
    if (isValidDate(date)) {
      return format(date, dateFormat, { locale })
    } else {
      // a Date, but not valid, bail to now
      return formatDate(getDate(), dateFormat, locale)
    }
  }
  return formatDate(getDate(date), dateFormat, locale)
}

export function formatDateWithoutYear(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, t('date.day_month'))
}

/**
 * Helper function to create a shortform version of the date (i.e. months at max 3 letters)
 * @param date
 */
export function formatShortDate(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, t('date.date_short_format'))
}

/**
 * Helper function to also display the time after the date.
 * @param date
 */
export function formatDateTime(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, t('date.date_time_format'))
}

export function formatShortDateTime(date: Date | DateFormat | DateTimeFormat): string {
  return formatDate(date, t('date.date_time_short_format'))
}

export function formatAPIDate(date: Date | DateFormat): DateFormat {
  return formatDate(date, 'yyyy-MM-dd', enGB)
}

type timeGranularity = 'day' | 'hour' | 'second'

export function isTimeSame(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  switch (granularity) {
    case 'day':
      return isSameDay(date, dateToCompare)
    case 'hour':
      return isSameHour(date, dateToCompare)
    case 'second':
    default:
      return isSameSecond(date, dateToCompare)
  }
}

/**
 * Determines whether the `date` is before `dateToCompare`
 * @param {Date | DateFormat | DateTimeFormat} date Our start
 * @param {Date | DateFormat | DateTimeFormat} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day, hour or second level
 */
export function isTimeBefore(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  switch (granularity) {
    case 'day':
      return isBefore(startOfDay(date), startOfDay(dateToCompare))
    case 'hour':
      return isBefore(startOfHour(date), startOfHour(dateToCompare))
    case 'second':
    default:
      return isBefore(date, dateToCompare)
  }
}

/**
 * Determines whether the `date` is the same as or before `dateToCompare`
 * @param {Date | DateFormat | DateTimeFormat} date Our start
 * @param {Date | DateFormat | DateTimeFormat} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day, hour or second level
 */
export function isSameOrBefore(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  if (isTimeSame(date, dateToCompare, granularity)) {
    return true
  }
  return isTimeBefore(date, dateToCompare, granularity)
}

/**
 * Determines whether the `date` is after `dateToCompare`
 * @param {Date | DateFormat | DateTimeFormat} date Our start
 * @param {Date | DateFormat | DateTimeFormat} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day, hour or second level
 */
export function isTimeAfter(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateToCompare = dateToCompare instanceof Date ? dateToCompare : getDate(dateToCompare)
  switch (granularity) {
    case 'day':
      return isAfter(startOfDay(date), startOfDay(dateToCompare))
    case 'hour':
      return isAfter(startOfHour(date), startOfHour(dateToCompare))
    case 'second':
    default:
      return isAfter(date, dateToCompare)
  }
}

/**
 * Determines whether the `date` is the same as or after `dateToCompare`
 * @param {Date} date Our start
 * @param {Date} dateToCompare Our date to compare
 * @param [granularity=day] Whether to compare on day, hour or second level
 */
export function isSameOrAfter(
  date: Date | DateFormat | DateTimeFormat,
  dateToCompare: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day'
): boolean {
  if (isTimeSame(date, dateToCompare, granularity)) {
    return true
  }
  return isTimeAfter(date, dateToCompare, granularity)
}

/**
 * Determines whether `date` is between `dateFrom` and `dateTo`
 * @param {Date} date Our start
 * @param {Date} dateFrom Our earliest point of the time span
 * @param {Date} dateTo Our latest point of the time span
 * @param [granularity=day] Whether to compare on day, hour or second level
 * @param [inclusive=true] Whether the date can match the end points
 */
export function isTimeBetween(
  date: Date | DateFormat | DateTimeFormat,
  dateFrom: Date | DateFormat | DateTimeFormat,
  dateTo: Date | DateFormat | DateTimeFormat,
  granularity: timeGranularity = 'day',
  inclusive = true
): boolean {
  date = date instanceof Date ? date : getDate(date)
  dateFrom = dateFrom instanceof Date ? dateFrom : getDate(dateFrom)
  dateTo = dateTo instanceof Date ? dateTo : getDate(dateTo)
  if (inclusive) {
    return isSameOrAfter(date, dateFrom, granularity) && isSameOrBefore(date, dateTo, granularity)
  }
  return isTimeAfter(date, dateFrom, granularity) && isTimeBefore(date, dateTo, granularity)
}

export function getDateForWeekDay(dayInWeek: Date, day: Day): Date {
  const weekStart = startOfWeek(dayInWeek, { weekStartsOn: 1 })
  let n = dayToNumber(day) - 1 // so Monday is 0, and Sunday is -1
  if (n === -1) {
    n = 6
  }
  return addDays(weekStart, n)
}

export function trimCurrentYear(str: string, year?: string): string {
  if (!year) {
    year = getDate().getFullYear().toString()
  }
  return str.replace(year, ' ').replace(/  +/, ' ').trim()
}

export function formatDateInterval(firstDate: Date, lastDate: Date): string {
  if (isSameMonth(firstDate, lastDate)) {
    return t('date.date_interval', { start: formatDate(firstDate, t('date.day_of_month')), end: formatDate(lastDate) })
  }
  if (isSameYear(firstDate, lastDate)) {
    return t('date.date_interval', { start: formatDateWithoutYear(firstDate), end: formatDate(lastDate) })
  }
  return t('date.date_interval', { start: formatDate(firstDate), end: formatDate(lastDate) })
}

export function formatMonth(month: number): string {
  return formatDate(buildDate(2020, month, 1), 'MMMM')
}
