import { addYears, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { getCarAllowances } from '../../actions/car-allowances'
import { getOneTimePays } from '../../actions/one-time-pays'
import { getReimbursementVouchers } from '../../actions/reimbursement-vouchers'
import { getSalaryRegistrations } from '../../actions/salary-registrations'
import { getSwipes } from '../../actions/swipes'
import { getTimeRegistrations } from '../../actions/time-registrations'
import { DateFormat, SettledState } from '../../model/types'
import Reducers from '../../reducers'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { CompanyReducer } from '../../reducers/companies'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { SwipeReducer } from '../../reducers/swipes'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { connectToReducer } from '../../utils/reducer-utils'

type Reducers = {
  companies: CompanyReducer
  carAllowances: CarAllowanceReducer
  timeRegistrations: TimeRegistrationReducer
  oneTimePays: OneTimePayReducer
  swipes: SwipeReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryRegistrations: SalaryRegistrationReducer
}

type Actions = {
  getOneTimePays: (companyID?: string, employeeID?: string) => void
  getCarAllowances: (
    companyID: string | undefined,
    employeeID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  getSalaryRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat,
    stateFilter?: SettledState
  ) => void
  getSwipes: () => void
  getReimbursementVouchers: () => void
}

// This might seem overkill for a component that only renders basically a number, but it requires a lot of prep work,
// and since it is used _two_ places, it is just extremely useful to have it like this.
function PendingApprovalCount(props: Reducers & Actions): ReactElement | null {
  const company = props.companies.company
  const {
    oneTimePays,
    getOneTimePays,
    carAllowances,
    getCarAllowances,
    timeRegistrations,
    getTimeRegistrations,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryRegistrations,
    getSalaryRegistrations,
    swipes,
    getSwipes,
  } = props

  useEffect(() => {
    if (!company) {
      return
    }
    if (
      !carAllowances.companyID ||
      carAllowances.companyID !== company.id ||
      (!carAllowances.loaded && !carAllowances.loading)
    ) {
      getCarAllowances(
        company.id,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
    if (
      !timeRegistrations.companyID ||
      timeRegistrations.companyID !== company.id ||
      (!timeRegistrations.loaded && !timeRegistrations.loading)
    ) {
      getTimeRegistrations(
        company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
    if (
      !oneTimePays.companyID ||
      oneTimePays.companyID !== company.id ||
      (!oneTimePays.loaded && !oneTimePays.loading)
    ) {
      getOneTimePays(company.id)
    }
    if (
      !reimbursementVouchers.companyID ||
      reimbursementVouchers.companyID !== company.id ||
      (!reimbursementVouchers.loaded && !reimbursementVouchers.loading)
    ) {
      getReimbursementVouchers()
    }
    if (!swipes.companyID || swipes.companyID !== company.id || (!swipes.loaded && !swipes.loading)) {
      getSwipes()
    }
    if (
      !salaryRegistrations.companyID ||
      salaryRegistrations.companyID !== company.id ||
      (!salaryRegistrations.loaded && !salaryRegistrations.loading)
    ) {
      getSalaryRegistrations(
        company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10)),
        'Pending'
      )
    }
  }, [
    company,
    oneTimePays,
    getOneTimePays,
    carAllowances,
    getCarAllowances,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryRegistrations,
    getSalaryRegistrations,
    swipes,
    getSwipes,
    timeRegistrations,
    getTimeRegistrations,
  ])

  const getPendingApprovalCount = (): number => {
    return (
      carAllowances.carAllowances.filter((allowance) => !allowance.approved).size +
      timeRegistrations.timeRegistrations.filter((timeReg) => !timeReg.approved).size +
      swipes.swipes.filter((swipe) => !swipe.approved).size +
      oneTimePays.oneTimePays.filter(
        (otp) => otp.type !== 'Swipe' && otp.type !== 'Reimbursement Voucher' && !otp.approved
      ).size +
      reimbursementVouchers.reimbursementVouchers.filter((voucher) => voucher.approvalState === 'Ready').size +
      salaryRegistrations.salaryRegistrations.filter((reg) => !reg.approved).size
    )
  }
  const pendingApprovalCount = getPendingApprovalCount()

  if (!pendingApprovalCount) {
    return null
  }

  return <span className="approval-count">{pendingApprovalCount}</span>
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    companies: state.companies,
    oneTimePays: state.oneTimePays,
    timeRegistrations: state.timeRegistrations,
    swipes: state.swipes,
    reimbursementVouchers: state.reimbursementVouchers,
    carAllowances: state.carAllowances,
    salaryRegistrations: state.salaryRegistrations,
  }),
  {
    getOneTimePays: getOneTimePays,
    getCarAllowances: getCarAllowances,
    getTimeRegistrations: getTimeRegistrations,
    getSalaryRegistrations: getSalaryRegistrations,
    getSwipes: getSwipes,
    getReimbursementVouchers: getReimbursementVouchers,
  }
)(PendingApprovalCount)
