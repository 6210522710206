import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import Company from '../../model/company'
import { t, tx } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'

type Props = {
  companies: List<Company>
}

export default function TerminateTab(props: Props): ReactElement | null {
  const multipleCompanies = props.companies.size > 1
  const companyID = !multipleCompanies ? props.companies.first()?.id : undefined
  return (
    <Card className="terminate-account-card">
      <Subtitle>{t('account.terminate.title')}</Subtitle>
      <p>
        {multipleCompanies && t('account.terminate.message.multiple_companies')}
        {!multipleCompanies &&
          tx('account.terminate.message.single_company', {
            link: (
              <Link to={'/' + paths.COMPANIES + '/' + companyID + '/support'}>
                {t('account.terminate.message.single_company.click_here')}
              </Link>
            ),
          })}
      </p>
    </Card>
  )
}
