import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import Remuneration from '../../../model/remuneration'
import { ContractReducer } from '../../../reducers/contracts'
import { formatDate } from '../../../utils/date-utils'
import { formatBenefitDefinition } from '../../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import EmploymentBenefitsEdit from './EmploymentBenefitsEdit'

type Props = {
  mutableContract: Contract
  employee: Employee
  contracts: ContractReducer
  isMutableContract: boolean

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentBenefitsCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const { isMutableContract } = props
  const setEditVisibility = useCallback(
    (visible: boolean) => {
      if (!isMutableContract) {
        return
      }
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setShowEdit(visible)
    },
    [isMutableContract]
  )

  const { contracts } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    // Check for save callback
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      // Check for no error occurred
      if (!contracts.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  }, [previousContracts, contracts, setEditVisibility])

  const getPaidCar = (remuneration: Remuneration) => {
    const row = remuneration.benefits.find((row) => row.type === 'Car Simple' || row.type === 'Car Managed')
    if (!row) {
      return t('benefit.card.generic.false')
    }
    let benefit
    if (row.type === 'Car Simple') {
      benefit = t('benefit.card.paid_car.simple', { amount: formatCurrency(row.amount, 0) })
    } else {
      if ((row.amount ?? 0) > 0) {
        benefit = t('benefit.card.paid_car.managed.value_own', {
          value: formatCurrency(row.carValuation, 0),
          own: formatCurrency(row.amount, 0),
        })
      } else {
        benefit = t('benefit.card.paid_car.managed.value', { value: formatCurrency(row.carValuation, 0) })
      }
      if (row.carAcquisitionDate) {
        benefit = t('benefit.card.paid_car.managed.acquired', {
          prefix: benefit,
          date: formatDate(row.carAcquisitionDate),
        })
      }
    }
    return benefit
  }
  const getPaidInternet = (remuneration: Remuneration) => {
    return remuneration.benefits.some((row) => row.type === 'Internet')
      ? t('benefit.card.generic.true')
      : t('benefit.card.generic.false')
  }
  const getPaidTelephone = (remuneration: Remuneration) => {
    return remuneration.benefits.some((row) => row.type === 'Telephone')
      ? t('benefit.card.generic.true')
      : t('benefit.card.generic.false')
  }
  const getForenedeGruppeliv = (remuneration: Remuneration) => {
    return remuneration.benefits.some((row) => row.type === 'Forenede Gruppeliv')
      ? t('benefit.card.generic.true')
      : t('benefit.card.generic.false')
  }
  const getPaidHealth = (remuneration: Remuneration) => {
    const row = remuneration.benefits.find((row) => row.type === 'Health')
    if (!row) {
      return t('benefit.card.generic.false')
    }
    return t('benefit.card.paid_health.value', { amount: formatCurrency(row.amount, 0) })
  }
  const getPaidLunch = (remuneration: Remuneration) => {
    let row = remuneration.benefits.find((row) => row.type === 'Lunch')
    if (row) {
      return t('benefit.card.paid_lunch.regular', { amount: formatCurrency(row.amount, 0) })
    }
    row = remuneration.benefits.find((row) => row.type === 'Lunch Daily')
    if (row) {
      return t('benefit.card.paid_lunch.daily', { amount: formatCurrency(row.amount, 0) })
    }
    return t('benefit.card.generic.false')
  }
  const getEmployeeAssociation = (remuneration: Remuneration) => {
    const row = remuneration.benefits.find((row) => row.type === 'Employee Association')
    if (!row) {
      return t('benefit.card.generic.false')
    }
    return t('benefit.card.employee_association.value', { amount: formatCurrency(row.amount, 0) })
  }
  const getBoardLodging = (remuneration: Remuneration) => {
    const row = remuneration.benefits.find((row) => row.type === 'Board and Lodging')
    if (!row) {
      return t('benefit.card.generic.false')
    }
    return t('benefit.card.board_lodging.value', { amount: formatCurrency(row.amount, 0) })
  }
  const getPermanentResidence = (remuneration: Remuneration) => {
    const row = remuneration.benefits.find((row) => row.type === 'Permanent Residence')
    if (!row) {
      return t('benefit.card.generic.false')
    }
    return t('benefit.card.permanent_residence.value', { amount: formatCurrency(row.amount, 0) })
  }
  const getEmployeeStock = (remuneration: Remuneration) => {
    return remuneration.benefits.some((row) => row.type === 'Benefit In Kind')
      ? t('benefit.card.generic.true')
      : t('benefit.card.generic.false')
  }
  const getFreeTransport = (remuneration: Remuneration) => {
    return remuneration.benefits.some((row) => row.type === 'Free Transport')
      ? t('benefit.card.generic.true')
      : t('benefit.card.generic.false')
  }
  const getEmployeeIncrease = (remuneration: Remuneration) => {
    const sum = remuneration.benefits.reduce((sum, row) => {
      if (row.type === 'Fixed Disbursement') {
        sum += row.amount || 0
      }
      return sum
    }, 0.0)
    if (sum > 0.0) {
      return t('benefit.card.employee_increase.value', { amount: formatCurrency(sum, 0) })
    }
    return t('benefit.card.generic.false')
  }

  type SalaryReductionRow = {
    key: string
    title: string
    isGross: boolean
    affectsVacation: boolean
    affectsPension: boolean
    reduction?: number
    reporting?: number
  }
  const salaryReductionColumns = [
    { title: t('benefit.card.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: t('benefit.card.table.header.reduction'),
      dataIndex: '',
      key: 'x1',
      render: (row: SalaryReductionRow) => {
        if (!row.reduction) {
          return '-'
        }
        return (
          <span>
            {tx('benefit.card.table.reduction.number_format', {
              amount: formatNumber(row.reduction, 0),
              suffix: (
                <em>
                  {t(
                    row.isGross
                      ? 'benefit.card.table.reduction.number_format.suffix_gross'
                      : 'benefit.card.table.reduction.number_format.suffix_net'
                  )}
                </em>
              ),
            })}
            {row.isGross && (
              <>
                <br />
                <span style={{ fontSize: '10px' }}>
                  <em>
                    {row.affectsVacation
                      ? t('benefit.card.table.reduction.gross.vacation.true')
                      : tx('benefit.card.table.reduction.gross.vacation.false', {
                          not: <strong>{t('benefit.card.table.reduction.gross.vacation.false.not')}</strong>,
                        })}
                  </em>
                  <br />
                  <em>
                    {row.affectsVacation
                      ? t('benefit.card.table.reduction.gross.pension.true')
                      : tx('benefit.card.table.reduction.gross.pension.false', {
                          not: <strong>{t('benefit.card.table.reduction.gross.pension.false.not')}</strong>,
                        })}
                  </em>
                </span>
              </>
            )}
          </span>
        )
      },
    },
    {
      title: t('benefit.card.table.header.reporting'),
      dataIndex: '',
      key: 'x2',
      render: (row: SalaryReductionRow) => {
        if (!row.reporting) {
          return '-'
        }
        return (
          <span>
            {tx('benefit.card.table.reporting.number_format', {
              amount: formatNumber(row.reporting, 0),
              suffix: <em>{t('benefit.card.table.reporting.number_format.suffix')}</em>,
            })}
          </span>
        )
      },
    },
  ]
  const getSalaryReductionRows = (remuneration: Remuneration): SalaryReductionRow[] => {
    return remuneration.benefits
      .filter(
        (row) =>
          row.type === 'Gross Pay Reduction' ||
          row.type === 'Gross Pay Reduction No Vacation' ||
          row.type === 'Gross Pay Reduction No Pension' ||
          row.type === 'Gross Pay Reduction No Vacation and Pension' ||
          row.type === 'Net Pay Reduction' ||
          row.type === 'Tax Reporting' ||
          row.type === 'Tax Reporting No Threshold'
      )
      .map((row) => {
        const isGross =
          row.type === 'Gross Pay Reduction' ||
          row.type === 'Gross Pay Reduction No Vacation' ||
          row.type === 'Gross Pay Reduction No Pension' ||
          row.type === 'Gross Pay Reduction No Vacation and Pension'
        return {
          key: row.id,
          title: formatBenefitDefinition(row),
          isGross,
          affectsVacation: row.type === 'Gross Pay Reduction' || row.type === 'Gross Pay Reduction No Pension',
          affectsPension: row.type === 'Gross Pay Reduction' || row.type === 'Gross Pay Reduction No Vacation',
          reduction: isGross || row.type === 'Net Pay Reduction' ? row.amount : undefined,
          reporting: row.type === 'Tax Reporting' || row.type === 'Tax Reporting No Threshold' ? row.amount : undefined,
        }
      })
  }

  const remuneration = props.mutableContract.remuneration
  if (!remuneration) {
    return null
  }
  const salaryReductionRows = getSalaryReductionRows(remuneration)
  return (
    <Card>
      <TitleMenu>
        {props.isMutableContract && <Button className="ant-btn-edit" onClick={() => setEditVisibility(true)} />}
      </TitleMenu>
      <Title>{t('benefit.card.title')}</Title>

      <Row>
        <Col span={6}>
          {t('benefit.card.items.health')}:
          <br />
          <strong>{getPaidHealth(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.paid_internet')}:
          <br />
          <strong>{getPaidInternet(remuneration)}</strong>
        </Col>
        <Col span={12}>
          {t('benefit.card.items.paid_car')}:
          <br />
          <strong>{getPaidCar(remuneration)}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {t('benefit.card.items.paid_telephone')}:
          <br />
          <strong>{getPaidTelephone(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.paid_lunch')}:
          <br />
          <strong>{getPaidLunch(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.board_lodging')}:
          <br />
          <strong>{getBoardLodging(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.permanent_residence')}:
          <br />
          <strong>{getPermanentResidence(remuneration)}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {t('benefit.card.items.employee_stock')}:
          <br />
          <strong>{getEmployeeStock(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.employee_increase')}:
          <br />
          <strong>{getEmployeeIncrease(remuneration)}</strong>
        </Col>
        <Col span={12}>
          {t('benefit.card.items.free_transport')}:
          <br />
          <strong>{getFreeTransport(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.forenede_gruppeliv')}:
          <br />
          <strong>{getForenedeGruppeliv(remuneration)}</strong>
        </Col>
        <Col span={6}>
          {t('benefit.card.items.employee_association')}:
          <br />
          <strong>{getEmployeeAssociation(remuneration)}</strong>
        </Col>
      </Row>

      {salaryReductionRows.length > 0 && (
        <Table
          columns={salaryReductionColumns}
          dataSource={getSalaryReductionRows(remuneration)}
          size="small"
          pagination={false}
        />
      )}

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={788}
        footer={null}
      >
        <EmploymentBenefitsEdit
          visible={showEdit}
          employee={props.employee}
          mutableContract={props.mutableContract}
          contracts={props.contracts}
          addContract={props.addContract}
          updateContract={props.updateContract}
        />
      </Modal>
    </Card>
  )
}
