import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyDeviationConfiguration, {
  CompanyDeviationConfigurationMutableFields,
} from '../../model/companyDeviationConfiguration'
import { CompanyDeviationConfigurationReducer } from '../../reducers/companyDeviationConfigurations'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DeviationsEdit from './DeviationsEdit'

type Props = {
  company: Company
  companyDeviationConfigurations: CompanyDeviationConfigurationReducer

  addAlert: addAlertSignature
  getCompanyDeviationConfigurations: () => void
  addCompanyDeviationConfiguration: (configuration: CompanyDeviationConfigurationMutableFields) => void
  updateCompanyDeviationConfiguration: (configuration: CompanyDeviationConfiguration) => void
  deleteCompanyDeviationConfiguration: (id: string) => void
}

export default function DeviationsTab(props: Props): ReactElement | null {
  const { companyDeviationConfigurations, getCompanyDeviationConfigurations } = props

  useEffect(() => {
    if (!companyDeviationConfigurations.loaded && !companyDeviationConfigurations.loading) {
      getCompanyDeviationConfigurations()
    }
  })

  return (
    <Card>
      <TitleMenu>
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/pay'}>
          <Button>{t('deviation.header.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('deviation.header.title')}</Title>
      <p>{t('deviation.introduction')}</p>
      <DeviationsEdit
        company={props.company}
        companyDeviationConfigurations={props.companyDeviationConfigurations}
        addAlert={props.addAlert}
        addCompanyDeviationConfiguration={props.addCompanyDeviationConfiguration}
        updateCompanyDeviationConfiguration={props.updateCompanyDeviationConfiguration}
        deleteCompanyDeviationConfiguration={props.deleteCompanyDeviationConfiguration}
      />
    </Card>
  )
}
