import { List } from 'immutable'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { EmployeeConversionType } from '../../api/employees'
import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../../api/reimbursement-vouchers'
import { TimeRegistrationBulk } from '../../api/time-registrations'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanyPricingPackage from '../../model/companyPricingPackage'
import { ContractCreationFields, ContractMutableFields } from '../../model/contract'
import CostCenter from '../../model/costCenter'
import Department, { DepartmentCreationFields } from '../../model/department'
import Employee from '../../model/employee'
import { EmployeeEmergencyContactMutableFields } from '../../model/employeeEmergencyContact'
import Employment from '../../model/employment'
import ExpenseCategory from '../../model/expenseCategory'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../model/oneTimePay'
import PaySlip from '../../model/paySlip'
import PricingPackage from '../../model/pricingPackage'
import ProfileImage from '../../model/profileImage'
import Project from '../../model/project'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import SalaryCycle from '../../model/salaryCycle'
import { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../model/timeRegistration'
import { DateFormat } from '../../model/types'
import Warning from '../../model/warning'
import { AlertReducer } from '../../reducers/alerts'
import { BankReducer } from '../../reducers/banks'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { ContractReducer } from '../../reducers/contracts'
import { DepartmentReducer } from '../../reducers/departments'
import { EmployeeEmergencyContactReducer } from '../../reducers/employeeEmergencyContacts'
import { EmployeeInviteReducer } from '../../reducers/employeeInvites'
import { EmployeeProfileImageReducer } from '../../reducers/employeeProfileImages'
import { EmployeeReducer } from '../../reducers/employees'
import { EmploymentReducer } from '../../reducers/employments'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { UserReducer } from '../../reducers/user'
import {
  getMainMenuFreelancerMenu,
  MainMenuFreelancerID,
  MenuContext,
  setMainMenuFreelancerMenu,
} from '../../utils/menu-utils'
import { hasDepartmentPermission, isDepartmentRestricted } from '../../utils/permissions-utils'
import { isPricingPackageGroup } from '../../utils/pricing-package-utils'
import { projectFilter } from '../../utils/projects-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import DeleteEmployee from '../employees-single/delete-employee/DeleteEmployee'
import FreeTextTab from '../employees-single/free-text/FreeTextTab'
import PayCheckAdvanceTab from '../employees-single/pay-check-advance/PayCheckAdvanceTab'
import ProfileTab from '../employees-single/profile/ProfileTab'
import RehireEmployee from '../employees-single/rehire-employee/RehireEmployee'
import ReimbursementTab from '../employees-single/reimbursement/ReimbursementTab'
import SalaryReductionTab from '../employees-single/salary-reduction/SalaryReductionTab'
import TerminateEmployee from '../employees-single/terminate-employee/TerminateEmployee'
import TimeRegistrationTab from '../employees-single/time-registration/TimeRegistrationTab'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import EmploymentTab from './employment/EmploymentTab'
import FeeTab from './fee/FeeTab'
import PayInvoicesTab from './pay-invoices/PayInvoicesTab'
import Sidebar from './sidebar/Sidebar'

import '../employees-single/EmployeesSingle.css'
import './FreelancersSingle.css'

type Props = {
  section?: string
  subsection?: string
  alerts: AlertReducer
  employee: Employee
  user: UserReducer
  employees: EmployeeReducer
  company: Company
  companyFeatures: List<CompanyFeature>
  companyUsers: CompanyUserReducer
  departments: DepartmentReducer
  paySlips: List<PaySlip>
  salaryCycles: List<SalaryCycle>
  banks: BankReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employments: EmploymentReducer
  contracts: ContractReducer
  costCenters: List<CostCenter>
  oneTimePays: OneTimePayReducer
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  pricingPackages: List<PricingPackage>
  companyPricingPackages: List<CompanyPricingPackage>
  timeRegistrations: TimeRegistrationReducer
  projects: List<Project>
  warnings: List<Warning>
  hasEmployeeEmergencyContactsFeature: boolean
  companyAccountPlans: CompanyAccountPlanReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  deleteEmployee: (employeeID: string) => Promise<boolean | void>
  getBanks: () => void
  updateEmployeeEmergencyContact: (
    employeeID: string,
    employeeEmergencyContact: EmployeeEmergencyContactMutableFields
  ) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  updateEmployment: (employeeID: string, employment: Employment) => void
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  getDepartments: () => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  approveOneTimePays: (oneTimePayIDs: string[]) => void
  unapproveOneTimePays: (oneTimePayIDs: string[]) => void
  deleteOneTimePay: (id: string) => void
  sendInvite: (employeeID: string) => void
  deleteEmployeeUser: (employeeID: string) => void
  updateProfileImage: (employeeID: string, image: ProfileImage) => void
  addReimbursementVoucher: (o: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  getEmployments: (employeeID: string) => void
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  rehireEmployee: (employeeID: string, startDate: DateFormat) => Promise<boolean | void>
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
  convertEmployee: (employeeID: string, toType: EmployeeConversionType) => Promise<Employee | void>
  deleteWarning: (id: string) => void
  getCompanyAccountPlans: () => void
}

export default function FreelancersSingle(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showDelete, setShowDelete] = useState(props.section === 'delete')
  const [showTerminate, setShowTerminate] = useState(false)
  const [showRehire, setShowRehire] = useState(false)
  const [showLeave, setShowLeave] = useState(false)
  const { menu, setMenu } = useContext(MenuContext)

  const { contracts, addAlert, employee } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        if (showLeave) {
          if (!employee) {
            return // should not happen
          }
          addAlert('success', t('freelancer.single.alert.leave', { name: employee.name }), { timeout: 5 })
          setShowLeave(false)
          if (employee.affiliationType === 'Freelancer') {
            jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/employment')
          } else {
            jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/employment')
          }
        }
      }
    }
  }, [previousContracts, contracts, addAlert, employee, showLeave])

  const { employees } = props
  const previousEmployees = usePrevious(employees)

  useEffect(() => {
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      if (!employees.error) {
        if (!employee) {
          return // should not happen
        }
        if (showDelete) {
          addAlert('success', t('freelancer.single.alert.deleted', { name: employee.name }), { timeout: 5 })
          jsBrowserHistory.push('/' + paths.FREELANCERS)
        }
        if (showTerminate) {
          addAlert('success', t('freelancer.single.alert.terminated', { name: employee.name }), { timeout: 5 })
          jsBrowserHistory.push('/' + paths.FREELANCERS)
        }
      }
    }
  }, [previousEmployees, employees, addAlert, employee, showDelete, showTerminate])

  const setDeleteVisibility = (v: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowDelete(v)
  }
  const setTerminateVisibility = (v: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowTerminate(v)
  }
  const setRehireVisibility = (v: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowRehire(v)
  }

  const { projects } = props

  useEffect(() => {
    const menuItems: MainMenuFreelancerID[] = []
    menuItems.push('freelancer-menu-profile')
    menuItems.push('freelancer-menu-overview')
    menuItems.push('freelancer-menu-payments')
    if (projects.some((p) => projectFilter(p, employee))) {
      menuItems.push('freelancer-menu-project-registration')
    }
    menuItems.push('freelancer-menu-employment')
    setMainMenuFreelancerMenu(setMenu, employee.id, menuItems)
  }, [employee, projects, setMenu])

  const getWarnings = (): Warning[] => {
    return props.warnings
      .filter((warning) => warning.subjectID === employee.id && warning.warningType === 'EmployeeIsForeignResident')
      .toArray()
  }

  const section = props.section
  const costCenterAccounting = props.company.costCenterAccounting || 'Off'
  const canEditObjects = hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'EditObjects')
  const canHireFire = hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'HireFire')
  const canApproveObjects = hasDepartmentPermission(
    props.companyUsers.companyUser,
    employee.departmentID,
    'ApproveObjects'
  )
  const hasPremiumPackage = isPricingPackageGroup(
    props.pricingPackages.toArray(),
    props.companyPricingPackages.toArray(),
    props.company.id,
    ['Premium']
  )
  const salaryCycle = props.salaryCycles.find(
    (cycle) => cycle.id === props.employee.earliestMutableContract?.salaryCycleID
  )
  return (
    <div className="employees-single freelancers-single">
      {!props.user.user?.uiSettings.sideMenu && (
        <div className="employees-single-menu">
          {getMainMenuFreelancerMenu(menu).map((item) => {
            if (item.type === 'item-employee-selector') {
              return null
            }
            return (
              <Link
                key={item.key}
                to={item.link}
                className={section === item.id.replace(/^freelancer-menu-/, '') ? 'active' : ''}
              >
                {t(item.labelID)}
              </Link>
            )
          })}
        </div>
      )}
      <div className="employees-single-main">
        {getWarnings().map((warning) => {
          let onClose = undefined
          if (warning.canAcknowledge) {
            onClose = () => {
              props.deleteWarning(warning.id)
            }
          }
          switch (warning.warningType) {
            case 'EmployeeIsForeignResident':
              return (
                <Alert
                  key={warning.id}
                  message={t('employee_single.warning.employee_is_foreign_resident')}
                  type={'warning'}
                  showIcon
                  closable={!!onClose}
                  onClose={onClose}
                />
              )
            case 'InvalidBirthDate':
              return (
                <Alert
                  key={warning.id}
                  message={t('employee_single.warning.invalid_birth_date')}
                  type={'warning'}
                  showIcon
                  closable={!!onClose}
                  onClose={onClose}
                />
              )
            default:
              return null
          }
        })}
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
        {(section === 'overview' || section === 'delete') && (
          <FeeTab
            subsection={props.subsection}
            canEditObjects={canEditObjects}
            canApproveObjects={canApproveObjects}
            employee={props.employee}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            costCenters={props.costCenters}
            costCenterAccounting={costCenterAccounting}
            companyAccountPlans={props.companyAccountPlans}
            addAlert={props.addAlert}
            addOneTimePay={props.addOneTimePay}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
            getCompanyAccountPlans={props.getCompanyAccountPlans}
          />
        )}
        {section === 'employment' && (
          <EmploymentTab
            employee={employee}
            company={props.company}
            contracts={props.contracts}
            departments={props.departments}
            employees={props.employees}
            viewingContract={employee.activeContract}
            isDepartmentManager={isDepartmentRestricted(props.companyUsers.companyUser)}
            canEditObjects={canEditObjects}
            canHireFire={canHireFire}
            salaryCycles={props.salaryCycles}
            addAlert={props.addAlert}
            removeAlert={props.removeAlert}
            updateEmployee={props.updateEmployee}
            addContract={props.addContract}
            updateContract={props.updateContract}
            getDepartments={props.getDepartments}
            addDepartment={props.addDepartment}
            convertEmployee={props.convertEmployee}
          />
        )}
        {section === 'payments' && (
          <PayInvoicesTab
            employee={employee}
            employees={props.employees}
            paySlips={props.paySlips}
            hasPremiumPackage={hasPremiumPackage}
            updateEmployee={props.updateEmployee}
          />
        )}
        {section === 'profile' && (
          <ProfileTab
            user={props.user}
            employee={props.employee}
            canEditObjects={canEditObjects}
            company={props.company}
            companyFeatures={props.companyFeatures}
            employees={props.employees}
            employeeEmergencyContacts={props.employeeEmergencyContacts}
            employments={props.employments}
            banks={props.banks}
            addAlert={props.addAlert}
            updateEmployee={props.updateEmployee}
            updateEmployeeEmergencyContact={props.updateEmployeeEmergencyContact}
            deleteEmployeeEmergencyContact={props.deleteEmployeeEmergencyContact}
            updateEmployment={props.updateEmployment}
            getBanks={props.getBanks}
          />
        )}
        {section === 'reimbursement' && (
          <ReimbursementTab
            subsection={props.subsection}
            canEditObjects={canEditObjects}
            canApproveObjects={canApproveObjects}
            employee={employee}
            employees={props.employees.employees}
            isFreelancer={true}
            company={props.company}
            companyUser={props.companyUsers.companyUser}
            companyUsers={props.companyUsers.companyUsers}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            expenseCategories={props.expenseCategories}
            reimbursementVouchers={props.reimbursementVouchers}
            costCenters={props.costCenters}
            costCenterAccounting={costCenterAccounting}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
            addReimbursementVoucher={props.addReimbursementVoucher}
            updateReimbursementVoucher={props.updateReimbursementVoucher}
            updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
            approveReimbursementVouchers={props.approveReimbursementVouchers}
            unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
            draftReimbursementVouchers={props.draftReimbursementVouchers}
          />
        )}
        {section === 'pay-check-advance' && (
          <PayCheckAdvanceTab
            subsection={props.subsection}
            canEditObjects={canEditObjects}
            canApproveObjects={canApproveObjects}
            employee={props.employee}
            isFreelancer={true}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            costCenters={props.costCenters}
            costCenterAccounting={costCenterAccounting}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'free-text' && (
          <FreeTextTab
            subsection={props.subsection}
            canEditObjects={canEditObjects}
            canApproveObjects={canApproveObjects}
            employee={props.employee}
            isFreelancer={true}
            eIncomeText={false}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'salary-reduction' && (
          <SalaryReductionTab
            subsection={props.subsection}
            canEditObjects={canEditObjects}
            canApproveObjects={canApproveObjects}
            employee={props.employee}
            isFreelancer={true}
            company={props.company}
            oneTimePays={props.oneTimePays}
            salaryCycle={salaryCycle}
            costCenters={props.costCenters}
            costCenterAccounting={costCenterAccounting}
            addAlert={props.addAlert}
            approveOneTimePays={props.approveOneTimePays}
            unapproveOneTimePays={props.unapproveOneTimePays}
            addOneTimePay={props.addOneTimePay}
            updateOneTimePay={props.updateOneTimePay}
            deleteOneTimePay={props.deleteOneTimePay}
          />
        )}
        {section === 'project-registration' && (
          <TimeRegistrationTab
            forFreelancer={true}
            mode={'Project'}
            subsection={props.subsection}
            company={props.company}
            employee={props.employee}
            contracts={props.contracts}
            timeRegistrations={props.timeRegistrations}
            salaryCycles={props.salaryCycles}
            salaryCycle={salaryCycle}
            projects={props.projects}
            costCenterAccounting={costCenterAccounting}
            costCenters={props.costCenters}
            departments={props.departments.departments}
            canEditObjects={canEditObjects}
            canHireFire={canHireFire}
            canApproveObjects={canApproveObjects}
            addAlert={props.addAlert}
            addContract={props.addContract}
            updateContract={props.updateContract}
            createTimeRegistration={props.createTimeRegistration}
            approveTimeRegistrations={props.approveTimeRegistrations}
            unapproveTimeRegistrations={props.unapproveTimeRegistrations}
            updateTimeRegistration={props.updateTimeRegistration}
            deleteTimeRegistration={props.deleteTimeRegistration}
            deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
          />
        )}
      </div>
      <div className="employees-single-side">
        <Sidebar
          section={props.section}
          subsection={props.subsection}
          employee={props.employee}
          employeeEmergencyContact={props.employeeEmergencyContacts.employeeEmergencyContact}
          employees={props.employees}
          paySlips={props.paySlips}
          employeeInvites={props.employeeInvites}
          employeeProfileImages={props.employeeProfileImages}
          hasEmployeeEmergencyContactsFeature={props.hasEmployeeEmergencyContactsFeature}
          addAlert={props.addAlert}
          sendInvite={props.sendInvite}
          deleteEmployeeUser={props.deleteEmployeeUser}
          updateProfileImage={props.updateProfileImage}
          setDeleteVisibility={setDeleteVisibility}
          setTerminateVisibility={setTerminateVisibility}
          setRehireVisibility={setRehireVisibility}
        />
      </div>

      <Modal
        key={`delete-${modalKey}`}
        visible={showDelete}
        onOk={() => setDeleteVisibility(false)}
        onCancel={() => setDeleteVisibility(false)}
        width={572}
        footer={null}
      >
        <DeleteEmployee
          addAlert={props.addAlert}
          visible={showDelete}
          employee={props.employee}
          employees={props.employees}
          deleteEmployee={props.deleteEmployee}
          closeModal={() => setDeleteVisibility(false)}
        />
      </Modal>

      <Modal
        key={`terminate-${modalKey}`}
        visible={showTerminate}
        onOk={() => setTerminateVisibility(false)}
        onCancel={() => setTerminateVisibility(false)}
        width={572}
        footer={null}
      >
        <TerminateEmployee
          visible={showTerminate}
          employeeID={employee.id}
          employees={props.employees}
          employments={props.employments}
          getEmployments={props.getEmployments}
          terminateEmployment={props.terminateEmployment}
          addAlert={props.addAlert}
          closeModal={() => setTerminateVisibility(false)}
        />
      </Modal>

      <Modal
        key={`rehire-${modalKey}`}
        visible={showRehire}
        onOk={() => setRehireVisibility(false)}
        onCancel={() => setRehireVisibility(false)}
        width={572}
        footer={null}
      >
        <RehireEmployee
          visible={showRehire}
          employee={employee}
          employments={props.employments}
          addAlert={props.addAlert}
          rehireEmployee={props.rehireEmployee}
          closeModal={() => setRehireVisibility(false)}
        />
      </Modal>
    </div>
  )
}
