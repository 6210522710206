import { isSameDay } from 'date-fns'

import VacationCalendar from '../../../model/vacationCalendar'
import { buildDate, getDate } from '../../../utils/date-utils'
import { t } from '../../../utils/translation-utils'
import { formatVacationDay } from '../../../utils/vacation-utils'

export type CurrentMonth = {
  year: number
  month: number
}

export function getToday(): CurrentMonth {
  return {
    year: getDate().getFullYear(),
    month: getDate().getMonth(),
  }
}

export function moveMonth(current: CurrentMonth, dir: number): CurrentMonth {
  const result = current.month + dir
  if (result < 0) {
    return {
      year: current.year - 1,
      month: 11,
    }
  } else if (result > 11) {
    return {
      year: current.year + 1,
      month: 0,
    }
  } else {
    return { ...current, month: current.month + dir }
  }
}

export type HolidayStorage = {
  holidays: Record<string, boolean>
  holidayNames: Record<string, string>
}

export function initialiseHolidayStorage(): HolidayStorage {
  return {
    holidays: {},
    holidayNames: {},
  }
}

export function dayKey(date: Date): string {
  return `${date.getFullYear()}${date.getMonth()}${date.getDate()}`
}

export function isDayHoliday(
  hC: HolidayStorage,
  setHolidays: (f: (prev: HolidayStorage) => HolidayStorage) => void,
  currentVacationCalendar: VacationCalendar | undefined,
  checkDate: Date
): boolean {
  if (!currentVacationCalendar || !currentVacationCalendar.days) {
    return false
  }
  const key = dayKey(checkDate)
  const holidays = hC.holidays
  let isHoliday = holidays[key]
  if (isHoliday !== undefined) {
    return isHoliday
  }
  const dayOfWeek = checkDate.getDay()
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    holidays[key] = true
    return true
  }
  isHoliday = currentVacationCalendar.days.some((date) => date.date && isSameDay(getDate(date.date), checkDate))
  holidays[key] = isHoliday
  setHolidays((prev) => ({ ...prev, holidays: holidays }))
  return isHoliday
}

export function getHolidayName(
  hC: HolidayStorage,
  setHolidays: (f: (prev: HolidayStorage) => HolidayStorage) => void,
  currentVacationCalendar: VacationCalendar | undefined,
  checkDate: Date
): string {
  if (!currentVacationCalendar || !currentVacationCalendar.days) {
    return ''
  }
  const key = dayKey(checkDate)
  const holidayNames = hC.holidayNames
  let holidayName = holidayNames[key]
  if (holidayName !== undefined) {
    return holidayName
  }
  holidayName = ''
  const holiday = currentVacationCalendar.days.find((date) => date.date && isSameDay(getDate(date.date), checkDate))
  if (holiday) {
    holidayName = formatVacationDay(holiday)
  }
  holidayNames[key] = holidayName
  setHolidays((prev) => ({ ...prev, holidayNames: holidayNames }))
  return holidayName
}

export function shortHandWeekdayName(current: CurrentMonth, day: number): string {
  const weekDay = buildDate(current.year, current.month, day).getDay()
  switch (weekDay) {
    case 1:
      return t('calendar.week.short.monday')
    case 2:
      return t('calendar.week.short.tuesday')
    case 3:
      return t('calendar.week.short.wednesday')
    case 4:
      return t('calendar.week.short.thursday')
    case 5:
      return t('calendar.week.short.friday')
    case 6:
      return t('calendar.week.short.saturday')
    case 0:
      return t('calendar.week.short.sunday')
    default:
      return ''
  }
}
