import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company, { CompanyMutableFields } from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyReducer } from '../../reducers/companies'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { createSynchroniser, executeSynchroniser } from '../../utils/sync-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import AdvancedSettingsForm, { AdvancedResult } from './AdvancedSettingsForm'

type Props = {
  alerts: AlertReducer
  company: Company
  companies: CompanyReducer
  companyFeatures: List<CompanyFeature>

  addAlert: addAlertSignature
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
  updateCompany: (company: CompanyMutableFields) => Promise<Company | void>
}

export default function AdvancedSettingsTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { company, companies, addAlert } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        addAlert('success', t('advanced_setting.alert.success', { name: company.name }), { timeout: 5 })
        window.scrollTo(0, 0)
      }
    }
  }, [previousCompanies, company, companies, addAlert])

  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const handleSubmit = (values: AdvancedResult) => {
    const actions = createSynchroniser<Company>()
    if (values.disable.length > 0) {
      actions.push(() => props.disableCompanySettings(props.company.id, values.disable))
    }
    if (values.enable.length > 0) {
      actions.push(() => props.enableCompanySettings(props.company.id, values.enable))
    }
    if (props.company.workHourLunchLimit !== values.workHourLunchLimit) {
      actions.push(() => {
        const company = props.company
        company.workHourLunchLimit = values.workHourLunchLimit ? values.workHourLunchLimit : 0
        return props.updateCompany(company)
      })
    }
    executeSynchroniser(actions)
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <Card>
        <TitleMenu>
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id}>
            <Button>{t('advanced_setting.header.back')}</Button>
          </Link>
        </TitleMenu>
        <Title>{t('advanced_setting.header.title')}</Title>
        <p>{t('advanced_setting.introduction.paragraph_1')}</p>
        <p>
          {tx('advanced_setting.introduction.paragraph_2', {
            link: <a href="mailto:support@salary.dk">{t('advanced_setting.introduction.paragraph_2_link')}</a>,
          })}
        </p>
        <AdvancedSettingsForm
          settingsEnabled={props.company.settingsEnabled}
          company={props.company}
          featureTypes={props.companyFeatures.map((feature) => feature.featureType).toArray()}
          isCompanyGroup={false}
          onSubmit={handleSubmit}
        />
      </Card>
    </div>
  )
}
