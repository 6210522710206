import { List } from 'immutable'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { CompanyLogoCreate } from '../../api/company-logos'
import Company, { CompanyMutableFields } from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import { AgreementReducer } from '../../reducers/agreements'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyLogoReducer } from '../../reducers/companyLogo'
import { EmployeeReducer } from '../../reducers/employees'
import { SalaryCycleReducer } from '../../reducers/salaryCycles'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { getMainMenuCompanyMenuCompany, MenuContext } from '../../utils/menu-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CompanyForm, { ResultFields } from './CompanyForm'
import PaySlipFieldsModal from './PaySlipFieldsModal'

type Props = {
  user: UserReducer
  company: Company
  companies: CompanyReducer
  employees: EmployeeReducer
  agreements: AgreementReducer
  companyFeatures: List<CompanyFeature>
  salaryCycles: SalaryCycleReducer
  companyLogo: CompanyLogoReducer

  addAlert: addAlertSignature
  updateCompany: (company: CompanyMutableFields) => Promise<Company | void>
  getSalaryCycles: () => void
  getCompanyLogo: () => void
  updateCompanyLogo: (image: CompanyLogoCreate) => void
  deleteCompanyLogo: () => void
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
}

export default function CompanyTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [showPaySlipFields, setShowPaySlipFields] = useState(false)

  const { menu } = useContext(MenuContext)

  const { salaryCycles, getSalaryCycles, companyLogo, getCompanyLogo } = props
  useEffect(() => {
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
    if (!companyLogo.loading && !companyLogo.loaded) {
      getCompanyLogo()
    }
  }, [salaryCycles, getSalaryCycles, companyLogo, getCompanyLogo])

  const { companies, addAlert, company } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        addAlert('success', t('company.edit.alert.success', { name: company.name }), { timeout: 5 })
      }
    }

    regularComponentDidUpdate(companies.error, error, setError)
  }, [previousCompanies, companies, addAlert, error, setError, company])

  const handleSubmit = (values: ResultFields) => {
    const company = { ...props.company, ...values }
    props.updateCompany(company).then(() => {
      let enable: CompanySetting[] = []
      const disable: CompanySetting[] = []
      if (values.paySlipTransportDefaultEBoks) {
        enable = ['PaySlipTransportDefaultEBoks']
      } else {
        disable.push('PaySlipTransportDefaultEBoks')
      }
      if (values.paySlipTransportDefaultMitDK) {
        enable = ['PaySlipTransportDefaultMitDK']
      } else {
        disable.push('PaySlipTransportDefaultMitDK')
      }
      if (values.paySlipTransportDefaultEMail) {
        enable = ['PaySlipTransportDefaultEMail']
      } else {
        disable.push('PaySlipTransportDefaultEMail')
      }
      if (values.autoApproveTimeRegistrationHours) {
        enable.push('AutoApproveTimeRegistrationHours')
      } else {
        disable.push('AutoApproveTimeRegistrationHours')
      }
      if (values.autoApproveTimeRegistrationLeave) {
        enable.push('AutoApproveTimeRegistrationLeave')
      } else {
        disable.push('AutoApproveTimeRegistrationLeave')
      }
      if (values.autoApproveCarAllowances) {
        enable.push('AutoApproveCarAllowances')
      } else {
        disable.push('AutoApproveCarAllowances')
      }
      if (values.autoApproveReimbursementVouchers) {
        enable.push('AutoApproveReimbursementVouchers')
      } else {
        disable.push('AutoApproveReimbursementVouchers')
      }
      if (values.autoApproveCompensations) {
        enable.push('AutoApproveCompensations')
      } else {
        disable.push('AutoApproveCompensations')
      }
      if (values.autoApproveSalaryRegistrations) {
        enable.push('AutoApproveSalaryRegistrations')
      } else {
        disable.push('AutoApproveSalaryRegistrations')
      }
      props.disableCompanySettings(company.id, disable).then(() => {
        if (enable.length > 0) {
          props.enableCompanySettings(company.id, enable)
        }
      })
    })
  }

  if (!props.salaryCycles.loaded || !props.companyLogo.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <TitleMenu>
        {!props.user.user?.uiSettings.sideMenu &&
          getMainMenuCompanyMenuCompany(menu)
            .reverse()
            .map((item) => {
              if (item.type !== 'item-label') {
                return null
              }
              return (
                <Link to={item.link}>
                  <Button>
                    {item.icon} {t(item.labelID)}
                  </Button>
                </Link>
              )
            })}
        <DumbLink
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            setShowPaySlipFields(true)
          }}
        >
          <Button>{t('company.edit.header.pay_slip_fields')}</Button>
        </DumbLink>
      </TitleMenu>
      <Title>{t('company.edit.header.title')}</Title>

      <div className="companies-single-form">
        <p>&nbsp;</p>
        {error && <Alert message={formatError(error)} type="error" showIcon />}
        <CompanyForm
          company={props.company}
          companies={props.companies}
          companyFeatures={props.companyFeatures}
          salaryCycles={props.salaryCycles.salaryCycles}
          onSubmit={handleSubmit}
        />
      </div>

      <Modal
        visible={showPaySlipFields}
        onOk={() => setShowPaySlipFields(false)}
        onCancel={() => setShowPaySlipFields(false)}
        width={582}
        footer={null}
      >
        <PaySlipFieldsModal
          visible={showPaySlipFields}
          company={props.company}
          companies={props.companies}
          companyLogo={props.companyLogo}
          addAlert={props.addAlert}
          updateCompanyLogo={props.updateCompanyLogo}
          deleteCompanyLogo={props.deleteCompanyLogo}
          closeModal={() => setShowPaySlipFields(false)}
          updateCompany={props.updateCompany}
        />
      </Modal>
    </Card>
  )
}
