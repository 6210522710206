import { browserHistory } from 'react-router'

// TODO rename this to no longer be jsBrowserHistory

const jsBrowserHistory = {
  push: (p: string | { pathname: string; search: string }) => browserHistory.push(p),
  length: browserHistory.length,
  goBack: () => browserHistory.goBack(),
}

export default jsBrowserHistory
