import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { formatDate } from '../../../utils/date-utils'
import { formatCurrency, formatDisplayNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import PayCheckAdvanceEdit from './PayCheckAdvanceEdit'

type Props = {
  subsection?: string
  employee: Employee
  isFreelancer: boolean
  company: Company
  canEditObjects: boolean
  canApproveObjects: boolean
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  costCenters: List<CostCenter>
  costCenterAccounting: CostCenterAccounting

  addAlert: addAlertSignature
  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  deleteOneTimePay: (id: string) => void
}

export default function PayCheckAdvanceTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [mutable, setMutable] = useState(false)

  const setEditVisibility = (id: string | boolean, mutable = true) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setMutable(mutable)
  }

  const { oneTimePays } = props
  const previousOneTimePays = usePrevious(oneTimePays)

  useEffect(() => {
    // Check for save callback
    if (previousOneTimePays && previousOneTimePays.saving && !oneTimePays.saving) {
      // Check for no error occurred
      if (!oneTimePays.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const showHistory = () => {
    return props.subsection === 'history'
  }

  const approve = (oneTimePayID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, oneTimePayID])
      props.approveOneTimePays([oneTimePayID])
    }
  }
  const unapprove = (oneTimePayID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== oneTimePayID))
      props.unapproveOneTimePays([oneTimePayID])
    }
  }
  const remove = (oneTimePayID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, oneTimePayID])
        props.deleteOneTimePay(oneTimePayID)
      }
    }
  }

  type OneTimePayRow = {
    key: string
    id: string
    date: string
    title: string
    amount: string
    rate?: string
    units?: string
    approved: boolean
    immutable: boolean
  }

  const columns = [
    {
      title: t('pay_check_advance.table.header.approved'),
      key: 'x1',
      className: 'one-time-pay-table-approved',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.approved) {
          return (
            <span>
              {t('pay_check_advance.table.approved.true')}
              {!oneTimePay.immutable && props.canApproveObjects && (
                <Tooltip title={t('pay_check_advance.table.approved.remove')}>
                  <span onClick={unapprove(oneTimePay.id)} style={{ cursor: 'pointer' }}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(oneTimePay.id) !== -1) {
          return t('pay_check_advance.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('pay_check_advance.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(oneTimePay.id)} type="standard">
            {t('pay_check_advance.table.approved.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('pay_check_advance.table.header.date'), dataIndex: 'date', key: 'date' },
    { title: t('pay_check_advance.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: t('pay_check_advance.table.header.amount'),
      key: 'xAmount',
      render: (oneTimePay: OneTimePayRow) => {
        if (!oneTimePay.rate) {
          return oneTimePay.amount
        }
        return (
          <>
            {oneTimePay.amount}
            <br />
            <small>
              ({t('pay_check_advance.table.amount_format', { units: oneTimePay.units, rate: oneTimePay.rate })})
            </small>
          </>
        )
      },
    },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (oneTimePay: OneTimePayRow) => {
        if (showHistory()) {
          return null
        }
        if (deleting.indexOf(oneTimePay.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip
              title={
                oneTimePay.immutable
                  ? t('pay_check_advance.table.actions.view')
                  : t('pay_check_advance.table.actions.edit')
              }
            >
              <span
                onClick={() => setEditVisibility(oneTimePay.id, !oneTimePay.immutable)}
                style={{ cursor: 'pointer' }}
              >
                <Icon type={oneTimePay.immutable ? 'search' : 'edit'} color="grey" />
              </span>
            </Tooltip>
            {!oneTimePay.immutable && (
              <Tooltip title={t('pay_check_advance.table.actions.delete')}>
                <span onClick={remove(oneTimePay.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getPayCheckAdvances = (): OneTimePayRow[] => {
    return props.oneTimePays.oneTimePays
      .filter((oneTimePay) => oneTimePay.type === 'Pay Check Advanced')
      .filter((oneTimePay) => (showHistory() ? oneTimePay.settled : !oneTimePay.settled))
      .map((oneTimePay) => {
        let title = oneTimePay.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }
        return {
          key: oneTimePay.id,
          id: oneTimePay.id,
          date: formatDate(oneTimePay.dispositionDate),
          amount: formatCurrency(oneTimePay.amount, 2),
          rate: oneTimePay.rate ? formatCurrency(oneTimePay.rate, 2) : undefined,
          units: oneTimePay.units ? formatDisplayNumber(oneTimePay.units) : undefined,
          title,
          immutable: oneTimePay.immutable,
          approved: oneTimePay.approved,
          original: oneTimePay,
        }
      })
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const oneTimePays = getPayCheckAdvances().filter((reg) => !reg.approved)
      setApproving(oneTimePays.map((reg) => reg.id))
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  const hasUnapprovedPayCheckAdvances = () => {
    return getPayCheckAdvances().some((otp) => !otp.approved)
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.salaryCycle) {
    return null
  }

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {!showHistory() && props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20 }}>
            <Icon type="user" color="grey" />
            {t('pay_check_advance.header.add_pay_check_advance')}
          </Button>
        )}
        {showHistory() ? (
          <Link
            to={
              '/' +
              (props.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) +
              '/' +
              props.employee.id +
              '/pay-check-advance'
            }
          >
            <Button className="gtm-hide-pay-check-advance-history" style={{ paddingRight: 14 }}>
              {t('pay_check_advance.header.hide_history')}
            </Button>
          </Link>
        ) : (
          <Link
            to={
              '/' +
              (props.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) +
              '/' +
              props.employee.id +
              '/pay-check-advance/history'
            }
          >
            <Button className="gtm-show-pay-check-advance-history" style={{ paddingRight: 14 }}>
              {t('pay_check_advance.header.show_history')}
            </Button>
          </Link>
        )}

        {hasUnapprovedPayCheckAdvances() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('pay_check_advance.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>{showHistory() ? t('pay_check_advance.title.history') : t('pay_check_advance.title.standard')}</Title>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getPayCheckAdvances()} size="small" pagination={false} />
      {props.oneTimePays.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <PayCheckAdvanceEdit
          visible={editing !== false}
          editing={mutable}
          canApproveObjects={props.canApproveObjects}
          employee={props.employee}
          company={props.company}
          oneTimePayID={typeof editing === 'string' ? editing : undefined}
          oneTimePays={props.oneTimePays}
          salaryCycle={props.salaryCycle}
          costCenters={props.costCenters}
          costCenterAccounting={props.costCenterAccounting}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>
    </Card>
  )
}
