import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import LeaveType from '../../model/leaveType'
import { LeaveTypeReducer } from '../../reducers/leaveTypes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { formatLeaveSubType, formatLeaveTypeName } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  leaveTypes: LeaveTypeReducer

  addAlert: addAlertSignature
  updateLeaveType: (leaveTypeID: string, selectable: boolean) => void
  updateLeaveSubTypes: (leaveTypeID: string, selectableLeaveSubTypeIDs: string[]) => Promise<LeaveType | void>
}

export default function LeaveTypeSettingsTab(props: Props): ReactElement | null {
  const [changeLeaveTypeID, setChangeLeaveTypeID] = useState<string>()
  const [selectableLeaveSubTypes, setSelectableLeaveSubTypes] = useState<Record<string, string[]>>(() => {
    return props.leaveTypes.leaveTypes.reduce((rec: Record<string, string[]>, leaveType) => {
      if (leaveType.leaveSubTypes.length === 0) {
        return rec
      }
      rec[leaveType.id] = leaveType.leaveSubTypes
        .filter((subType) => subType.employeeSelectable)
        .map((subType) => subType.id)
      return rec
    }, {})
  })
  const [error, setError] = useState<Error | null>(null)

  const { leaveTypes, addAlert } = props
  const previousLeaveTypes = usePrevious(leaveTypes)

  useEffect(() => {
    if (previousLeaveTypes && previousLeaveTypes.saving && !leaveTypes.saving) {
      if (!leaveTypes.error) {
        if (changeLeaveTypeID) {
          const leaveType = leaveTypes.leaveTypes.find((type) => type.id === changeLeaveTypeID)
          if (leaveType) {
            addAlert('success', t('leave_type_setting.alert.success', { type: formatLeaveTypeName(leaveType.name) }), {
              timeout: 5,
            })
          }
        }
      }
    }
  }, [previousLeaveTypes, leaveTypes, addAlert, changeLeaveTypeID])

  useEffect(() => {
    regularComponentDidUpdate(leaveTypes.error, error, setError)
  }, [leaveTypes, error])

  const changeLeaveTypeEmployeeSelectable = (leaveTypeID: string, selectable: boolean) => {
    setChangeLeaveTypeID(leaveTypeID)
    props.updateLeaveType(leaveTypeID, selectable)
  }

  const changeLeaveSubTypeEmployeeSelectable = (leaveTypeID: string, leaveSubTypeID: string, selectable: boolean) => {
    setChangeLeaveTypeID(leaveTypeID)
    let ids = selectableLeaveSubTypes[leaveTypeID]
    if (selectable) {
      ids.push(leaveSubTypeID)
    } else {
      ids = ids.filter((id) => id !== leaveSubTypeID)
    }
    props.updateLeaveSubTypes(leaveTypeID, ids).then(() =>
      setSelectableLeaveSubTypes((prev) => {
        prev[leaveTypeID] = ids
        return prev
      })
    )
  }

  type LeaveTypeRow = {
    key: string
    id: string
    name: string
    canSelect: string
    employeeSelectable: boolean
  }

  const leaveTypeColumns = [
    { title: t('leave_type_setting.leave_type.table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('leave_type_setting.leave_type.table.header.can_select'), dataIndex: 'canSelect', key: 'canSelect' },
    {
      title: t('leave_type_setting.leave_type.table.header.configure'),
      key: 'x1',
      render: (leaveType: LeaveTypeRow) => {
        return (
          <DumbLink onClick={() => changeLeaveTypeEmployeeSelectable(leaveType.id, !leaveType.employeeSelectable)}>
            {leaveType.employeeSelectable
              ? t('leave_type_setting.leave_type.table.actions.restrict')
              : t('leave_type_setting.leave_type.table.actions.permit')}
          </DumbLink>
        )
      },
    },
  ]

  const getLeaveTypes = (): LeaveTypeRow[] => {
    return props.leaveTypes.leaveTypes
      .filter(
        (leaveType) =>
          !leaveType.assignable && leaveType.name !== 'DenmarkFlexTime' && leaveType.name !== 'DenmarkOvertime'
      )
      .map((leaveType): LeaveTypeRow => {
        return {
          key: leaveType.id,
          id: leaveType.id,
          name: formatLeaveTypeName(leaveType.name),
          canSelect: leaveType.employeeSelectable
            ? t('leave_type_setting.leave_type.table.can_select.true')
            : t('leave_type_setting.leave_type.table.can_select.false'),
          employeeSelectable: leaveType.employeeSelectable,
        }
      })
      .toArray()
  }

  type LeaveSubTypeRow = {
    key: string
    id: string
    leaveTypeID: string
    name: string
    parentName: string
    canSelect: string
    employeeSelectable: boolean
  }

  const leaveSubColumns = [
    { title: t('leave_type_setting.leave_sub_type.table.header.name'), dataIndex: 'name', key: 'name' },
    {
      title: t('leave_type_setting.leave_sub_type.table.header.parent_name'),
      dataIndex: 'parentName',
      key: 'parentName',
    },
    { title: t('leave_type_setting.leave_sub_type.table.header.can_select'), dataIndex: 'canSelect', key: 'canSelect' },
    {
      title: t('leave_type_setting.leave_sub_type.table.header.configure'),
      key: 'x1',
      render: (subType: LeaveSubTypeRow) => {
        return (
          <DumbLink
            onClick={() =>
              changeLeaveSubTypeEmployeeSelectable(subType.leaveTypeID, subType.id, !subType.employeeSelectable)
            }
          >
            {subType.employeeSelectable
              ? t('leave_type_setting.leave_sub_type.table.actions.restrict')
              : t('leave_type_setting.leave_sub_type.table.actions.permit')}
          </DumbLink>
        )
      },
    },
  ]

  const getLeaveSubTypes = (): LeaveSubTypeRow[] => {
    return props.leaveTypes.leaveTypes
      .filter((leaveType) => leaveType.leaveSubTypes.length > 0)
      .reduce((list: LeaveSubTypeRow[], leaveType) => {
        return [
          ...list,
          ...leaveType.leaveSubTypes.map((subType): LeaveSubTypeRow => {
            return {
              key: subType.id,
              id: subType.id,
              leaveTypeID: leaveType.id,
              name: formatLeaveSubType(subType.name),
              parentName: formatLeaveTypeName(leaveType.name),
              canSelect: subType.employeeSelectable
                ? t('leave_type_setting.leave_sub_type.table.can_select.true')
                : t('leave_type_setting.leave_sub_type.table.can_select.false'),
              employeeSelectable: subType.employeeSelectable,
            }
          }),
        ]
      }, [])
      .sort((a, b) => {
        if (a.leaveTypeID === b.leaveTypeID) {
          return a.name.localeCompare(b.name)
        }
        return a.parentName.localeCompare(b.parentName)
      })
  }

  if (!props.company.settingsEnabled.some((setting) => setting === 'AllowLeaveTypeEmployeeSelectable')) {
    jsBrowserHistory.push('/' + paths.COMPANIES + '/' + props.company.id)
    return null
  }

  return (
    <Card>
      {props.leaveTypes.saving && <LoadingOverlay />}
      <TitleMenu>
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/advance-settings'}>
          <Button>{t('leave_type_setting.tab.header.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('leave_type_setting.tab.title')}</Title>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <p>{t('leave_type_setting.tab.type_intro')}</p>

      <Table columns={leaveTypeColumns} dataSource={getLeaveTypes()} size="small" pagination={false} />

      <p style={{ marginTop: '50px' }}>{t('leave_type_setting.tab.sub_type_intro')}</p>

      <Table columns={leaveSubColumns} dataSource={getLeaveSubTypes()} size="small" pagination={false} />
    </Card>
  )
}
