import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import Company from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import CompanyUser from '../../../model/companyUser'
import { DocumentCreationFields, DocumentMutableFields } from '../../../model/document'
import Employee from '../../../model/employee'
import { DateTimeFormat } from '../../../model/types'
import { DocumentCategoryReducer } from '../../../reducers/documentCategories'
import { DocumentReducer } from '../../../reducers/documents'
import { FileChangeEvent, HandleFileEventError } from '../../../utils/antd-utils'
import { getAccessToken } from '../../../utils/cookie-utils'
import { formatDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatDocumentSignatureType } from '../../../utils/format-utils'
import { isDepartmentRestricted } from '../../../utils/permissions-utils'
import { RequestError, secureUrl, url } from '../../../utils/request-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import UploadDragger from '../../antd/upload/Dragger'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import DocumentEdit from './DocumentEdit'

type Props = {
  company: Company
  companyUser?: CompanyUser
  companyUsers: List<CompanyUser>
  employee: Employee
  companyFeatures: List<CompanyFeature>
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer

  addAlert: addAlertSignature
  getDocuments: () => void
  addDocument: (companyID: string, document: DocumentCreationFields) => void
  updateDocument: (companyID: string, document: DocumentMutableFields) => void
  deleteDocument: (documentID: string) => void
  getDocumentCategories: () => void
}

export default function DocumentsTab(props: Props): ReactElement | null {
  type State = {
    modalKey: number
    editing: string | boolean
    fileID?: string
  }
  const [deleting, setDeleting] = useState<string[]>([])
  const [state, setState] = useState<State>({ modalKey: 1, editing: false })
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<RequestError>()

  const { documents, documentCategories, getDocuments, getDocumentCategories } = props

  useEffect(() => {
    if (!documents.loading && !documents.loaded) {
      getDocuments()
    }
    if (!documentCategories.loading && !documentCategories.loaded) {
      getDocumentCategories()
    }
  }, [documents, documentCategories, getDocuments, getDocumentCategories])

  const setEditVisibility = (id: string | boolean, fileID?: string) => {
    setState((prev) => ({
      modalKey: prev.modalKey + 1,
      editing: id,
      fileID: fileID,
    }))
  }

  const previousDocuments = usePrevious(documents)
  useEffect(() => {
    if (previousDocuments && previousDocuments.saving && !documents.saving) {
      if (!documents.error) {
        setEditVisibility(false)
      }
    }
  })

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setError(undefined)
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        setEditVisibility(true, e.file.response.data?.id)
        break
      case 'error': {
        setUploading(false)
        setError(HandleFileEventError(e.file.response))
        break
      }
      default:
        break
    }
  }

  const archive = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      const document = props.documents.documents.find((document) => document.id === id)
      if (document) {
        if (!document.archived) {
          if (window.confirm(t('document_tab.confirm.archive'))) {
            props.updateDocument(props.company.id, { ...document, archived: true })
          }
        } else {
          props.updateDocument(props.company.id, { ...document, archived: false })
        }
      }
    }
  }

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('document_tab.confirm.remove'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteDocument(id)
      }
    }
  }

  type DocumentRow = {
    key: string
    id: string
    category: string
    filename: string
    name: string
    createdBy: string
    createdAt: string
    visibleForEmployee: string
    archived: string
    isArchived: boolean
    signed: boolean
    signedAt?: string
    signedWith: string
  }

  const columns = [
    { title: t('document_tab.table.header.category'), dataIndex: 'category', key: 'category' },
    {
      title: t('document_tab.table.header.filename'),
      key: 'x1',
      render: (document: DocumentRow) => {
        return (
          <div>
            <Tooltip title={document.filename}>
              <span className="table-overflow-ellipsis">{document.filename}</span>
            </Tooltip>
            {document.signed && (
              <Tooltip
                title={
                  document.signedAt
                    ? t('document_tab.table.document_signed_at', { date: document.signedAt })
                    : t('document_tab.table.document_signed')
                }
              >
                <span className="document-signed-note">
                  <Icon type="signed" style={{ position: 'relative', top: '5px' }} />
                </span>
              </Tooltip>
            )}
            <small className="small-note">{t('document_tab.table.created_at', { date: document.createdAt })}</small>
          </div>
        )
      },
    },
    { title: t('document_tab.table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('document_tab.table.header.created_by'), dataIndex: 'createdBy', key: 'createdBy' },
    {
      title: t('document_tab.table.header.visible_for_employee'),
      dataIndex: 'visibleForEmployee',
      key: 'visibleForEmployee',
    },
    { title: t('document_tab.table.header.archived'), dataIndex: 'archived', key: 'archived' },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (document: DocumentRow) => {
        if (deleting.indexOf(document.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('document_tab.table.actions.download')}>
              <a
                href={secureUrl('v2/documentDownload/' + document.id + '?disposition=attachment')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="download" color="lightgrey" />
              </a>
            </Tooltip>
            <Tooltip title={t('document_tab.table.actions.edit')}>
              <span onClick={() => setEditVisibility(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="edit" color="lightgrey" />
              </span>
            </Tooltip>
            <Tooltip
              title={
                document.isArchived ? t('document_tab.table.actions.refresh') : t('document_tab.table.actions.archive')
              }
            >
              <span onClick={archive(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type={document.isArchived ? 'refresh' : 'archive'} color="lightgrey" />
              </span>
            </Tooltip>
            <Tooltip title={t('document_tab.table.actions.delete')}>
              <span onClick={remove(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="cross" color="grey" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getDocumentRows = (): DocumentRow[] => {
    return props.documents.documents
      .filter((document) => document.employeeID === props.employee.id)
      .map((document): DocumentRow => {
        let category = t('common.unknown')
        const documentCategory = props.documentCategories.documentCategories.find(
          (documentCategory) => documentCategory.id === document.documentCategoryID
        )
        if (documentCategory) {
          category = documentCategory.name
        }
        let creator = props.companyUsers.find((user) => user.userID === document.userID)?.name
        if (!creator && document.userID) {
          // TODO Fix this to actually check against a list of employee users
          creator = props.employee.name
        }
        const signedAt = document.signers.reduce((date: DateTimeFormat | undefined, signer) => {
          if (signer.state !== 'Signed') {
            return date
          }
          if (!date) {
            return signer.signedAt
          }
          if (!signer.signedAt) {
            return date
          }
          if (date.localeCompare(signer.signedAt) < 0) {
            return signer.signedAt
          }
          return date
        }, undefined)
        return {
          key: document.id,
          id: document.id,
          category,
          filename: document.filename,
          name: document.name,
          createdBy: creator ?? t('document_tab.table.created_by_system'),
          createdAt: formatDate(document.createdAt),
          visibleForEmployee: document.visibleForEmployee
            ? t('document_tab.table.visible_for_employee.true')
            : t('document_tab.table.visible_for_employee.false'),
          archived: document.archived ? t('document_tab.table.archived.true') : t('document_tab.table.archived.false'),
          isArchived: document.archived,
          signed: !!signedAt,
          signedAt: signedAt ? formatDate(signedAt) : undefined,
          signedWith: formatDocumentSignatureType(document.signatureType),
        }
      })
      .toArray()
  }

  const hasDocumentStoreFeature = props.companyFeatures.some((feature) => feature.featureType === 'Document Store')

  return (
    <Card className="employees-single-form">
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <TitleMenu>
        {!isDepartmentRestricted(props.companyUser) && (
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/document-categories'}>
            <Button>{t('document_tab.header.document_categories')}</Button>
          </Link>
        )}
        {hasDocumentStoreFeature && (
          <div style={{ float: 'right', marginLeft: '20px' }}>
            <UploadDragger
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ authorization: getAccessToken() }}
              showUploadList={false}
              onChange={handleFileUpload}
            >
              <Button type="primary" className="ant-btn-primary gtm-upload-document">
                <Icon type="file" color="white" />
                {t('document_tab.header.add_document')}
              </Button>
            </UploadDragger>
          </div>
        )}
      </TitleMenu>
      <Title>{t('document_tab.header.title')}</Title>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getDocumentRows()} size="small" pagination={false} />
      {(props.documents.saving || uploading) && <LoadingOverlay />}

      <Modal
        key={state.modalKey}
        visible={state.editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <DocumentEdit
          visible={state.editing !== false}
          company={props.company}
          employee={props.employee}
          documentID={typeof state.editing === 'string' ? state.editing : undefined}
          fileID={state.fileID}
          documents={props.documents}
          documentCategories={props.documentCategories.documentCategories}
          addDocument={props.addDocument}
          updateDocument={props.updateDocument}
        />
      </Modal>
    </Card>
  )
}
