import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import { postSigningDocumentCallback } from '../../api/documents'
import { postVerifyPersonCallback } from '../../api/person-verification'
import { postVerifyUserCallback } from '../../api/user-verification'
import Alert from '../../components/elements/alert'
import DumbLink from '../../components/widgets/DumbLink'
import jsBrowserHistory from '../../components/widgets/jsBrowserHistory'
import paths from '../../constants/paths'
import { isRequestError } from '../../utils/error-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RouteProps } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

function CriiptoSigned(props: RouteProps): ReactElement | null {
  type State = {
    type: string
    token?: string
    error?: string
    errorDescription?: string
    onError: ReactElement
    onPostVerification: () => void
  }

  const [error, setError] = useState<string | null>(null)

  const [state] = useState<State | null>(() => {
    const state = props.location.query.state
    if (!state) {
      return null
    }
    const s = state.split('_')
    if (s.length === 0) {
      // Not valid if there are no parameters
      return null
    }
    const token = props.location.query.code
    const error = props.location.query.error
    const errorDescription = props.location.query.error_description

    switch (s[0]) {
      case paths.USER_VERIFICATION:
        if (s.length !== 1) {
          return null
        }
        return {
          type: s[0],
          token: token || undefined,
          error: error || undefined,
          errorDescription: errorDescription || undefined,
          onPostVerification: () => {
            postVerifyUserCallback(token)
              .then(() => {
                // force a refresh, so that bootstrap is called
                document.location = '/' + paths.ACCOUNT + '/mitid'
              })
              .catch((e) => {
                if (isRequestError(e)) {
                  setError(t('signing_document.error.error_during_callback'))
                }
              })
          },
          onError: (
            <>
              <Alert type={'error'} message={error} showIcon />
              {state && (
                <p>
                  <DumbLink
                    onClick={() => {
                      // again, force a refresh
                      document.location = '/' + paths.ACCOUNT + '/mitid'
                    }}
                  >
                    {t('signing_document.restart_flow')}
                  </DumbLink>
                </p>
              )}
            </>
          ),
        }
      case paths.PERSON_VERIFICATION:
        if (s.length !== 1) {
          return null
        }
        return {
          type: s[0],
          token: token || undefined,
          error: error || undefined,
          errorDescription: errorDescription || undefined,
          onPostVerification: () => {
            postVerifyPersonCallback(token)
              .then(() => {
                jsBrowserHistory.push('/' + paths.PERSON_VERIFICATION + '?signed=true')
              })
              .catch((e) => {
                if (isRequestError(e)) {
                  setError(t('signing_document.error.error_during_callback'))
                }
              })
          },
          onError: (
            <>
              <Alert type={'error'} message={error} showIcon />
              {state && (
                <p>
                  <Link to={'/' + paths.PERSON_VERIFICATION}>{t('signing_document.restart_flow')}</Link>
                </p>
              )}
            </>
          ),
        }
      case 'document-signing': {
        if (s.length !== 4) {
          return null
        }
        const documentID = s[1]
        const signID = s[2]
        const key = s[3]
        return {
          type: s[0],
          token: token || undefined,
          error: error || undefined,
          errorDescription: errorDescription || undefined,
          onPostVerification: () => {
            postSigningDocumentCallback(documentID, signID, key, token)
              .then((res) => {
                if (!res.data) {
                  setError(t('signing_document.error.error_during_callback'))
                  return
                }
                jsBrowserHistory.push(
                  '/' + paths.DOCUMENT_SIGNING_PHONE_SITE + `/${documentID}?signID=${signID}&key=${key}`
                )
              })
              .catch((e) => {
                if (isRequestError(e)) {
                  setError(t('signing_document.error.error_during_callback'))
                }
              })
          },
          onError: (
            <>
              <Alert type={'error'} message={error} showIcon />
              {state && (
                <p>
                  <Link to={'/' + paths.DOCUMENT_SIGNING_PHONE_SITE + `/${documentID}?signID=${signID}&key=${key}`}>
                    {t('signing_document.restart_flow')}
                  </Link>
                </p>
              )}
            </>
          ),
        }
      }
    }

    return null
  })

  useEffectOnce(() => {
    if (!state) {
      setError(t('signing_document.error.missing_callback_parameters'))
      return
    }
    if (!state.token) {
      switch (state.errorDescription) {
        case 'User%20cancelled':
        case 'User cancelled':
          setError(t('signing_document.error.user_cancelled'))
          return
        default:
          setError(t('signing_document.error.unknown_error', { error: state.errorDescription || state.error || '' }))
          return
      }
    }
    state.onPostVerification()
  })

  if (error) {
    if (state?.onError) {
      return state.onError
    } else {
      return null
    }
  }

  return null
}

export default connectToReducer<object, any, RouteProps>((_state): Record<string, never> => ({}), {})(CriiptoSigned)
