import React, { ReactElement, useEffect } from 'react'

import { getAccountingDimensions } from '../actions/accounting-dimensions'
import { getCompanyAccountPlans } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { addApiKey, deleteApiKey, getApiKeys } from '../actions/api-keys'
import {
  createAssetCategory,
  deleteAssetCategory,
  getAssetCategories,
  updateAssetCategory,
} from '../actions/asset-categories'
import { getAsynchronousTasks, startExcelEmployeesData, storeStagedImportData } from '../actions/asynchronous-tasks'
import {
  disableCompanySettings,
  enableCompanySettings,
  getNetsMessages,
  toggleNemKontoAllEmployees,
  updateActiveCompany,
  updateCompany,
} from '../actions/companies'
import {
  addCompanyDeviationConfiguration,
  deleteCompanyDeviationConfiguration,
  getCompanyDeviationConfigurations,
  updateCompanyDeviationConfiguration,
} from '../actions/company-deviation-configurations'
import { deleteCompanyLogo, getCompanyLogo, updateCompanyLogo } from '../actions/company-logos'
import { getCompanyPaymentIntegrations } from '../actions/company-payment-integrations'
import {
  activateCompanyPricingPackageCode,
  addCompanyPricingPackage,
  deleteCompanyPricingPackage,
  getCompanyPricingPackages,
} from '../actions/company-pricing-packages'
import { getCompanyUsers } from '../actions/company-users'
import { addDepartment, deleteDepartment, getDepartments, updateDepartment } from '../actions/departments'
import { addDocumentCategory, getDocumentCategories, updateDocumentCategory } from '../actions/document-categories'
import { updateEmployee } from '../actions/employees'
import {
  addExpenseCategory,
  deleteExpenseCategory,
  getExpenseCategories,
  updateExpenseCategory,
} from '../actions/expense-categories'
import { getExternalSalaryFileSettings, saveExternalSalaryFileSettings } from '../actions/external-salary-file-settings'
import { getInvoices } from '../actions/invoices'
import { updateLeaveSubTypes, updateLeaveType } from '../actions/leave-types'
import { getPayRolls } from '../actions/pay-rolls'
import {
  addCompanyBankAccount,
  addStripeConfiguration,
  getPaymentConfigurations,
  getStripeConfiguration,
  updatePaymentConfiguration,
  updateStripeConfiguration,
} from '../actions/payment-configurations'
import { getPricingPackages } from '../actions/pricing-packages'
import { getSalaryCycles } from '../actions/salary-cycles'
import { addSalaryType, deleteSalaryType, reactivateSalaryType, updateSalaryType } from '../actions/salary-types'
import { grantSupportAccess, loadSupportAccess, revokeSupportAccess } from '../actions/support-access'
import { getTaxCards } from '../actions/tax-cards'
import {
  approveTransfer,
  automateTransfer,
  declineTransfer,
  getTransfers,
  tryTransferAgain,
  updateTransferDate,
} from '../actions/transfers'
import { updateUserCompany } from '../actions/user-companies'
import { getVacationCalendar, updateVacationCalendar } from '../actions/vacation-calendars'
import { CompanyLogoCreate } from '../api/company-logos'
import CompaniesSingleComponent from '../components/companies-single/CompaniesSingle'
import { CompaniesSingleSection } from '../components/types'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import { ApiKeyCreationFields } from '../model/apiKey'
import { AssetCategoryMutableFields } from '../model/assetCategory'
import AsynchronousTask from '../model/asynchronousTask'
import BankAccount from '../model/bankAccount'
import Company, { CompanyMutableFields } from '../model/company'
import CompanyDeviationConfiguration, {
  CompanyDeviationConfigurationMutableFields,
} from '../model/companyDeviationConfiguration'
import CompanyPricingPackage from '../model/companyPricingPackage'
import CompanySetting from '../model/companySetting'
import CompanyUser from '../model/companyUser'
import { DepartmentCreationFields, DepartmentMutableFields } from '../model/department'
import DocumentCategory, { DocumentCategoryMutableFields } from '../model/documentCategory'
import Employee from '../model/employee'
import { ExpenseCategoryBaseFields, ExpenseCategoryUpdateFields } from '../model/expenseCategory'
import { ExternalSalaryFileSettingMutableFields } from '../model/externalSalaryFileSetting'
import LeaveType from '../model/leaveType'
import { TransferSetting } from '../model/paymentConfiguration'
import { SalaryTypeMutableFields } from '../model/salaryType'
import StripeConfiguration, { StripeConfigurationSetup } from '../model/stripeConfiguration'
import { PaymentMethod } from '../model/transfer'
import { DateFormat } from '../model/types'
import VacationCalendar from '../model/vacationCalendar'
import { AccountingDimensionReducer } from '../reducers/accountingDimensions'
import { AgreementReducer } from '../reducers/agreements'
import { AlertReducer } from '../reducers/alerts'
import { ApiKeyReducer } from '../reducers/apiKeys'
import { AssetCategoryReducer } from '../reducers/assetCategories'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountPlanReducer } from '../reducers/companyAccountPlans'
import { CompanyBankAccountReducer } from '../reducers/companyBankAccounts'
import { CompanyDeviationConfigurationReducer } from '../reducers/companyDeviationConfigurations'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyLogoReducer } from '../reducers/companyLogo'
import { CompanyPaymentIntegrationReducer } from '../reducers/companyPaymentIntegrations'
import { CompanyPricingPackageReducer } from '../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { DocumentCategoryReducer } from '../reducers/documentCategories'
import { EmployeeReducer } from '../reducers/employees'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { ExternalSalaryFileSettingReducer } from '../reducers/externalSalaryFileSettings'
import { InvoiceReducer } from '../reducers/invoices'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { NetsMessageReducer } from '../reducers/netsMessages'
import { PaymentConfigurationReducer } from '../reducers/paymentConfigurations'
import { PayRollReducer } from '../reducers/payRolls'
import { PricingPackageReducer } from '../reducers/pricingPackages'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { StripeConfigurationReducer } from '../reducers/stripeConfiguration'
import { SupportAccessReducer } from '../reducers/supportAccess'
import { TaxCardReducer } from '../reducers/taxCards'
import { TransferReducer } from '../reducers/transfers'
import { UserReducer } from '../reducers/user'
import { UserCompanyReducer } from '../reducers/userCompanies'
import { VacationCalendarReducer } from '../reducers/vacationCalendars'
import { WarningReducer } from '../reducers/warnings'
import { getActiveCompany } from '../utils/cookie-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  apiKeys: ApiKeyReducer
  user: UserReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyAccountPlans: CompanyAccountPlanReducer
  invoices: InvoiceReducer
  transfers: TransferReducer
  userCompanies: UserCompanyReducer
  agreements: AgreementReducer
  asynchronousTasks: AsynchronousTaskReducer
  paymentConfigurations: PaymentConfigurationReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  companyBankAccounts: CompanyBankAccountReducer
  stripeConfiguration: StripeConfigurationReducer
  netsMessages: NetsMessageReducer
  departments: DepartmentReducer
  documentCategories: DocumentCategoryReducer
  employees: EmployeeReducer
  expenseCategories: ExpenseCategoryReducer
  leaveTypes: LeaveTypeReducer
  payRolls: PayRollReducer
  salaryCycles: SalaryCycleReducer
  salaryTypes: SalaryTypeReducer
  supportAccess: SupportAccessReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
  companyFeatures: CompanyFeatureReducer
  companyDeviationConfigurations: CompanyDeviationConfigurationReducer
  taxCards: TaxCardReducer
  companyLogo: CompanyLogoReducer
  warnings: WarningReducer
  vacationCalendars: VacationCalendarReducer
  externalSalaryFileSettings: ExternalSalaryFileSettingReducer
  assetCategories: AssetCategoryReducer
  accountingDimensions: AccountingDimensionReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateCompany: (company: CompanyMutableFields) => Promise<Company | void>
  updateUserCompany: (userCompany: CompanyUser) => void
  updateActiveCompany: (id: string) => void
  getInvoices: () => void
  getTransfers: (
    payRollID: string | undefined,
    companyID: string | undefined,
    fromDate?: DateFormat,
    offset?: number
  ) => void
  automateTransfer: (transferID: string, paymentMethod: PaymentMethod) => void
  tryTransferAgain: (transferID: string) => void
  updateTransferDate: (transferID: string, date: DateFormat) => void
  getPaymentConfigurations: () => void
  getCompanyPaymentIntegrations: () => void
  updatePaymentConfiguration: (transferSettings: TransferSetting[]) => void
  getStripeConfiguration: () => void
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
  getNetsMessages: () => void
  getDepartments: () => void
  addDepartment: (department: DepartmentCreationFields) => void
  updateDepartment: (department: DepartmentMutableFields) => void
  deleteDepartment: (departmentID: string) => void
  getDocumentCategories: () => void
  addDocumentCategory: (category: DocumentCategoryMutableFields) => void
  updateDocumentCategory: (documentCategory: DocumentCategory) => void
  getExpenseCategories: () => void
  addExpenseCategory: (expenseCategory: ExpenseCategoryBaseFields) => void
  updateExpenseCategory: (expenseCategory: ExpenseCategoryUpdateFields) => void
  deleteExpenseCategory: (expenseCategoryID: string) => void
  getPayRolls: () => void
  getSalaryCycles: () => void
  addSalaryType: (salaryType: SalaryTypeMutableFields) => void
  updateSalaryType: (salaryType: SalaryTypeMutableFields) => void
  deleteSalaryType: (salaryTypeID: string) => void
  reactivateSalaryType: (salaryTypeID: string) => void
  grantSupportAccess: () => void
  revokeSupportAccess: () => void
  loadSupportAccess: () => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  addCompanyPricingPackage: (pricingPackageID: string) => Promise<CompanyPricingPackage | void>
  activateCompanyPricingPackageCode: (code: string) => void
  getCompanyPricingPackages: () => void
  deleteCompanyPricingPackage: (companyPricingPackageID: string) => Promise<boolean | void>
  getTaxCards: () => void
  getCompanyLogo: () => void
  updateCompanyLogo: (image: CompanyLogoCreate) => void
  deleteCompanyLogo: () => void
  addApiKey: (apiKey: ApiKeyCreationFields) => void
  deleteApiKey: (apiKeyID: string) => void
  startExcelEmployeesData: (fileID: string) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string) => Promise<AsynchronousTask | void>
  getAsynchronousTasks: (companyID: string) => void
  toggleNemKontoAllEmployees: (company: Company, enable: boolean) => void
  getCompanyDeviationConfigurations: () => void
  addCompanyDeviationConfiguration: (companyDeviationConfiguration: CompanyDeviationConfigurationMutableFields) => void
  updateCompanyDeviationConfiguration: (companyDeviationConfiguration: CompanyDeviationConfiguration) => void
  deleteCompanyDeviationConfiguration: (companyDeviationConfigurationID: string) => void
  enableCompanySettings: (companyID: string, settings: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, settings: CompanySetting[]) => Promise<Company | void>
  getCompanyAccountPlans: () => void
  updateLeaveType: (leaveTypeID: string, employeeSelectable: boolean) => void
  updateLeaveSubTypes: (leaveTypeID: string, selectableLeaveSubTypeIDs: string[]) => Promise<LeaveType | void>
  updateEmployee: (employee: Employee) => void
  getVacationCalendar: (companyID: string) => void
  updateVacationCalendar: (companyID: string, vacationCalendar: VacationCalendar) => void
  getExternalSalaryFileSettings: (companyID: string) => void
  saveExternalSalaryFileSettings: (companyID: string, settings: ExternalSalaryFileSettingMutableFields[]) => void
  addCompanyBankAccount: (bankAccount: BankAccount) => void
  createAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  updateAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  deleteAssetCategory: (assetCategoryID: string) => void
  getAssetCategories: () => void
  getApiKeys: () => void
  getCompanyUsers: (companyID?: string) => void
  approveTransfer: (transferID: string) => void
  declineTransfer: (transferID: string) => void
  getAccountingDimensions: () => void
}

function CompaniesSingle(props: Reducers & Actions & RouteProps): ReactElement | null {
  const resolveSplat = () => {
    const parts = splatString(props.params.splat).split('/')
    const companyID = parts[0]
    let section = ''
    if (parts.length > 1) {
      section = parts[1]
    }
    let subsection = undefined
    if (parts.length > 2) {
      subsection = parts[2]
    }
    section = section || 'company'
    return { companyID, section, subsection }
  }

  const { updateActiveCompany, companyUsers, companies, getCompanyUsers } = props

  useEffect(() => {
    const { companyID } = resolveSplat()
    const activeCompanyID = getActiveCompany()
    if (!activeCompanyID || activeCompanyID !== companyID) {
      updateActiveCompany(companyID)
    }
    const thisCompanyID = companies.company?.id
    if (
      (!companyUsers.loading && !companyUsers.loaded) ||
      (thisCompanyID && companyUsers.companyID !== thisCompanyID)
    ) {
      getCompanyUsers()
    }
  })

  const loading = !props.companyUsers.loaded || !props.companies.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const { companyID, section, subsection } = resolveSplat()
  const company = props.companies.companies.find((company) => company.id === companyID)
  if (!company) {
    // no company?  Send them to their account page
    jsBrowserHistory.push('/' + paths.ACCOUNT)
    return null
  }

  return (
    <CompaniesSingleComponent
      route={props.route}
      location={props.location}
      params={props.params}
      section={section as CompaniesSingleSection}
      subsection={subsection}
      alerts={props.alerts}
      user={props.user}
      company={company}
      companies={props.companies}
      companyUsers={props.companyUsers}
      companyAccountPlans={props.companyAccountPlans}
      userCompanies={props.userCompanies}
      agreements={props.agreements}
      asynchronousTasks={props.asynchronousTasks}
      invoices={props.invoices}
      transfers={props.transfers}
      paymentConfigurations={props.paymentConfigurations}
      companyPaymentIntegrations={props.companyPaymentIntegrations}
      companyBankAccounts={props.companyBankAccounts}
      companyDeviationConfigurations={props.companyDeviationConfigurations}
      stripeConfiguration={props.stripeConfiguration}
      netsMessages={props.netsMessages}
      departments={props.departments}
      documentCategories={props.documentCategories}
      employees={props.employees}
      expenseCategories={props.expenseCategories}
      leaveTypes={props.leaveTypes}
      payRolls={props.payRolls}
      salaryCycles={props.salaryCycles}
      salaryTypes={props.salaryTypes}
      supportAccess={props.supportAccess}
      pricingPackages={props.pricingPackages}
      companyPricingPackages={props.companyPricingPackages}
      companyFeatures={props.companyFeatures}
      taxCards={props.taxCards}
      companyLogo={props.companyLogo}
      warnings={props.warnings}
      apiKeys={props.apiKeys}
      vacationCalendars={props.vacationCalendars}
      externalSalaryFileSettings={props.externalSalaryFileSettings}
      accountingDimensions={props.accountingDimensions}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateCompany={props.updateCompany}
      updateUserCompany={props.updateUserCompany}
      getInvoices={props.getInvoices}
      getTransfers={props.getTransfers}
      automateTransfer={props.automateTransfer}
      tryTransferAgain={props.tryTransferAgain}
      updateTransferDate={props.updateTransferDate}
      getPaymentConfigurations={props.getPaymentConfigurations}
      getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
      updatePaymentConfiguration={props.updatePaymentConfiguration}
      getStripeConfiguration={props.getStripeConfiguration}
      addStripeConfiguration={props.addStripeConfiguration}
      updateStripeConfiguration={props.updateStripeConfiguration}
      getNetsMessages={props.getNetsMessages}
      getDepartments={props.getDepartments}
      addDepartment={props.addDepartment}
      updateDepartment={props.updateDepartment}
      deleteDepartment={props.deleteDepartment}
      getDocumentCategories={props.getDocumentCategories}
      addDocumentCategory={props.addDocumentCategory}
      updateDocumentCategory={props.updateDocumentCategory}
      getExpenseCategories={props.getExpenseCategories}
      addExpenseCategory={props.addExpenseCategory}
      updateExpenseCategory={props.updateExpenseCategory}
      deleteExpenseCategory={props.deleteExpenseCategory}
      getPayRolls={props.getPayRolls}
      getSalaryCycles={props.getSalaryCycles}
      addSalaryType={props.addSalaryType}
      updateSalaryType={props.updateSalaryType}
      deleteSalaryType={props.deleteSalaryType}
      reactivateSalaryType={props.reactivateSalaryType}
      grantSupportAccess={props.grantSupportAccess}
      revokeSupportAccess={props.revokeSupportAccess}
      loadSupportAccess={props.loadSupportAccess}
      getPricingPackages={props.getPricingPackages}
      addCompanyPricingPackage={props.addCompanyPricingPackage}
      activateCompanyPricingPackageCode={props.activateCompanyPricingPackageCode}
      getCompanyPricingPackages={props.getCompanyPricingPackages}
      deleteCompanyPricingPackage={props.deleteCompanyPricingPackage}
      getTaxCards={props.getTaxCards}
      getCompanyLogo={props.getCompanyLogo}
      updateCompanyLogo={props.updateCompanyLogo}
      deleteCompanyLogo={props.deleteCompanyLogo}
      getApiKeys={props.getApiKeys}
      addApiKey={props.addApiKey}
      deleteApiKey={props.deleteApiKey}
      startExcelEmployeesData={props.startExcelEmployeesData}
      storeStagedImportData={props.storeStagedImportData}
      getAsynchronousTasks={props.getAsynchronousTasks}
      toggleNemKontoAllEmployees={props.toggleNemKontoAllEmployees}
      getCompanyDeviationConfigurations={props.getCompanyDeviationConfigurations}
      addCompanyDeviationConfiguration={props.addCompanyDeviationConfiguration}
      updateCompanyDeviationConfiguration={props.updateCompanyDeviationConfiguration}
      deleteCompanyDeviationConfiguration={props.deleteCompanyDeviationConfiguration}
      enableCompanySettings={props.enableCompanySettings}
      disableCompanySettings={props.disableCompanySettings}
      getCompanyAccountPlans={props.getCompanyAccountPlans}
      updateLeaveType={props.updateLeaveType}
      updateLeaveSubTypes={props.updateLeaveSubTypes}
      updateEmployee={props.updateEmployee}
      getVacationCalendar={props.getVacationCalendar}
      updateVacationCalendar={props.updateVacationCalendar}
      getExternalSalaryFileSettings={props.getExternalSalaryFileSettings}
      saveExternalSalaryFileSettings={props.saveExternalSalaryFileSettings}
      addCompanyBankAccount={props.addCompanyBankAccount}
      createAssetCategory={props.createAssetCategory}
      updateAssetCategory={props.updateAssetCategory}
      deleteAssetCategory={props.deleteAssetCategory}
      getAssetCategories={props.getAssetCategories}
      assetCategories={props.assetCategories}
      approveTransfer={props.approveTransfer}
      declineTransfer={props.declineTransfer}
      getAccountingDimensions={props.getAccountingDimensions}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    assetCategories: state.assetCategories,
    user: state.user,
    companies: state.companies,
    companyUsers: state.companyUsers,
    companyAccountPlans: state.companyAccountPlans,
    userCompanies: state.userCompanies,
    agreements: state.agreements,
    asynchronousTasks: state.asynchronousTasks,
    invoices: state.invoices,
    transfers: state.transfers,
    paymentConfigurations: state.paymentConfigurations,
    companyPaymentIntegrations: state.companyPaymentIntegrations,
    companyBankAccounts: state.companyBankAccounts,
    companyDeviationConfigurations: state.companyDeviationConfigurations,
    stripeConfiguration: state.stripeConfiguration,
    netsMessages: state.netsMessages,
    departments: state.departments,
    documentCategories: state.documentCategories,
    employees: state.employees,
    expenseCategories: state.expenseCategories,
    leaveTypes: state.leaveTypes,
    payRolls: state.payRolls,
    salaryCycles: state.salaryCycles,
    salaryTypes: state.salaryTypes,
    supportAccess: state.supportAccess,
    pricingPackages: state.pricingPackages,
    companyPricingPackages: state.companyPricingPackages,
    companyFeatures: state.companyFeatures,
    taxCards: state.taxCards,
    companyLogo: state.companyLogo,
    warnings: state.warnings,
    apiKeys: state.apiKeys,
    vacationCalendars: state.vacationCalendars,
    externalSalaryFileSettings: state.externalSalaryFileSettings,
    accountingDimensions: state.accountingDimensions,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateCompany: updateCompany,
    updateActiveCompany: updateActiveCompany,
    getCompanyUsers: getCompanyUsers,
    updateUserCompany: updateUserCompany,
    getInvoices: getInvoices,
    getTransfers: getTransfers,
    automateTransfer: automateTransfer,
    tryTransferAgain: tryTransferAgain,
    updateTransferDate: updateTransferDate,
    getPaymentConfigurations: getPaymentConfigurations,
    getCompanyPaymentIntegrations: getCompanyPaymentIntegrations,
    updatePaymentConfiguration: updatePaymentConfiguration,
    getStripeConfiguration: getStripeConfiguration,
    addStripeConfiguration: addStripeConfiguration,
    updateStripeConfiguration: updateStripeConfiguration,
    getNetsMessages: getNetsMessages,
    addDepartment: addDepartment,
    getDepartments: getDepartments,
    updateDepartment: updateDepartment,
    deleteDepartment: deleteDepartment,
    addDocumentCategory: addDocumentCategory,
    getDocumentCategories: getDocumentCategories,
    updateDocumentCategory: updateDocumentCategory,
    addExpenseCategory: addExpenseCategory,
    getExpenseCategories: getExpenseCategories,
    updateExpenseCategory: updateExpenseCategory,
    deleteExpenseCategory: deleteExpenseCategory,
    getPayRolls: getPayRolls,
    getSalaryCycles: getSalaryCycles,
    addSalaryType: addSalaryType,
    updateSalaryType: updateSalaryType,
    deleteSalaryType: deleteSalaryType,
    reactivateSalaryType: reactivateSalaryType,
    grantSupportAccess: grantSupportAccess,
    revokeSupportAccess: revokeSupportAccess,
    loadSupportAccess: loadSupportAccess,
    getPricingPackages: getPricingPackages,
    addCompanyPricingPackage: addCompanyPricingPackage,
    activateCompanyPricingPackageCode: activateCompanyPricingPackageCode,
    getCompanyPricingPackages: getCompanyPricingPackages,
    deleteCompanyPricingPackage: deleteCompanyPricingPackage,
    getTaxCards: getTaxCards,
    getCompanyLogo: getCompanyLogo,
    updateCompanyLogo: updateCompanyLogo,
    deleteCompanyLogo: deleteCompanyLogo,
    addApiKey: addApiKey,
    getApiKeys: getApiKeys,
    deleteApiKey: deleteApiKey,
    startExcelEmployeesData: startExcelEmployeesData,
    storeStagedImportData: storeStagedImportData,
    getAsynchronousTasks: getAsynchronousTasks,
    toggleNemKontoAllEmployees: toggleNemKontoAllEmployees,
    getCompanyDeviationConfigurations: getCompanyDeviationConfigurations,
    addCompanyDeviationConfiguration: addCompanyDeviationConfiguration,
    updateCompanyDeviationConfiguration: updateCompanyDeviationConfiguration,
    deleteCompanyDeviationConfiguration: deleteCompanyDeviationConfiguration,
    enableCompanySettings: enableCompanySettings,
    disableCompanySettings: disableCompanySettings,
    getCompanyAccountPlans: getCompanyAccountPlans,
    updateLeaveType: updateLeaveType,
    updateLeaveSubTypes: updateLeaveSubTypes,
    updateEmployee: updateEmployee,
    getVacationCalendar: getVacationCalendar,
    updateVacationCalendar: updateVacationCalendar,
    getExternalSalaryFileSettings: getExternalSalaryFileSettings,
    saveExternalSalaryFileSettings: saveExternalSalaryFileSettings,
    addCompanyBankAccount: addCompanyBankAccount,
    createAssetCategory: createAssetCategory,
    updateAssetCategory: updateAssetCategory,
    deleteAssetCategory: deleteAssetCategory,
    getAssetCategories: getAssetCategories,
    approveTransfer: approveTransfer,
    declineTransfer: declineTransfer,
    getAccountingDimensions: getAccountingDimensions,
  }
)(CompaniesSingle)
