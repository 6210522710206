import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import BankAccount from '../../model/bankAccount'
import Company from '../../model/company'
import { CompanyBankAccountReducer } from '../../reducers/companyBankAccounts'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import CompanyBankAccountForm, { BankAccountResult } from './CompanyBankAccountForm'

type Props = {
  company: Company
  companyBankAccounts: CompanyBankAccountReducer

  addAlert: addAlertSignature
  addCompanyBankAccount: (bankAccount: BankAccount) => void
}

export default function CompanyBankAccount(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const { companyBankAccounts } = props

  useEffect(() => {
    regularComponentDidUpdate(companyBankAccounts.error, error, setError)
  }, [companyBankAccounts, error])

  const handleBankAccountSubmit = (values: BankAccountResult) => {
    props.addCompanyBankAccount(values)
  }

  return (
    <Card>
      <TitleMenu>
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfer-settings'}>
          <Button>{t('company_bank_account.header.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('company_bank_account.header.title')}</Title>
      <Row>
        <Col span={24}>
          <p>
            {tx('company_bank_account.description.text', {
              link: (
                <a href="https://overfoerselsservice.mastercard.com/" target="_blank" rel="noopener noreferrer">
                  {t('company_bank_account.description.link')}
                </a>
              ),
              cvr: <strong>37628409</strong>,
            })}
          </p>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      <Subcard>
        <Subtitle>{t('company_bank_account.request.title')}</Subtitle>
        <p>
          {t('company_bank_account.request.line_1')}
          <br />
          {t('company_bank_account.request.line_2')}
        </p>
        <CompanyBankAccountForm
          addAlert={props.addAlert}
          company={props.company}
          companyBankAccounts={props.companyBankAccounts}
          onSubmit={handleBankAccountSubmit}
        />
      </Subcard>
    </Card>
  )
}
