import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { CompanyUserPermission, UserPermission } from '../../model/companyUser'
import Department from '../../model/department'
import { DepartmentPermission, DepartmentUserMutableFields } from '../../model/departmentUser'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { UserReducer } from '../../reducers/user'
import { UserInviteReducer } from '../../reducers/userInvites'
import UserType from '../../types/user-company-type'
import UserTypes from '../../types/user-company-type'
import { formatDate } from '../../utils/date-utils'
import { formatDepartmentPermission, formatUserPermission, formatUserType } from '../../utils/format-utils'
import { formatSavingText } from '../../utils/loading-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CompanyUsersAdd from './CompanyUsersAdd'
import EditCompanyUser from './EditCompanyUser'
import EditCompanyUserDepartments from './EditCompanyUserDepartments'

import './CompanyUsers.css'

const permissionOrder: UserPermission[] = ['Admin', 'Manager', 'ApprovePayRoll', 'ReviewPayRoll']

const permissionComparator = (a: UserPermission, b: UserPermission) => {
  return permissionOrder.indexOf(a) - permissionOrder.indexOf(b)
}
const permissionObjectComparator = (a: CompanyUserPermission, b: CompanyUserPermission) => {
  return permissionComparator(a.permission, b.permission)
}

type Props = {
  alerts: AlertReducer
  user: UserReducer
  company: Company
  companyUsers: CompanyUserReducer
  departments: List<Department>
  userInvites: UserInviteReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteCompanyUser: (userID: string) => void
  addUserInvite: (
    email: string,
    userType: UserTypes,
    permissions: UserPermission[],
    departments: DepartmentUserMutableFields[],
    name?: string,
    phoneNumberCountryCode?: string,
    phoneNumber?: string
  ) => void
  deleteUserInvite: (email: string) => void
  getCompanyUsers: () => void
  grantCompanyUserPermission: (userCompanyID: string, permission: UserPermission) => void
  revokeCompanyUserPermission: (userCompanyID: string, permissionID: string) => void
  grantDepartmentUserPermission: (
    userCompanyID: string,
    departmentID: string,
    permissions: DepartmentPermission[]
  ) => void
  revokeDepartmentUserPermission: (userCompanyID: string, departmentID: string) => void
}

export default function CompanyUsers(props: Props): ReactElement | null {
  const [showAddUserInvite, setAddUserInvite] = useState(false)
  const [showEditUser, setShowEditUser] = useState<string | boolean>(false)
  const [showEditDepartments, setShowEditDepartments] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)
  const [inviteDeleting, setInviteDeleting] = useState<string[]>([])
  const [companyUserDeleting, setCompanyUserDeleting] = useState<string[]>([])

  const { userInvites, companyUsers } = props
  const previousUserInvites = usePrevious(userInvites)
  const previousCompanyUsers = usePrevious(companyUsers)

  const setAddUserInviteVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setAddUserInvite(visible)
  }

  const setEditUserVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setShowEditUser(id)
  }

  const setEditDepartmentsVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setShowEditDepartments(id)
  }

  useEffect(() => {
    if (previousUserInvites && previousUserInvites.saving && !userInvites.saving) {
      if (!userInvites.error) {
        setAddUserInviteVisibility(false)
      }
      setInviteDeleting([]) // empty
    }
  }, [previousUserInvites, userInvites])

  useEffect(() => {
    if (previousCompanyUsers && previousCompanyUsers.saving && !companyUsers.saving) {
      if (!companyUsers.error) {
        setEditUserVisibility(false)
      }
      setCompanyUserDeleting([]) // empty
    }
  }, [previousCompanyUsers, companyUsers])

  const editCompanyUser = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setEditUserVisibility(id)
    }
  }
  const editDepartments = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setEditDepartmentsVisibility(id)
    }
  }
  const deleteCompanyUser = (userID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setCompanyUserDeleting((prev) => [...prev, userID])
        props.deleteCompanyUser(userID)
      }
    }
  }
  const deleteInvitation = (email: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setInviteDeleting((prev) => [...prev, email])
        props.deleteUserInvite(email)
      }
    }
  }

  type CompanyUserRow = {
    key: string
    id: string
    userID: string
    userType: UserType
    name: string
    profileImageURL?: string
    type: string
    permissions: string
    departments: string[]
    createdAt?: string
    email: string
    isManager: boolean
    isApproveOnly: boolean
  }

  const userColumns = [
    {
      title: t('company_users.table.header.name'),
      dataIndex: '',
      key: 'xName',
      render: (companyUser: CompanyUserRow) => {
        return (
          <Headline>
            <UserImage name={companyUser.name} />
            {companyUser.name}
            <small>{companyUser.type}</small>
          </Headline>
        )
      },
    },
    {
      title: t('company_users.table.header.permissions'),
      dataIndex: '',
      key: 'xPermissions',
      render: (companyUser: CompanyUserRow) => {
        if (companyUser.departments.length === 0) {
          return companyUser.permissions
        }
        return (
          <div>
            <div>{companyUser.permissions}:</div>
            {companyUser.departments.map((d, i) => (
              <div key={i}>{d}</div>
            ))}
          </div>
        )
      },
    },
    { title: t('company_users.table.header.created_at'), dataIndex: 'createdAt', key: 'createdAt' },
    { title: t('company_users.table.header.email'), dataIndex: 'email', key: 'email' },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (companyUser: CompanyUserRow) => {
        if (props.user.id === companyUser.userID) {
          return null
        }
        return (
          <ContextMenu>
            {companyUser.userType === UserType.DEPARTMENT_MANAGER && (
              <DumbLink onClick={editDepartments(companyUser.userID)}>
                <Icon type="edit" color="grey" /> {t('company_users.table.actions.edit_departments')}
              </DumbLink>
            )}
            {companyUser.userType !== UserType.DEPARTMENT_MANAGER && !companyUser.isApproveOnly && (
              <DumbLink onClick={editCompanyUser(companyUser.id)}>
                <Icon type="edit" color="grey" /> {t('company_users.table.actions.edit_permissions')}
              </DumbLink>
            )}
            {(companyUser.isManager ||
              companyUser.userType === UserType.DEPARTMENT_MANAGER ||
              companyUser.isApproveOnly) && (
              <DumbLink onClick={deleteCompanyUser(companyUser.userID)}>
                <Icon type="user-delete" color="grey" /> {t('company_users.table.actions.delete_access')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    },
  ]

  type UserInviteRow = {
    id: string
    email: string
    type: string
    createdAt?: string
  }

  const userInviteColumns = [
    {
      title: t('company_users.invite_table.header.email'),
      dataIndex: '',
      key: 'xEmail',
      render: (userInvite: UserInviteRow) => {
        return (
          <div>
            {userInvite.email}
            <small>{userInvite.type}</small>
          </div>
        )
      },
    },
    { title: t('company_users.invite_table.header.created_at'), dataIndex: 'createdAt', key: 'createdAt' },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (userInvite: UserInviteRow) => {
        return (
          <ContextMenu>
            <DumbLink onClick={deleteInvitation(userInvite.id)}>
              <Icon type="user-delete" color="grey" /> {t('company_users.invite_table.actions.delete')}
            </DumbLink>
          </ContextMenu>
        )
      },
    },
  ]

  const getCompanyUsers = (): CompanyUserRow[] => {
    return props.companyUsers.companyUsers
      .filter((companyUser) => !companyUserDeleting.some((userID) => userID === companyUser.userID))
      .map((companyUser) => {
        return {
          key: companyUser.id || '',
          id: companyUser.id || '',
          userID: companyUser.userID,
          companyID: companyUser.companyID,
          profileImageURL: companyUser.profileImageURL,
          name: companyUser.name || '',
          email: companyUser.email,
          userType: companyUser.departments.length > 0 ? UserType.DEPARTMENT_MANAGER : companyUser.userType,
          isManager:
            companyUser.permissions &&
            companyUser.permissions.some(
              (permission) => permission.permission === 'Manager' || permission.permission === 'Admin'
            ),
          isApproveOnly:
            companyUser.permissions &&
            companyUser.permissions.some((permission) => permission.permission === 'ApproveOnly'),
          type:
            companyUser.permissions && companyUser.permissions.length === 0
              ? t('company_users.table.department_manager')
              : companyUser.permissions.some((v) => v.permission === 'ApproveOnly')
              ? formatUserType(UserType.APPROVE_ONLY)
              : companyUser.permissions.some((v) => v.permission === 'ReadOnly')
              ? formatUserType(UserType.READ_ONLY)
              : formatUserType(companyUser.userType),
          permissions:
            companyUser.permissions && companyUser.permissions.length === 0
              ? t('company_users.table.departments', { count: companyUser.departments.length })
              : companyUser.permissions
                  .sort(permissionObjectComparator)
                  .map((v) => formatUserPermission(v.permission))
                  .join(', '),
          departments: companyUser.departments
            .map((userDepartment) => {
              const department = props.departments.find((department) => department.id === userDepartment.departmentID)
              if (!department) {
                return ''
              }
              return (
                department.name + ': ' + userDepartment.permissions.map((v) => formatDepartmentPermission(v)).join(', ')
              )
            })
            .filter((row) => row !== '')
            .sort((a, b) => a.localeCompare(b, 'da-DK')),
          createdAt: companyUser.createdAt ? formatDate(companyUser.createdAt) : undefined,
        }
      })
      .toArray()
  }

  const getUserInvites = (): UserInviteRow[] => {
    return props.userInvites.userInvites
      .filter((userInvite) => userInvite.companyID && !inviteDeleting.some((email) => email === userInvite.email))
      .map((userInvite) => {
        let type = t('common.unknown')
        let showPermissions = true
        if (userInvite.departments && userInvite.departments.length > 0) {
          type = formatUserType(UserType.DEPARTMENT_MANAGER)
        } else if (userInvite.permissions.some((v) => v === 'ApproveOnly')) {
          showPermissions = false
          type = formatUserType(UserType.APPROVE_ONLY)
        } else if (userInvite.permissions.some((v) => v === 'ReadOnly')) {
          showPermissions = false
          type = formatUserType(UserType.READ_ONLY)
        } else if (userInvite.userType) {
          type = formatUserType(userInvite.userType)
        }
        if (showPermissions && userInvite.permissions && userInvite.permissions.length > 0) {
          type +=
            ' (' +
            userInvite.permissions
              .sort(permissionComparator)
              .map((v) => formatUserPermission(v))
              .join(', ') +
            ')'
        }
        return {
          key: userInvite.email,
          id: userInvite.email,
          email: userInvite.email,
          type,
          createdAt: userInvite.createdAt ? formatDate(userInvite.createdAt) : undefined,
        }
      })
      .toArray()
  }

  const inviteCount = getUserInvites().length
  const userCount = getCompanyUsers().length

  return (
    <div className="company-users">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <Button type="primary" onClick={() => setAddUserInviteVisibility(true)}>
          <Icon type="user-add" />
          {t('company_users.header.add_user')}
        </Button>
      </TitleMenu>
      <Title>{t('company_users.invite_table.title', { count: inviteCount })}</Title>
      <Table
        columns={userInviteColumns}
        dataSource={getUserInvites()}
        pagination={inviteCount > 10 ? undefined : false}
      />

      <Title>{t('company_users.table.title', { count: userCount })}</Title>
      <Table columns={userColumns} dataSource={getCompanyUsers()} />

      <Modal
        key={`add-user-${modalKey}`}
        visible={showAddUserInvite}
        onOk={() => setAddUserInviteVisibility(false)}
        onCancel={() => setAddUserInviteVisibility(false)}
        width={622}
        footer={null}
      >
        <CompanyUsersAdd
          company={props.company}
          departments={props.departments}
          userInvites={props.userInvites}
          addUserInvite={props.addUserInvite}
        />
      </Modal>
      <Modal
        key={`edit-user-${modalKey}`}
        visible={!!showEditUser}
        onOk={() => setEditUserVisibility(false)}
        onCancel={() => setEditUserVisibility(false)}
        width={582}
        footer={null}
      >
        <EditCompanyUser
          visible={!!showEditUser}
          companyUserID={typeof showEditUser === 'boolean' ? undefined : showEditUser}
          closeModal={() => setEditUserVisibility(false)}
          company={props.company}
          companyUsers={props.companyUsers}
          getCompanyUsers={props.getCompanyUsers}
          grantCompanyUserPermission={props.grantCompanyUserPermission}
          revokeCompanyUserPermission={props.revokeCompanyUserPermission}
        />
      </Modal>
      <Modal
        key={`edit-departments-${modalKey}`}
        visible={!!showEditDepartments}
        onOk={() => setEditDepartmentsVisibility(false)}
        onCancel={() => setEditDepartmentsVisibility(false)}
        width={582}
        footer={null}
      >
        <EditCompanyUserDepartments
          visible={!!showEditDepartments}
          id={typeof showEditDepartments === 'boolean' ? undefined : showEditDepartments}
          company={props.company}
          companyUsers={props.companyUsers}
          departments={props.departments}
          addAlert={props.addAlert}
          closeModal={() => setEditDepartmentsVisibility(false)}
          grantDepartmentUserPermission={props.grantDepartmentUserPermission}
          revokeDepartmentUserPermission={props.revokeDepartmentUserPermission}
        />
      </Modal>
      {(props.userInvites.saving || props.companyUsers.saving) && (
        <LoadingOverlay
          text={formatSavingText([
            { loading: props.userInvites.saving, text: t('loading.reducer.user_invites') },
            { loading: props.companyUsers.saving, text: t('loading.reducer.company_users') },
          ])}
        />
      )}
    </div>
  )
}
