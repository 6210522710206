import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company from '../../model/company'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import VacationCalendarForm, { ResultFields } from './VacationCalendarForm'

type Props = {
  company: Company
  vacationCalendars: VacationCalendarReducer

  addAlert: addAlertSignature
  getVacationCalendar: (companyID: string) => void
  updateVacationCalendar: (companyID: string, vacationCalendars: ResultFields) => void
}

export default function VacationCalendarTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { company, vacationCalendars, getVacationCalendar } = props

  useEffect(() => {
    if (!vacationCalendars.vacationCalendar || (!vacationCalendars.loading && !vacationCalendars.loaded)) {
      getVacationCalendar(company.id)
    }
  }, [vacationCalendars, getVacationCalendar, company])

  const previousVacationCalendars = usePrevious(vacationCalendars)
  const { addAlert } = props

  useEffect(() => {
    if (previousVacationCalendars && previousVacationCalendars.saving && !vacationCalendars.saving) {
      if (!vacationCalendars.error) {
        addAlert('success', t('vacation_calendar.alert.success', { name: company.name }), { timeout: 5 })
        window.scrollTo(0, 0)
      }
    }

    regularComponentDidUpdate(vacationCalendars.error, error, setError)
  }, [previousVacationCalendars, vacationCalendars, error, setError, company, addAlert])

  const handleSubmit = (values: ResultFields) => {
    props.updateVacationCalendar(props.company.id, values)
  }

  if (!vacationCalendars.loaded || !vacationCalendars.vacationCalendar) {
    return <LoadingOverlay />
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Card>
        <TitleMenu>
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/vacation-settings'}>
            <Button>{t('vacation_calendar.header.back')}</Button>
          </Link>
        </TitleMenu>

        <Title>{t('vacation_calendar.title')}</Title>
        <p>{t('vacation_calendar.intro.line_1')}</p>
        <p>{t('vacation_calendar.intro.line_2')}</p>
        <VacationCalendarForm
          vacationCalendar={vacationCalendars.vacationCalendar}
          vacationCalendars={props.vacationCalendars}
          onSubmit={handleSubmit}
        />
      </Card>
    </div>
  )
}
