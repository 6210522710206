import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import Company, { CompanyMutableFields } from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import LeaveType from '../../model/leaveType'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyReducer } from '../../reducers/companies'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import VacationSettingsForm, { VacationSettingResult } from './VacationSettingsForm'

type Props = {
  company: Company
  companies: CompanyReducer
  companyFeatures: List<CompanyFeature>
  alerts: AlertReducer
  leaveTypes: List<LeaveType>

  addAlert: addAlertSignature
  updateCompany: (company: CompanyMutableFields) => void
}

export default function VacationSettingsTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companies } = props

  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const { company, addAlert } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    // Check for save callback
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      // Check for no error occurred
      if (!companies.error) {
        addAlert('success', t('vacation_settings.alert.success', { name: company.name }), { timeout: 5 })
        window.scrollTo(0, 0)
      }
    }
  })

  const handleSubmit = (values: VacationSettingResult) => {
    props.updateCompany({ ...props.company, ...values })
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Card>
        <TitleMenu>
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/vacation-calendar'}>
            <Button>{t('vacation_settings.header.vacation_calendar')}</Button>
          </Link>
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id}>
            <Button>{t('vacation_settings.header.back')}</Button>
          </Link>
        </TitleMenu>

        <Title>{t('vacation_settings.title')}</Title>
        <VacationSettingsForm
          company={props.company}
          companies={props.companies}
          companyFeatures={props.companyFeatures}
          leaveTypes={props.leaveTypes}
          onSubmit={handleSubmit}
        />
      </Card>
    </div>
  )
}
