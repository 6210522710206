import React from 'react'

import {
  delEmployee,
  delEmployeeUser,
  deleteEmployeeReady,
  delProfileImage,
  EmployeeConversionType,
  fetchEmployees,
  postEmployee,
  postEmployeeConvert,
  postEmployeePDFPassword,
  postEmployeeReady,
  postInvite,
  putEmployee,
  putProfileImage,
} from '../api/employees'
import ActionTypes from '../constants/action-types'
import Employee, { EmployeeCreation } from '../model/employee'
import ProfileImage from '../model/profileImage'
import { EmployeeInviteAction } from '../reducers/employeeInvites'
import { EmployeeProfileImageAction } from '../reducers/employeeProfileImages'
import { EmployeeAction } from '../reducers/employees'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingEmployees(): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEES_LOADING,
  }
}
export function loadedEmployees(companyID: string, employees: Employee[], partial = false): EmployeeAction {
  return {
    type: partial ? ActionTypes.EMPLOYEES_LOADED_PARTIAL : ActionTypes.EMPLOYEES_LOADED,
    employees,
    companyID,
  }
}
function failedLoadingEmployees(companyID: string, error: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEES_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingEmployee(): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_ADDING,
  }
}
export function addedEmployee(companyID: string, employee: Employee): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_ADDED,
    companyID,
    employee,
  }
}
function failedAddingEmployee(companyID: string, error: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingEmployee(companyID: string, employeeID: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_UPDATING,
    companyID,
    employeeID,
  }
}
export function updatedEmployee(companyID: string, employeeID: string, employee: Employee): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_UPDATED,
    companyID,
    employeeID,
    employee,
  }
}
function failedUpdatingEmployee(companyID: string, employeeID: string, error: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_UPDATE_FAILED,
    error,
    companyID,
    employeeID,
  }
}

function deletingEmployee(companyID: string, employeeID: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_DELETING,
    companyID,
    employeeID,
  }
}
export function deletedEmployee(companyID: string, employeeID: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_DELETED,
    companyID,
    employeeID,
  }
}
function failedDeletingEmployee(companyID: string, employeeID: string, error: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_DELETE_FAILED,
    error,
    companyID,
    employeeID,
  }
}

function sendingInvite(): EmployeeInviteAction {
  return {
    type: ActionTypes.EMPLOYEE_INVITE_SENDING,
  }
}
function sentInvite(employeeID: string): EmployeeInviteAction {
  return {
    type: ActionTypes.EMPLOYEE_INVITE_SENT,
    employeeID,
  }
}
function failedSendingInvite(employeeID: string, error: Error): EmployeeInviteAction {
  return {
    type: ActionTypes.EMPLOYEE_INVITE_SEND_FAILED,
    error,
    employeeID,
  }
}

function deletingEmployeeUser(): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_USER_DELETING,
  }
}
function deletedEmployeeUser(employeeID: string): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_USER_DELETE,
    employeeID,
  }
}
function failedDeletingEmployeeUser(employeeID: string, error: Error): EmployeeAction {
  return {
    type: ActionTypes.EMPLOYEE_USER_DELETE_FAILED,
    error,
    employeeID,
  }
}

function updatingProfileImage(): EmployeeProfileImageAction {
  return {
    type: ActionTypes.EMPLOYEE_PROFILE_IMAGE_UPDATING,
  }
}
function updatedProfileImage(employeeID: string): EmployeeProfileImageAction {
  return {
    type: ActionTypes.EMPLOYEE_PROFILE_IMAGE_UPDATED,
    employeeID,
  }
}
function failedUpdatingProfileImage(error: Error): EmployeeProfileImageAction {
  return {
    type: ActionTypes.EMPLOYEE_PROFILE_IMAGE_UPDATE_FAILED,
    error,
  }
}

function deletingProfileImage(): EmployeeProfileImageAction {
  return {
    type: ActionTypes.EMPLOYEE_PROFILE_IMAGE_DELETING,
  }
}
function deletedProfileImage(employeeID: string): EmployeeProfileImageAction {
  return {
    type: ActionTypes.EMPLOYEE_PROFILE_IMAGE_DELETED,
    employeeID,
  }
}
function failedDeletingProfileImage(error: Error): EmployeeProfileImageAction {
  return {
    type: ActionTypes.EMPLOYEE_PROFILE_IMAGE_DELETE_FAILED,
    error,
  }
}

export function getEmployees(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Employee[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingEmployees())
      offset = 0
    }
    const limit = 500
    return fetchEmployees(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedEmployees(companyID, data)),
          (data) => dispatch(loadedEmployees(companyID, data, true)),
          (offset) => dispatch(getEmployees(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingEmployees(companyID, e))
        }
      })
  }
}

export function addEmployee(employee: EmployeeCreation) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Employee | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingEmployee())
    return postEmployee(companyID, employee)
      .then((res) => {
        dispatch(addedEmployee(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingEmployee(companyID, e))
        }
      })
  }
}

export function deleteEmployee(employeeID: string, removeOrphans = false) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingEmployee(companyID, employeeID))
    return delEmployee(employeeID, removeOrphans)
      .then(() => {
        dispatch(deletedEmployee(companyID, employeeID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingEmployee(companyID, employeeID, e))
        }
        return false
      })
  }
}

export function updateEmployee(employee: Employee) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Employee | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingEmployee(companyID, employee.id))
    return putEmployee(employee)
      .then((res) => {
        dispatch(updatedEmployee(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployee(companyID, employee.id, e))
        }
      })
  }
}

export function setEmployeePDFPassword(employeeID: string, password: string | null) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Employee | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingEmployee(companyID, employeeID))
    return postEmployeePDFPassword(employeeID, password)
      .then((res) => {
        dispatch(updatedEmployee(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployee(companyID, employeeID, e))
        }
      })
  }
}

export function sendInvite(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(sendingInvite())
    return postInvite(employeeID)
      .then(() => {
        dispatch(sentInvite(employeeID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSendingInvite(employeeID, e))
        }
      })
  }
}

export function deleteEmployeeUser(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingEmployeeUser())
    return delEmployeeUser(employeeID)
      .then(() => {
        dispatch(deletedEmployeeUser(employeeID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingEmployeeUser(employeeID, e))
        }
      })
  }
}

export function updateProfileImage(employeeID: string, image: ProfileImage) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(updatingProfileImage())
    return putProfileImage(employeeID, image)
      .then(() => {
        dispatch(updatedProfileImage(employeeID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingProfileImage(e))
        }
      })
  }
}

export function deleteProfileImage(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingProfileImage())
    return delProfileImage(employeeID)
      .then(() => {
        dispatch(deletedProfileImage(employeeID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingProfileImage(e))
        }
      })
  }
}

export function convertEmployee(employeeID: string, toType: EmployeeConversionType) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingEmployee(companyID, employeeID))
    return postEmployeeConvert(employeeID, toType)
      .then((res) => {
        dispatch(updatedEmployee(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployee(companyID, employeeID, e))
        }
      })
  }
}

export function markEmployeeReady(employeeID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingEmployee(companyID, employeeID))
    return postEmployeeReady(employeeID)
      .then((res) => {
        dispatch(updatedEmployee(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployee(companyID, employeeID, e))
        }
      })
  }
}

export function unmarkEmployeeReady(employeeID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingEmployee(companyID, employeeID))
    return deleteEmployeeReady(employeeID)
      .then((res) => {
        dispatch(updatedEmployee(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployee(companyID, employeeID, e))
        }
      })
  }
}
