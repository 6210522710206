import { isSameDay, subDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import TimeRegistration from '../../model/timeRegistration'
import { DateFormat } from '../../model/types'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate, formatDateInterval, getDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatLeaveSubType, formatLeaveTypeName } from '../../utils/format-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { TableSorter } from '../../utils/table-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  companyUser?: CompanyUser
  timeRegistrations: TimeRegistrationReducer

  employees: Map<string, Employee>
  departments: List<Department>
  companyUsers: List<CompanyUser>
  leaveTypes: List<LeaveType>

  addAlert: addAlertSignature
  approveTimeRegistrations: (ids: string[]) => void
}

export default function LeaveRegistrationsCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  type Sort = {
    sortOn?: 'employee' | 'date' | 'days'
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const { timeRegistrations, addAlert } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (timeRegistrations.error) {
        addAlert('error', formatError(timeRegistrations.error))
      }
      setApproving([]) //empty it
    }
  }, [previousTimeRegistrations, timeRegistrations, addAlert, setApproving])

  const pendingLeaveRegistrations: TimeRegistration[] = timeRegistrations.timeRegistrations
    .filter(
      (timeReg) =>
        !timeReg.approved &&
        (timeReg.class === 'Leave' || timeReg.class === 'Flex') &&
        !approving.some((approve) => approve === timeReg.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employees, timeReg.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (ids: string[]) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, ...ids])
      props.approveTimeRegistrations(ids)
    }
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingLeaveRegistrations.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveTimeRegistrations(unapproved)
  }

  type LeaveRegistrationRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string[]
    employeeID: string
    date: string
    dateSort: DateFormat
    days: string
    daysNo: number
    leaveType: string
    leaveSubType?: string
    original: TimeRegistration[]
  }

  const columns = [
    {
      title: t('approve_tab.leave_registrations.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      sorter: 'employee',
      render: (row: LeaveRegistrationRow) => {
        return <ApproveCardEmployeeName {...row} linkTo={'/' + paths.EMPLOYEES + '/' + row.employeeID + '/leave'} />
      },
    },
    { title: t('approve_tab.leave_registrations.header.date'), dataIndex: 'date', key: 'date', sorter: 'date' },
    {
      title: t('approve_tab.leave_registrations.header.leave_type'),
      dataIndex: '',
      key: 'xLeaveType',
      render: (row: LeaveRegistrationRow) => {
        if (!row.leaveSubType) {
          return row.leaveType
        }
        return (
          <>
            {row.leaveType}
            <br />
            <small>{row.leaveSubType}</small>
          </>
        )
      },
    },
    { title: t('approve_tab.leave_registrations.header.days'), dataIndex: 'days', key: 'days', sorter: 'days' },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: LeaveRegistrationRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            {t('approve_tab.leave_registrations.actions.approve')}
          </span>
        )
      },
    },
  ]

  const pendingLeaveRegistrationRows: LeaveRegistrationRow[] = pendingLeaveRegistrations
    .map((timeReg) => {
      let leaveLine = ''
      let subLine
      const leaveType = props.leaveTypes.find((type) => type.id === timeReg.leaveTypeID)
      if (leaveType) {
        leaveLine = formatLeaveTypeName(leaveType.name)
        if (timeReg.leaveSubTypeID) {
          const subType = leaveType.leaveSubTypes.find((subType) => subType.id === timeReg.leaveSubTypeID)
          if (subType) {
            subLine = formatLeaveSubType(subType.name)
          }
        }
      }
      return {
        key: timeReg.id,
        id: [timeReg.id],
        employeeID: timeReg.employeeID,
        ...getEmployeeRowEmployeeDetails(
          props.employees.get(timeReg.employeeID),
          props.departments,
          props.companyUsers
        ),
        date: formatDate(timeReg.date),
        dateSort: timeReg.date,
        days: t('unit.day_count', { count: timeReg.days || 1 }),
        daysNo: timeReg.days || 1,
        leaveType: leaveLine,
        leaveSubType: subLine,
        original: [timeReg],
      }
    })
    .sort((a, b) => {
      if (a.employeeName === b.employeeName) {
        return a.original[0].date.localeCompare(b.original[0].date)
      }
      return a.employeeName.localeCompare(b.employeeName)
    })
    .reduce((list: LeaveRegistrationRow[], reg: LeaveRegistrationRow) => {
      if (list.length === 0) {
        return [reg]
      }
      const lastIdx = list.length - 1
      const last = list[lastIdx]
      if (
        last.employeeName !== reg.employeeName ||
        last.leaveType !== reg.leaveType ||
        last.leaveSubType !== reg.leaveSubType
      ) {
        return [...list, reg]
      }
      const thisDate = reg.original[0].date
      if (!isSameDay(subDays(getDate(thisDate), 1), getDate(last.original[last.original.length - 1].date))) {
        return [...list, reg]
      }
      last.id.push(reg.id[0])
      last.original.push(reg.original[0])
      const firstDate = last.original[0].date
      last.date = formatDateInterval(getDate(firstDate), getDate(thisDate))
      last.dateSort = firstDate
      last.daysNo = last.original.reduce((days, reg) => days + (reg.days || 1), 0)
      last.days = t('unit.day_count', { count: last.daysNo })
      list[lastIdx] = last
      return list
    }, [])
    .sort((a, b) => {
      let i = a
      let j = b
      if (sort.sortOrder === 'descend') {
        j = a
        i = b
      }
      switch (sort.sortOn) {
        case 'employee':
          if (i.employeeName === j.employeeName) {
            return a.dateSort.localeCompare(b.dateSort)
          }
          return i.employeeName.localeCompare(j.employeeName)
        case 'date':
          if (i.dateSort === j.dateSort) {
            return a.employeeName.localeCompare(b.employeeName)
          }
          return i.dateSort.localeCompare(j.dateSort)
        case 'days':
          if (i.daysNo === j.daysNo) {
            if (i.employeeName === j.employeeName) {
              return a.dateSort.localeCompare(b.dateSort)
            }
            return a.employeeName.localeCompare(b.employeeName)
          }
          return j.daysNo - i.daysNo
        default:
          if (a.employeeName === b.employeeName) {
            return a.dateSort.localeCompare(b.dateSort)
          }
          return a.employeeName.localeCompare(b.employeeName)
      }
    })

  const tableChange = (_1: unknown, _2: unknown, sorter: TableSorter) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'date':
      case 'days':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
      default:
        break
    }
  }

  if (pendingLeaveRegistrations.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="large">
          {t('approve_tab.leave_registrations.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.leave_registrations.title')}</Title>
      <Table columns={columns} dataSource={pendingLeaveRegistrationRows} onChange={tableChange} pagination={false} />
    </div>
  )
}
