import React, { ReactElement, useContext } from 'react'
import { Link } from 'react-router'

import PackageLock from '../../components/widgets/PackageLock'
import { UserReducer } from '../../reducers/user'
import { getAllParentsForCurrentPath, getMainMenuIntegrationMenu, MenuContext } from '../../utils/menu-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

import './SubmenuLayout.css'

type Reducers = {
  user: UserReducer
}

type Props = {
  location: RoutePropsLocation
  children: React.ReactNode
  className?: string
}

function IntegrationsLayout(props: Reducers & Props): ReactElement | null {
  const { menu } = useContext(MenuContext)
  const activeItems = getAllParentsForCurrentPath(props.location.pathname).map((item) => item.id)

  return (
    <div className={'submenu integrations-layout' + (props.className ? ' ' + props.className : '')}>
      {!props.user.user?.uiSettings.sideMenu && (
        <div className="submenu-topbar">
          {getMainMenuIntegrationMenu(menu).map((item) => {
            if (item.type !== 'item-label') {
              return null
            }
            const active = activeItems.includes(item.id)
            if (item.packageLock) {
              return (
                <PackageLock packageGroups={item.packageLock}>
                  <Link key={item.key} to={item.link} className={active ? 'active' : ''}>
                    {t(item.labelID)}
                  </Link>
                </PackageLock>
              )
            }
            return (
              <Link key={item.key} to={item.link} className={active ? 'active' : ''}>
                {t(item.labelID)}
              </Link>
            )
          })}
        </div>
      )}
      <div className="submenu-main">{props.children}</div>
    </div>
  )
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    user: state.user,
  }),
  {}
)(IntegrationsLayout)
