import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import PDFPreviewTypes from '../../../constants/pdf-preview-types'
import Employee from '../../../model/employee'
import EmployeeEmergencyContact from '../../../model/employeeEmergencyContact'
import PaySlip from '../../../model/paySlip'
import ProfileImage from '../../../model/profileImage'
import { EmployeeInviteReducer } from '../../../reducers/employeeInvites'
import { EmployeeProfileImageReducer } from '../../../reducers/employeeProfileImages'
import { EmployeeReducer } from '../../../reducers/employees'
import { FileChangeEvent } from '../../../utils/antd-utils'
import { getAccessToken } from '../../../utils/cookie-utils'
import { formatDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { url } from '../../../utils/request-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import { Dragger } from '../../antd/upload'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/Icon'
import Subtitle from '../../elements/Subtitle'
import UserImage from '../../elements/UserImage'
import EmployeeAppInvite from '../../employees-single/sidebar/EmployeeAppInvite'
import jsBrowserHistory from '../../widgets/jsBrowserHistory'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  section?: string
  subsection?: string
  employee: Employee
  employeeEmergencyContact?: EmployeeEmergencyContact
  employees: EmployeeReducer
  paySlips: List<PaySlip>
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  hasEmployeeEmergencyContactsFeature: boolean

  addAlert: addAlertSignature
  sendInvite: (id: string) => void
  deleteEmployeeUser: (id: string) => void
  updateProfileImage: (employeeID: string, image: ProfileImage) => void
  setDeleteVisibility: (visible: boolean) => void
  setTerminateVisibility: (visible: boolean) => void
  setRehireVisibility: (visible: boolean) => void
}

export default function Sidebar(props: Props): ReactElement | null {
  const [uploading, setUploading] = useState(false)

  const { employeeProfileImages, addAlert } = props
  const previousEmployeeProfileImages = usePrevious(employeeProfileImages)

  useEffect(() => {
    if (previousEmployeeProfileImages && previousEmployeeProfileImages.saving && !employeeProfileImages.saving) {
      if (employeeProfileImages.error) {
        addAlert('error', formatError(employeeProfileImages.error), { timeout: 5 })
      }
    }
  })

  const handleProfileImageUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        if (e.file.response.data) {
          props.updateProfileImage(props.employee.id, {
            type: 'Custom',
            fileID: e.file.response.data.id,
          })
        }
        break
      default:
        break
    }
  }

  const _handleSwitch = (id: string) => {
    let url = '/' + paths.FREELANCERS + '/' + id
    if (props.section) {
      url += '/' + props.section

      if (props.subsection) {
        url += '/' + props.subsection
      }
    }
    jsBrowserHistory.push(url)
  }

  const getEmployees = () => {
    return props.employees.employees.filter((employee) => employee.affiliationType === 'Freelancer')
  }

  const getActions = () => {
    const actions = []
    const employee = props.employee
    if (employee.activeEmployment) {
      actions.push({
        text: t('employee.sidebar.actions.reimbursement'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/reimbursement')
        },
      })
      actions.push({
        text: t('employee.sidebar.actions.pay_check_advance'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/pay-check-advance')
        },
      })
      actions.push({
        text: t('employee.sidebar.actions.salary_reduction'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/salary-reduction')
        },
      })
    }
    actions.push({
      text: t('employee.sidebar.actions.free_text'),
      onClick: () => {
        jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id + '/free-text')
      },
    })
    if (
      employee.employmentStatus === 'Terminated' ||
      (employee.activeEmployment && employee.activeEmployment.endDate)
    ) {
      actions.push({
        text: t('freelancer.sidebar.actions.rehire'),
        onClick: () => {
          props.setRehireVisibility(true)
        },
      })
    }
    if (employee.activeEmployment && !employee.activeEmployment.endDate) {
      actions.push({
        text: t('freelancer.sidebar.actions.terminate'),
        onClick: () => {
          props.setTerminateVisibility(true)
        },
      })
    }
    if (!employee.immutableEndDate) {
      actions.push({
        text: t('freelancer.sidebar.actions.delete'),
        onClick: () => {
          props.setDeleteVisibility(true)
        },
      })
    }
    return actions
  }

  const employee = props.employee
  const emergencyContact = props.employeeEmergencyContact
  const paySlips = props.paySlips.filter((paySlip) => paySlip.settled)
  return (
    <div>
      <div className="employee-switcher">
        <Subtitle>{t('freelancer.sidebar.employee_switcher.title')}</Subtitle>
        <Select
          value={employee.id}
          showSearch={true}
          filterOption={(inputValue: string, option: ReactElement) => {
            const item = option.props.children
            return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }}
          onChange={_handleSwitch}
        >
          {getEmployees().map((v) => {
            return (
              <Select.Option key={v.id} value={v.id}>
                {v.name || v.email}
              </Select.Option>
            )
          })}
        </Select>
      </div>

      <div className="employee-profile">
        <div className="employee-profile-edit">
          <Link to={'/' + paths.FREELANCERS + '/' + employee.id + '/profile'}>
            <Icon type="edit" />
          </Link>
        </div>
        <div className="employee-profile-image">
          <Dragger
            name={'fileData'}
            action={url('v2/stagedFiles')}
            headers={{ authorization: getAccessToken() }}
            accept={'.jpg,.jpeg,.png'}
            showUploadList={false}
            onChange={handleProfileImageUpload}
          >
            <UserImage src={employee.profileImageURL || '/images/avatar-basic-106x106.png'} size="large" />
            <div className="employee-profile-image-hover">
              {employee.profileImageURL
                ? t('employee.sidebar.upload_profile_image.edit')
                : t('employee.sidebar.upload_profile_image.create')}
            </div>
          </Dragger>
          {(uploading || props.employeeProfileImages.saving) && <LoadingOverlay />}
        </div>
        <Subtitle>{employee.name}</Subtitle>
        <p>{employee.earliestMutableContract ? employee.earliestMutableContract.position : ''}</p>

        <EmployeeAppInvite
          employee={employee}
          employeeInvites={props.employeeInvites}
          addAlert={props.addAlert}
          sendInvite={props.sendInvite}
          deleteEmployeeUser={props.deleteEmployeeUser}
        />
      </div>

      <div className="employee-details">
        {!!employee.activeEmployment && (
          <Row>
            <Col span={12}>{t('employee.sidebar.details.employee_number')}</Col>
            <Col span={12}>#{employee.activeEmployment.employeeNumber}</Col>
          </Row>
        )}
        <Row>
          <Col span={8}>{t('employee.sidebar.details.address')}</Col>
          <Col span={16}>{employee.address}</Col>
        </Row>
        <Row>
          <Col span={8}>{t('employee.sidebar.details.city')}</Col>
          <Col span={16}>
            {employee.postalCode} {employee.city}
          </Col>
        </Row>
        {!!employee.email && (
          <Row>
            <Col span={8}>{t('employee.sidebar.details.email')}</Col>
            <Col span={16}>{employee.email}</Col>
          </Row>
        )}
        {!!employee.phoneNumber && (
          <Row>
            <Col span={8}>{t('employee.sidebar.details.phone_number')}</Col>
            <Col span={16}>
              +{employee.phoneNumberCountryCode} {employee.phoneNumber}
            </Col>
          </Row>
        )}
        {props.hasEmployeeEmergencyContactsFeature && emergencyContact && (
          <div>
            <Row>
              <Col span={8}>{t('employee.sidebar.details.emergency_contact')}</Col>
              <Col span={16}>
                {emergencyContact.name}
                {emergencyContact.relation && ' (' + emergencyContact.relation + ')'}
              </Col>
            </Row>
            {emergencyContact.email && (
              <Row>
                <Col span={8} />
                <Col span={16}>{emergencyContact.email}</Col>
              </Row>
            )}
            {emergencyContact.phoneNumber && (
              <Row>
                <Col span={8} />
                <Col span={16}>
                  +{emergencyContact.phoneNumberCountryCode} {emergencyContact.phoneNumber}
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>

      {paySlips.size > 0 && (
        <div className="employee-pay-slips">
          <Subtitle>{t('employee.sidebar.pay_slips')}</Subtitle>
          {paySlips.map((paySlip, i) => {
            if (i > 2) {
              return null
            }
            let grossPay = '-'
            if (paySlip.calculations) {
              paySlip.calculations.forEach((calculation) => {
                if (calculation.type === 'Salary') {
                  grossPay = formatCurrency(calculation.result, 0)
                }
              })
            }
            return (
              <Row key={paySlip.id}>
                <Col span={12}>{formatDate(paySlip.dispositionDate)}</Col>
                <Col span={12}>
                  {grossPay}
                  <Link
                    to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.PAY_SLIP + '/' + paySlip.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="download" color="grey" />
                  </Link>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

      <div className="employee-actions">
        {getActions().map((_, i) => {
          if (i % 2 === 0) {
            return (
              <Row key={i}>
                {getActions()
                  .filter((_, idx) => idx === i || idx === i + 1)
                  .map((action) => {
                    return (
                      <Col span={12} key={action.text}>
                        <Button onClick={action.onClick}>{action.text}</Button>
                      </Col>
                    )
                  })}
              </Row>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
