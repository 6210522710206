import React, { ReactElement, useContext } from 'react'
import { Link } from 'react-router'

import { UserReducer } from '../../reducers/user'
import { getMainMenuAdministrationMenu, MenuContext } from '../../utils/menu-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RoutePropsRoute } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

import './SubmenuLayout.css'

type Reducers = {
  user: UserReducer
}

type Props = {
  route: RoutePropsRoute
  children: React.ReactNode
}

function AdministrationLayout(props: Reducers & Props): ReactElement | null {
  const { menu } = useContext(MenuContext)

  const path = props.route.path.split('/')[0]

  if (props.user.user?.uiSettings.sideMenu) {
    return <>{props.children}</>
  }

  return (
    <div className="submenu">
      <div className="submenu-topbar">
        {getMainMenuAdministrationMenu(menu).map((item) => {
          if (item.type === 'item-employee-selector') {
            return null
          }
          return (
            <Link key={item.key} to={item.link} className={item.link.includes(path) ? 'active' : ''}>
              {t(item.labelID)}
            </Link>
          )
        })}
      </div>
      <div className="submenu-main">{props.children}</div>
    </div>
  )
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    user: state.user,
  }),
  {}
)(AdministrationLayout)
