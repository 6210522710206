import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import CompanyFeature from '../../model/companyFeature'
import CompanyUser from '../../model/companyUser'
import { EmploymentStatus, OnboardingState } from '../../model/employee'
import Employment from '../../model/employment'
import { DateFormat } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { EmployeeReducer } from '../../reducers/employees'
import { EmploymentReducer } from '../../reducers/employments'
import { formatCurrency } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'
import DeleteEmployee from '../employees-single/delete-employee/DeleteEmployee'
import TerminateEmployee from '../employees-single/terminate-employee/TerminateEmployee'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import FeatureLock from '../widgets/FeatureLock'
import jsBrowserHistory from '../widgets/jsBrowserHistory'

import './Freelancers.css'

type Props = {
  alerts: AlertReducer
  employees: EmployeeReducer
  employments: EmploymentReducer
  companyUser?: CompanyUser
  companyFeatures: List<CompanyFeature>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteEmployee: (employeeID: string) => Promise<boolean | void>
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  getEmployments: (employeeID: string) => void
}

export default function Freelancers(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [showWaiting, setShowWaiting] = useState(false)
  const [showTerminated, setShowTerminated] = useState(false)
  const [showTerminate, setShowTerminate] = useState<string | boolean>(false)
  const [showDelete, setShowDelete] = useState<string | boolean>(false)
  const [modalKey, setModalKey] = useState(1)

  const hasEmployeeOnboardingFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Employee Onboarding')
  }

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const setTerminateVisibility = (id: string | boolean) => {
    setShowTerminate(id)
    setModalKey((prev) => prev + 1)
  }
  const setDeleteVisibility = (id: string | boolean) => {
    setShowDelete(id)
    setModalKey((prev) => prev + 1)
  }

  type FreelancerRow = {
    key: string
    id: string
    profileImageURL?: string
    name: string
    payoutThisYear: string
    canHireFire: boolean
    employmentStatus: EmploymentStatus
    immutableEndDate?: DateFormat
    onboardingState: OnboardingState
    noContract: boolean
  }

  const columns = [
    {
      title: t('freelancers.table.header.freelancer'),
      dataIndex: '',
      key: 'xFreelancer',
      render: (freelancer: FreelancerRow) => {
        return (
          <Headline>
            <UserImage src={freelancer.profileImageURL} name={freelancer.name} />
            <Link to={'/' + paths.EMPLOYEES + '/' + freelancer.id + (freelancer.noContract ? '/profile' : '')}>
              {freelancer.name}
            </Link>
          </Headline>
        )
      },
    },
    {
      title: t('freelancers.table.header.payout_this_year'),
      dataIndex: '',
      key: 'xPayoutThisYear',
      render: (freelancer: FreelancerRow) => {
        if (freelancer.noContract) {
          if (freelancer.onboardingState === 'Draft') {
            return (
              <Link
                to={'/' + paths.FREELANCERS + '/' + freelancer.id + '/profile'}
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
              >
                + {t('freelancers.table.fill_in_information')}
              </Link>
            )
          }
          return (
            <Link
              to={'/' + paths.FREELANCERS + '/' + freelancer.id + '/employment'}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              + {t('freelancers.table.add_contract')}
            </Link>
          )
        }
        return freelancer.payoutThisYear
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (freelancer: FreelancerRow) => {
        return (
          <ContextMenu>
            <Link to={'/' + paths.FREELANCERS + '/' + freelancer.id}>
              <Icon type="team" color="grey" /> {t('freelancers.table.actions.details')}
            </Link>
            {freelancer.canHireFire && (
              <Link to={'/' + paths.FREELANCERS + '/' + freelancer.id}>
                <Icon type="edit" color="grey" /> {t('freelancers.table.actions.edit')}
              </Link>
            )}
            {freelancer.canHireFire && freelancer.immutableEndDate && freelancer.employmentStatus !== 'Terminated' && (
              <DumbLink
                onClick={() => {
                  setTerminateVisibility(freelancer.id)
                }}
              >
                <Icon type="user-delete" color="grey" /> {t('freelancers.table.actions.terminate')}
              </DumbLink>
            )}
            {freelancer.canHireFire && !freelancer.immutableEndDate && freelancer.employmentStatus !== 'Terminated' && (
              <DumbLink
                onClick={() => {
                  setDeleteVisibility(freelancer.id)
                }}
              >
                <Icon type="user-delete" color="grey" /> {t('freelancers.table.actions.delete')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    },
  ]

  const getActiveFreelancers = (): FreelancerRow[] => {
    return props.employees.employees
      .filter((employee) => {
        if (employee.affiliationType !== 'Freelancer') {
          return false
        }
        if (employee.employmentStatus === 'Terminated' || employee.onboardingState !== 'Final') {
          return false
        }
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          return !(!pattern.test(employee.name ?? '') && (!employee.nationalID || !pattern.test(employee.nationalID)))
        }
        return true
      })
      .map((freelancer) => {
        return {
          key: freelancer.id,
          id: freelancer.id,
          profileImageURL: freelancer.profileImageURL,
          name: freelancer.name || freelancer.email || '-',
          payoutThisYear: formatCurrency(freelancer.paidOutThisYear),
          canHireFire: hasDepartmentPermission(props.companyUser, freelancer.departmentID, 'HireFire'),
          employmentStatus: freelancer.employmentStatus,
          onboardingState: freelancer.onboardingState,
          noContract: !freelancer.earliestMutableContract,
        }
      })
      .toArray()
  }

  const getAwaitingFreelancers = (): FreelancerRow[] => {
    return props.employees.employees
      .filter((employee) => {
        if (employee.affiliationType !== 'Freelancer') {
          return false
        }
        if (employee.employmentStatus === 'Terminated' || employee.onboardingState !== 'Draft') {
          return false
        }
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          return !(!pattern.test(employee.name ?? '') && (!employee.nationalID || !pattern.test(employee.nationalID)))
        }
        return true
      })
      .map((freelancer) => {
        return {
          key: freelancer.id,
          id: freelancer.id,
          profileImageURL: freelancer.profileImageURL,
          name: freelancer.name || freelancer.email || '-',
          payoutThisYear: formatCurrency(freelancer.paidOutThisYear),
          canHireFire: hasDepartmentPermission(props.companyUser, freelancer.departmentID, 'HireFire'),
          employmentStatus: freelancer.employmentStatus,
          immutableEndDate: freelancer.immutableEndDate,
          onboardingState: freelancer.onboardingState,
          noContract: !freelancer.earliestMutableContract,
        }
      })
      .toArray()
  }

  const getTerminatedFreelancers = (): FreelancerRow[] => {
    return props.employees.employees
      .filter((employee) => {
        if (employee.affiliationType !== 'Freelancer') {
          return false
        }
        if (employee.employmentStatus !== 'Terminated') {
          return false
        }
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          return !(!pattern.test(employee.name ?? '') && (!employee.nationalID || !pattern.test(employee.nationalID)))
        }
        return true
      })
      .map((freelancer) => {
        return {
          key: freelancer.id,
          id: freelancer.id,
          profileImageURL: freelancer.profileImageURL,
          name: freelancer.name || freelancer.email || '-',
          payoutThisYear: formatCurrency(freelancer.paidOutThisYear),
          canHireFire: hasDepartmentPermission(props.companyUser, freelancer.departmentID, 'HireFire'),
          employmentStatus: freelancer.employmentStatus,
          onboardingState: freelancer.onboardingState,
          noContract: !freelancer.earliestMutableContract,
        }
      })
      .toArray()
  }

  const count = getActiveFreelancers().length
  const waitingCount = getAwaitingFreelancers().length
  const terminatedCount = getTerminatedFreelancers().length

  const canHireFire = hasDepartmentPermission(props.companyUser, undefined, 'HireFire')

  return (
    <div className="freelancers">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <Input.Search placeholder={t('employee_filter.search')} onChange={handleSearch} />
        {hasDepartmentPermission(props.companyUser, undefined, 'HireFire') && (
          <FeatureLock featureType={'Freelancers'} description={t('freelancers.header.add_freelancer_lock')}>
            <Link to={'/' + paths.FREELANCERS + '/' + paths.ADD}>
              <Button type="primary" className="gtm-add-employee">
                <Icon type="user-add" />
                {t('freelancers.header.add_freelancer')}
              </Button>
            </Link>
          </FeatureLock>
        )}
        {canHireFire && hasEmployeeOnboardingFeature() && (
          <Link to={'/' + paths.FREELANCERS + '/' + paths.INVITE}>
            <Button className="gtm-invite-employee">{t('freelancers.header.invite_freelancer')}</Button>
          </Link>
        )}
      </TitleMenu>
      <Title>
        {t('freelancers.title.freelancer', { count })}
        {searchQuery && <>{t('freelancers.title.searching_for', { query: searchQuery })}</>}
      </Title>
      <Table
        columns={columns}
        dataSource={getActiveFreelancers()}
        pagination={
          count > 50
            ? {
                defaultCurrent: getPage(),
                defaultPageSize: 50,
                onChange: setPage,
              }
            : false
        }
        onRowClick={(employee: FreelancerRow) => {
          jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id)
        }}
      />
      {waitingCount > 0 && (
        <div>
          <TitleMenu>
            <span
              onClick={() => setShowWaiting((prev) => !prev)}
              className={'employees-toggle' + (showWaiting ? ' employees-toggle-visible' : '')}
            >
              {showWaiting ? t('freelancers.hide') : t('freelancers.show')}
            </span>
          </TitleMenu>
          <Title>{t('freelancers.awaiting.title')}</Title>
          <Table
            columns={columns}
            dataSource={getAwaitingFreelancers()}
            pagination={
              count > 50
                ? {
                    defaultCurrent: getPage(),
                    defaultPageSize: 50,
                    onChange: setPage,
                  }
                : false
            }
            className={'employees-table' + (showWaiting ? ' employees-table-visible' : '')}
            onRowClick={(employee: FreelancerRow) => {
              jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id)
            }}
          />
        </div>
      )}
      {terminatedCount > 0 && (
        <div>
          <TitleMenu>
            <span
              onClick={() => setShowTerminated((prev) => !prev)}
              className={'employees-toggle' + (showTerminated ? ' employees-toggle-visible' : '')}
            >
              {showTerminated ? t('freelancers.hide') : t('freelancers.show')}
            </span>
          </TitleMenu>
          <Title>{t('freelancers.terminated.title')}</Title>
          <Table
            columns={columns}
            dataSource={getTerminatedFreelancers()}
            pagination={
              count > 50
                ? {
                    defaultCurrent: getPage(),
                    defaultPageSize: 50,
                    onChange: setPage,
                  }
                : false
            }
            className={'employees-table' + (showTerminated ? ' employees-table-visible' : '')}
            onRowClick={(employee: FreelancerRow) => {
              jsBrowserHistory.push('/' + paths.FREELANCERS + '/' + employee.id)
            }}
          />
        </div>
      )}

      <Modal
        key={`delete-${modalKey}`}
        visible={showDelete !== false}
        onOk={() => setDeleteVisibility(false)}
        onCancel={() => setDeleteVisibility(false)}
        width={572}
        footer={null}
      >
        <DeleteEmployee
          visible={showDelete !== false}
          employeeID={typeof showDelete !== 'boolean' ? showDelete : undefined}
          employees={props.employees}
          deleteEmployee={props.deleteEmployee}
          addAlert={props.addAlert}
          closeModal={() => setDeleteVisibility(false)}
        />
      </Modal>

      <Modal
        key={`terminate-${modalKey}`}
        visible={showTerminate !== false}
        onOk={() => setTerminateVisibility(false)}
        onCancel={() => setTerminateVisibility(false)}
        width={572}
        footer={null}
      >
        <TerminateEmployee
          visible={showTerminate !== false}
          employeeID={typeof showTerminate !== 'boolean' ? showTerminate : undefined}
          employees={props.employees}
          employments={props.employments}
          getEmployments={props.getEmployments}
          terminateEmployment={props.terminateEmployment}
          addAlert={props.addAlert}
          closeModal={() => setTerminateVisibility(false)}
        />
      </Modal>
    </div>
  )
}
