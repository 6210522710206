import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import {
  AuthParameters,
  DataIntegrationSetup,
  DataIntegrationStep,
  patchCompanyDataIntegrationSetup,
  postCompanyDataIntegrationSetup,
} from '../../api/company-data-integration-setup'
import paths from '../../constants/paths'
import AvailableDataIntegration from '../../model/availableDataIntegration'
import Company from '../../model/company'
import SalaryType from '../../model/salaryType'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyDataIntegrationReducer } from '../../reducers/companyDataIntegration'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { getDataIntegration, setDataIntegration } from '../../utils/cookie-utils'
import { formatError, isRequestError } from '../../utils/error-utils'
import { logWarning } from '../../utils/log-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ConnectionForm, { ParameterConnectionResult } from './ConnectionForm'
import IntegrationTypeForm, { IntegrationTypeResult } from './IntegrationTypeForm'

import './CompanyDataIntegration.css'

function resolveStep(next: DataIntegrationStep) {
  switch (next) {
    case 'NeedKey': {
      return 1
    }
    default: {
      logWarning('Encountered unresolved time registration step: `' + next + '`')
      return 0
    }
  }
}

interface Props {
  query?: Record<string, string>
  alerts: AlertReducer
  company: Company
  availableDataIntegrations: List<AvailableDataIntegration>
  companyDataIntegration: CompanyDataIntegrationReducer
  salaryTypes: List<SalaryType>

  updateCompanyDataIntegration: (state: string) => void
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

interface State {
  state?: string
  saving: boolean
  step: number
  integrationType?: string
  displayName?: string
}

export default function CompanyDataIntegration(props: Props): ReactElement | null {
  const [state, setState] = useState<State>(() => {
    let queryState = undefined
    if (props.query) {
      queryState = props.query['state']
    }
    return {
      state: queryState,
      saving: !!queryState,
      step: queryState ? 1 : 0,
    }
  })
  const [error, setError] = useState<Error | null>(null)

  const { availableDataIntegrations } = props

  const getDisplayName = useCallback(
    (type: string): string => {
      const integration = availableDataIntegrations.find((integration) => integration.type === type)
      return integration ? integration.displayName : t('common.unknown')
    },
    [availableDataIntegrations]
  )

  const _handleError = (e: Error) => {
    if (isRequestError(e)) {
      setError(e)
      setState((prev) => {
        prev.saving = false
        return prev
      })
    } else {
      throw e
    }
  }

  const { company, updateCompanyDataIntegration } = props

  type AuthConnectionResult = {
    readonly step: 'NeedAuth'
    integrationType: string
    authParameters: AuthParameters
  }

  const _handleNext = useCallback(
    (inputValues: IntegrationTypeResult | ParameterConnectionResult | AuthConnectionResult) => {
      setState((prev) => {
        prev.saving = true
        return prev
      })
      switch (state.step) {
        case 0: {
          const values = inputValues as IntegrationTypeResult
          postCompanyDataIntegrationSetup(company.id, {
            type: values.integrationType,
          })
            .then((res) => {
              if (res.data.next === 'NeedAuth') {
                setDataIntegration(values.integrationType)
                document.location = res.data.authURL || ''
              } else {
                setState({
                  state: res.data.state,
                  integrationType: values.integrationType,
                  displayName: getDisplayName(values.integrationType),
                  saving: false,
                  step: resolveStep(res.data.next),
                })
              }
            })
            .catch(_handleError)
          break
        }
        default: {
          const stateValue = state.state
          if (!stateValue) {
            return
          }
          const values = inputValues as ParameterConnectionResult | AuthConnectionResult
          let fields: DataIntegrationSetup = {}
          switch (values.step) {
            case 'NeedAuth':
            case 'NeedKey':
              fields = {
                authParameters: values.authParameters,
              }
              break
          }
          if (stateValue) {
            patchCompanyDataIntegrationSetup(stateValue, fields)
              .then((res) => {
                if (res.data.next === 'Done') {
                  updateCompanyDataIntegration(stateValue)
                } else {
                  setState((prev) => ({
                    integrationType: prev.integrationType,
                    saving: false,
                    step: resolveStep(res.data.next),
                  }))
                }
              })
              .catch(_handleError)
          }
          break
        }
      }
    },
    [state, company, updateCompanyDataIntegration, getDisplayName]
  )

  const { query } = props

  useEffectOnce(() => {
    const integrationType = getDataIntegration()
    if (!integrationType) {
      return
    }
    if (query?.state) {
      const authParameters: AuthParameters = {}
      Object.keys(query).forEach((key) => {
        if (key === 'state') {
          return
        }
        authParameters[key as string] = query[key]
      })
      _handleNext({ step: 'NeedAuth', integrationType, authParameters })
    }
  })

  const { companyDataIntegration, addAlert } = props
  const previousDataIntegration = usePrevious(companyDataIntegration)

  useEffect(() => {
    if (previousDataIntegration && previousDataIntegration.saving && !companyDataIntegration.saving) {
      if (!companyDataIntegration.error) {
        addAlert('success', t('company_data_integration.alert.success'), { timeout: 5 })

        jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.DATA_PROVIDER)
      }
    }

    regularComponentDidUpdate(companyDataIntegration.error, error, setError)
  }, [previousDataIntegration, companyDataIntegration, error, addAlert])

  const _handlePrevious = () => {
    setState((prev) => {
      prev.step = prev.step - 1
      switch (prev.integrationType) {
        case undefined:
          if (prev.step >= 1) {
            prev.step = 0
          }
          break
        default:
          break
      }
      return prev
    })
  }

  const saving = props.companyDataIntegration.saving || state.saving
  return (
    <div className="company-data-integration">
      <Row>
        <Col span={16}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {saving && (
            <div style={{ position: 'relative', minHeight: '400px' }}>
              <LoadingOverlay />
            </div>
          )}
          {!saving && (
            <div className="company-data-integration-overview">
              {error && <Alert message={formatError(error)} type="error" showIcon />}
              {/* Select time registration integration */}
              {state.step === 0 && (
                <Card>
                  <IntegrationTypeForm
                    availableDataIntegrations={props.availableDataIntegrations}
                    integrationType={state.integrationType}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
              {/* Enter API key or username/password */}
              {state.step === 1 && state.integrationType && (
                <Card>
                  <ConnectionForm
                    availableDataIntegrations={props.availableDataIntegrations}
                    integrationType={state.integrationType}
                    {...state}
                    onBack={_handlePrevious}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
            </div>
          )}
        </Col>
        <Col span={8}>
          <Card>
            <Subtitle>{t('company_data_integration.steps.title')}</Subtitle>
            <p>{t('company_data_integration.steps.intro')}</p>

            <Steps direction="vertical" current={state.step}>
              <Steps.Step title={t('company_data_integration.steps.point.0')} />
              <Steps.Step title={t('company_data_integration.steps.point.1')} />
            </Steps>
          </Card>
          <small style={{ textAlign: 'center' }}>
            {tx('company_data_integration.steps.help_note', {
              email: <a href="mailto:support@salary.dk">support@salary.dk</a>,
            })}
          </small>
        </Col>
      </Row>
    </div>
  )
}
