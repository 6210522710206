import { addDays, isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import paths from '../../../constants/paths'
import PDFPreviewTypes from '../../../constants/pdf-preview-types'
import CompanyFeature from '../../../model/companyFeature'
import CompanySetting from '../../../model/companySetting'
import Contract from '../../../model/contract'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import EmployeeEmergencyContact from '../../../model/employeeEmergencyContact'
import PaySlip from '../../../model/paySlip'
import ProfileImage from '../../../model/profileImage'
import SalaryCycle from '../../../model/salaryCycle'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { EmployeeInviteReducer } from '../../../reducers/employeeInvites'
import { EmployeeProfileImageReducer } from '../../../reducers/employeeProfileImages'
import { FileChangeEvent } from '../../../utils/antd-utils'
import { getAccessToken } from '../../../utils/cookie-utils'
import { formatAPIDate, formatDate, getDate } from '../../../utils/date-utils'
import {
  isContractDeltaActive,
  isContractDeltaExpired,
  isContractDeltaFuture,
} from '../../../utils/employee-contract-utils'
import { formatError } from '../../../utils/error-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { url } from '../../../utils/request-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import UploadDragger from '../../antd/upload/Dragger'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/Icon'
import Subtitle from '../../elements/Subtitle'
import UserImage from '../../elements/UserImage'
import jsBrowserHistory from '../../widgets/jsBrowserHistory'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import EmployeeAppInvite from './EmployeeAppInvite'

type Props = {
  section?: string
  subsection?: string
  employee: Employee
  salaryCycle?: SalaryCycle
  employeeContractDeltas: EmployeeContractDeltaReducer
  viewingContract?: Contract
  employeeEmergencyContact?: EmployeeEmergencyContact
  companyFeatures: List<CompanyFeature>
  settingsEnabled: CompanySetting[]
  employees: List<Employee>
  paySlips: List<PaySlip>
  departments: List<Department>
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  canEditObjects: boolean
  canSeeSalaryRates: boolean
  hasFutureContractsFeature: boolean
  hasEmployeeEmergencyContactsFeature: boolean

  addAlert: addAlertSignature
  sendInvite: (id: string) => void
  deleteEmployeeUser: (id: string) => void
  updateProfileImage: (employeeID: string, image: ProfileImage) => void
  setLeaveVisibility: (v: boolean) => void
  setEmployeeContract: (id: string, contractID: string) => void
}

export default function Sidebar(props: Props): ReactElement | null {
  const [uploading, setUploading] = useState(false)

  const { addAlert, employeeProfileImages } = props
  const wasSaving = usePrevious(employeeProfileImages.saving)

  useEffect(() => {
    if (wasSaving && !employeeProfileImages.saving) {
      if (employeeProfileImages.error) {
        addAlert('error', formatError(employeeProfileImages.error), { timeout: 5 })
      }
    }
  }, [wasSaving, employeeProfileImages, addAlert])

  const handleProfileImageUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        if (e.file.response.data) {
          props.updateProfileImage(props.employee.id, {
            type: 'Custom',
            fileID: e.file.response.data.id,
          })
        }
        break
      default:
        break
    }
  }

  const _handleSwitch = (id: string) => {
    let url = '/' + paths.EMPLOYEES + '/' + id
    if (props.section) {
      url += '/' + props.section

      if (props.subsection) {
        url += '/' + props.subsection
      }
    }
    jsBrowserHistory.push(url)
  }

  const _handleContractSwitch = (id: string) => {
    props.setEmployeeContract(props.employee.id, id)
  }

  const hasDocumentStoreFeature = () =>
    props.companyFeatures.some((feature) => feature.featureType === 'Document Store')
  const hasAssetManagementFeature = () =>
    props.companyFeatures.some((feature) => feature.featureType === 'Asset Management')
  const hasEIncomeTextFeature = () => props.settingsEnabled.some((setting) => setting === 'EnableEIncomeTexts')

  const getEmployees = () =>
    props.employees.filter(
      (employee) =>
        employee.affiliationType !== 'Freelancer' &&
        (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed')
    )

  const canCreateOrRemoveLeave = (): boolean => {
    if (!props.viewingContract) {
      return false // wat
    }
    if (props.employee.employmentStatus !== 'OnLeave') {
      return true // can always create leave
    }
    if (!props.employeeContractDeltas.contracts) {
      return true // probably yeah
    }
    let expired = false
    let contractBefore: Contract | undefined = undefined
    const contracts = props.employeeContractDeltas.contracts.toArray()
    for (const i in contracts) {
      const delta = contracts[i]
      if (!props.viewingContract) {
        continue
      }
      if (delta.contractID === props.viewingContract.id) {
        if (isContractDeltaExpired(delta)) {
          expired = true
        }
      }
      if (!delta.contract.validTo) {
        continue
      }
      if (delta.contract.validTo < (props.viewingContract.validTo || formatAPIDate(getDate()))) {
        contractBefore = delta.contract
      }
    }
    if (expired) {
      return false
    }
    // if there is a contract before, and it is right up to this contract, then we cannot remove leave
    if (
      contractBefore &&
      !!contractBefore.validTo &&
      isSameDay(addDays(getDate(contractBefore.validTo), 1), getDate(props.viewingContract.validFrom))
    ) {
      return false
    }
    return true
  }

  const getActions = () => {
    const employee = props.employee
    let actionDefinitions: {
      text: string
      onClick: () => void
      canSeeSalaryRates?: boolean
      canEditObjects?: boolean
    }[] = [
      {
        text: t('employee.sidebar.actions.bonus'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/bonus')
        },
        canSeeSalaryRates: true,
      },
    ]
    if (canCreateOrRemoveLeave()) {
      actionDefinitions.push({
        text:
          employee.employmentStatus === 'OnLeave'
            ? t('employee.sidebar.actions.delete_leave')
            : t('employee.sidebar.actions.give_leave'),
        onClick: () => {
          props.setLeaveVisibility(true)
        },
        canSeeSalaryRates: true,
        canEditObjects: true,
      })
    }
    actionDefinitions = [
      ...actionDefinitions,
      {
        text: t('employee.sidebar.actions.reimbursement'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/reimbursement')
        },
        canSeeSalaryRates: true,
      },
      {
        text: t('employee.sidebar.actions.travel_allowance'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/travel-allowance')
        },
        canSeeSalaryRates: true,
      },
      {
        text: t('employee.sidebar.actions.pay_check_advance'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/pay-check-advance')
        },
        canSeeSalaryRates: true,
      },
      {
        text: t('employee.sidebar.actions.salary_reduction'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/salary-reduction')
        },
        canSeeSalaryRates: true,
      },
    ]
    if (hasDocumentStoreFeature()) {
      actionDefinitions.push({
        text: t('employee.sidebar.actions.documents'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/documents')
        },
        canSeeSalaryRates: true,
      })
    }
    if (hasAssetManagementFeature()) {
      actionDefinitions.push({
        text: t('employee.sidebar.actions.assets'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/assets')
        },
        canEditObjects: true,
      })
    }
    actionDefinitions.push({
      text: t('employee.sidebar.actions.free_text'),
      onClick: () => {
        jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/free-text')
      },
      canSeeSalaryRates: true,
    })
    if (hasEIncomeTextFeature()) {
      actionDefinitions.push({
        text: t('employee.sidebar.actions.e_income_text'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/e-income-text')
        },
        canSeeSalaryRates: true,
        canEditObjects: true,
      })
    }
    if (props.employee.immutableEndDate) {
      actionDefinitions.push({
        text: t('employee.sidebar.actions.balance_adjustments'),
        onClick: () => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/balance-adjustments')
        },
        canSeeSalaryRates: true,
        canEditObjects: true,
      })
    }
    actionDefinitions.push({
      text: t('employee.sidebar.actions.extra_pension'),
      onClick: () => {
        jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/extra-pension')
      },
      canSeeSalaryRates: true,
      canEditObjects: true,
    })
    return actionDefinitions.reduce((actions: { text: string; onClick: () => void }[], definition) => {
      if (definition.canSeeSalaryRates) {
        if (!props.canSeeSalaryRates) {
          return actions
        }
        if (!employee.activeEmployment || !employee.activeContract) {
          return actions
        }
      }
      if (definition.canEditObjects) {
        if (!props.canEditObjects) {
          return actions
        }
      }
      actions.push({ text: definition.text, onClick: definition.onClick })
      return actions
    }, [])
  }

  const employee = props.employee
  const emergencyContact = props.employeeEmergencyContact
  const paySlips = props.paySlips.filter((paySlip) => paySlip.settled)
  const salaryPeriod = props.salaryCycle
    ? getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)
    : undefined
  const currentPaySlip = salaryPeriod && props.paySlips.find((paySlip) => paySlip.salaryPeriodID === salaryPeriod.id)
  return (
    <div>
      {(employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') && (
        <div className="employee-switcher">
          <Subtitle>{t('employee.sidebar.employee_switcher.title')}</Subtitle>
          <Select
            value={employee.id}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              const item = option.props.children
              if (!item) {
                return false
              }
              return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
            onChange={_handleSwitch}
          >
            {getEmployees().map((v) => {
              return (
                <Select.Option key={v.id} value={v.id}>
                  {v.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
      )}

      {props.canEditObjects &&
        props.canSeeSalaryRates &&
        props.hasFutureContractsFeature &&
        (!props.employeeContractDeltas.loaded || props.employeeContractDeltas.employeeID !== props.employee.id ? (
          <div className={'employee-contract-switcher'}>
            <LoadingOverlay text={t('employee.sidebar.loading')} />
          </div>
        ) : props.employeeContractDeltas.contracts.size > 1 ? (
          <div className={'employee-contract-switcher'}>
            <Subtitle>{t('employee.sidebar.contract_switcher.title')}</Subtitle>
            <Select
              value={
                props.viewingContract
                  ? props.viewingContract.id
                  : props.employeeContractDeltas.viewingContract
                  ? props.employeeContractDeltas.viewingContract.id
                  : ''
              }
              onChange={_handleContractSwitch}
            >
              {props.employeeContractDeltas.contracts.reverse().map((delta) => {
                const isActive = isContractDeltaActive(delta)
                const className =
                  'employee-contract-switcher-item ' +
                  (isActive ? 'active' : isContractDeltaFuture(delta) ? 'future' : 'expired')
                return (
                  <Select.Option key={delta.contractID} value={delta.contractID} className={className}>
                    {t(
                      isActive
                        ? 'employee.sidebar.contract_switcher.active'
                        : 'employee.sidebar.contract_switcher.other',
                      { from: formatDate(delta.validFrom), to: delta.validTo ? formatDate(delta.validTo) : '' }
                    )}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
        ) : (
          ''
        ))}

      <div className="employee-profile">
        {props.canEditObjects && (
          <div className="employee-profile-edit">
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/profile'}>
              <Icon type="edit" />
            </Link>
          </div>
        )}
        <div className="employee-profile-image">
          {props.canEditObjects && (
            <UploadDragger
              name={'fileData'}
              action={url('v2/stagedFiles')}
              headers={{ authorization: getAccessToken() }}
              accept={'.jpg,.jpeg,.png'}
              showUploadList={false}
              onChange={handleProfileImageUpload}
            >
              <UserImage src={employee.profileImageURL || '/images/avatar-basic-106x106.png'} size="large" />
              <div className="employee-profile-image-hover">
                {employee.profileImageURL
                  ? t('employee.sidebar.upload_profile_image.edit')
                  : t('employee.sidebar.upload_profile_image.create')}
              </div>
            </UploadDragger>
          )}
          {!props.canEditObjects && (
            <UserImage src={employee.profileImageURL || '/images/avatar-basic-106x106.png'} size="large" />
          )}
          {(uploading || props.employeeProfileImages.saving) && (
            <LoadingOverlay text={t('employee.sidebar.upload_profile_image.uploading')} />
          )}
        </div>
        <Subtitle>{employee.name}</Subtitle>
        <p>{employee.activeContract ? employee.activeContract.position : ''}</p>

        {props.canEditObjects && (
          <EmployeeAppInvite
            employee={employee}
            employeeInvites={props.employeeInvites}
            addAlert={props.addAlert}
            sendInvite={props.sendInvite}
            deleteEmployeeUser={props.deleteEmployeeUser}
          />
        )}
      </div>

      <div className="employee-details">
        {!!employee.activeEmployment && (
          <Row>
            <Col span={12}>{t('employee.sidebar.details.employee_number')}</Col>
            <Col span={12}>#{employee.activeEmployment.employeeNumber}</Col>
          </Row>
        )}
        <Row>
          <Col span={8}>{t('employee.sidebar.details.address')}</Col>
          <Col span={16}>{employee.address}</Col>
        </Row>
        <Row>
          <Col span={8}>{t('employee.sidebar.details.city')}</Col>
          <Col span={16}>
            {employee.postalCode} {employee.city}
          </Col>
        </Row>
        {!!employee.email && (
          <Row>
            <Col span={8}>{t('employee.sidebar.details.email')}</Col>
            <Col span={16}>{employee.email}</Col>
          </Row>
        )}
        {!!employee.phoneNumber && (
          <Row>
            <Col span={8}>{t('employee.sidebar.details.phone_number')}</Col>
            <Col span={16}>
              +{employee.phoneNumberCountryCode} {employee.phoneNumber}
            </Col>
          </Row>
        )}
        {!!props.viewingContract && (
          <Row>
            <Col span={8}>{t('employee.sidebar.details.position')}</Col>
            <Col span={16}>{props.viewingContract.position}</Col>
          </Row>
        )}
        {props.departments.size > 0 && (
          <Row>
            <Col span={8}>{t('employee.sidebar.details.department')}</Col>
            <Col span={16}>
              {props.departments.find((department) => department.id === props.employee.departmentID)?.name ?? (
                <i>{t('employee.sidebar.details.department.none')}</i>
              )}
            </Col>
          </Row>
        )}
        {props.canEditObjects && salaryPeriod && (
          <Row>
            <Col span={12}>{t('employee.sidebar.current_pay_slip.title')}</Col>
            <Col span={12}>
              {currentPaySlip && (
                <Link
                  to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.PAY_SLIP + '/' + currentPaySlip.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button size="small">
                    <Icon type="download" color="grey" />
                    {t('employee.sidebar.current_pay_slip.download')}
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
        )}
        {props.hasEmployeeEmergencyContactsFeature && emergencyContact && (
          <div>
            <Row>
              <Col span={8}>{t('employee.sidebar.details.emergency_contact')}</Col>
              <Col span={16}>
                {emergencyContact.name}
                {emergencyContact.relation && ' (' + emergencyContact.relation + ')'}
              </Col>
            </Row>
            {emergencyContact.email && (
              <Row>
                <Col span={8} />
                <Col span={16}>{emergencyContact.email}</Col>
              </Row>
            )}
            {emergencyContact.phoneNumber && (
              <Row>
                <Col span={8} />
                <Col span={16}>
                  +{emergencyContact.phoneNumberCountryCode} {emergencyContact.phoneNumber}
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>

      {props.canEditObjects && paySlips.size > 0 && (
        <div className="employee-pay-slips">
          <Subtitle>{t('employee.sidebar.pay_slips')}</Subtitle>
          {paySlips.map((paySlip, i) => {
            if (i > 2) {
              return null
            }
            let grossPay = '-'
            if (paySlip.calculations) {
              paySlip.calculations.forEach((calculation) => {
                if (calculation.type === 'Salary') {
                  grossPay = formatCurrency(calculation.result, 0)
                }
              })
            }
            return (
              <Row key={paySlip.id}>
                <Col span={12}>{formatDate(paySlip.dispositionDate)}</Col>
                <Col span={12}>
                  {grossPay}
                  <Link
                    to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.PAY_SLIP + '/' + paySlip.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="download" color="grey" />
                  </Link>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

      <div className="employee-actions">
        {getActions().map((_, i) => {
          if (i % 2 === 0) {
            return (
              <Row key={i}>
                {getActions()
                  .filter((_, idx) => idx === i || idx === i + 1)
                  .map((action) => {
                    return (
                      <Col span={12} key={action.text}>
                        <Button onClick={action.onClick}>{action.text}</Button>
                      </Col>
                    )
                  })}
              </Row>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
