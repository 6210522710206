import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import LeaveType from '../../../model/leaveType'
import TimeRegistration from '../../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { VacationCalendarReducer } from '../../../reducers/vacationCalendars'
import { formatLeaveTypeName } from '../../../utils/format-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { decapitalise } from '../../../utils/string-utils'
import { t } from '../../../utils/translation-utils'
import Calendar from '../../elements/calendar'

type Props = {
  company: Company
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationFilter: (reg: TimeRegistration) => boolean
  vacationCalendars: VacationCalendarReducer
  leaveTypes: List<LeaveType>
  setEditVisibility: (id: string | boolean, date?: Date) => void

  getVacationCalendarYear: (companyID: string, year: number) => void
}

export default function LeaveTabCalendar(props: Props): ReactElement | null {
  const getLeaveTypeByID = (id?: string) => props.leaveTypes.find((t) => t.id === id)

  return (
    <>
      <Calendar.Single
        company={props.company}
        timeRegistrations={props.timeRegistrations.timeRegistrations}
        timeRegistrationFilter={props.timeRegistrationFilter}
        vacationCalendars={props.vacationCalendars}
        formatTimeRegistration={(reg) => {
          const leaveType = getLeaveTypeByID(reg.leaveTypeID)
          let title = t('common.unknown')
          if (leaveType) {
            title =
              formatDisplayNumber(reg.days ?? 0) +
              ' ' +
              decapitalise(formatLeaveTypeName(leaveType.name, (reg.days ?? 0) !== 1))
          }
          return title
        }}
        onEditRegistration={(id) => props.setEditVisibility(id)}
        onNewRegistration={(date) => props.setEditVisibility(true, date)}
        getVacationCalendarYear={props.getVacationCalendarYear}
      />
    </>
  )
}
